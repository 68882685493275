// select RANG_MODALISTE, LABEL_MODALISTE from Modaliste.MODALISTE where MODALISTE_ID = 'TYUT'

export const RULE = {
  SUPERVISEUR_DOMAINE: 1,
  TECHNICIEN_ADMIN: 2,
  TECHNICIEN_COOP: 3,
  CONTACT_ELEVEUR: 4,
};

export const LIMIT = {
  MIN_KENDO_DATEPICKER_YEAR: 1900,
};
