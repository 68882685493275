import { useQueryClient } from "@tanstack/react-query";
import React from "react";



/**
 * 
 * @typedef {Object} LigneProps
 * @property {string} nom
 * @property {string} valeur
 * @property {string} titre
 * @property {string} alinea
 * @param {LigneProps} props {@link LigneProps}
 */
function Ligne ({nom, valeur, titre=false, alinea=false})
{
  return (
      <div className="ligne ml-5 pa-top-bottom">
        <div className={`intitule-ligne ${titre ? "fb " : ""} ${alinea ? "ml-5 " : ""}`}>{nom}</div>
        <div className={`valeur-ligne ${titre ? "fb " : ""}`}>{valeur?.toLocaleString()}</div>
      </div>
    )
}


/**
 * @typedef {Object} Donnee
 * @property {string} Nom
 * @property {string} Valeur
 * @property {boolean} Titre
 * @property {boolean} Alinea
 * 
 * 
 * @typedef {Object} BlocProps
 * @property {string} titre
 * @property {string} position
 * @property {string} style
 * @property {Array<Donnee>} donnees
 * @param {BlocProps} props {@link BlocProps}
 */
const Bloc = ({titre, position, style="", donnees}) => 
{
  const Lignes = donnees.map(
    ligne => <Ligne key={ligne.Nom} nom={ligne.Nom} valeur={ligne.Valeur} titre={ligne.Titre} alinea={ligne.Alinea} />
  );
  return (
    <div className={position}>
      {titre && <p className="titre">{titre}</p>}
      <div className={`${titre ? "border-t " : ""} ${style}`}>
        {Lignes}
      </div>
    </div>
  )
}

/**
 * @typedef {Object} BlocProps
 * @property {string} type
 * @property {Array<string>} donnees
 * @param {BlocProps} props {@link BlocProps}
 */
const BlocMarge = ({type, donnees}) => 
{
  const titre = type === "Brute" ? "Brute" : "Alimentaire";

  return (
    <>
      <div className="bloc-gd">
        <div className="ligne-marge ml-40 border-t border-b border-l">
          <div className="fb ml-5">{`Marge ${titre}`}<br/>Totale :</div>
          <div className="mr-10">{donnees[`Marge${type}Tot`]?.toLocaleString()}</div>
        </div>
        <div className="ligne-marge border-t border-b border-l">
          <div className="ml-5">Par chèvre :</div>
          <div className="mr-10">{donnees[`Marge${type}ParChev`]?.toLocaleString()}</div>
        </div>
        <div className="ligne-marge border-t border-b border-l">
          <div className="ml-5">Pour 1000L :</div>
          <div className="mr-10">{donnees[`Marge${type}MilleL`]?.toLocaleString()}</div>
        </div>
        <div className="ligne-marge mr-40 border-r border-t border-b border-l">
          <div className="ml-5">Par ha SFP caprine :</div>
          <div className="mr-10">{donnees[`Marge${type}HaSfpCapr`]?.toLocaleString()}</div>
        </div>
      </div>
    </>
  )
}


/**
 * @typedef {Object} Page1Props
 * @param {Page1Props} props {@link Page1Props}
 */
export const Page1 = () => {
    const queryClient = useQueryClient();
    const CriteresAnnuel =
    /** @type {Array<string> | undefined} */ (
      queryClient.getQueryData(["donneesAnnuelles", "CriteresAnnuels"])
    );

  return (
    <>
        <div className="pdf-annuel-page1">
        <div className="bloc">
          <p className="titre">Résultats techniques</p>
          <div className="bloc-gd">
            <Bloc titre="Données générales" position="bloc-gauche border-t" donnees={[
            {Nom:"Effectif moyen chèvre", Valeur:CriteresAnnuel.ChevresEffectifMoyen, Titre: true, Alinea:false},
            {Nom:"% réformes de chèvres", Valeur:CriteresAnnuel.PcentRefChev, Titre: false, Alinea:true},
            {Nom:"% mortalité chèvres", Valeur:CriteresAnnuel.PcentMortChev, Titre: false, Alinea:true},
            {Nom:"Nombres de mises-bas", Valeur:CriteresAnnuel.NbTotMisesBasAn, Titre: false, Alinea:false},
            {Nom:"% lactations longues", Valeur:CriteresAnnuel.PcentLacLong, Titre: false, Alinea:true},
            {Nom:"% mises-bas primipares", Valeur:CriteresAnnuel.PcentMisesBasPrim, Titre: false, Alinea:true},
            {Nom:"% chèvres traites", Valeur:CriteresAnnuel.PcentChevTrait, Titre: false, Alinea:true},
            {Nom:"% chevrettes élevées / effectif chèvres (au 31/07)", Valeur:CriteresAnnuel.PcentChet, Titre: false, Alinea:true},
            {Nom:"% mortalité de chevreaux", Valeur:CriteresAnnuel.PcentChevreauxPerte, Titre: false, Alinea:true}
              ]}/>

            <Bloc titre="Lait" position="bloc-droit border-t" style="border-b" donnees={[
            {Nom:"Lait vendu laiterie (en litres)", Valeur:CriteresAnnuel.Cum12QteLaitPaye, Titre: true, Alinea:false},
            {Nom:"Lait divers", Valeur:CriteresAnnuel.Cum12LaitDiv, Titre: false, Alinea:false},
            {Nom:"Lait produit par chèvre présente", Valeur:CriteresAnnuel.QteLaitProdParChev, Titre: true, Alinea:false},
            {Nom:"Taux butyreux", Valeur:CriteresAnnuel.DMTB, Titre: false, Alinea:true},
            {Nom:"Taux protéique", Valeur:CriteresAnnuel.DMTP, Titre: false, Alinea:true},
            {Nom:"% de lait d'octobre à décembre", Valeur:CriteresAnnuel.PcentLaitPayeOctNovDec, Titre: false, Alinea:true},
            {Nom:"% de lait en bonne qualité", Valeur:CriteresAnnuel.DM12MoisProductionQuantiteLaitPayeSansPenalitePrc, Titre: false, Alinea:true}
            ]}/>
          </div>

          

          <div className="bloc-gd">
            <Bloc titre="Assolement" position="bloc-gauche border-t border-b" donnees={[
              {Nom:"S.A.U (ha)", Valeur:CriteresAnnuel.AsSauHa, Titre: false, Alinea:false},
              {Nom:"S.F.P troupeau captrin (ha)", Valeur:CriteresAnnuel.SurfTroupCaprinHa, Titre: false, Alinea:false},
              {Nom:"Nbre chèvres / ha S.F.P Caprine", Valeur:CriteresAnnuel.NbChevHaSfpCaprin, Titre: false, Alinea:false},
              {Nom:"UMO troupeau caprin", Valeur:CriteresAnnuel.MoTroupCap, Titre: false, Alinea:false}
              ]}/>
            
            <Bloc titre="Alimentation des chèvres" position="bloc-droit border-t border-b" donnees={[
              {Nom:"Fourrages (kgMS/Ch/an)", Valeur:CriteresAnnuel.QteTotFourMSChevCorrecParChev, Titre: false, Alinea:false},
              {Nom:"Aliments simples (kg/Ch/an)", Valeur:CriteresAnnuel.QteTotASChevCorrecParChev, Titre: false, Alinea:false},
              {Nom:"Aliments composés (kg/Ch/an)", Valeur:CriteresAnnuel.QteTotACChevCorrecParChev, Titre: false, Alinea:false},
              {Nom:"Aliments minéraux (kg/Ch/an)", Valeur:CriteresAnnuel.QteTotAMChevCorrecParChev, Titre: false, Alinea:false}
              ]}/>
          </div>
        </div>

        <div className="bloc">
          <p className="titre">Résultats économiques</p>
          <div className="bloc-gd">
            <Bloc titre="Produits" position="bloc-gauche border-t" style="border-b" donnees={[
            {Nom:"+ Recette lait laiterie", Valeur:CriteresAnnuel.RecetteLaitLaiterie, Titre: true, Alinea:false},
            {Nom:"Recette moyenne laiterie 1 000 l", Valeur:CriteresAnnuel.RecetteMoyLaiterie1000L, Titre: false, Alinea:true},
            {Nom:"+ Autre produit lait", Valeur:CriteresAnnuel.Cum12AutreProdLait, Titre: false, Alinea:false},
            {Nom:"+ Ventes de chevreaux", Valeur:CriteresAnnuel.Cum12ChevreauxMt, Titre: false, Alinea:false},
            {Nom:"Prix moyen 4 jours", Valeur:"(" + CriteresAnnuel.ChevreauxVenteNombre + ") " + CriteresAnnuel.DMMouvementsChevreauxVenteValeurHTPU, Titre: false, Alinea:true},            
            {Nom:"Prix moyen engraissés", Valeur:"(" + CriteresAnnuel.ChevreauxEngraissesNombrePaye + ") " + CriteresAnnuel.DMMouvementsChevreauxEngraissesValeurHTPU, Titre: false, Alinea:true},
            {Nom:"+ Vente de réformes", Valeur:CriteresAnnuel.DMMouvementsChevresBoucsVenteValeurHT, Titre: false, Alinea:false},
            {Nom:"Prix moyen des réformes", Valeur:"(" + CriteresAnnuel.ReformesNombrePaye + ") " + CriteresAnnuel.DMMouvementsChevresBoucsVenteValeurHTPU, Titre: false, Alinea:true},
            {Nom:"+ Vente de reproducteurs", Valeur:CriteresAnnuel.Cum12ReprodVenteValeur, Titre: false, Alinea:false},
            {Nom:"- Achat d'animaux (à soustraire)", Valeur:CriteresAnnuel.AchatAx, Titre: false, Alinea:false},
            {Nom:"+ Variation d'inventaire animaux", Valeur:CriteresAnnuel.VariationInventAx, Titre: false, Alinea:false},
            {Nom:"+ Fourrages vendus", Valeur:CriteresAnnuel.TotPrFourVendMt, Titre: false, Alinea:false},
            {Nom:"+ Total autres produits", Valeur:CriteresAnnuel.TotAutreProd, Titre: false, Alinea:false}
              ]}/>
            
            <Bloc titre="Charges" position="bloc-droit border-t" donnees={[
            {Nom:"+ Alimentation chèvres", Valeur:CriteresAnnuel.CoutTotAlimChev, Titre: true, Alinea:false},
            {Nom:"Aliments simples chèvres", Valeur:CriteresAnnuel.CoutTotASChevCorrec, Titre: false, Alinea:true},
            {Nom:"Aliments composés chèvres", Valeur:CriteresAnnuel.CoutTotACChevCorrec, Titre: false, Alinea:true},
            {Nom:"Aliments minéraux chèvres", Valeur:CriteresAnnuel.CoutTotAMChevCorrec, Titre: false, Alinea:true},
            {Nom:"Fourrages achetés", Valeur:CriteresAnnuel.TotCfFourAchMt, Titre: false, Alinea:true},
            {Nom:"+ Frais d'élevage", Valeur:CriteresAnnuel.FeTot, Titre: true, Alinea:false},
            {Nom:"Coût aliments d'allaitement", Valeur:CriteresAnnuel.FeAlimAllaitTot, Titre: false, Alinea:true},
            {Nom:"Coût aliments chevrettes", Valeur:CriteresAnnuel.CoutTotAlimChet, Titre: false, Alinea:true},
            {Nom:"Coût aliments boucs ", Valeur:CriteresAnnuel.CoutTotAlimBouc, Titre: false, Alinea:true},
            {Nom:"Oligo, vitamines, hépato", Valeur:CriteresAnnuel.FeOligoTot, Titre: false, Alinea:true},
            {Nom:"Frais vétérinaires", Valeur:CriteresAnnuel.FeVetTotMt, Titre: false, Alinea:true},
            {Nom:"Frais reproduction", Valeur:CriteresAnnuel.FeReproTot, Titre: false, Alinea:true},
            {Nom:"Contrôle laitier", Valeur:CriteresAnnuel.FeCtrlLaitTot, Titre: false, Alinea:true},
            {Nom:"Hygiène", Valeur:CriteresAnnuel.FeHygTot, Titre: false, Alinea:true},
            {Nom:"Entretien petit matériel", Valeur:CriteresAnnuel.FeEntrMatTot, Titre: false, Alinea:true},
            {Nom:"Services", Valeur:CriteresAnnuel.FeEcornTot, Titre: false, Alinea:true},
            {Nom:"Paille litière", Valeur:CriteresAnnuel.FePailleTot, Titre: false, Alinea:true},
            {Nom:"Divers", Valeur:CriteresAnnuel.FeDivTot, Titre: false, Alinea:true},
            {Nom:"+ Frais de cultures", Valeur:CriteresAnnuel.FcTot, Titre: true, Alinea:false},
            {Nom:"Frais semences", Valeur:CriteresAnnuel.FcSemTot, Titre: false, Alinea:true},
            {Nom:"Frais traitements", Valeur:CriteresAnnuel.FcTraitTot, Titre: false, Alinea:true},
            {Nom:"Frais fertilisation", Valeur:CriteresAnnuel.FcFertTot, Titre: false, Alinea:true},
            {Nom:"Bâches, conservateurs, ficelles", Valeur:CriteresAnnuel.FeBacheTot, Titre: false, Alinea:true}
            ]}/>
          </div>
        </div>


        <div className="bloc-gd">
            <Bloc titre="" position="bloc-gauche border-t" donnees={[
            {Nom:"Charges / Produits en % ", Valeur:CriteresAnnuel.PcentChargProd, Titre: false, Alinea:false}
            ]}/>
            <Bloc titre="" position="bloc-droit border-t" donnees={[
            {Nom:"+ Total autres charges", Valeur:CriteresAnnuel.FdAutreCharg, Titre: true, Alinea:false}
            ]}/>
        </div>

        <div className="bloc-gd">
            <Bloc titre="" position="bloc-gauche border-t" donnees={[
            {Nom:"PRODUIT TOTAL ", Valeur:CriteresAnnuel.PrTot, Titre: false, Alinea:false},
            {Nom:"PRODUIT / CHEVRE ", Valeur:CriteresAnnuel.PrParChev, Titre: false, Alinea:true}
            ]}/>
            <Bloc titre="" position="bloc-droit border-t" donnees={[
            {Nom:"CHARGES TOTALES ", Valeur:CriteresAnnuel.ChargOpTot, Titre: false, Alinea:false},
            {Nom:"CHARGES / CHEVRE ", Valeur:CriteresAnnuel.ChargOpParChev, Titre: false, Alinea:true}
            ]}/>
        </div>
        
        <BlocMarge type="Brute" donnees={CriteresAnnuel}/>
        <Spacer width={"2mm"} height={"2mm"} />
        <BlocMarge type="Alim" donnees={CriteresAnnuel}/>

      </div>
    </>
  )
}


/**
 * @typedef {Object} SpacerProps
 * @property {number | string} [width]
 * @property {number | string} [height]
 * @param {SpacerProps} props
 */
const Spacer = ({ width, height }) => <div style={{ width, height }} />;
