import { Component } from "react";
import PropTypes from "prop-types";
import CustomGrid from "../../../shared/CustomGrid";

class Groupes extends Component {
  columns = [
    {
      title: "Domaine",
      type: "text",
      field: "coopLibLong",
      width: 100,
    },
    {
      title: "Code",
      type: "text",
      field: "code",
      width: 70,
    },
    {
      title: "Nom groupe",
      type: "text",
      field: "nom",
      width: 100,
    },
    {
      title: "Ordre mensuel",
      type: "text",
      field: "ordreMensuel",
      width: 100,
    },
    {
      title: "Ordre annuel",
      type: "text",
      field: "ordreAnnuel",
      width: 70,
    },
    {
      title: "Défaut",
      type: "text",
      field: "defaut",
      width: 100,
    },
    {
      title: "Date début",
      type: "text",
      field: "dateDebut",
      width: 100,
    },
    {
      title: "Date fin",
      type: "text",
      field: "dateFin",
      width: 100,
    },
  ];

  render() {
    const { valueGetter } = this.props;
    const groupes = valueGetter("groupes") || [];

    return (
      <CustomGrid
        columns={this.columns}
        style={{ maxHeight: "60vh" }}
        dataGrid={groupes}
        className="grid-admin"
      />
    );
  }
}

Groupes.propTypes = {
  valueGetter: PropTypes.func.isRequired,
};

export default Groupes;
