import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { unique } from "../../shared/ReportViewer/utils";

/**
 * @typedef {Object} FluxGroupeNumExplProps
 * @property {import('../ReportViewer').ReportParameter} params
 * @param {FluxGroupeNumExplProps} props {@link FluxGroupeNumExplProps}
 */
export const FluxGroupeNumExpl = ({ params }) => {
  return (
    <div style={{ display: "flex" }}>
      <table>
        <thead>
          <tr>
            <th className="border-x border-y" rowSpan={2}>
              Num. Expl.
            </th>
            <th
              style={{ height: "1cm" }}
              className="border-r border-y"
              colSpan={3}
            >
              Chèvres laitières
            </th>
            <th className="border-r border-t">Lait/jour/chèvre traite</th>
            <th className="border-r border-t">Fourrages</th>
            <th className="border-r border-t">Aliments simples</th>
            <th className="border-r border-t">Aliments composés</th>
            <th className="border-r border-t">Ingestion totale</th>
            <th className="border-r border-t">Coût alimentaire total</th>
          </tr>
          <tr>
            <th style={{ height: "2cm" }} className="border-b border-r">
              Effectif moyen de chèvres
            </th>
            <th className="border-b border-r">Nombre de chèvres traites</th>
            <th className="border-b border-r">Nombre de mises bas</th>
            <th className="border-b border-r">Litres</th>
            <th className="border-b border-r">Kg MS/ch/j</th>
            <th className="border-b border-r">g/ch/j</th>
            <th className="border-b border-r">g/ch/j</th>
            <th className="border-b border-r">Kg MS/ch/j</th>
            <th className="border-b border-r">€/ch/j</th>
          </tr>
        </thead>
        <tbody>
          <Rows
            renderRow={(row, i) => <span style={{fontWeight:row.Highlight ? 'bold' : 'normal'}}>{i === 0 ? row.NumExploit : row.Valeur}</span>}
            reportKey="ListFluxGroupeNumExpl"
            params={params}
          />
        </tbody>
      </table>
      <Spacer width={"2mm"} />
      <table>
        <thead>
          <tr>
            <th
              style={{ height: "1cm" }}
              className="border-x border-t border-b border-b-transparent"
            >
              conc.+des.+ total ss prod.
            </th>
            <th className="border-r border-t">Coût alim. total</th>
          </tr>
          <tr>
            <th style={{ height: "2cm" }} className="border-b border-x">
              €/1000 L
            </th>
            <th className="border-b border-r">€/1000 L</th>
          </tr>
        </thead>
        <tbody>
          <Rows
            renderRow={(row) => <span style={{fontWeight:row.Highlight ? 'bold' : 'normal'}}>{row.Valeur}</span>}
            reportKey="ListFluxGroupeCout"
            params={params}
          />
        </tbody>
      </table>
      <Spacer width={"2mm"} />
      <table>
        <thead>
          <tr>
            <th
              style={{ height: "1cm" }}
              colSpan={4}
              className="border-x border-t border-b border-b-transparent"
            >
              Paie du mois
            </th>
          </tr>
          <tr>
            <th style={{ height: "2cm" }} className="border-b border-x">
              Prix du lait
              <br />
              €/1000 L
            </th>
            <th className="border-b border-r">Points de Pénalités</th>
            <th className="border-b border-r">Taux butyreux</th>
            <th className="border-b border-r">Taux protéique</th>
          </tr>
        </thead>
        <tbody>
          <Rows
            renderRow={(row) => <span style={{fontWeight:row.Highlight ? 'bold' : 'normal'}}>{row.Valeur}</span>}
            reportKey="ListFluxGroupePaieDuMois"
            params={params}
          />
        </tbody>
      </table>
    </div>
  );
};

/**
 * @typedef {Object} RowsProps
 * @property {import('../ReportViewer').ReportParameter} params
 * @property {keyof import('../ReportViewer').Report} reportKey
 * @property {(row: import('../ReportViewer').FluxGroupeNumExpl, index: number) => string | number} renderRow
 * @param {RowsProps} props {@link RowsProps}
 */
const Rows = ({ params, reportKey, renderRow }) => {
  const queryClient = useQueryClient();
  const rows =
    /** @type {Array<import('../ReportViewer').FluxGroupeNumExpl>} */ (
      queryClient.getQueryData([
        "donneesMensuelles",
        "RowGroupeNumExpl",
        reportKey,
        params,
      ]) || []
    );
  const exploits = unique(rows, "NumExploit");

  return (
    <>
      {exploits.map((numExploit, i) => (
        <Row
          renderRow={renderRow}
          row={rows}
          numExploit={numExploit}
          key={numExploit}
          rowClassName={i === exploits.length - 1 ? "border-y" : ""}
        />
      ))}
    </>
  );
};

/**
 * @typedef {Object} RowProps
 * @property {string} [rowClassName]
 * @property {Array<import('../ReportViewer').FluxGroupeNumExpl>} row
 * @property {string} numExploit
 * @property {(row: import('../ReportViewer').FluxGroupeNumExpl, index: number) => string | number} renderRow
 * @param {RowProps} props {@link RowProps}
 */
const Row = ({ row, numExploit, rowClassName, renderRow }) => {
  return (
    <tr className={rowClassName}>
      {row
        .filter(({ NumExploit }) => NumExploit === numExploit)
        .map((row, i) => (
          <td
            key={i}
            align="center"
            className={`${i === 0 ? "border-x" : "border-r"}`}
          >
            {renderRow(row, i)}
          </td>
        ))}
    </tr>
  );
};

/**
 * @typedef {Object} SpacerProps
 * @property {number | string} [width]
 * @property {number | string} [height]
 * @param {SpacerProps} props
 */
const Spacer = ({ width, height }) => <div style={{ width, height }} />;
