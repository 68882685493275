import toastService from "../utils/toastService";
import { msalConfig } from "../Azure.js";

const { PUBLIC_URL } = process.env;
const fetchData = async (url, params) => {
  const { method, headers, body } = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": "FR-fr",
      "Authorization": localStorage.getItem("idToken"),
    },
    ...params,
  };
  const urlSent = PUBLIC_URL + url;
  const response = await fetch(urlSent, { method, headers, body });
  if (!response.ok) {
    let errorMessage;
    if (headers["Content-Type"] === "application/json") {
      const j = await response.json();
      errorMessage = j.message;
    } else {
      errorMessage = await response.text();
    }
    if (errorMessage !== undefined && errorMessage !== "") {
      const error = new Error(errorMessage);
      error.status = response.status;
      if (error.status === 401) {
        if (
          msalConfig !== null &&
          msalConfig.auth !== null &&
          msalConfig.auth.postLogoutRedirectUri !== null &&
          msalConfig.auth.postLogoutRedirectUri !== ""
        ) {
          window.location.assign(msalConfig.auth.postLogoutRedirectUri);
        } else {
          window.location.assign("/login");
        }
      }
      toastService.addToast({
        id: "request-forbidden",
        type: "error",
        message: errorMessage,
      });
      // throw error;
      return [];
    } else {
      return [];
    }
  }
  return headers["Content-Type"] === "application/json"
    ? response.json()
    : response.blob();
};

const loadDonneesMensuelles = async (params = {}) => {
  const urlParams = new URLSearchParams(params);
  return fetchData(`/api/donneesMensuelles?${urlParams}`);
};

const loadDonneeMensuelleById = async (id) => {
  return fetchData(`/api/donneesMensuelles/${id}`);
};

const loadDonneeAnnuelleById = async (id) => {
  return fetchData(`/api/donneesAnnuelles/${id}`);
};

const updateDonneeAnnuelleById = async (id, data) => {
  return fetchData(`/api/donneesAnnuelles/${id}`, {
    method: "PUT",
    body: JSON.stringify({
      ...data,
    }),
  });
};

const addDonneeAnnuelle = async (data) => {
  return fetchData(`/api/donneesAnnuelles`, {
    method: "POST",
    body: JSON.stringify({ ...data, donAnId: 0 }),
  });
};

const updateDonneeMensuelleById = async (id, data) => {
  return fetchData(`/api/donneesMensuelles/${id}`, {
    method: "PUT",
    body: JSON.stringify({
      ...data,
    }),
  });
};

const generateDonneesMensuelles = async (domaineId, moisDep, anneeDep) => {
  const urlParams = new URLSearchParams({ domaineId, moisDep, anneeDep });
  return fetchData(`/api/donneesMensuelles/generation?${urlParams}`, {
    method: "POST",
  });
};

const loadGroupes = async (params = {}) => {
  const urlParams = new URLSearchParams(params);
  return fetchData(`/api/groupes?${urlParams}`);
};

const loadElevages = async (params = {}) => {
  const urlParams = new URLSearchParams(params);
  return fetchData(`/api/elevagesByGroupe?${urlParams}`);
};

const loadStatuts = async () => {
  return fetchData(`/api/modaliste/STATUT`);
};
const loadCampagnes = async (params = {}) => {
  const urlParams = new URLSearchParams(params);
  return fetchData(`/api/donneesAnnuelles/campagneGroupes?${urlParams}`);
};

const loadGroupesDonneesAnnuelles = async (params = {}) => {
  const urlParams = new URLSearchParams(params);
  return fetchData(`/api/donneesAnnuelles/groupes?${urlParams}`);
};

const loadEleveursGroupes = async (params = {}) => {
  const urlParams = new URLSearchParams(params);
  return fetchData(`/api/donneesAnnuelles/eleveurs?${urlParams}`);
};

const loadEleveurDonneesAnnuelles = async (id = null) => {
  return fetchData(`/api/donneesAnnuelles/societeAgricoles/${id}`);
};

const loadCategoriesAliments = async () => {
  return fetchData(`/api/categorieAliments`);
};

const loadTypesAliments = async () => {
  return fetchData(`/api/modaliste/ALTY`);
};

const loadGroupeAliments = async (groupeId) => {
  return fetchData(`/api/groupes/${groupeId}/aliments`);
};

const createAliment = async (groupeId, aliment) => {
  return fetchData(`/api/groupes/${groupeId}/aliments`, {
    method: "POST",
    body: JSON.stringify(aliment),
  });
};

const login = (loginParams) => {
  return fetchData(`/api/login`, {
    method: "POST",
    body: JSON.stringify({
      ...loginParams,
    }),
  });
};

const logout = async () => {
  return fetchData(`/api/logout`, {
    method: "POST",
  });
};

const refreshToken = async () => {
  return fetchData(`/api/refresh`, { method: "POST" });
};

const loadInfosUtilisateur = async (id) => {
  return fetchData(`/api/utilisateurs/${id}`);
};

const updateInfosUtilisateurById = async (id, data) => {
  return fetchData(`/api/utilisateurs/${id}`, {
    method: "PUT",
    body: JSON.stringify({
      ...data,
    }),
  });
};

const updatePasswordById = async (id, data) => {
  return fetchData(`/api/utilisateurs/${id}/password`, {
    method: "PUT",
    body: JSON.stringify({
      ...data,
    }),
  });
};

const downloadFile = async (url, fileType) => {
  return fetchData(url, {
    headers: {
      "Content-Type": `application/${fileType}`,
      "Authorization": localStorage.getItem("idToken")
    },
  });
};

// Administration
const loadSocietesAgricoles = async (params = {}) => {
  const { dateDebut, dateFin, ...searchParams } = params;
  const urlParams = new URLSearchParams(searchParams);
  let filterDate = "";
  if (dateDebut) {
    filterDate = `${filterDate}&dateDebut=${dateDebut.start}&dateDebut=${dateDebut.end}`;
  }
  if (dateFin) {
    filterDate = `${filterDate}&dateFin=${dateFin.start}&dateFin=${dateFin.end}`;
  }
  return fetchData(`/api/societeagricoles?${urlParams}${filterDate}`);
};

const postTechnicien = async (data) => {
  return fetchData(`/api/admin/techniciens`, {
    method: "POST",
    body: JSON.stringify(data),
  });
};

const putTechnicien = async (id, data) => {
  return fetchData(`/api/admin/techniciens/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
};

const deleteTechnicien = async (id) => {
  return fetchData(`/api/admin/techniciens/${id}`, {
    method: "DELETE",
  });
};

const postSocieteAgricole = async (data) => {
  return fetchData(`/api/societeagricoles`, {
    method: "POST",
    body: JSON.stringify(data),
  });
};

const putSocieteAgricole = async (id, data) => {
  return fetchData(`/api/societeagricoles/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
};

const putAcceptationCgu = async (id, data) => {
  return fetchData(`/api/utilisateurs/${id}/acceptation`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
};

const loadSocietesAgricolesById = async (id) => {
  return fetchData(`/api/societeagricoles/${id}`);
};

const loadAdminCooperativeById = async (id) => {
  return fetchData(`/api/admin/cooperatives/${id}`);
};

const deleteAdminCooperative = async (id) => {
  return fetchData(`/api/admin/cooperatives/${id}`, {
    method: "DELETE",
  });
};

const loadDroits = async () => {
  return fetchData(`/api/modaliste/COOPDRT`);
};

const loadStructures = async () => {
  return fetchData(`/api/structures`);
};

const loadTechniciens = async () => {
  return fetchData(`/api/techniciens`);
};

const loadAdminTechniciens = async (params = {}) => {
  const { dateDebut, dateFin, ...searchParams } = params;
  const urlParams = new URLSearchParams(searchParams);
  let filterDate = "";
  if (dateDebut) {
    filterDate = `${filterDate}&dateDebut=${dateDebut.start}&dateDebut=${dateDebut.end}`;
  }
  if (dateFin) {
    filterDate = `${filterDate}&dateFin=${dateFin.start}&dateFin=${dateFin.end}`;
  }
  return fetchData(`/api/admin/techniciens?${urlParams}${filterDate}`);
};

const loadAdminCooperatives = async (params = {}) => {
  const { dateDebut, dateFin, ...searchParams } = params;
  const urlParams = new URLSearchParams(searchParams);
  let filterDate = "";
  if (dateDebut) {
    filterDate = `${filterDate}&dateDebut=${dateDebut.start}&dateDebut=${dateDebut.end}`;
  }
  if (dateFin) {
    filterDate = `${filterDate}&dateFin=${dateFin.start}&dateFin=${dateFin.end}`;
  }
  return fetchData(`/api/admin/cooperatives?${urlParams}${filterDate}`);
};

const postAdminCooperative = async (data) => {
  return fetchData(`/api/admin/cooperatives`, {
    method: "POST",
    body: JSON.stringify(data),
  });
};

const putAdminCooperative = async (id, data) => {
  return fetchData(`/api/admin/cooperatives/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
};

const loadModaliste = async (key) => {
  return fetchData(`/api/modaliste/${key}`);
};

const loadTypesCoop = async () => {
  return fetchData(`/api/typeCoop`);
};

const loadGroupesElevages = async (params = {}) => {
  const urlParams = new URLSearchParams(params);
  return fetchData(`/api/groupeElevages?${urlParams}`);
};

const loadGroupeElevage = async (id) => {
  return fetchData(`/api/groupeElevages/${id}`);
};

const updateGroupeElevage = async (id, data) => {
  return fetchData(`/api/groupeElevages/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
};

const deleteGroupeElevage = async (id) => {
  return fetchData(`/api/groupeElevages/${id}`, {
    method: "DELETE",
  });
};

const postGroupeElevage = async (data) => {
  return fetchData(`/api/groupeElevages`, {
    method: "POST",
    body: JSON.stringify(data),
  });
};

const loadAllElevages = async () => {
  return fetchData(`/api/elevages`);
};

const loadPaieDeLait = async (mois, annee, groupeId, isPaieNonSaisie) => {
  const urlParams = new URLSearchParams(
    `mois=${mois}&annee=${annee}&groupeId=${groupeId}&isPaieNonSaisie=${isPaieNonSaisie}`
  );
  return fetchData(`/api/paieDeLaits?${urlParams}`);
};

const updatePaieDeLait = async (data) => {
  return fetchData(`/api/paieDeLaits/`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
};

const postExportCalcul = async (params) => {
  const urlParams = new URLSearchParams(params);
  return fetchData(`/api/donneesAnnuelles/export/calcul?${urlParams}`, {
    method: "POST",
  });
};

const createAndDownloadFile = (data, headers) => {
  const file = window.URL.createObjectURL(data);
  const link = document.createElement("a");
  link.href = file;
  const filename = headers.get("content-disposition").split("filename=")[1];
  link.download = filename;
  link.click();
  // For Firefox it is necessary to delay revoking the ObjectURL.
  setTimeout(() => {
    window.URL.revokeObjectURL(file);
  }, 250);
};

const loadExportAll = async (params) => {
  const urlParams = new URLSearchParams(params);
  const response = await fetch(
    `${PUBLIC_URL}/api/donneesAnnuelles/export/tout?${urlParams}`,
    { headers: { Authorization: localStorage.getItem("idToken") } }
  );
  const data = await response.blob();
  createAndDownloadFile(data, response.headers);
};

const loadExportEleveur = async (params) => {
  const urlParams = new URLSearchParams(params);
  const response = await fetch(
    `${PUBLIC_URL}/api/donneesAnnuelles/export/eleveur?${urlParams}`,
    { headers: { Authorization: localStorage.getItem("idToken") } }
  );
  const data = await response.blob();
  createAndDownloadFile(data, response.headers);
};

const loadExportGroupe = async (params) => {
  const urlParams = new URLSearchParams(params);
  const response = await fetch(
    `${PUBLIC_URL}/api/donneesAnnuelles/export/groupe?${urlParams}`,
    { headers: { Authorization: localStorage.getItem("idToken") } }
  );
  const data = await response.blob();
  createAndDownloadFile(data, response.headers);
};

const fetchPdf = async (params) => {
  return fetchData(`/api/editions/v2/donneesMensuellesResultat?${params}`);
};

const fetchPdfDMA = async (params) => {
  return fetchData(`/api/editions/v2/donneesMensuellesAlimentation?${params}`);
};

const fetchPdfDa = async (params) => {
  return fetchData(`/api/editions/v2/DonneesAnnuellesBilan?${params}`);
};

export default {
  fetchPdf,
  fetchPdfDa,
  fetchPdfDMA,
  loadDonneesMensuelles,
  loadDonneeMensuelleById,
  loadDonneeAnnuelleById,
  updateDonneeAnnuelleById,
  addDonneeAnnuelle,
  updateDonneeMensuelleById,
  loadGroupes,
  loadStatuts,
  loadElevages,
  login,
  logout,
  refreshToken,
  generateDonneesMensuelles,
  loadCategoriesAliments,
  loadTypesAliments,
  loadGroupeAliments,
  loadInfosUtilisateur,
  updateInfosUtilisateurById,
  putAcceptationCgu,
  createAliment,
  downloadFile,
  updatePasswordById,
  loadCampagnes,
  loadGroupesDonneesAnnuelles,
  loadEleveursGroupes,
  loadEleveurDonneesAnnuelles,
  loadSocietesAgricoles,
  postTechnicien,
  putTechnicien,
  deleteTechnicien,
  postSocieteAgricole,
  putSocieteAgricole,
  loadSocietesAgricolesById,
  loadDroits,
  loadStructures,
  loadTechniciens,
  loadAdminTechniciens,
  loadAdminCooperatives,
  postAdminCooperative,
  loadAdminCooperativeById,
  putAdminCooperative,
  deleteAdminCooperative,
  loadModaliste,
  loadTypesCoop,
  loadGroupesElevages,
  loadGroupeElevage,
  updateGroupeElevage,
  loadAllElevages,
  postExportCalcul,
  loadExportAll,
  loadExportEleveur,
  loadExportGroupe,
  loadPaieDeLait,
  updatePaieDeLait,
  postGroupeElevage,
  deleteGroupeElevage,
};
