import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { Component } from "react";
import PropTypes from "prop-types";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import dayjs from "dayjs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-buttons";

class General extends Component {
  render() {
    const { edit, valueGetter, onChange, errors } = this.props;

    return (
      <div className="k-d-flex k-flex-column k-m-4">
        <div className="k-d-grid k-grid-cols-2 k-gap-3">
          <div className="k-p-4">
            <div className="grid-wrapper-form-2 k-mb-4">
              <Tooltip>
                <Label>
                  Code
                  <span className="required k-ml-2" title="Champ obligatoire">
                    *
                  </span>
                </Label>
              </Tooltip>
              <Input
                value={valueGetter("code")}
                onChange={(value) => onChange("code", value)}
                disabled={!edit}
                name="code"
                required
                valid={!errors?.general?.code}
              />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Tooltip>
                <Label>
                  Libellé
                  <span className="required k-ml-2" title="Champ obligatoire">
                    *
                  </span>
                </Label>
              </Tooltip>
              <Input
                value={valueGetter("nom")}
                onChange={(value) => onChange("nom", value)}
                disabled={!edit}
                name="nom"
                required
                valid={!errors?.general?.nom}
              />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Tooltip>
                <Label>
                  Date début
                  <span className="required k-ml-2" title="Champ obligatoire">
                    *
                  </span>
                </Label>
              </Tooltip>
              <div className="k-d-flex">
                <DatePicker
                  disabled={!edit}
                  value={
                    valueGetter("dateDebut") &&
                    dayjs(valueGetter("dateDebut")).isValid()
                      ? dayjs(valueGetter("dateDebut")).toDate()
                      : null
                  }
                  onChange={({ value }) => {
                    onChange("dateDebut", {
                      value,
                    });
                  }}
                  required
                  valid={!errors?.general?.dateDebut}
                />
                <Button
                  type="button"
                  disabled={!edit}
                  icon="reset"
                  title="réinitialiser la date"
                  onClick={() => {
                    onChange("dateDebut", { value: "" });
                  }}
                />
              </div>
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Label>Date fin</Label>
              <div className="k-d-flex">
                <DatePicker
                  disabled={!edit}
                  value={
                    valueGetter("dateFin") &&
                    dayjs(valueGetter("dateFin")).isValid()
                      ? dayjs(valueGetter("dateFin")).toDate()
                      : null
                  }
                  onChange={({ value }) =>
                    onChange("dateFin", {
                      value,
                    })
                  }
                  valid={!errors?.general?.dateFin}
                />
                <Button
                  type="button"
                  disabled={!edit}
                  icon="reset"
                  title="réinitialiser la date"
                  onClick={() => {
                    onChange("dateFin", { value: "" });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

General.propTypes = {
  edit: PropTypes.bool.isRequired,
  valueGetter: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

General.defaultProps = {
  errors: {},
};

export default General;
