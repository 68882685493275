import { Fade } from "@progress/kendo-react-animation";
import { Button } from "@progress/kendo-react-buttons";
import {
  IntlProvider,
  load,
  LocalizationProvider,
} from "@progress/kendo-react-intl";
import { AppBar, AppBarSection, Menu } from "@progress/kendo-react-layout";
import { Notification } from "@progress/kendo-react-notification";
import { Tooltip } from "@progress/kendo-react-tooltip";
import currencyData from "cldr-core/supplemental/currencyData.json";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import weekData from "cldr-core/supplemental/weekData.json";
import caGregorian from "cldr-dates-full/main/fr/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/fr/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/fr/timeZoneNames.json";
import currencyFr from "cldr-numbers-full/main/fr/currencies.json";
import numbers from "cldr-numbers-full/main/fr/numbers.json";
import { Component } from "react";
import { connect } from "react-redux";
import { Link, Route, Routes } from "react-router-dom";
import { withRouter } from "./utils/withRouter";
import "./App.scss";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import customParseFormat from "dayjs/plugin/customParseFormat";
import AppRouter from "./AppRouter";
import Login from "./components/auth/Login";
import Component404 from "./components/shared/404";
import { attemptLogout } from "./redux/actions/auth";
import { removeToast } from "./redux/actions/toastr";
import toastService from "./utils/toastService";
import { DOMAINE_OPTICHEVRE } from "./redux/reducers/app";
import { RULE } from "./components/shared/constants";
import { loadAadConfig, msalContainer, setAzureAccount } from "./Azure.js";
import { CacheLookupPolicy } from "@azure/msal-browser"

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);

const mapStateToProps = (state) => ({
  utilisateurId: state.auth.utilisateurId,
  typeUtilisateurId: state.auth.typeUtilisateurId,
  domaineId: state.auth.domaineId,
  nom: state.auth.nom,
  prenom: state.auth.prenom,
  cguChecked: state.auth.cguChecked,
  privacyChecked: state.auth.privacyChecked,
  toasts: state.toastr.toasts,
  domaine: state.app.domaine,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleLogout: async () => {
    dispatch(attemptLogout());
    ownProps.router.navigate("/login");
  },
  closeToast: (id) => dispatch(removeToast({ id })),
});

/** Load i18n translation files */
load(
  likelySubtags,
  currencyData,
  currencyFr,
  weekData,
  numbers,
  caGregorian,
  dateFields,
  timeZoneNames
);

const sleep = ms => new Promise(r => setTimeout(r, ms));
class App extends Component {

  menubarItems = [
    {
      text: "Administration",
      rule: RULE.SUPERVISEUR_DOMAINE,
      items: [
        {
          text: "Élevages",
          data: { route: "/app/administration/elevages" },
        },
        {
          text: "Groupes",
          data: { route: "/app/administration/groupes" },
        },
        {
          text: "Techniciens",
          data: { route: "/app/administration/techniciens" },
        },
        {
          text: "Coopératives",
          data: { route: "/app/administration/cooperatives" },
        },
      ],
    },
    {
      text: "Données mensuelles",
      items: [
        {
          text: "Saisie",
          data: { route: "/app/donnees-mensuelles/saisie" },
        },
        {
          text: "Paie de lait",
          rule: RULE.SUPERVISEUR_DOMAINE,
          data: { route: "/app/donnees-mensuelles/paie-de-lait" },
        },
        {
          text: "Génération des données mensuelles",
          rule: RULE.SUPERVISEUR_DOMAINE,
          data: { route: "/app/donnees-mensuelles/generation" },
        },
      ],
    },
    {
      text: "Données annuelles",
      data: {
        route: "/app/donnees-annuelles",
      },
    },
  ];

  handleAzureAccount = () => {
    const { router } = this.props;
    setAzureAccount();
    if ((msalContainer.msalInstance === null) || (!msalContainer.msalInstance.getActiveAccount())) {
      if (!router.location.pathname.startsWith("/login")) {
        router.navigate("/login", { replace: true });
      }
    } else if (!router.location.pathname.startsWith("/app") && !router.location.pathname.startsWith("/login")) {
      router.navigate("/app");
    }
  }

  async componentDidMount() {
    await sleep(3000);
    toastService.clearAllToasts();
    this.interval = setInterval(() => {
      if (msalContainer.isRefreshingToken) {
        return
      }
      const exp = localStorage.getItem('exp')
      if (exp !== undefined && exp !== null) {
        const ts_stop = parseInt(exp, 10)
        // On demande un nouveau token 10 minutes avant la date de fin
        const ts_now = Math.trunc(Date.now() / 1000 + 10 * 60)
        if ((ts_stop - ts_now) < 0) {
          msalContainer.isRefreshingToken = true
          this.handleRefreshToken()
        }
      }
    }, 60000);
    document.addEventListener("HANDLE_REDIRECT_END", this.handleAzureAccount);
    const { router } = this.props;
    await loadAadConfig(router);
    if (msalContainer.isloadingAadConfig) {
      return;
    }
    this.handleAzureAccount();

  }

  handleRefreshToken = async () => {
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md

    if (msalContainer.msalInstance === undefined) {
      console.error('App.js handleRefreshToken msalInstance not defined !')
      msalContainer.isRefreshingToken = false
      return;
    }

    const currentAccount = msalContainer.msalInstance.getActiveAccount()
    const silentRequest = {
      scopes: [""],
      account: currentAccount,
      forceRefresh: false,
      cacheLookupPolicy: CacheLookupPolicy.Default // will default to CacheLookupPolicy.Default if omitted
    };

    const request = {
      scopes: [""],
      loginHint: currentAccount.username // For v1 endpoints, use upn from idToken claims
    };

    const authResult = await msalContainer.msalInstance.acquireTokenSilent(silentRequest).catch(error => {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        localStorage.removeItem('exp')
        msalContainer.isRefreshingToken = false
        return msalContainer.msalInstance.acquireTokenRedirect(request)
      }
    });

    localStorage.setItem('authResult', JSON.stringify(authResult, null, 2))
    localStorage.setItem('idToken', authResult.idToken)
    localStorage.setItem('exp', authResult.idTokenClaims.exp)
    msalContainer.isRefreshingToken = false
  }

  handleLogoutRedirect = () => {
    localStorage.clear()
    if (msalContainer.msalInstance !== undefined && msalContainer.msalInstance !== null) {
      msalContainer.msalInstance.logoutRedirect();
    } else {
      if (history !== null) {
        if (history.location !== undefined) {
          if (!history.location.pathname.startsWith("/login")) {
            // history.replace("/login");
            navigate("/login", { replace: true })
          }
        } else {
          window.location.assign('/login');
        }
      } else {
        window.location.assign('/login');
        // navigate("/app");

      }
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  onSelect = (event) => {
    const { router } = this.props,
      route = event?.item?.data?.route;
    if (!!route) {
      router.navigate(route);
    }
  };

  applyRights = (menu, type) => {
    if (Array.isArray(menu)) {
      let finalMenu = [];
      menu.forEach((item) => {
        const tmp = this.applyRights(item, type);
        if (tmp !== null) {
          finalMenu = [...finalMenu, this.applyRights(item, type)];
        }
      });
      return finalMenu.length > 0 ? finalMenu : null;
    }
    if (menu.rule !== undefined) {
      if (menu.rule !== type) {
        return null;
      }
    }
    const finalItem = {
      text: menu.text,
      data: menu.data,
    };
    if (menu.items !== undefined) {
      const tmp = this.applyRights(menu.items, type);
      if (tmp !== null) {
        finalItem.items = tmp;
      }
    }
    return finalItem;
  };

  render() {
    const { typeUtilisateurId } = this.props;
    const finalMenu = this.applyRights(this.menubarItems, typeUtilisateurId);
    const { domaine, nom, prenom, toasts, closeToast } =
      this.props;
    return (
      <LocalizationProvider language={"fr"}>
        <IntlProvider locale={"fr"}>
          <Routes>
            <Route path="/login" exact element={
              <Login />}>
            </Route>
            <Route path="/signup" exact element={
              <div>signup</div>}>
            </Route>
            <Route path="/app/*" element={
              <div className="app">
                <AppBar
                  className="k-d-flex k-justify-content-between display-column-or-row align-item-start"
                  style={{ flexShrink: 0, minHeight: "5rem" }}
                >
                  <section className="k-d-flex k-align-items-center display-column-or-row align-item-start">
                    <AppBarSection className="k-mr-4">
                      <Link to="/app/">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/${
                            domaine === DOMAINE_OPTICHEVRE
                              ? "logo_opti_chevre.png"
                              : "logo_chevry_plan.png"
                          }`}
                          alt="Logo"
                          style={{ maxHeight: "2rem" }}
                        />
                      </Link>
                    </AppBarSection>
                    <AppBarSection>
                      <Menu
                        items={finalMenu}
                        onSelect={this.onSelect}
                        className="menu"
                      />
                    </AppBarSection>
                  </section>
                  <AppBarSection className="actions-user">
                    <h3 className="k-mr-6 username-menu">
                      {nom} {prenom}
                    </h3>
                    <section>
                      <Tooltip
                        openDelay={100}
                        position="bottom"
                        anchorElement="target"
                      >
                        <Link to="/app/infos-personnelles">
                          <Button
                            type="button"
                            className="k-button k-button-clear k-mr-3"
                          >
                            <span
                              className="k-icon k-i-user"
                              title={`Profil utilisateur : \n\r${nom} ${prenom}`}
                            />
                          </Button>
                        </Link>
                        <Link to="/app/assistance">
                          <Button
                            type="button"
                            className="k-button k-button-clear k-mr-3"
                          >
                            <span
                              className="k-icon k-i-help"
                              title="Assistance"
                            />
                          </Button>
                        </Link>
                        <Button
                          type="button"
                          className="k-button k-button-clear"
                          onClick={this.handleLogoutRedirect}
                        >
                          <span
                            className="k-icon k-i-logout"
                            title="Se déconnecter"
                          />
                        </Button>
                      </Tooltip>
                    </section>
                  </AppBarSection>
                </AppBar>
                <AppRouter/>
              </div>
            }>
            </Route>
            <Route path="*" element={
              <Component404 />}>
            </Route>
          </Routes>
          <Fade enter exit id="notification-position">
            {toasts?.map((toast) => (
              <Notification
                key={toast.id}
                type={{ style: toast.type, icon: true }}
                closable
                onClose={() => {
                  closeToast(toast.id);
                }}
              >
                <span>{toast.message}</span>
              </Notification>
            ))}
          </Fade>
        </IntlProvider>
      </LocalizationProvider>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
