/* eslint-disable no-nested-ternary */
import { Field } from "@progress/kendo-react-form";
import { NumericTextBox, Input } from "@progress/kendo-react-inputs";
import { Component } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";

class FraisFixes extends Component {
  constructor(props) {
    super(props);
    const { valueGetter } = props;
    this.state = {
      foncDisabledField:
        valueGetter("ffFoncHa") !== null
          ? "ffFoncTot"
          : valueGetter("ffFoncTot")
          ? "ffFoncHa"
          : undefined,
      mecaDisabledField:
        valueGetter("ffMecaHa") !== null
          ? "ffMecaTot"
          : valueGetter("ffMecaTot")
          ? "ffMecaHa"
          : undefined,
      batDisabledField:
        valueGetter("ffBatHa") !== null
          ? "ffBatTot"
          : valueGetter("ffBatTot")
          ? "ffBatHa"
          : undefined,
      moMsaDisabledField:
        valueGetter("ffMoMsaHa") !== null
          ? "ffMoMsaTot"
          : valueGetter("ffMoMsaTot")
          ? "ffMoMsaHa"
          : undefined,
      finDisabledField:
        valueGetter("ffFinHa") !== null
          ? "ffFinTot"
          : valueGetter("ffFinTot")
          ? "ffFinHa"
          : undefined,
      divDisabledField:
        valueGetter("ffDivHa") !== null
          ? "ffDivTot"
          : valueGetter("ffDivTot")
          ? "ffDivHa"
          : undefined,
    };
  }

  handleExclusiveFields = (fieldGroup, disableField, value) => {
    this.setState({
      [fieldGroup]: value !== null ? disableField : undefined,
    });
  };

  render() {
    const { edit, valueGetter } = this.props;
    const {
      foncDisabledField,
      mecaDisabledField,
      batDisabledField,
      moMsaDisabledField,
      finDisabledField,
      divDisabledField,
    } = this.state;

    return (
      <div className="k-m-4 tab-donnees-annuelles k-d-flex k-gap-8">
        <PanelBar
          className={`${edit && "panelbar-editable"}`}
          style={{ width: "50%" }}
        >
          <PanelBarItem title="FRAIS FIXES" expanded>
            <div className="k-m-4">
              <div>
                <div className="grid-wrapper-form-2-custom">
                  <div
                    style={{ gridColumnStart: 2 }}
                    className="grid-wrapper-form-3-custom k-mb-4"
                  >
                    <div className="k-font-weight-bold">
                      Montant/ha
                      <br />
                      SFP caprine
                    </div>
                    <div
                      className="k-font-weight-bold k-text-center"
                      style={{ gridColumnStart: 3 }}
                    >
                      Montant total <br /> Atelier lait <br /> (€ HT)
                    </div>
                  </div>
                </div>

                <div className="k-mb-4 grid-wrapper-form-2-custom">
                  <div>Foncier</div>
                  <div className="grid-wrapper-form-3-custom">
                    <div>
                      <Field
                        component={NumericTextBox}
                        format="n0"
                        name="ffFoncHa"
                        disabled={
                          edit ? foncDisabledField === "ffFoncHa" : true
                        }
                        onChange={({ value }) => {
                          this.handleExclusiveFields(
                            "foncDisabledField",
                            "ffFoncTot",
                            value
                          );
                        }}
                        spinners={edit}
                        min={0}
                      />
                    </div>

                    <div style={{ gridColumnStart: 3 }}>
                      <Field
                        component={NumericTextBox}
                        format="n0"
                        name="ffFoncTot"
                        disabled={
                          edit ? foncDisabledField === "ffFoncTot" : true
                        }
                        onChange={({ value }) => {
                          this.handleExclusiveFields(
                            "foncDisabledField",
                            "ffFoncHa",
                            value
                          );
                        }}
                        spinners={edit}
                        min={0}
                      />
                    </div>
                  </div>
                </div>

                <div className="k-mb-4 grid-wrapper-form-2-custom">
                  <div>Mécanisation</div>
                  <div className="grid-wrapper-form-3-custom">
                    <div>
                      <Field
                        component={NumericTextBox}
                        format="n0"
                        name="ffMecaHa"
                        disabled={
                          edit ? mecaDisabledField === "ffMecaHa" : true
                        }
                        onChange={({ value }) => {
                          this.handleExclusiveFields(
                            "mecaDisabledField",
                            "ffMecaTot",
                            value
                          );
                        }}
                        spinners={edit}
                        min={0}
                      />
                    </div>

                    <div style={{ gridColumnStart: 3 }}>
                      <Field
                        component={NumericTextBox}
                        format="n0"
                        name="ffMecaTot"
                        disabled={
                          edit ? mecaDisabledField === "ffMecaTot" : true
                        }
                        onChange={({ value }) => {
                          this.handleExclusiveFields(
                            "mecaDisabledField",
                            "ffMecaHa",
                            value
                          );
                        }}
                        spinners={edit}
                        min={0}
                      />
                    </div>
                  </div>
                </div>

                <div className="k-mb-4 grid-wrapper-form-2-custom">
                  <div>Bâtiments</div>
                  <div className="grid-wrapper-form-3-custom">
                    <div>
                      <Field
                        component={NumericTextBox}
                        format="n0"
                        name="ffBatHa"
                        disabled={edit ? batDisabledField === "ffBatHa" : true}
                        onChange={({ value }) => {
                          this.handleExclusiveFields(
                            "batDisabledField",
                            "ffBatTot",
                            value
                          );
                        }}
                        spinners={edit}
                        min={0}
                      />
                    </div>

                    <div className="k-font-weight-bold">
                      <span>OU</span>
                      <Tooltip>
                        <span
                          className="k-icon k-i-information"
                          title="Vous devez effectuer la saisie uniquement dans l'une ou l'autre des colonnes"
                        />
                      </Tooltip>
                    </div>

                    <div>
                      <Field
                        component={NumericTextBox}
                        format="n0"
                        name="ffBatTot"
                        disabled={edit ? batDisabledField === "ffBatTot" : true}
                        onChange={({ value }) => {
                          this.handleExclusiveFields(
                            "batDisabledField",
                            "ffBatHa",
                            value
                          );
                        }}
                        spinners={edit}
                        min={0}
                      />
                    </div>
                  </div>
                </div>

                <div className="k-mb-4 grid-wrapper-form-2-custom">
                  <div>Mains d&#39;œuvre-MSA</div>
                  <div className="grid-wrapper-form-3-custom">
                    <div>
                      <Field
                        component={NumericTextBox}
                        format="n0"
                        name="ffMoMsaHa"
                        disabled={
                          edit ? moMsaDisabledField === "ffMoMsaHa" : true
                        }
                        onChange={({ value }) => {
                          this.handleExclusiveFields(
                            "moMsaDisabledField",
                            "ffMoMsaTot",
                            value
                          );
                        }}
                        spinners={edit}
                        min={0}
                      />
                    </div>

                    <div style={{ gridColumnStart: 3 }}>
                      <Field
                        component={NumericTextBox}
                        format="n0"
                        name="ffMoMsaTot"
                        disabled={
                          edit ? moMsaDisabledField === "ffMoMsaTot" : true
                        }
                        onChange={({ value }) => {
                          this.handleExclusiveFields(
                            "moMsaDisabledField",
                            "ffMoMsaHa",
                            value
                          );
                        }}
                        spinners={edit}
                        min={0}
                      />
                    </div>
                  </div>
                </div>

                <div className="k-mb-4 grid-wrapper-form-2-custom">
                  <div>Frais financiers</div>
                  <div className="grid-wrapper-form-3-custom">
                    <div>
                      <Field
                        component={NumericTextBox}
                        format="n0"
                        name="ffFinHa"
                        disabled={edit ? finDisabledField === "ffFinHa" : true}
                        onChange={({ value }) => {
                          this.handleExclusiveFields(
                            "finDisabledField",
                            "ffFinTot",
                            value
                          );
                        }}
                        spinners={edit}
                        min={0}
                      />
                    </div>

                    <div style={{ gridColumnStart: 3 }}>
                      <Field
                        component={NumericTextBox}
                        format="n0"
                        name="ffFinTot"
                        disabled={edit ? finDisabledField === "ffFinTot" : true}
                        onChange={({ value }) => {
                          this.handleExclusiveFields(
                            "finDisabledField",
                            "ffFinHa",
                            value
                          );
                        }}
                        spinners={edit}
                        min={0}
                      />
                    </div>
                  </div>
                </div>

                <div className="k-mb-8 grid-wrapper-form-2-custom">
                  <div>Autres charges</div>
                  <div className="grid-wrapper-form-3-custom">
                    <div>
                      <Field
                        component={NumericTextBox}
                        format="n0"
                        name="ffDivHa"
                        disabled={edit ? divDisabledField === "ffDivHa" : true}
                        onChange={({ value }) => {
                          this.handleExclusiveFields(
                            "divDisabledField",
                            "ffDivTot",
                            value
                          );
                        }}
                        spinners={edit}
                        min={0}
                      />
                    </div>

                    <div style={{ gridColumnStart: 3 }}>
                      <Field
                        component={NumericTextBox}
                        format="n0"
                        name="ffDivTot"
                        disabled={edit ? divDisabledField === "ffDivTot" : true}
                        onChange={({ value }) => {
                          this.handleExclusiveFields(
                            "divDisabledField",
                            "ffDivHa",
                            value
                          );
                        }}
                        spinners={edit}
                        min={0}
                      />
                    </div>
                  </div>
                </div>
                <div className="grid-wrapper-form-2-custom">
                  <div className="k-text-right k-align-self-center k-font-weight-bold">
                    TOTAL
                  </div>
                  <div className="grid-wrapper-form-3-custom">
                    <div>
                      <Input
                        format="n0"
                        className="k-state-disabled"
                        disabled
                        value={
                          valueGetter("ffFoncHa") +
                          valueGetter("ffMecaHa") +
                          valueGetter("ffBatHa") +
                          valueGetter("ffMoMsaHa") +
                          valueGetter("ffFinHa") +
                          valueGetter("ffDivHa")
                        }
                        min={0}
                      />
                    </div>

                    <div style={{ gridColumnStart: 3 }}>
                      <Input
                        format="n0"
                        className="k-state-disabled"
                        disabled
                        value={
                          valueGetter("ffFoncTot") +
                          valueGetter("ffMecaTot") +
                          valueGetter("ffBatTot") +
                          valueGetter("ffMoMsaTot") +
                          valueGetter("ffFinTot") +
                          valueGetter("ffDivTot")
                        }
                        min={0}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PanelBarItem>
        </PanelBar>

        <PanelBar
          className={`${edit && "panelbar-editable"}`}
          style={{ width: "50%" }}
        >
          <PanelBarItem title="PRIMES" expanded>
            <div className="k-m-4">
              <div>
                <div className="grid-wrapper-form-2">
                  <div
                    className="k-mb-8 k-font-weight-bold"
                    style={{ gridColumnStart: 2 }}
                  >
                    Montant HT
                  </div>
                </div>
                <div className="k-mb-4 grid-wrapper-form-2">
                  <div>
                    Primes liées à la surface fourragère <br />
                    (prime maïs, prime à l&#39;herbe, DPU, …)
                  </div>
                  <Field
                    className="k-align-self-start"
                    component={NumericTextBox}
                    format="n0"
                    name="prPrimSurfFour"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                </div>

                <div className="grid-wrapper-form-2">
                  <div>
                    Primes structurelles liées à l&#39;élevage <br />
                    (prime montagne, haute montagne, zones défavorisées, …)
                  </div>
                  <Field
                    className="k-align-self-start"
                    component={NumericTextBox}
                    format="n0"
                    name="prPrimStruct"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                </div>
                <div className="grid-wrapper-form-2 grid-wrapper-form-2-custom k-mt-2">
                  <div className="k-font-weight-bold k-text-right k-align-self-center">
                    TOTAL
                  </div>
                  <Input
                    className="k-state-disabled"
                    format="n0"
                    value={
                      valueGetter("prPrimSurfFour") +
                      valueGetter("prPrimStruct")
                    }
                    disabled
                    min={0}
                  />
                </div>
              </div>
            </div>
          </PanelBarItem>
        </PanelBar>
      </div>
    );
  }
}

FraisFixes.propTypes = {
  edit: PropTypes.bool.isRequired,
  valueGetter: PropTypes.func.isRequired,
};

export default FraisFixes;
