/**
 * @template T
 * @template {keyof T} K
 * @param {Array<T>} arr
 * @param {K} key
 * @returns {Array<T[K]>}
 */
export const unique = (arr, key) => [
  ...new Set(arr.map((l) => l[key])).values(),
];

/**
 *
 * @param {number} num
 * @param {number} min
 * @param {number} max
 * @returns number
 */
export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
