import { Field } from "@progress/kendo-react-form";
import { NumericTextBox, Input } from "@progress/kendo-react-inputs";
import { Window } from "@progress/kendo-react-dialogs";
import { Component } from "react";
import PropTypes from "prop-types";
import DetailFraisVeterinaires from "./FraisElevage/DetailFraisVeterinaires";

// TODO extraire ces 2 fonctions dans un module `utils` ?
function sumOrNull(arr) {
  return arr.every((a) => a === null)
    ? null
    : arr.reduce((acc, curr) => acc + curr, 0);
}

function initExclusiveSet(valueGetter, total, chevAndChet) {
  if (chevAndChet.map(valueGetter).some((a) => a !== null)) {
    return new Set([total]);
  }
  if (valueGetter(total) !== null) {
    return new Set(chevAndChet);
  }
  return new Set();
}

class FraisElevage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alimAllaitDisabledFields: initExclusiveSet(
        props.valueGetter,
        "feAlimAllaitTot",
        ["feAlimAllaitChet"]
      ),
      reproDisabledFields: initExclusiveSet(props.valueGetter, "feReproTot", [
        "feReproChev",
        "feReproChet",
      ]),
      ctrlLaitDisabledFields: initExclusiveSet(
        props.valueGetter,
        "feCtrlLaitTot",
        ["feCtrlLaitChev"]
      ),
      entrMatDisabledFields: initExclusiveSet(
        props.valueGetter,
        "feEntrMatTot",
        ["feEntrMatChev", "feEntrMatChet"]
      ),
      pailleDisabledFields: initExclusiveSet(props.valueGetter, "fePailleTot", [
        "fePailleChev",
        "fePailleChet",
      ]),
      visible: false,
    };
  }

  handleExclusiveFields = (fieldGroup, disableFields, value) => {
    if (value !== null) {
      this.setState(({ [fieldGroup]: oldSet }) => {
        const newSet = new Set(oldSet);
        disableFields.forEach((df) => {
          newSet.add(df);
        });
        return {
          [fieldGroup]: newSet,
        };
      });
    } else {
      this.setState(({ [fieldGroup]: oldSet }) => {
        const newSet = new Set(oldSet);
        disableFields.forEach((df) => {
          newSet.delete(df);
        });
        return {
          [fieldGroup]: newSet,
        };
      });
    }
  };

  toggleDialog = () => {
    this.setState((s) => ({ visible: !s.visible }));
  };

  render() {
    const { edit, valueGetter, onChange, onSubmit } = this.props;
    const {
      alimAllaitDisabledFields,
      reproDisabledFields,
      ctrlLaitDisabledFields,
      entrMatDisabledFields,
      pailleDisabledFields,
      visible,
    } = this.state;

    return (
      <div className="k-m-4 tab-donnees-annuelles">
        <div className="k-mb-4 grid-wrapper-form-3-3-1 ">
          <div style={{ gridColumnStart: 2 }}>
            <p
              className={
                edit
                  ? "background-color-primary text-color-white k-font-weight-bold k-text-center k-py-2 k-m-0"
                  : "background-color-default k-font-weight-bold k-text-center k-py-2 k-m-0"
              }
            >
              MONTANT HT (€)
            </p>
          </div>
        </div>
        <div className="k-mb-4 grid-wrapper-form-3-3-1">
          <div className="grid-wrapper-form-2">
            <h4>Aliments d&#39;allaitement</h4>
          </div>
          <div className="k-flex-1 grid-wrapper-form-3 k-align-items-center">
            <div className="k-font-weight-bold k-text-center">CHÈVRES</div>
            <div className="k-font-weight-bold k-text-center">CHEVRETTES</div>
            <div className="k-font-weight-bold k-text-center">TOTAL</div>
          </div>
        </div>
        <div className="k-mb-4 grid-wrapper-form-3-3-1">
          <div className="k-d-flex k-flex-column">
            <div className="grid-wrapper-form-2 k-mt-2 k-align-items-end">
              <div className="grid-wrapper-form-2">
                <div>Type / Marque</div>
                <Field
                  component={Input}
                  format="n0"
                  name="feAlimAllaitLib"
                  disabled={!edit}
                  spinners={edit}
                />
              </div>
              <div className="grid-wrapper-form-2 k-align-items-end">
                <div>Quantité totale (kg)</div>
                <Field
                  component={NumericTextBox}
                  format="n0"
                  name="feAlimAllaitQte"
                  disabled={!edit}
                  spinners={edit}
                />
              </div>
            </div>
          </div>

          <div className="k-align-content-end grid-wrapper-form-3 grid-place-items-center">
            <div
              style={{ gridColumnStart: 2 }}
              className="k-flex-1 k-font-weight-bold k-text-center"
            >
              <Field
                component={NumericTextBox}
                format="n0"
                name="feAlimAllaitChet"
                disabled={
                  edit ? alimAllaitDisabledFields.has("feAlimAllaitChet") : true
                }
                onChange={({ value }) => {
                  this.handleExclusiveFields(
                    "alimAllaitDisabledFields",
                    ["feAlimAllaitTot"],
                    value
                  );
                  onChange("feAlimAllaitTot", {
                    value: sumOrNull([value]),
                  });
                }}
                min={0}
                spinners={edit}
              />
            </div>
            <div className="k-flex-1 k-font-weight-bold k-text-center">
              <Field
                component={NumericTextBox}
                name="feAlimAllaitTot"
                format="n0"
                disabled={
                  edit
                    ? alimAllaitDisabledFields.has("feAlimAllaitTot") ||
                      valueGetter("feAlimAllaitChet") !== null
                    : true
                }
                onChange={({ value }) => {
                  this.handleExclusiveFields(
                    "alimAllaitDisabledFields",
                    ["feAlimAllaitChet"],
                    value
                  );
                }}
                spinners={false}
                min={0}
              />
            </div>
          </div>
        </div>

        <div className="k-mb-4 grid-wrapper-form-3-3-1">
          <div>Oligo-éléments, sel, vitamines, hépatoprotecteurs, …</div>
          <div className="grid-wrapper-form-3 grid-place-items-center">
            <div style={{ gridColumnStart: 3 }}>
              <Field
                component={NumericTextBox}
                format="n0"
                name="feOligoTot"
                disabled={!edit}
                spinners={edit}
                min={0}
              />
            </div>
          </div>
        </div>

        <div className="k-mb-4 grid-wrapper-form-3-3-1">
          <div>
            Frais vétérinaires (global)
            <button
              type="button"
              className="k-button k-ml-4"
              onClick={this.toggleDialog}
            >
              Détail
            </button>
            {visible && (
              <Window
                title="Détail frais vétérinaires"
                onClose={this.toggleDialog}
                initialHeight={550}
                initialLeft={150}
                width={"75%"}
                maximizeButton={() => null}
                minimizeButton={() => null}
                modal
              >
                <DetailFraisVeterinaires
                  edit={edit}
                  onClose={() => this.setState({ visible: false })}
                  onChange={onChange}
                  onSubmit={onSubmit}
                  valueGetter={valueGetter}
                />
              </Window>
            )}
          </div>
          {/* TODO pop-up et reporter les totaux */}
          <div className="grid-wrapper-form-3 grid-place-items-center">
            <NumericTextBox
              format="n0"
              disabled
              value={
                valueGetter("feVetHonoChevMt") +
                valueGetter("feVetDeparChevMt") +
                valueGetter("feVetCellChevMt") +
                valueGetter("feVetTariChevMt") +
                valueGetter("feVetVaccChevMt") +
                valueGetter("feVetProphyChevMt") +
                valueGetter("feVetAutreChevMt")
              }
              spinners={false}
              min={0}
            />

            <NumericTextBox
              format="n0"
              disabled
              value={
                valueGetter("feVetHonoChetMt") +
                valueGetter("feVetDeparChetMt") +
                valueGetter("feVetCellChetMt") +
                valueGetter("feVetTariChetMt") +
                valueGetter("feVetVaccChetMt") +
                valueGetter("feVetProphyChetMt") +
                valueGetter("feVetAutreChetMt")
              }
              spinners={false}
              min={0}
            />

            <NumericTextBox
              format="n0"
              disabled
              value={
                valueGetter("feVetHonoChevMt") +
                valueGetter("feVetHonoChetMt") +
                valueGetter("feVetDeparChevMt") +
                valueGetter("feVetDeparChetMt") +
                valueGetter("feVetCellChevMt") +
                valueGetter("feVetCellChetMt") +
                valueGetter("feVetTariChevMt") +
                valueGetter("feVetTariChetMt") +
                valueGetter("feVetVaccChevMt") +
                valueGetter("feVetVaccChetMt") +
                valueGetter("feVetProphyChevMt") +
                valueGetter("feVetProphyChetMt") +
                valueGetter("feVetAutreChevMt") +
                valueGetter("feVetAutreChetMt")
              }
              spinners={false}
              min={0}
            />
          </div>
        </div>

        <div className="k-mb-4 grid-wrapper-form-3-3-1">
          <div>
            Frais reproduction (insémination, synchronisation, diagnostic
            gestation, échographie)
          </div>
          <div className="grid-wrapper-form-3 grid-place-items-center">
            <Field
              component={NumericTextBox}
              format="n0"
              name="feReproChev"
              disabled={edit ? reproDisabledFields.has("feReproChev") : true}
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "reproDisabledFields",
                  ["feReproTot"],
                  value
                );
                onChange("feReproTot", {
                  value: sumOrNull([value, valueGetter("feReproChet")]),
                });
              }}
              spinners={edit}
              min={0}
            />

            <div className="k-font-weight-bold k-text-center">
              <Field
                component={NumericTextBox}
                format="n0"
                name="feReproChet"
                disabled={edit ? reproDisabledFields.has("feReproChet") : true}
                onChange={({ value }) => {
                  this.handleExclusiveFields(
                    "reproDisabledFields",
                    ["feReproTot"],
                    value
                  );
                  onChange("feReproTot", {
                    value: sumOrNull([value, valueGetter("feReproChev")]),
                  });
                }}
                spinners={edit}
                min={0}
              />
            </div>
            <div className="k-font-weight-bold k-text-center">
              <Field
                component={NumericTextBox}
                format="n0"
                name="feReproTot"
                disabled={
                  edit
                    ? reproDisabledFields.has("feReproTot") ||
                      valueGetter("feReproChev") !== null ||
                      valueGetter("feReproChet") !== null
                    : true
                }
                onChange={({ value }) => {
                  this.handleExclusiveFields(
                    "reproDisabledFields",
                    ["feReproChev", "feReproChet"],
                    value
                  );
                }}
                spinners={edit}
                min={0}
              />
            </div>
          </div>
        </div>

        <div className="k-mb-4 grid-wrapper-form-3-3-1">
          <div>Contrôle laitier / capgènes</div>
          <div className="grid-wrapper-form-3 grid-place-items-center">
            <Field
              component={NumericTextBox}
              format="n0"
              name="feCtrlLaitChev"
              disabled={
                edit ? ctrlLaitDisabledFields.has("feCtrlLaitChev") : true
              }
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "ctrlLaitDisabledFields",
                  ["feCtrlLaitTot"],
                  value
                );
                onChange("feCtrlLaitTot", {
                  value: sumOrNull([value]),
                });
              }}
              spinners={edit}
              min={0}
            />

            <div className="k-font-weight-bold k-text-center" />
            <div className="k-font-weight-bold k-text-center">
              <Field
                component={NumericTextBox}
                format="n0"
                name="feCtrlLaitTot"
                disabled={
                  edit
                    ? ctrlLaitDisabledFields.has("feCtrlLaitTot") ||
                      valueGetter("feCtrlLaitChev") !== null
                    : true
                }
                onChange={({ value }) => {
                  this.handleExclusiveFields(
                    "ctrlLaitDisabledFields",
                    ["feCtrlLaitChev"],
                    value
                  );
                }}
                spinners={edit}
                min={0}
              />
            </div>
          </div>
        </div>

        <div className="k-mb-4 grid-wrapper-form-3-3-1">
          <div>
            Hygiène (Frais lessive, manchons, anti-mouches, contrôle machines à
            traire, …)
          </div>
          <div className="grid-wrapper-form-3 grid-place-items-center">
            <div> </div>
            <div> </div>
            <Field
              component={NumericTextBox}
              format="n0"
              name="feHygTot"
              disabled={!edit}
              spinners={edit}
              min={0}
            />
          </div>
        </div>

        <div className="k-mb-4 grid-wrapper-form-3-3-1">
          <div>Entretien petit matériel (louve, DAC)</div>
          <div className="grid-wrapper-form-3 grid-place-items-center">
            <Field
              component={NumericTextBox}
              format="n0"
              name="feEntrMatChev"
              disabled={
                edit ? entrMatDisabledFields.has("feEntrMatChev") : true
              }
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "entrMatDisabledFields",
                  ["feEntrMatTot"],
                  value
                );
                onChange("feEntrMatTot", {
                  value: sumOrNull([value, valueGetter("feEntrMatChet")]),
                });
              }}
              spinners={edit}
              min={0}
            />

            <Field
              component={NumericTextBox}
              format="n0"
              name="feEntrMatChet"
              disabled={
                edit ? entrMatDisabledFields.has("feEntrMatChet") : true
              }
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "entrMatDisabledFields",
                  ["feEntrMatTot"],
                  value
                );
                onChange("feEntrMatTot", {
                  value: sumOrNull([value, valueGetter("feEntrMatChev")]),
                });
              }}
              spinners={edit}
              min={0}
            />

            <Field
              component={NumericTextBox}
              format="n0"
              name="feEntrMatTot"
              disabled={
                edit
                  ? entrMatDisabledFields.has("feEntrMatTot") ||
                    valueGetter("feEntrMatChev") !== null ||
                    valueGetter("feEntrMatChet") !== null
                  : true
              }
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "entrMatDisabledFields",
                  ["feEntrMatChev", "feEntrMatChet"],
                  value
                );
              }}
              spinners={edit}
              min={0}
            />
          </div>
        </div>

        <div className="k-mb-4 grid-wrapper-form-3-3-1">
          <div>Services (écornage, parage, identification, analyses…)</div>
          <div className="grid-wrapper-form-3 grid-place-items-center">
            <div style={{ gridColumnStart: 3 }}>
              <Field
                component={NumericTextBox}
                format="n0"
                name="feEcornTot"
                disabled={!edit}
                spinners={edit}
                min={0}
              />
            </div>
          </div>
        </div>

        <div className="k-mb-4 grid-wrapper-form-3-3-1">
          <div>Paille, litière</div>
          <div className="grid-wrapper-form-3 k-d-flex grid-place-items-center">
            <Field
              component={NumericTextBox}
              format="n0"
              name="fePailleChev"
              disabled={edit ? pailleDisabledFields.has("fePailleChev") : true}
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "pailleDisabledFields",
                  ["fePailleTot"],
                  value
                );
                onChange("fePailleTot", {
                  value: sumOrNull([value, valueGetter("fePailleChet")]),
                });
              }}
              spinners={edit}
              min={0}
            />
            <Field
              component={NumericTextBox}
              format="n0"
              name="fePailleChet"
              disabled={edit ? pailleDisabledFields.has("fePailleChet") : true}
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "pailleDisabledFields",
                  ["fePailleTot"],
                  value
                );
                onChange("fePailleTot", {
                  value: sumOrNull([value, valueGetter("fePailleChev")]),
                });
              }}
              spinners={edit}
              min={0}
            />
            <Field
              component={NumericTextBox}
              format="n0"
              name="fePailleTot"
              disabled={
                edit
                  ? pailleDisabledFields.has("fePailleTot") ||
                    valueGetter("fePailleChev") !== null ||
                    valueGetter("fePailleChet") !== null
                  : true
              }
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "pailleDisabledFields",
                  ["fePailleChev", "fePailleChet"],
                  value
                );
              }}
              spinners={edit}
              min={0}
            />
          </div>
        </div>

        <div className="k-mb-4 grid-wrapper-form-3-3-1">
          <div>Bâches, conservateurs, ficelle</div>
          <div className="grid-wrapper-form-3 grid-place-items-center">
            <div style={{ gridColumnStart: 3 }}>
              <Field
                component={NumericTextBox}
                format="n0"
                name="feBacheTot"
                disabled={!edit}
                spinners={edit}
                min={0}
              />
            </div>
          </div>
        </div>

        <div className="k-mb-4 grid-wrapper-form-3-3-1">
          <div>Divers</div>
          <div className="grid-wrapper-form-3 grid-place-items-center">
            <div style={{ gridColumnStart: 3 }}>
              <Field
                component={NumericTextBox}
                format="n0"
                name="feDivTot"
                disabled={!edit}
                spinners={edit}
                min={0}
              />
            </div>
          </div>
        </div>
        <div className="k-mb-4 grid-wrapper-form-3-3-1">
          <div
            style={{ gridColumnStart: 2 }}
            className="grid-wrapper-form-3 grid-place-items-center"
          >
            <div style={{ gridColumnStart: 2 }}>TOTAL</div>

            <Input
              className="k-state-disabled"
              format="n0"
              value={
                valueGetter("feAlimAllaitTot") +
                valueGetter("feOligoTot") +
                valueGetter("feReproTot") +
                valueGetter("feCtrlLaitTot") +
                valueGetter("feHygTot") +
                valueGetter("feEntrMatTot") +
                valueGetter("feEcornTot") +
                valueGetter("fePailleTot") +
                valueGetter("feBacheTot") +
                valueGetter("feDivTot") +
                valueGetter("feVetHonoChevMt") +
                valueGetter("feVetHonoChetMt") +
                valueGetter("feVetDeparChevMt") +
                valueGetter("feVetDeparChetMt") +
                valueGetter("feVetCellChevMt") +
                valueGetter("feVetCellChetMt") +
                valueGetter("feVetTariChevMt") +
                valueGetter("feVetTariChetMt") +
                valueGetter("feVetVaccChevMt") +
                valueGetter("feVetVaccChetMt") +
                valueGetter("feVetProphyChevMt") +
                valueGetter("feVetProphyChetMt") +
                valueGetter("feVetAutreChevMt") +
                valueGetter("feVetAutreChetMt")
              }
              disabled
              min={0}
            />
          </div>
        </div>
      </div>
    );
  }
}

FraisElevage.propTypes = {
  edit: PropTypes.bool.isRequired,
  valueGetter: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default FraisElevage;
