import {
    Button,
    ButtonGroup,
    Toolbar,
    ToolbarItem,
    ToolbarSpacer,
  } from "@progress/kendo-react-buttons";
  import { Dialog } from "@progress/kendo-react-dialogs";
  import { Loader } from "@progress/kendo-react-indicators";
  import { useQuery, useQueryClient } from "@tanstack/react-query";
  // https://github.com/eKoopmans/html2pdf.js/issues/570
  import html2pdf from "html2pdf.js/dist/html2pdf.min.js";
  import React, { useState } from "react";
  import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    BarController,
    LineController,
    Colors,
    ArcElement
  } from "chart.js";
  import { Header } from "../../shared/ReportViewer/Header";
  import { clamp } from "../../shared/ReportViewer/utils";
  import dataSource from "../../../dataSource/dataSource";
  import { Page1 } from "./Page1";
  import { Page2 } from "./Page2";
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineController,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    BarController,
    // @ts-ignore
    Colors,
    ArcElement
  );
  
  const { PUBLIC_URL } = process.env;
  const ZOOM_STEP = 5;
  
  /**
   * @template T
   * @param {Record<string, T>} o
   * @returns {Record<string,string>}
   */
  function convertPropertiesToString(o) {
    /** @type {Record<string,string>} */
    let result = {};
    Object.keys(o).forEach((key) => {
      result[key] = String(o[key]);
    });
  
    return result;
  }


  /**
   * @typedef {Object} ReportParameter paramètre pour l'appel d'API
   * @property {number} domaine
   * @property {number} anneeDateFinCamp
   * @property {number} donAnId
   * @property {number} groupeId
   * @property {number} moisDateFinCamp
   * @property {number} societeAgricoleId
   */


  
  /**
   * @param {ReportParameter} params
   * @returns {Promise<Report>}
   */
  async function fetchReport(params) {
    const stringParameters = convertPropertiesToString(params);
    const urlParams = new URLSearchParams(stringParameters);
    const response = await dataSource.fetchPdfDa(urlParams);
    
    return response;
  }
  

  /**
   * @typedef {Object} printReportData
   * @property {string} Groupe
   * @property {string} Nom
   * @property {string} Copyright
   * @property {string} Logo1
   * @property {string} Logo2
   * @property {string | undefined} daCloture
   * @param {printReportData | undefined} data
   */
  async function printReport(data) {
    let element = document.getElementById("pdf-annuel");
    const opt = {
      filename: `Rapport Données Annuelles Résultats ${data?.Nom}.pdf`,
      html2canvas: { scale: 2 },
      jsPDF: { orientation: "portrait" },
    };
  
    html2pdf()
      .from(element)
      .set(opt)
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          pdf.setTextColor(150);
          pdf.text(
            pdf.internal.pageSize.getWidth() - 30,
            pdf.internal.pageSize.getHeight() - 8,
            "Page " + i + "/" + totalPages
          );

          pdf.text(
            pdf.internal.pageSize.getWidth() - 30,
            pdf.internal.pageSize.getHeight() - 13,
            "Le " + new Date().toLocaleDateString('fr-FR')
          );

          {data.Copyright && 

            pdf.text(
            pdf.internal.pageSize.getWidth() / 2 - 20,
            pdf.internal.pageSize.getHeight() - 8,
            data.Copyright
            );
            }

          if (data.daCloture != null && data.daCloture != "")
          {
            pdf.setFont("Helvetica", "bold");
            pdf.text(
            50,
            pdf.internal.pageSize.getHeight() - 13,
            data.daCloture);
            pdf.setFont("Helvetica", "normal");
          }

          const img = new Image();
          img.src = `${PUBLIC_URL}/images/logo_adm.png`;
          pdf.addImage(img, "PNG", 5, pdf.internal.pageSize.getHeight() - 17, 15, 15*557/675);

          // Logo en haut à droite
          // if (data.Logo1)
          // {
          //   const img2 = new Image();
          //   img2.src = "data:image/png;base64," + data.Logo1;
          //   pdf.addImage(img2, "PNG", pdf.internal.pageSize.getWidth() - 40, 5);
          // }

        }
      })
      .save();
  }
  
  /**
   * @param {ReportParameter} params
   */
  const useReport = (params) => {
    const queryClient = useQueryClient();
    return useQuery(["donneesAnnuelles", params], async () => {
       const report = await fetchReport(params);

      let nom = null;
      let numAdh = null;
      let nomFichierPdf = report["fluxPresentation"]["_CodeGroupe"];
      if (params.societeAgricoleId != -1) // Si on a un rapport d'éleveur
      {
        nom = report["fluxPresentation"]["_Entreprise"] + " " + report["fluxPresentation"]["_Nom"];
        numAdh = report["fluxPresentation"]["_SocieteAgricoleId"];
        nomFichierPdf = nom;
      }

      queryClient.setQueryData(
        ["Header", params],
        {
          "Groupe":report["fluxPresentation"]["_CodeGroupe"], 
          "Periode":report["fluxPresentation"]["_Periode"],
          "Nom":nom,
          "NumAdh":numAdh
        }
      );

      queryClient.setQueryData(
        ["PrintReport"],
        {
          "Groupe":report["fluxPresentation"]["_CodeGroupe"],
          "Nom":nomFichierPdf,
          "Copyright":report["fluxPresentation"]["Copyright"],
          "Logo1":report["fluxPresentation"]["_logo1"],
          "Logo2":report["fluxPresentation"]["_logo2"],
          "daCloture":report["fluxPresentation"]["_daCloture"],
        }
      );
      
      queryClient.setQueryData(
        ["donneesAnnuelles", "CriteresAnnuels"],
        report.CriteresAnnuel
      );

      return report;
    });
  };
  
  /**
   * ReportViewer est une pop-up pour afficher _et_ télécharger le fichier pdf
   * et le fichier Excel pour un éleveur et un mois défini
   * @typedef {Object} Props
   * @property {string} title titre de la pop-up
   * @property {() => void} onClose callback appelé lors de la fermeture
   * @property {ReportParameter} params paramètre pour l'appel d'API
   * @param {Props} props {@link Props}
   */
  const ReportViewerDA = ({ title, onClose, params }) => {
    const [zoom, setZoom] = useState(100);
    const { data: report, isLoading } = useReport(params);
    const queryClient = useQueryClient();


    const handleOnItemClick = () => {
      const data = /** @type {printReportData | undefined} */ (
       queryClient.getQueryData(["PrintReport"])
      );
      
      printReport(data);
      
    };
  
    return (
      <Dialog
        title={title}
        onClose={onClose}
        contentStyle={{
          height: "80vh",
          width: "75vw",
          padding: 0,
        }}
      >
        <div
          className="k-d-flex-col"
          style={{ overflow: "auto", height: "100%" }}
        >
          <Toolbar className="k-flex-shrink-0">
            <ToolbarItem>Zoom : {zoom}%</ToolbarItem>
            <ToolbarItem>
              <ButtonGroup>
                <Button
                  icon="zoom-in"
                  title="Zoom +"
                  onClick={() => {
                    setZoom((zoom) => clamp(zoom + ZOOM_STEP, 50, 150));
                  }}
                />
                <Button
                  icon="zoom-out"
                  title="Zoom -"
                  onClick={() => {
                    setZoom((zoom) => clamp(zoom - ZOOM_STEP, 50, 150));
                  }}
                />
                <Button
                  onClick={() => {
                    setZoom(100);
                  }}
                >
                  Ré-initialiser
                </Button>
              </ButtonGroup>
            </ToolbarItem>
            <ToolbarSpacer />
            <ToolbarItem>
              <Button disabled={report === undefined} onClick={handleOnItemClick}>
                Télécharger le PDF
              </Button>
            </ToolbarItem>
          </Toolbar>
          <div className="flex-fill k-overflow-auto k-p-5 bg-white">
            {isLoading && <Loader />}
            {!isLoading && report !== undefined && (
              <div
                id="pdf-annuel"
                className="pdf-annuel"
                style={
                  /** @type {React.CSSProperties} */ ({
                    "--zoom": zoom,
                  })
                }
              >
                
                <Header
                params={params}
                style={
                  /** @type {React.CSSProperties} */ ({
                    "--cell-height": "5mm",
                    "marginLeft": "5mm",
                  })
                }
                supertitle={`Bilan annuel ${params.societeAgricoleId != -1 ? "Éleveur" : "Groupe"}`}
                />
                
                <Page1/>

                <Header
                params={params}
                style={
                  /** @type {React.CSSProperties} */ ({
                    "--cell-height": "5mm",
                    "marginLeft": "5mm",
                  })
                }/>

                <Page2/>
            
              </div>
            )}
          </div>
        </div>
      </Dialog>
    );
  };
  
  export default ReportViewerDA;
  