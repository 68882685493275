import { Component, createRef } from "react";
import { filterBy } from "@progress/kendo-react-data-tools";
import { mapTree, extendDataItem } from "@progress/kendo-react-common";
import { DropDownTree } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import toastService from "../../utils/toastService";

function processTreeData(data, state, fields) {
  const { selectField, expandField, dataItemKey, subItemsField } = fields;
  const { expanded, value, filter } = state;
  const filtering = Boolean(filter && filter.value);

  return mapTree(
    filtering ? filterBy(data, [filter], subItemsField) : data,
    subItemsField,
    (item) => {
      const props = {
        [expandField]: expanded.includes(item[dataItemKey]),
        [selectField]: value && item[dataItemKey] === value[dataItemKey],
      };
      return filtering
        ? extendDataItem(item, subItemsField, props)
        : { ...item, ...props };
    }
  );
}

function expandedState(item, dataItemKey, expanded) {
  const nextExpanded = expanded.slice();
  const itemKey = item[dataItemKey];
  const index = expanded.indexOf(itemKey);
  // eslint-disable-next-line no-unused-expressions
  index === -1 ? nextExpanded.push(itemKey) : nextExpanded.splice(index, 1);

  return nextExpanded;
}

function createTree(data, textField, groupKey) {
  return data.reduce((prev, curr) => {
    const branch = prev.findIndex((p) => p[textField] === curr[groupKey]);
    if (branch !== -1) {
      prev[branch].items.push(curr);
    } else {
      prev.push({ [textField]: curr[groupKey], items: [curr] });
    }
    return prev;
  }, []);
}

class GridDropDownTree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: [],
      filter: undefined,
    };

    // Utilisation d'une ref, si c'était dans le state, le composant se
    // rafraichirait *avant* que l'état soit changé : onChange et onClose se
    // déclenchent *avant* la mise à jour de l'état
    this.leafSelected = createRef();
  }

  handleOnChange = (event) => {
    const { onChange, textField } = this.props;
    const { expanded } = this.state;

    // on a cliqué sur la `X` d'éffacement
    if (event.value === null) {
      onChange({ value: { text: null } });

      return;
    }

    // on déplie une catégorie
    if (event.level.length === 1) {
      this.setState({
        opened: true,
        expanded: expandedState(event.value, textField, expanded),
      });
      this.leafSelected.current = false;
    } else {
      this.leafSelected.current = true;
      onChange({ value: event.value });
    }
  };

  handleOnExpandChange = (event) => {
    const { textField } = this.props;
    const { expanded } = this.state;
    if (event.level.length === 1) return;
    this.setState({
      expanded: expandedState(event.item, textField, expanded),
    });
  };

  handleOnFilterChange = (event) => {
    this.setState({ filter: event.filter });
  };

  handleOnOpen = () => {
    this.setState({ opened: true });
  };

  handleOnClose = ({
    nativeEvent: {
      srcElement: { className },
    },
  }) => {
    this.setState({
      opened: className === "k-input" ? false : !this.leafSelected.current,
    });
  };

  render() {
    const {
      value,
      tabIndex,
      textField,
      groupKey,
      onChange,
      canAdd,
      onClickAdd,
      list,
    } = this.props;
    const { expanded, opened, filter } = this.state;
    const treeData = processTreeData(
      createTree(list, textField, groupKey),
      {
        expanded,
        value,
        filter,
      },
      {
        selectField: "selected",
        expandField: "expanded",
        dataItemKey: textField,
        subItemsField: "items",
      }
    );

    return (
      <div className="k-d-flex">
        <DropDownTree
          popupSettings={{
            className: "ch-dropdowntree",
          }}
          key={`dropdowntree-${tabIndex}`}
          tabIndex={tabIndex}
          data={treeData}
          value={{ text: value }}
          onChange={this.handleOnChange}
          onExpandChange={this.handleOnExpandChange}
          onOpen={this.handleOnOpen}
          onClose={this.handleOnClose}
          onFilterChange={this.handleOnFilterChange}
          opened={opened}
          dataItemKey={textField}
          textField={textField}
          filterable
        />
        {canAdd && (
          <Button
            tabIndex={-1}
            type="button"
            icon="plus"
            title="Ajouter un aliment"
            onClick={() =>
              new Promise(onClickAdd)
                .then((newAliment) => {
                  onChange(
                    {
                      value: { ...newAliment, text: newAliment.nomAliment },
                    },
                    () => {}
                  );
                })
                .catch((error) => {
                  if (error !== "close") {
                    toastService.addToast({
                      id: "add-aliment-error",
                      type: "error",
                      message: `Erreur lors de l'ajout de l'aliment : ${error.toString()}`,
                    });
                  }
                })
            }
          />
        )}
      </div>
    );
  }
}

export default GridDropDownTree;
