const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const passwordRegex = new RegExp(
  /^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.:=></;|]).{5,}$/
);

export const requiredValidator = (value) =>
  !value ? "Le champ nom doit être renseigné" : "";

export const emailValidator = (value) => {
  if (!value) {
    return "Le champ email doit être renseigné";
  }
  if (emailRegex.test(value)) {
    return "";
  }
  return "Le format de l'email n'est pas valide";
};

export const checkPassword = (password) => {
  if (!password) {
    return "Le mot de passe doit être renseigné";
  }
  if (passwordRegex.test(password)) {
    return "";
  }
  return "Le mot de passe n'a pas le bon format";
};

export const prixPourMLitresValidator = (value) => {
  const computedValue =
    value.productionQuantiteLaitPaye > 0
      ? (1000 * value.productionQuantiteLaitValeurTotaleHT) /
        value.productionQuantiteLaitPaye
      : Number(value);
  if (computedValue !== 0) {
    return computedValue >= 100 && computedValue <= 4000;
  }
  return true;
};

export const tbValidator = (value) => {
  if (value != null) {
    return value >= 20 && value <= 60;
  }
  return true;
};

export const tpValidator = (value) => {
  if (value != null) {
    return value >= 20 && value <= 50;
  }
  return true;
};

export const penaliteValidator = (value) => {
  return value >= 0 && value <= 150;
};

export const quantiteFourrageValidator = (value) => {
  return value >= 0 && value <= 5;
};

export const quantiteAutresAlimentsValidator = (value) => {
  return value >= 0 && value <= 5000;
};

export const quantiteLotTariesValidator = (value) => {
  return (
    value.effectifChevresNombre -
      value.alimentationChevresLot1Nombre -
      value.alimentationChevresLot2Nombre -
      value.alimentationChevresLot3Nombre -
      value.alimentationChevresLot4Nombre >=
    0
  );
};

export const effectifsChevresValidator = (value) => {
  return (
    value.effectifChevresNombre ===
    value.effectifChevresNombreM_1 -
      value.mouvementsChevresVenteNombre +
      value.mouvementsChevresAchatNombre -
      value.mouvementsChevresPerteNombre +
      value.misesBasChevrettesNombre
  );
};

export const effectifsGrandesChevrettesValidator = (value) => {
  return (
    value.effectifChevrettesGrandesNombre ===
    value.effectifChevrettesGrandesNombreM_1 - value.misesBasChevrettesNombre
  );
};

export const troisiemeDecadeValidator = (value) => {
  return value.productionChevresTraitesDecade3 <= value.effectifChevresNombre;
};

export const effectifsLot1Validator = (value) => {
  return value.alimentationChevresLot1Nombre === "" ||
    value.alimentationChevresLot1Nombre == null
    ? value.alimChevresFourrages.every(
        (item) => item.quantiteLot1 == null || item.quantiteLot1 === 0
      ) &&
        value.alimChevresAlimentsSimples.every(
          (item) => item.quantiteLot1 == null || item.quantiteLot1 === 0
        )
    : true;
};
export const effectifsLot2Validator = (value) => {
  return value.alimentationChevresLot2Nombre === "" ||
    value.alimentationChevresLot2Nombre == null
    ? value.alimChevresFourrages.every(
        (item) => item.quantiteLot2 == null || item.quantiteLot2 === 0
      ) &&
        value.alimChevresAlimentsSimples.every(
          (item) => item.quantiteLot2 == null || item.quantiteLot2 === 0
        )
    : true;
};
export const effectifsLot3Validator = (value) => {
  return value.alimentationChevresLot3Nombre === "" ||
    value.alimentationChevresLot3Nombre == null
    ? value.alimChevresFourrages.every(
        (item) => item.quantiteLot3 == null || item.quantiteLot3 === 0
      ) &&
        value.alimChevresAlimentsSimples.every(
          (item) => item.quantiteLot3 == null || item.quantiteLot3 === 0
        )
    : true;
};
export const effectifsLot4Validator = (value) => {
  return value.alimentationChevresLot4Nombre === "" ||
    value.alimentationChevresLot4Nombre == null
    ? value.alimChevresFourrages.every(
        (item) => item.quantiteLot4 == null || item.quantiteLot4 === 0
      ) &&
        value.alimChevresAlimentsSimples.every(
          (item) => item.quantiteLot4 == null || item.quantiteLot4 === 0
        )
    : true;
};

export const productionValidator = (value) => {
  const totalMois =
    value.productionQuantiteLaitLivre +
    value.productionLaitDivers +
    value.productionLaitConsomme;
  const totalMois1 =
    value.productionQuantiteLaitLivreM_1 +
    value.productionLaitDiversM_1 +
    value.productionLaitConsommeM_1;
  return totalMois > totalMois1 / 2;
};
