import { EventType, PublicClientApplication } from '@azure/msal-browser';
import toastService from "./utils/toastService";

// https://stackoverflow.com/questions/18765869/accessing-http-status-code-constants

const { PUBLIC_URL } = process.env;

export const HTTP_CODE =
{
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
};

export const msalConfig = {
  auth: null,
  cache: {
    cacheLocation: "localStorage",
  },
};

export const loginRequest = {
  scopes: ["openid"],
};

export const msalContainer = {
  msalInstance: null,
  isCallbackInstalled: false,
  isloadingAadConfig: false,
  isRegisterHandle: false,
  isRefreshingToken: false,
  eventType: null,
};

export const setAzureAccount = () => {
  if (msalContainer.msalInstance === null) {
    return;
  }
  if (
    !msalContainer.msalInstance.getActiveAccount() &&
    msalContainer.msalInstance.getAllAccounts().length > 0
  ) {
    msalContainer.msalInstance.setActiveAccount(msalContainer.msalInstance.getAllAccounts()[0]);
  }
};

export const loadAadConfig = async (history) => {
  if (msalConfig.auth !== null || msalContainer.isloadingAadConfig) {
    return;
  }
  if (msalContainer.isloadingAadConfig) {
    return;
  }
  msalContainer.isloadingAadConfig = true;
  const ts = Date.now()
  const response = await fetch(
    PUBLIC_URL + `/api/aad_config?ts=${ts}`
  );
  const json = await response.json();
  msalConfig.auth = json
  const event = new CustomEvent("StopLoading", { detail: '' });
  document.dispatchEvent(event);
  if (!response.ok) {
    toastService.addToast({
      id: "network-error-aad-config",
      type: "error",
      message: `Erreur réseau : ${response.status} ${response.statusText}`
    });
    return
  }
  document.dispatchEvent(event);
  
  if (msalContainer.msalInstance === null) {
    msalContainer.msalInstance = new PublicClientApplication(msalConfig);
    setAzureCallbackEvent(history);
  }
  msalContainer.isloadingAadConfig = false;
}

export const setAzureCallbackEvent = (history) => {
  if (msalContainer.msalInstance === null || msalContainer.isCallbackInstalled) {
    return;
  }
  msalContainer.isCallbackInstalled = true;

  msalContainer.msalInstance.addEventCallback((event) => {
    msalContainer.eventType = event.eventType;
    if (event.eventType === EventType.HANDLE_REDIRECT_END) {
      const app_ev = new CustomEvent("HANDLE_REDIRECT_END", { detail: '' });
      document.dispatchEvent(app_ev);
    } else if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      event.payload.account
    ) {
      msalContainer.msalInstance.setActiveAccount(event.payload.account);
      if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        localStorage.setItem('event_token', JSON.stringify(event, null, 2))
      }
    }
  }, error=>{
    console.log('Azure.js callback error', error);
  });
  doRegisterHandle(history);
}

export const doRegisterHandle = (history) => {
  if (msalContainer.msalInstance === null || msalContainer.isRegisterHandle) {
    return;
  }
  msalContainer.isRegisterHandle = true;
  msalContainer.msalInstance
  .handleRedirectPromise()
  .then(async (authResult) => {
    if (authResult !== null) {
      localStorage.setItem('authResult', JSON.stringify(authResult, null, 2))
      localStorage.setItem('idToken', authResult.idToken)
      localStorage.setItem('exp', authResult.idTokenClaims.exp)
    }
    const account = msalContainer.msalInstance.getActiveAccount();
    if (!account) {
      // redirect anonymous user to login page
    } else {
      // La date de validité du JWT est vérifiée sur le serveur afin de ne pas être pertubé par un mauvais
      // réglage du navigateur
      const event = new CustomEvent("StartLoading", { detail: '' });
      document.dispatchEvent(event);
      const url = PUBLIC_URL + `/api/aad_login`
      try {
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: JSON.stringify({
            username: account.username,
            idToken: localStorage.getItem('idToken')
          }),
        });
        const event = new CustomEvent("StopLoading", { detail: '' });
        document.dispatchEvent(event);
        if (response.status === HTTP_CODE.FORBIDDEN) {
          const json = await response.json()
          toastService.addToast({
            id: "local-login-error",
            type: "error",
            message: `Vous n'avez pas les autorisations pour vous connecter : ${json.message}`,
          });
          if (msalContainer.msalInstance !== undefined && msalContainer.msalInstance !== null) {
            msalContainer.msalInstance.logoutRedirect();
          }
        } else if (response.status !== HTTP_CODE.OK) {
          const json = await response.json()
          toastService.addToast({
            id: "local-login-error",
            type: "error",
            message: "Une erreur locale s'est produite :" + JSON.stringify(json),
          });
          if (msalContainer.msalInstance !== undefined && msalContainer.msalInstance !== null) {
            msalContainer.msalInstance.logoutRedirect();
          }
        } else {
          const json = await response.json()
          if (!history.location.pathname.startsWith("/app")) {
            const event = new CustomEvent("AadLoginOk", {
              detail: json
            });
            document.dispatchEvent(event);
          }
        }
      } catch (error) {
        console.log('Azure.js login error', error);
        const event = new CustomEvent("StopLoading", { detail: '' });
        document.dispatchEvent(event);
      }
    }
  })
  .catch((err) => {
    console.log("Azure.js handle error", err);
  });
}

