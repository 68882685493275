import React from "react";
import { Pie } from "react-chartjs-2";

/**
 * @typedef {Object} CharPie TODO
 * @property {} data  TODO
 */
export const ChartPie = ({ intitules, donnees, couleurs, titre }) => {


  const datasets = [{
    label: "Total",
    data: donnees,
    backgroundColor: couleurs,
    hoverBackgroundColor: couleurs
  }]
  const data = { "labels": intitules, "datasets": datasets}
  const option = {plugins:{legend: {display: false}, title:{display: true, text: titre}}}


  return (
    <Pie
      data={data}
      options={option}
      />
  );
  };
