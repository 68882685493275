import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import PropTypes from "prop-types";
import { Component } from "react";
import CustomGrid from "../../shared/CustomGrid";
import { BOUCS_ALIMENTS_SIMPLES, BOUCS_FOURRAGE } from "./FicheDonneeMensuelle";

class AlimBoucs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentEditedGrid: undefined,
    };
  }

  render() {
    const {
      valueGetter,
      onChange,
      errors: { errorsBoucsFourrages, errorsBoucsAlimentsSimples },
      alimentsFourrages,
      alimentsSimples,
      onAddAliment,
      canEdit,
    } = this.props;

    const columnsFourrage = (key) => {
      const { currentEditedGrid } = this.state;

      return [
        {
          field: "isAVolonte",
          title: "A volonté",
          type: "checkbox",
          width: 110,
          editable: () => currentEditedGrid === key,
        },
        {
          field: "nomAliment",
          title: "Nom du fourrage",
          type: "dropdowntree",
          dropdowntree: {
            list: alimentsFourrages,
            textField: "text",
            dataItemKey: "groupeAlimentId",
            groupKey: "groupText",
            value: ({ groupeAlimentId, nomAliment, nomCategorieAliment }) =>
              alimentsFourrages.find(
                (a) => a.groupeAlimentId === groupeAlimentId
              ) || {
                groupText: nomCategorieAliment,
                groupeAlimentId,
                text: nomAliment,
              },
            updateItem: (
              dataItem,
              { text, groupeAlimentId, msPourcent, prixEuroHT }
            ) => ({
              ...dataItem,
              groupeAlimentId,
              nomAliment: text,
              msPourcent,
              prixAchatEuroHT: prixEuroHT,
            }),
          },
          width: 260,
          editable: () => currentEditedGrid === key,
        },
        {
          field: "msPourcent",
          fieldProps: { spinners: false },
          title: "MS %",
          type: "number",
          width: 150,
          format: "n1",
          editable: () => currentEditedGrid === key,
        },
        {
          field: "prixAchatEuroHT",
          fieldProps: { spinners: false },
          title: "Prix € HT",
          type: "number",
          width: 200,
          format: "n0",
          editable: () => currentEditedGrid === key,
        },
        {
          field: "quantiteLot1",
          fieldProps: { spinners: false },
          title: "Qté (kg/ani./j)",
          type: "number",
          width: "200",
          format: "n2",
          editable: () => true,
        },
        {
          type: "delete",
          width: 60,
          editable: () => currentEditedGrid === key,
        },
      ];
    };

    const columnsAlimentsSimples = (key) => {
      const { currentEditedGrid } = this.state;

      return [
        {
          width: 110,
          editable: () => false,
        },
        {
          field: "nomAliment",
          title: "Nom de l'aliment",
          type: "dropdowntree",
          dropdowntree: {
            list: alimentsSimples,
            textField: "text",
            dataItemKey: "groupeAlimentId",
            groupKey: "groupText",
            canAdd: true,
            onClickAdd: onAddAliment,
            value: ({ groupeAlimentId, nomAliment, nomCategorieAliment }) =>
              alimentsSimples.find(
                (a) => a.groupeAlimentId === groupeAlimentId
              ) || {
                groupText: nomCategorieAliment,
                groupeAlimentId,
                text: nomAliment,
              },
            updateItem: (
              dataItem,
              { text, groupeAlimentId, msPourcent, prixEuroHT }
            ) => ({
              ...dataItem,
              groupeAlimentId,
              nomAliment: text,
              msPourcent,
              prixAchatEuroHT: prixEuroHT,
            }),
          },
          width: 260,
          editable: () => currentEditedGrid === key,
        },
        {
          field: "msPourcent",
          fieldProps: { spinners: false },
          title: "MS %",
          type: "number",
          width: 150,
          format: "n1",
          editable: () => currentEditedGrid === key,
        },
        {
          field: "prixAchatEuroHT",
          fieldProps: { spinners: false },
          title: "Prix € HT",
          type: "number",
          width: 200,
          format: "n0",
          editable: () => currentEditedGrid === key,
        },
        {
          field: "quantiteLot1",
          fieldProps: { spinners: false },
          title: "Qté (g/ani./j)",
          type: "number",
          width: "200",
          precision: 0,
          editable: () => currentEditedGrid === key,
        },
        {
          type: "delete",
          width: 60,
          editable: () => currentEditedGrid === key,
        },
      ];
    };
    const fourragesBoucs = valueGetter(BOUCS_FOURRAGE);
    const alimentsSimplesBoucs = valueGetter(BOUCS_ALIMENTS_SIMPLES);
    const errorsFourages = Object.entries(errorsBoucsFourrages)
      .filter(([key]) => key !== "tabTitle")
      .flatMap(([, error]) =>
        error.lines.map((l) => ({ index: l, field: error.field }))
      );
    const errorsAlimentsSimples = Object.entries(errorsBoucsAlimentsSimples)
      .filter(([key]) => key !== "tabTitle")
      .flatMap(([, error]) =>
        error.lines.map((l) => ({ index: l, field: error.field }))
      );
    return (
      <div className="k-d-flex k-flex-column k-m-4">
        <div className="k-d-grid k-display-block" style={{ width: "100%" }}>
          <PanelBar>
            <PanelBarItem title="Boucs" expanded style={{ width: "100%" }}>
              <div className="k-m-4">
                <CustomGrid
                  title="Fourrages (Qté en Kg)"
                  className="grid-fourrage-bouc k-mb-4"
                  canAdd
                  dataGrid={fourragesBoucs}
                  fieldId="dmAlimentationId"
                  columns={columnsFourrage(BOUCS_FOURRAGE)}
                  onChange={(newData) => onChange(BOUCS_FOURRAGE, newData)}
                  onItemAdd={() =>
                    onChange(BOUCS_FOURRAGE, [
                      ...fourragesBoucs,
                      {
                        typeAnimal: 4,
                        typeAliment: 1,
                        msPourcent: null,
                        prixAchatEuroHT: null,
                        quantiteLot1: null,
                        quantiteLot2: null,
                        quantiteLot3: null,
                        quantiteLot4: null,
                        quantiteLotTari: null,
                      },
                    ])
                  }
                  errors={errorsFourages}
                  canEdit
                  disabled={!canEdit}
                  onRowClick={() => {
                    this.setState({ currentEditedGrid: BOUCS_FOURRAGE });
                  }}
                />
                <CustomGrid
                  title="Aliments simples, composés ou minéraux (Qté en g)"
                  className="grid-aliment-bouc"
                  canAdd
                  dataGrid={alimentsSimplesBoucs}
                  fieldId="dmAlimentationId"
                  columns={columnsAlimentsSimples(BOUCS_ALIMENTS_SIMPLES)}
                  onChange={(newData) =>
                    onChange(BOUCS_ALIMENTS_SIMPLES, newData)
                  }
                  onItemAdd={() =>
                    onChange(BOUCS_ALIMENTS_SIMPLES, [
                      ...alimentsSimplesBoucs,
                      {
                        typeAnimal: 4,
                        typeAliment: 2,
                        msPourcent: null,
                        prixAchatEuroHT: null,
                        quantiteLot1: null,
                        quantiteLot2: null,
                        quantiteLot3: null,
                        quantiteLot4: null,
                        quantiteLotTari: null,
                      },
                    ])
                  }
                  errors={errorsAlimentsSimples}
                  canEdit
                  disabled={!canEdit}
                  onRowClick={() => {
                    this.setState({
                      currentEditedGrid: BOUCS_ALIMENTS_SIMPLES,
                    });
                  }}
                />
              </div>
            </PanelBarItem>
          </PanelBar>
        </div>
      </div>
    );
  }
}

AlimBoucs.propTypes = {
  valueGetter: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  alimentsSimples: PropTypes.array.isRequired,
  alimentsFourrages: PropTypes.array.isRequired,
  canEdit: PropTypes.bool,
  onAddAliment: PropTypes.func.isRequired,
};

AlimBoucs.defaultProps = {
  canEdit: false,
};

export default AlimBoucs;
