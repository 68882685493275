import { Component, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "../../utils/withRouter";
import { compose } from "redux";
import PropTypes from "prop-types";
import { Form, FormElement } from "@progress/kendo-react-form";
import {
  PanelBar,
  PanelBarItem,
  TabStrip,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import dayjs from "dayjs";
import Description from "./fiche/cooperative/Description";
import dataSource from "../../dataSource/dataSource";
import toastService from "../../utils/toastService";
import truncTimeZone from "../../utils/dateTools";
import { RULE, LIMIT } from "../shared/constants";

const COOP_TYPE_DOMAINE = 1;

const mapStateToProps = (state) => ({
  utilisateurId: state.auth.utilisateurId,
  domaineId: state.auth.domaineId,
});

class SaisieCooperative extends Component {
  constructor(props) {
    super(props);
    this.formRef = createRef();
    this.state = {
      selectedTab: 0,
      edit: false,
      loading: false,
      cooperative: undefined,
      newCooperative: undefined,
    };
  }

  async componentDidMount() {
    const {idCooperative} = this.props.router.params;
    const { createNew, domaineId } = this.props;
    this.setState({ loading: true });
    const structures = await dataSource.loadStructures();
    // Par défaut, il y a au moins une structure correspondant au domaine de l'utilisateur connecté
    const defaultStructure = structures.find((s) => s.coopId === domaineId);
    if (createNew) {
      const today = dayjs().format("YYYY-MM-DD");
      this.setState({
        edit: true,
        newCooperative: {
          CoopId: 0,
          dateDebut: today,
          defaultStructure,
        },
        loading: false,
      });
    } else {
      let cooperative = await dataSource.loadAdminCooperativeById(
        idCooperative
      );
      if (Array.isArray(cooperative) && cooperative.length > 0) {
        cooperative = cooperative[0];
      }
      cooperative.defaultStructure = defaultStructure;
      this.setState({
        cooperative,
        loading: false,
      });
    }
  }

  handleOnClickEdit = () => this.setState((s) => ({ edit: !s.edit }));

  validator = (values, valueGetter) => {
    const errors = {};
    errors.description = {};
    errors.contact = {};
    if (!valueGetter("description.typeCoopId")) {
      errors.description = {
        ...errors.description,
        tabTitle: "Description",
        typeCoopId: {
          level: "error",
          message: "Saisie obligatoire du type de coopérative",
          tabIndex: 0,
        },
      };
    }
    if (!valueGetter("code")) {
      errors.description = {
        ...errors.description,
        tabTitle: "Description",
        code: {
          level: "error",
          message: "Saisie obligatoire du code",
          tabIndex: 0,
        },
      };
    }
    if (
      !valueGetter("dateDebut") ||
      valueGetter("dateDebut") === "Invalid Date" ||
      (valueGetter("dateDebut") instanceof Date &&
        valueGetter("dateDebut").getFullYear() <
          LIMIT.MIN_KENDO_DATEPICKER_YEAR)
    ) {
      errors.description = {
        ...errors.description,
        tabTitle: "Description",
        dateDebut: {
          level: "error",
          message: "Saisie obligatoire de la date de début",
          tabIndex: 0,
        },
      };
    }
    if (
      valueGetter("dateFin") instanceof Date &&
      valueGetter("dateFin").getFullYear() < LIMIT.MIN_KENDO_DATEPICKER_YEAR
    ) {
      errors.description = {
        ...errors.description,
        tabTitle: "Description",
        dateFin: {
          level: "error",
          message: "La date de fin est erronée",
          tabIndex: 0,
        },
      };
    }

    const isFormValid = !Object.values(errors)
      .map((item) => Object.values(item).length)
      .some((item) => item > 0);
    return isFormValid ? {} : errors;
  };

  submitValues = async ({ values, isValid }) => {
    const { createNew } = this.props;
    if (!isValid) return;
    if (createNew) {
      try {
        const newCooperative = await dataSource.postAdminCooperative({
          ...values,
          typeCoopId: values.description.typeCoopId || "",
          code: values.code || "",
          raisonSociale: values.raisonSociale || "",
          coopLibelleCourt: values.coopLibelleCourt || "",
          coopLibelleLong: values.coopLibelleLong || "",
          dateDebut: truncTimeZone(values.dateDebut),
          dateFin: truncTimeZone(values.dateFin),
        });
        toastService.addToast({
          id: "da-success",
          type: "success",
          message: "Vos informations ont bien été enregistrées",
        });
        window.location = `${process.env.PUBLIC_URL}/app/administration/cooperatives/${newCooperative.coopId}`;
      } catch (e) {
        toastService.addToast({
          id: "da-error",
          type: "error",
          message: e.message,
        });
      }
    } else {
      try {
        await dataSource.putAdminCooperative(values.coopId, {
          ...values,
          typeCoopId: values.description.typeCoopId || "",
          code: values.code || "",
          raisonSociale: values.raisonSociale || "",
          coopLibelleCourt: values.coopLibelleCourt || "",
          coopLibelleLong: values.coopLibelleLong || "",
          dateDebut: truncTimeZone(values.dateDebut),
          dateFin: truncTimeZone(values.dateFin),
        });
        toastService.addToast({
          id: "da-success",
          type: "success",
          message: "Vos informations ont bien été mises à jour",
        });
      } catch (e) {
        toastService.addToast({
          id: "da-error",
          type: "error",
          message: e.message,
        });
      }
    }
  };

  render() {
    const { createNew, router } = this.props;
    const {
      selectedTab,
      edit,
      loading,
      cooperative,
      newCooperative,
    } = this.state;
    // TODO à supprimer
    const typeUtilisateurId = 1;
    return loading ? (
      <div className="flex-fill k-text-center k-mt-18">
        <Loader themeColor="primary" />
      </div>
    ) : (
      <Form
        ref={this.formRef}
        initialValues={createNew ? newCooperative : cooperative}
        onSubmitClick={this.submitValues}
        validator={this.validator}
        render={(formProps) => {
          return (
            <div className="k-d-flex k-flex-column k-m-4">
              <FormElement>
                <div className="k-display-flex k-justify-content-between k-align-items-center k-mb-4">
                  <div className="k-d-inline-flex k-align-items-baseline">
                    <h3 className="k-my-0">
                      {createNew ? "Ajout" : "Édition"} d&#39;une coopérative
                    </h3>
                  </div>
                </div>
                {Object.keys(formProps.errors).filter(
                  (k) => typeof formProps.errors[k] === "object"
                ).length > 0 && (
                  <div className="row">
                    <div className="panel-error k-my-4">
                      <PanelBar>
                        <PanelBarItem
                          title="Erreurs dans le formulaire"
                          className="k-state-expanded"
                          style={{ width: "100%" }}
                        >
                          {Object.keys(formProps.errors)
                            .filter(
                              (k) => typeof formProps.errors[k] === "object"
                            )
                            .map((k) => formProps.errors[k])
                            .map(({ tabTitle, ...errors }) => {
                              return !!tabTitle &&
                                Object.values(errors).filter(
                                  (error) => !!error.message
                                ).length > 0 ? (
                                <div key={tabTitle}>
                                  <h3 style={{ marginBottom: "0.5em" }}>
                                    {tabTitle}
                                  </h3>
                                  {Object.entries(errors).map(
                                    ([key, { message, lines, tabIndex }]) => (
                                      <div key={key}>
                                        <Button
                                          type="button"
                                          key={key}
                                          className="k-mb-2 button-error"
                                          onClick={() =>
                                            this.setState({
                                              selectedTab:
                                                tabIndex != null ? tabIndex : 0,
                                            })
                                          }
                                        >
                                          {message}{" "}
                                          {lines && lines.length > 0 && (
                                            <span>
                                              (Ligne
                                              {lines.length > 1 && "s"}{" "}
                                              {lines
                                                .map((l) => l + 1)
                                                .join(",")}
                                              )
                                            </span>
                                          )}
                                        </Button>
                                      </div>
                                    )
                                  )}
                                </div>
                              ) : null;
                            })}
                        </PanelBarItem>
                      </PanelBar>
                    </div>
                  </div>
                )}
                <TabStrip
                  selected={selectedTab}
                  onSelect={({ selected }) =>
                    this.setState({
                      selectedTab: selected,
                    })
                  }
                  className="tab-strip-responsive"
                >
                  <TabStripTab title="Description">
                    <Description
                      edit={edit}
                      valueGetter={formProps.valueGetter}
                      onChange={formProps.onChange}
                      errors={formProps.errors}
                    />
                  </TabStripTab>
                </TabStrip>
              </FormElement>
              <div className="k-display-flex k-justify-content-center sticky-footer">
                {
                  <Button
                    className="k-mr-4"
                    icon="close"
                    title="Fermer"
                    onClick={() => {
                      router.navigate("/app/administration/cooperatives");
                    }}
                  >
                    Fermer
                  </Button>
                }
                {/* eslint-disable-next-line eqeqeq */}
                {(typeUtilisateurId === RULE.SUPERVISEUR_DOMAINE ||
                  typeUtilisateurId === RULE.TECHNICIEN_COOP) &&
                  !edit && (
                    <Button
                      className="k-mr-4"
                      icon={edit ? "undo" : "pencil"}
                      title={edit ? "Visualiser" : "Editer"}
                      onClick={() => {
                        this.handleOnClickEdit();
                      }}
                    >
                      Editer
                    </Button>
                  )}
                {edit &&
                  (!formProps.valueGetter("description.typeCoopId") ||
                    formProps.valueGetter("description.typeCoopId") !==
                      COOP_TYPE_DOMAINE) && (
                    <>
                      <Button
                        data-cy="submit_form"
                        icon="save"
                        onClick={() => {
                          formProps.onSubmit();
                        }}
                      >
                        Enregistrer les données
                      </Button>
                      <Button
                        className="k-ml-4"
                        icon="reload"
                        onClick={() => window.location.reload()}
                      >
                        Annuler la saisie
                      </Button>
                    </>
                  )}
              </div>
            </div>
          );
        }}
      />
    );
  }
}

SaisieCooperative.propTypes = {
  idCooperative: PropTypes.string,
  createNew: PropTypes.bool,
  router: PropTypes.object.isRequired,
  domaineId: PropTypes.number.isRequired,
};

SaisieCooperative.defaultProps = {
  idCooperative: "",
  createNew: false,
};
export default compose(connect(mapStateToProps), withRouter)(SaisieCooperative);
