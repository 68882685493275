import { Field } from "@progress/kendo-react-form";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { Component } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@progress/kendo-react-tooltip";

// TODO extraire ces 2 fonctions dans un module `utils` ?
function sumOrNull(arr) {
  return arr.every((a) => a === null)
    ? null
    : arr.reduce((acc, curr) => acc + curr, 0);
}

function initExclusiveSet(valueGetter, total, chev, chet) {
  if (valueGetter(chev) !== null || valueGetter(chet) !== null) {
    return new Set([total]);
  }
  if (valueGetter(total) !== null) {
    return new Set([chev, chet]);
  }
  return new Set();
}

class Assolement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gramDisabledFields: initExclusiveSet(
        props.valueGetter,
        "asGramTotHa",
        "asGramChevHa",
        "asGramChetHa"
      ),
      legDisabledFields: initExclusiveSet(
        props.valueGetter,
        "asLegTotHa",
        "asLegChevHa",
        "asLegChetHa"
      ),
      assocDisabledFields: initExclusiveSet(
        props.valueGetter,
        "asAssoTotHa",
        "asAssoChevHa",
        "asAssoChetHa"
      ),
      maisDisabledFields: initExclusiveSet(
        props.valueGetter,
        "asMaisTotHa",
        "asMaisChevHa",
        "asMaisChetHa"
      ),
      praiDisabledFields: initExclusiveSet(
        props.valueGetter,
        "asPraiTotHa",
        "asPraiChevHa",
        "asPraiChetHa"
      ),
      autre1DisabledFields: initExclusiveSet(
        props.valueGetter,
        "asAutre1TotHa",
        "asAutre1ChevHa",
        "asAutre1ChetHa"
      ),
      autre2DisabledFields: initExclusiveSet(
        props.valueGetter,
        "asAutre2TotHa",
        "asAutre2ChevHa",
        "asAutre2ChetHa"
      ),
      derob1DisabledFields: initExclusiveSet(
        props.valueGetter,
        "asDerob1TotHa",
        "asDerob1ChevHa",
        "asDerob1ChetHa"
      ),
      derob2DisabledFields: initExclusiveSet(
        props.valueGetter,
        "asDerob2TotHa",
        "asDerob2ChevHa",
        "asDerob2ChetHa"
      ),
    };
  }

  handleExclusiveFields = (fieldGroup, disableFields, value) => {
    if (value !== null) {
      this.setState(({ [fieldGroup]: oldSet }) => {
        const newSet = new Set(oldSet);
        disableFields.forEach((df) => {
          newSet.add(df);
        });
        return {
          [fieldGroup]: newSet,
        };
      });
    } else {
      this.setState(({ [fieldGroup]: oldSet }) => {
        const newSet = new Set(oldSet);
        disableFields.forEach((df) => {
          newSet.delete(df);
        });
        return {
          [fieldGroup]: newSet,
        };
      });
    }
  };

  render() {
    const { edit, valueGetter, onChange } = this.props;
    const {
      gramDisabledFields,
      legDisabledFields,
      assocDisabledFields,
      maisDisabledFields,
      praiDisabledFields,
      autre1DisabledFields,
      autre2DisabledFields,
      derob1DisabledFields,
      derob2DisabledFields,
    } = this.state;

    return (
      <div className="k-m-4 tab-donnees-annuelles">
        <div className="k-mb-4 grid-wrapper-form-4-custom">
          <div style={{ gridColumnStart: 4 }}>
            <p
              className={
                edit
                  ? "background-color-primary text-color-white k-font-weight-bold k-text-center k-py-2"
                  : "background-color-default k-font-weight-bold k-text-center k-py-2"
              }
            >
              Détail des surfaces fourragères caprins
            </p>
          </div>
        </div>
        <div className="k-mb-4 grid-wrapper-form-4-custom">
          <div style={{ gridColumnStart: 2 }}>
            <p className="k-font-weight-bold">Surface en Ha</p>
          </div>
          <div style={{ gridColumnStart: 4 }}>
            <p className="k-font-weight-bold k-text-center">Surface en Ha</p>
          </div>
        </div>
        <div className="k-mb-4 grid-wrapper-form-4-custom">
          <div style={{ gridColumnStart: 4 }}>
            <div className="grid-wrapper-form-3">
              <span className="k-font-weight-bold ">CHÈVRES</span>
              <span className="k-font-weight-bold ">CHEVRETTES</span>
              <span className="k-font-weight-bold ">TOTAL</span>
            </div>
          </div>
        </div>

        <div className="k-mb-4 grid-wrapper-form-4-custom">
          <div className="k-d-flex">
            <div>Surface Agricole Utile (SAU)</div>
            <Tooltip>
              <span className="required k-ml-2" title="Champ obligatoire">
                *
              </span>
            </Tooltip>
          </div>
          <div className="k-text-right">
            <Field
              component={NumericTextBox}
              disabled={!edit}
              name="asSauHa"
              format="n1"
              value={valueGetter("asSauHa")}
              onChange={(ev) => onChange("asSauHa", ev)}
              required
              spinners={edit}
              min={0}
            />
          </div>
          <div>Graminées cultivées pures (fétuque / ray-grass)</div>
          <div className="grid-wrapper-form-3">
            <Field
              component={NumericTextBox}
              format="n1"
              name="asGramChevHa"
              disabled={edit ? gramDisabledFields.has("asGramChevHa") : true}
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "gramDisabledFields",
                  ["asGramTotHa"],
                  value
                );
                onChange("asGramTotHa", {
                  value: sumOrNull([value, valueGetter("asGramChetHa")]),
                });
              }}
              spinners={edit}
              min={0}
            />
            <Field
              component={NumericTextBox}
              format="n1"
              name="asGramChetHa"
              disabled={edit ? gramDisabledFields.has("asGramChetHa") : true}
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "gramDisabledFields",
                  ["asGramTotHa"],
                  value
                );
                onChange("asGramTotHa", {
                  value: sumOrNull([value, valueGetter("asGramChevHa")]),
                });
              }}
              spinners={edit}
              min={0}
            />
            <Field
              component={NumericTextBox}
              format="n1"
              name="asGramTotHa"
              disabled={
                edit
                  ? gramDisabledFields.has("asGramTotHa") ||
                    valueGetter("asGramChevHa") !== null ||
                    valueGetter("asGramChetHa") !== null
                  : true
              }
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "gramDisabledFields",
                  ["asGramChevHa", "asGramChetHa"],
                  value
                );
              }}
              spinners={edit}
              min={0}
            />
          </div>
        </div>

        <div className="k-mb-4 grid-wrapper-form-4-custom">
          <div style={{ gridColumnStart: 3 }}>
            Légumineuses (Luzerne / Trèfle)
          </div>
          <div className="grid-wrapper-form-3">
            <Field
              component={NumericTextBox}
              format="n1"
              name="asLegChevHa"
              disabled={edit ? legDisabledFields.has("asLegChevHa") : true}
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "legDisabledFields",
                  ["asLegTotHa"],
                  value
                );
                onChange("asLegTotHa", {
                  value: sumOrNull([value, valueGetter("asLegChetHa")]),
                });
              }}
              spinners={edit}
              min={0}
            />
            <Field
              component={NumericTextBox}
              format="n1"
              name="asLegChetHa"
              disabled={edit ? legDisabledFields.has("asLegChetHa") : true}
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "legDisabledFields",
                  ["asLegTotHa"],
                  value
                );
                onChange("asLegTotHa", {
                  value: sumOrNull([value, valueGetter("asLegChevHa")]),
                });
              }}
              spinners={edit}
              min={0}
            />
            <Field
              component={NumericTextBox}
              format="n1"
              name="asLegTotHa"
              disabled={
                edit
                  ? legDisabledFields.has("asLegTotHa") ||
                    valueGetter("asLegChevHa") !== null ||
                    valueGetter("asLegChetHa") !== null
                  : true
              }
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "legDisabledFields",
                  ["asLegChevHa", "asLegChetHa"],
                  value
                );
              }}
              spinners={edit}
              min={0}
            />
          </div>
        </div>

        <div className="k-mb-4 grid-wrapper-form-4-custom k-align-items-center">
          <div className="k-align-items-center k-d-flex">
            <div>
              <span>
                Surface en cultures de vente <br />
                (blé, tournesol, maïs grain, orge…)
              </span>
            </div>
            <div>
              <Tooltip className="k-d-flex k-align-items-center">
                <span
                  className="k-icon k-i-information k-ml-2"
                  title="Y compris céréales distribuées au troupeau laitier caprin"
                />
              </Tooltip>
            </div>
          </div>

          <div className="k-text-right">
            <NumericTextBox
              disabled={!edit}
              name="asSurfCultHa"
              value={valueGetter("asSurfCultHa")}
              onChange={(ev) => onChange("asSurfCultHa", ev)}
              format="n1"
              spinners={edit}
              min={0}
            />
          </div>
          <div>Associations graminées + Légumineuses</div>
          <div className="grid-wrapper-form-3">
            <Field
              component={NumericTextBox}
              format="n1"
              name="asAssoChevHa"
              disabled={edit ? assocDisabledFields.has("asAssoChevHa") : true}
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "assocDisabledFields",
                  ["asAssoTotHa"],
                  value
                );
                onChange("asAssoTotHa", {
                  value: sumOrNull([value, valueGetter("asAssoChetHa")]),
                });
              }}
              spinners={edit}
              min={0}
            />
            <Field
              component={NumericTextBox}
              format="n1"
              name="asAssoChetHa"
              disabled={edit ? assocDisabledFields.has("asAssoChetHa") : true}
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "assocDisabledFields",
                  ["asAssoTotHa"],
                  value
                );
                onChange("asAssoTotHa", {
                  value: sumOrNull([value, valueGetter("asAssoChevHa")]),
                });
              }}
              spinners={edit}
              min={0}
            />
            <Field
              component={NumericTextBox}
              format="n1"
              name="asAssoTotHa"
              disabled={
                edit
                  ? assocDisabledFields.has("asAssoTotHa") ||
                    valueGetter("asAssoChevHa") !== null ||
                    valueGetter("asAssoChetHa") !== null
                  : true
              }
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "assocDisabledFields",
                  ["asAssoChevHa", "asAssoChetHa"],
                  value
                );
              }}
              spinners={edit}
              min={0}
            />
          </div>
        </div>

        <div className="k-mb-4 grid-wrapper-form-4-custom">
          <div style={{ gridColumnStart: 3 }}>
            Maïs ensilage ou céréales ensilées
          </div>
          <div className="grid-wrapper-form-3">
            <Field
              component={NumericTextBox}
              format="n1"
              name="asMaisChevHa"
              disabled={edit ? maisDisabledFields.has("asMaisChevHa") : true}
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "maisDisabledFields",
                  ["asMaisTotHa"],
                  value
                );
                onChange("asMaisTotHa", {
                  value: sumOrNull([value, valueGetter("asMaisChetHa")]),
                });
              }}
              spinners={edit}
              min={0}
            />
            <Field
              component={NumericTextBox}
              format="n1"
              name="asMaisChetHa"
              disabled={edit ? maisDisabledFields.has("asMaisChetHa") : true}
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "maisDisabledFields",
                  ["asMaisTotHa"],
                  value
                );
                onChange("asMaisTotHa", {
                  value: sumOrNull([value, valueGetter("asMaisChevHa")]),
                });
              }}
              spinners={edit}
              min={0}
            />
            <Field
              component={NumericTextBox}
              format="n1"
              name="asMaisTotHa"
              disabled={
                edit
                  ? maisDisabledFields.has("asMaisTotHa") ||
                    valueGetter("asMaisChevHa") !== null ||
                    valueGetter("asMaisChetHa") !== null
                  : true
              }
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "maisDisabledFields",
                  ["asMaisChevHa", "asMaisChetHa"],
                  value
                );
              }}
              spinners={edit}
              min={0}
            />
          </div>
        </div>

        <div className="k-mb-4 grid-wrapper-form-4-custom">
          <div className="k-d-flex k-align-items-center">
            Surface Fourragère totale
            <span
              className="k-icon k-i-information k-mx-2"
              title="Surface destinée à tous les troupeaux"
            />
            <Tooltip>
              <span className="required k-ml-2" title="Champ obligatoire">
                *
              </span>
            </Tooltip>
          </div>
          <div className="k-text-right">
            <Field
              component={NumericTextBox}
              format="n1"
              name="asSurfFourTotHa"
              value={valueGetter("asSurfFourTotHa")}
              onChange={(ev) => onChange("asSurfFourTotHa", ev)}
              disabled={!edit}
              required
              spinners={edit}
              min={0}
            />
          </div>
          <div>Prairies naturelles</div>
          <div className="grid-wrapper-form-3">
            <Field
              component={NumericTextBox}
              format="n1"
              name="asPraiChevHa"
              disabled={edit ? praiDisabledFields.has("asPraiChevHa") : true}
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "praiDisabledFields",
                  ["asPraiTotHa"],
                  value
                );
                onChange("asPraiTotHa", {
                  value: sumOrNull([value, valueGetter("asPraiChetHa")]),
                });
              }}
              spinners={edit}
              min={0}
            />
            <Field
              component={NumericTextBox}
              format="n1"
              name="asPraiChetHa"
              disabled={edit ? praiDisabledFields.has("asPraiChetHa") : true}
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "praiDisabledFields",
                  ["asPraiTotHa"],
                  value
                );
                onChange("asPraiTotHa", {
                  value: sumOrNull([value, valueGetter("asPraiChevHa")]),
                });
              }}
              spinners={edit}
              min={0}
            />
            <Field
              component={NumericTextBox}
              format="n1"
              name="asPraiTotHa"
              disabled={
                edit
                  ? praiDisabledFields.has("asPraiTotHa") ||
                    valueGetter("asPraiChevHa") !== null ||
                    valueGetter("asPraiChetHa") !== null
                  : true
              }
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "praiDisabledFields",
                  ["asPraiChevHa", "asPraiChetHa"],
                  value
                );
              }}
              spinners={edit}
              min={0}
            />
          </div>
        </div>

        <div className="k-mb-4 grid-wrapper-form-4-custom">
          <div style={{ gridColumnStart: 3 }} className="grid-wrapper-form-2">
            <span className="k-d-flex">
              Autre 1
              {(valueGetter("asAutre1ChevHa") != null ||
                valueGetter("asAutre1ChetHa") != null ||
                valueGetter("asAutre1TotHa") != null) && (
                <Tooltip className="k-d-flex k-align-items-center">
                  <span className="required k-mx-2" title="Champ obligatoire">
                    *
                  </span>
                </Tooltip>
              )}
            </span>
            <div className="k-d-flex">
              <Field
                className={`${!edit && "k-state-disabled"}`}
                component={Input}
                format="n1"
                name="asAutre1Lib"
                disabled={!edit}
                min={0}
              />
            </div>
          </div>
          <div className="grid-wrapper-form-3">
            <Field
              className="k-align-self-start"
              component={NumericTextBox}
              format="n1"
              name="asAutre1ChevHa"
              disabled={
                edit ? autre1DisabledFields.has("asAutre1ChevHa") : true
              }
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "autre1DisabledFields",
                  ["asAutre1TotHa"],
                  value
                );
                onChange("asAutre1TotHa", {
                  value: sumOrNull([value, valueGetter("asAutre1ChetHa")]),
                });
              }}
              spinners={edit}
              min={0}
            />
            <Field
              className="k-align-self-start"
              component={NumericTextBox}
              format="n1"
              name="asAutre1ChetHa"
              disabled={
                edit ? autre1DisabledFields.has("asAutre1ChetHa") : true
              }
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "autre1DisabledFields",
                  ["asAutre1TotHa"],
                  value
                );
                onChange("asAutre1TotHa", {
                  value: sumOrNull([value, valueGetter("asAutre1ChevHa")]),
                });
              }}
              spinners={edit}
              min={0}
            />
            <Field
              className="k-align-self-start"
              component={NumericTextBox}
              format="n1"
              name="asAutre1TotHa"
              disabled={
                edit
                  ? autre1DisabledFields.has("asAutre1TotHa") ||
                    valueGetter("asAutre1ChevHa") !== null ||
                    valueGetter("asAutre1ChetHa") !== null
                  : true
              }
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "autre1DisabledFields",
                  ["asAutre1ChevHa", "asAutre1ChetHa"],
                  value
                );
              }}
              spinners={edit}
              min={0}
            />
          </div>
        </div>

        <div className="k-mb-4 grid-wrapper-form-4-custom">
          <div>Surface Fourragère caprins</div>
          <NumericTextBox
            spinners={false}
            format="n1"
            disabled
            value={
              valueGetter("asGramTotHa") +
              valueGetter("asLegTotHa") +
              valueGetter("asAssoTotHa") +
              valueGetter("asMaisTotHa") +
              valueGetter("asPraiTotHa") +
              valueGetter("asAutre1TotHa") +
              valueGetter("asAutre2TotHa")
            }
            min={0}
          />
          <div className="grid-wrapper-form-2">
            <span className="k-d-flex">
              Autre 2
              {(valueGetter("asAutre2ChevHa") != null ||
                valueGetter("asAutre2ChetHa") != null ||
                valueGetter("asAutre2TotHa") != null) && (
                <Tooltip className="k-d-flex k-align-items-center">
                  <span className="required k-mx-2" title="Champ obligatoire">
                    *
                  </span>
                </Tooltip>
              )}
            </span>
            <Field
              disabled={!edit}
              component={Input}
              format="n1"
              name="asAutre2Lib"
              className={`${!edit && "k-state-disabled"}`}
              min={0}
            />
          </div>
          <div className="grid-wrapper-form-3">
            <Field
              component={NumericTextBox}
              format="n1"
              name="asAutre2ChevHa"
              disabled={
                edit ? autre2DisabledFields.has("asAutre2ChevHa") : true
              }
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "autre2DisabledFields",
                  ["asAutre2TotHa"],
                  value
                );
                onChange("asAutre2TotHa", {
                  value: sumOrNull([value, valueGetter("asAutre2ChetHa")]),
                });
              }}
              spinners={edit}
              min={0}
            />
            <Field
              component={NumericTextBox}
              format="n1"
              name="asAutre2ChetHa"
              disabled={
                edit ? autre2DisabledFields.has("asAutre2ChetHa") : true
              }
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "autre2DisabledFields",
                  ["asAutre2TotHa"],
                  value
                );
                onChange("asAutre2TotHa", {
                  value: sumOrNull([value, valueGetter("asAutre2ChevHa")]),
                });
              }}
              spinners={edit}
              min={0}
            />
            <Field
              component={NumericTextBox}
              format="n1"
              name="asAutre2TotHa"
              disabled={
                edit
                  ? autre2DisabledFields.has("asAutre2TotHa") ||
                    valueGetter("asAutre1ChevHa") !== null ||
                    valueGetter("asAutre1ChetHa") !== null
                  : true
              }
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "autre2DisabledFields",
                  ["asAutre2ChevHa", "asAutre2ChetHa"],
                  value
                );
              }}
              spinners={edit}
              min={0}
            />
          </div>
        </div>

        <div className="k-mb-4 grid-wrapper-form-4-custom">
          <span style={{ gridColumnStart: 3 }} className="k-font-weight-bold">
            TOTAL SPF CAPRINS
          </span>

          <div className="grid-wrapper-form-3">
            <NumericTextBox
              spinners={false}
              format="n1"
              disabled
              value={
                valueGetter("asGramChevHa") +
                valueGetter("asLegChevHa") +
                valueGetter("asAssoChevHa") +
                valueGetter("asMaisChevHa") +
                valueGetter("asPraiChevHa") +
                valueGetter("asAutre1ChevHa") +
                valueGetter("asAutre2ChevHa")
              }
              min={0}
            />
            <NumericTextBox
              spinners={false}
              format="n1"
              disabled
              value={
                valueGetter("asGramChevHa") +
                valueGetter("asLegChetHa") +
                valueGetter("asAssoChetHa") +
                valueGetter("asMaisChetHa") +
                valueGetter("asPraiChetHa") +
                valueGetter("asAutre1ChetHa") +
                valueGetter("asAutre2ChetHa")
              }
              min={0}
            />
            <NumericTextBox
              spinners={false}
              format="n1"
              disabled
              value={
                valueGetter("asGramTotHa") +
                valueGetter("asLegTotHa") +
                valueGetter("asAssoTotHa") +
                valueGetter("asMaisTotHa") +
                valueGetter("asPraiTotHa") +
                valueGetter("asAutre1TotHa") +
                valueGetter("asAutre2TotHa")
              }
              min={0}
            />
          </div>
        </div>

        <div className="k-mb-4 grid-wrapper-form-4-custom">
          <div style={{ gridColumnStart: 3 }}>Cultures dérobées</div>
          <div className="grid-wrapper-form-3">
            <NumericTextBox
              spinners={false}
              format="n1"
              disabled
              value={
                valueGetter("asDerob1ChevHa") + valueGetter("asDerob2ChevHa")
              }
              min={0}
            />
            <NumericTextBox
              spinners={false}
              format="n1"
              disabled
              value={
                valueGetter("asDerob1ChetHa") + valueGetter("asDerob2ChetHa")
              }
              min={0}
            />
            <NumericTextBox
              spinners={false}
              format="n1"
              disabled
              value={
                valueGetter("asDerob1TotHa") + valueGetter("asDerob2TotHa")
              }
              min={0}
            />
          </div>
        </div>

        <div className="k-mb-4 grid-wrapper-form-4-custom">
          <div style={{ gridColumnStart: 3 }} className="grid-wrapper-form-2">
            <span className="k-d-flex">
              Dérobée 1
              {(valueGetter("asDerob1ChevHa") != null ||
                valueGetter("asDerob1ChetHa") != null ||
                valueGetter("asDerob1TotHa") != null) && (
                <Tooltip className="k-d-flex k-align-items-center">
                  <span className="required k-mx-2" title="Champ obligatoire">
                    *
                  </span>
                </Tooltip>
              )}
            </span>
            <Field
              component={Input}
              className={`${!edit && "k-state-disabled"}`}
              format="n1"
              name="asDerob1Lib"
              disabled={!edit}
              required={
                valueGetter("asDerob1ChevHa") !== null ||
                valueGetter("asDerob1ChetHa") !== null ||
                valueGetter("asDerob1TotHa") !== null
              }
              min={0}
            />
          </div>
          <div className="grid-wrapper-form-3">
            <Field
              component={NumericTextBox}
              format="n1"
              name="asDerob1ChevHa"
              disabled={
                edit ? derob1DisabledFields.has("asDerob1ChevHa") : true
              }
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "derob1DisabledFields",
                  ["asDerob1TotHa"],
                  value
                );
                onChange("asDerob1TotHa", {
                  value: sumOrNull([value, valueGetter("asDerob1ChetHa")]),
                });
              }}
              spinners={edit}
              min={0}
            />
            <Field
              component={NumericTextBox}
              format="n1"
              name="asDerob1ChetHa"
              disabled={
                edit ? derob1DisabledFields.has("asDerob1ChetHa") : true
              }
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "derob1DisabledFields",
                  ["asDerob1TotHa"],
                  value
                );
                onChange("asDerob1TotHa", {
                  value: sumOrNull([value, valueGetter("asDerob1ChevHa")]),
                });
              }}
              spinners={edit}
              min={0}
            />
            <Field
              component={NumericTextBox}
              format="n1"
              name="asDerob1TotHa"
              disabled={
                edit
                  ? derob1DisabledFields.has("asDerob1TotHa") ||
                    valueGetter("asDerob1ChevHa") !== null ||
                    valueGetter("asDerob1ChetHa") !== null
                  : true
              }
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "derob1DisabledFields",
                  ["asDerob1ChevHa", "asDerob1ChetHa"],
                  value
                );
              }}
              spinners={edit}
              min={0}
            />
          </div>
        </div>

        <div className="k-mb-4 grid-wrapper-form-4-custom">
          <div style={{ gridColumnStart: 3 }} className="grid-wrapper-form-2">
            <span className="k-d-flex">
              Dérobée 2
              {(valueGetter("asDerob2ChevHa") != null ||
                valueGetter("asDerob2ChetHa") != null ||
                valueGetter("asDerob2TotHa") != null) && (
                <Tooltip className="k-d-flex k-align-items-center">
                  <span className="required k-mx-2" title="Champ obligatoire">
                    *
                  </span>
                </Tooltip>
              )}
            </span>
            <Field
              component={Input}
              className={`${!edit && "k-state-disabled"}`}
              format="n1"
              name="asDerob2Lib"
              disabled={!edit}
              required={
                valueGetter("asDerob2ChevHa") !== null ||
                valueGetter("asDerob2ChetHa") !== null ||
                valueGetter("asDerob2TotHa") !== null
              }
              min={0}
            />
          </div>
          <div className="grid-wrapper-form-3">
            <Field
              component={NumericTextBox}
              format="n1"
              name="asDerob2ChevHa"
              disabled={
                edit ? derob2DisabledFields.has("asDerob2ChevHa") : true
              }
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "derob2DisabledFields",
                  ["asDerob2TotHa"],
                  value
                );
                onChange("asDerob2TotHa", {
                  value: sumOrNull([value, valueGetter("asDerob2ChetHa")]),
                });
              }}
              spinners={edit}
              min={0}
            />
            <Field
              component={NumericTextBox}
              format="n1"
              name="asDerob2ChetHa"
              disabled={
                edit ? derob2DisabledFields.has("asDerob2ChetHa") : true
              }
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "derob2DisabledFields",
                  ["asDerob2TotHa"],
                  value
                );
                onChange("asDerob2TotHa", {
                  value: sumOrNull([value, valueGetter("asDerob2ChevHa")]),
                });
              }}
              spinners={edit}
              min={0}
            />
            <Field
              component={NumericTextBox}
              format="n1"
              name="asDerob2TotHa"
              disabled={
                edit
                  ? derob2DisabledFields.has("asDerob2TotHa") ||
                    valueGetter("asDerob2ChevHa") !== null ||
                    valueGetter("asDerob2ChetHa") !== null
                  : true
              }
              onChange={({ value }) => {
                this.handleExclusiveFields(
                  "derob2DisabledFields",
                  ["asDerob2ChevHa", "asDerob2ChetHa"],
                  value
                );
              }}
              spinners={edit}
              min={0}
            />
          </div>
        </div>
      </div>
    );
  }
}

Assolement.propTypes = {
  edit: PropTypes.bool.isRequired,
  valueGetter: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Assolement;
