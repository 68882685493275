import { Component } from "react";
import { Label } from "@progress/kendo-react-labels";
import { Field } from "@progress/kendo-react-form";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-buttons";
import dataSource from "../../../../dataSource/dataSource";
import { RULE } from "../../../shared/constants";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typesUser: [],
      structures: [],
      showStructure: true,
    };
  }

  async componentDidMount() {
    const structuresAll = await dataSource.loadStructures();
    const structures = structuresAll.sort((l, r) => {
      return r.coopLibelleCourt < l.coopLibelleCourt;
    });
    const typesUserAll = await dataSource.loadModaliste("TYUT");
    const typesUser = typesUserAll.filter((tu) => {
      return (
        tu.rang_modaliste === RULE.SUPERVISEUR_DOMAINE ||
        tu.rang_modaliste === RULE.TECHNICIEN_ADMIN ||
        tu.rang_modaliste === RULE.TECHNICIEN_COOP
      );
    });
    this.setState({ structures, typesUser });
  }

  render() {
    const {
      edit,
      valueGetter,
      onChange,
      createNew,
      errors,
      isTechnicien,
    } = this.props;
    const { structures, typesUser, showStructure } = this.state;
    const msgReprise = `Si non renseigné, sera automatiquement repris de l'onglet description lors de l'enregistrement`;

    // On fait ça car c'est un objet qui sert d'index pour la combobox
    const typeUser = typesUser.find(
      (tu) => tu.rang_modaliste === valueGetter("contact.typeUser")
    );
    const contact_structures = valueGetter("contact.structures");
    let structure = {};
    if (contact_structures !== undefined) {
      structure = structures.find((s) => {
        if (contact_structures.includes(",")) {
          // DOMAINE, COOP
          return contact_structures.split(",")[1].includes(s.coopLibelleCourt);
        }
        // Ce cas là est en fait inutile car on ne doit pas afficher la combo dans ce cas
        // puisque par défaut c'est le domaine, mais c'est mieux pour la cohérence du code
        // pour que cela fonctionne dans tous les cas
        return contact_structures.includes(s.coopLibelleCourt);
      });
    }

    return (
      <div className="k-d-flex k-flex-column k-m-4">
        <div className="k-d-grid k-grid-cols-2 k-gap-3">
          <div className="k-p-4">
            {isTechnicien && (
              <div className="grid-wrapper-form-2 k-mb-4">
                <Tooltip>
                  <Label>
                    Type
                    <span className="required k-ml-2" title="Champ obligatoire">
                      *
                    </span>
                  </Label>
                </Tooltip>
                <DropDownList
                  id="type_user"
                  disabled={!edit}
                  data={typesUser}
                  value={typeUser}
                  textField="libelle"
                  onChange={({ value }) => {
                    onChange("contact.typeUser", {
                      value: value.rang_modaliste,
                    });
                    this.setState({
                      showStructure:
                        value.rang_modaliste !== RULE.SUPERVISEUR_DOMAINE,
                    });
                  }}
                  required
                  valid={
                    !isTechnicien ||
                    (isTechnicien && !errors?.contact?.typeUser)
                  }
                />
              </div>
            )}
            {isTechnicien && showStructure && (
              <div
                className="grid-wrapper-form-2 k-mb-4"
                style={{ display: showStructure ? "" : "none" }}
              >
                <Tooltip>
                  <Label>
                    Structure
                    <span className="required k-ml-2" title="Champ obligatoire">
                      *
                    </span>
                  </Label>
                </Tooltip>
                <DropDownList
                  id="type_structure"
                  disabled={!edit}
                  data={structures}
                  value={structure}
                  textField="coopLibelleCourt"
                  onChange={({ value }) => {
                    // Juste pour être cohérent par le fait que contact.structures contient en permanence
                    // DOMAINE, COOP ou juste DOMAINE même s'il n'est n'est réutilisé que pour être envoyé
                    // et non affiché dans le catalogue (on ne revient pas sur le catalogue avec cet objet)
                    // c'est pour garder la même sémantique de ce champs.
                    // Si on ne gardait que la valeur COOP cela fonctionnerait quand-même.
                    // TODO: REFACTORING: n'utiliser que les identifiants numériques (BACK, FRONT)
                    let domain = "";
                    const cs = valueGetter("contact.structures");
                    if (cs !== undefined) {
                      if (cs.includes(",")) {
                        domain = `${cs.split(",")[0]}, `;
                      }
                    }
                    // Pour l'affichage
                    onChange("contact.structures", {
                      value: `${domain}${value.coopLibelleCourt}`,
                    });
                    // Pour l'enrergistrement
                    onChange("contact.coopId", {
                      value: value.coopId,
                    });
                  }}
                  required
                  valid={
                    !(isTechnicien && showStructure) ||
                    (isTechnicien &&
                      showStructure &&
                      !errors?.contact?.structures)
                  }
                />
              </div>
            )}
            {isTechnicien && (
              <div className="grid-wrapper-form-2 k-mb-4">
                <Tooltip>
                  <Label>Code</Label>
                </Tooltip>
                <Input
                  data-cy="contact.code"
                  value={valueGetter("contact.code")}
                  onChange={(value) => onChange("contact.code", value)}
                  disabled={!edit}
                  name="contact.code"
                />
              </div>
            )}
            <div className="grid-wrapper-form-2 k-mb-4">
              <Tooltip>
                <Label>
                  Nom
                  <span className="required k-ml-2" title="Champ obligatoire">
                    *
                  </span>
                </Label>
              </Tooltip>
              <Input
                data-cy="contact.nom"
                value={valueGetter("contact.nom")}
                onChange={(value) => onChange("contact.nom", value)}
                disabled={!edit}
                name="contact.nom"
                required
                valid={!errors?.contact?.nom}
              />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Label>Prénom</Label>
              <Field disabled={!edit} component={Input} name="contact.prenom" />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Label>Adresse</Label>
              <Field
                disabled={!edit}
                component={Input}
                name="contact.adresse"
                title={createNew ? msgReprise : ""}
              />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Label>Code postal</Label>
              <Field
                disabled={!edit}
                component={Input}
                name="contact.codePostal"
                title={createNew ? msgReprise : ""}
              />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Label>Ville</Label>
              <Field
                disabled={!edit}
                component={Input}
                name="contact.commune"
                title={createNew ? msgReprise : ""}
              />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Label>Téléphone</Label>
              <Field
                disabled={!edit}
                component={Input}
                name="contact.telephone"
              />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Label>Portable</Label>
              <Field
                disabled={!edit}
                component={Input}
                name="contact.portable"
              />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Label>e-mail</Label>
              <Field disabled={!edit} component={Input} name="contact.email" />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Tooltip>
                <Label>
                  Login
                  <span className="required k-ml-2" title="Champ obligatoire">
                    *
                  </span>
                </Label>
              </Tooltip>
              <Input
                value={valueGetter("contact.identifiant")}
                onChange={(value) => onChange("contact.identifiant", value)}
                disabled={!edit}
                name="contact.identifiant"
                autoComplete="off"
                required
                valid={!errors?.contact?.identifiant}
              />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              {!createNew && <Label>Réinitialisation du mot de passe</Label>}
              {createNew && (
                <Tooltip>
                  <Label>
                    Initialisation du mot de passe
                    <span className="required k-ml-2" title="Champ obligatoire">
                      *
                    </span>
                  </Label>
                </Tooltip>
              )}
              <Input
                disabled={!edit}
                name="contact.motDePasse"
                type="password"
                autoComplete="new-password"
                value={valueGetter("contact.motDePasse")}
                onChange={(value) => onChange("contact.motDePasse", value)}
                required={createNew}
                valid={createNew ? !errors?.contact?.motDePasse : true}
              />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Tooltip>
                <Label>
                  Date début
                  <span className="required k-ml-2" title="Champ obligatoire">
                    *
                  </span>
                </Label>
              </Tooltip>

              <div className="k-d-flex">
                <DatePicker
                  disabled={!edit}
                  value={
                    valueGetter("contact.dateDebut") &&
                    dayjs(valueGetter("contact.dateDebut")).isValid()
                      ? dayjs(valueGetter("contact.dateDebut")).toDate()
                      : null
                  }
                  onChange={({ value }) =>
                    onChange("contact.dateDebut", {
                      value,
                    })
                  }
                  valid={!errors?.contact?.dateDebut}
                />
                <Button
                  type="button"
                  disabled={!edit}
                  icon="reset"
                  title="réinitialiser la date"
                  onClick={() => {
                    onChange("contact.dateDebut", { value: "" });
                  }}
                />
              </div>
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Label>Date fin</Label>
              <div className="k-d-flex">
                <DatePicker
                  disabled={!edit}
                  value={
                    valueGetter("contact.dateFin") &&
                    dayjs(valueGetter("contact.dateFin")).isValid()
                      ? dayjs(valueGetter("contact.dateFin")).toDate()
                      : null
                  }
                  onChange={({ value }) =>
                    onChange("contact.dateFin", {
                      value,
                    })
                  }
                  valid={!errors?.contact?.dateFin}
                />
                <Button
                  type="button"
                  disabled={!edit}
                  icon="reset"
                  title="réinitialiser la date"
                  onClick={() => {
                    onChange("contact.dateFin", { value: "" });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Contact.propTypes = {
  edit: PropTypes.bool.isRequired,
  valueGetter: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  createNew: PropTypes.bool,
  isTechnicien: PropTypes.bool,
  errors: PropTypes.object,
};

Contact.defaultProps = {
  createNew: false,
  isTechnicien: false,
  errors: {},
};
export default Contact;
