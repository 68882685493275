import { useQueryClient } from "@tanstack/react-query";
import React from "react";

/**
 * @typedef {Object} HeaderData
 * @property {string | undefined} Groupe
 * @property {string | undefined} Nom
 * @property {string | undefined} NumAdh
 * @property {string | undefined} Laiterie
 * @property {string | undefined} MoisTraite
 * @property {string | undefined} Periode
 */

/**
 * @typedef {Object} HeaderProps
 * @property {import('../ReportViewer').ReportParameter} params {@link ReportParameter}
 * @property {string} [supertitle]
 * @param {HeaderProps & React.HTMLAttributes<HTMLTableElement>} props {@link HeaderProps}
 */
export const Header = ({ style, params, supertitle }) => {
  const queryClient = useQueryClient();
  const data = /** @type {HeaderData} */ (
    queryClient.getQueryData(["Header", params])
  );

  return data ? (
    <>
      <div
        style={{
          marginTop: "4mm",
          display: "flex",
          justifyContent: "space-between",
          fontSize: "12px",
        }}
      >
        <table style={style}>
          <tbody>
            {data.Nom && 
              <tr>
                <td>Nom :</td>
                <td>{data.Nom}</td>
              </tr>
            }
            {data.NumAdh && 
            <tr>
              <td>N° Adhérent :</td>
              <td>{data.NumAdh}</td>
            </tr>
            }
            {data.Groupe && 
            <tr>
              <td>Groupe :</td>
              <td>{data.Groupe}</td>
            </tr>
            }
            {data.Laiterie && 
              <tr>
                <td>Entreprise :</td>
                <td>{data.Laiterie}</td>
              </tr>
            }
          </tbody>
        </table>
        {data.MoisTraite != null && <div>Mois : {data.MoisTraite}</div>}
      </div>
      {supertitle && <h2 className="k-text-center" style={{"fontSize":"15px", "margin":"0px"}}>{supertitle}</h2>}
      {data.Periode && <h2 className="k-text-center" style={{"fontSize":"13px", "margin":"0px"}}>{data.Periode}</h2>}
      <h1 className="k-text-center" style={{"fontSize":"17px", "margin":"4px"}}>
        {params.domaine === 2 ? "CHEVRYPLAN" : "Optichèvre"}
      </h1>
    </>
  ) : null;
};
