import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { Header } from "../../shared/ReportViewer/Header";

/**
 * @typedef {Object} FluxRappDMA
 * @property {string} OrdreMensuel
 * @property {string} Fourrages
 * @property {string} AlimentsSimples
 * @property {string} AlimentsComposes
 * 
 * @typedef {Object} LigneProps
 * @property {FluxRappDMA} params
 * @param {LigneProps} props {@link LigneProps}
 */
const Ligne = ({params}) => {
  return (
    <tr>
      <td className="border-l numero">{params.OrdreMensuel}</td>
      <td className="texte">{params.Fourrages}</td>
      <td className="texte">{params.AlimentsSimples}</td>
      <td className="texte">{params.AlimentsComposes}</td>
    </tr>
  )
}

/**
 * @param {string} titre 
 */
const Entete = ({titre}) => {
  return (
    <thead>
      <tr>
        <th colSpan="4" className="border-l border-t">{titre}</th>
      </tr>
      <tr>
        <th className="border-l numero">N°</th>
        <th className="texte">FOURRAGES (kg/chèvre/jour)</th>
        <th className="texte">Aliments SIMPLES /Ch./jour</th>
        <th className="texte">Aliments COMPOSES /Ch/j (Prix HT/t)</th>
      </tr>
    </thead>
  )
}


/**
 * @typedef {Object} TableauProps
 * @property {Array<FluxRappDMA>} donnees
 * @property {string} classe
 * @param {TableauProps} props {@link TableauProps}
 */
const Tableau = ({donnees, classe=""}) =>{

  const tableau = donnees.map(donnee => <Ligne key={donnee.OrdreMensuel} params={donnee}/>)

  return (
    <tbody className={classe}> 
      {tableau}
    </tbody>
  )
}


/**
 * @typedef {Object} FluxRappDMAProps
 * @property {import('./ReportViewerDMA').ReportParameter} params
 * @param {FluxRappDMAProps} props {@link FluxRappDMAProps}
 */
export const FluxRappDMA = ({params}) => {
  const queryClient = useQueryClient();

  const headerData =
  /** @type {import('./ReportViewerDMA').FluxPresentation | undefined} */ (
    queryClient.getQueryData(["Header", params])
  );

  const listCritRappDMA =
  /** @type {Array<FluxRappDMA> | undefined} */ (
    queryClient.getQueryData(["donneesMensuellesDMA", "ListCritRappDMA", params])
  );


  
  let nombrePages = Math.ceil(listCritRappDMA.length / 14) ; // 14 éléments par page

  let pages = [];
  for (let i = 0; i < nombrePages; i++) {
    pages.push(
      <Header
      key={"h"+i}
      params={params}
      style={
        /** @type {React.CSSProperties} */ ({
          "--cell-height": "5mm",
          "marginLeft": "5mm",
        })
      }
      supertitle="Rapport Alimentation"
      />
    );
    pages.push(
      <div className="pdf-dma-table" key={i}>
        <table className={i !== 0 ? "border-t" : ""}>
          {i === 0 ? <Entete titre={headerData.Periode} /> : undefined }
          <Tableau donnees={listCritRappDMA.slice(i*14, (i+1)*14)} classe={i !== (nombrePages - 1) ? "separate" : ""} />
        </table>
      </div>
    )
  }

  if (listCritRappDMA.length === 0)
    pages.push(
      <Header
      key="h0"
      params={params}
      style={
        /** @type {React.CSSProperties} */ ({
          "--cell-height": "5mm",
          "marginLeft": "5mm",
        })
      }
      supertitle="Rapport Alimentation"
      />
    );

  return (
    <>
      {pages}
    </>
  )};