import { Field } from "@progress/kendo-react-form";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { Component } from "react";
import PropTypes from "prop-types";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";

class CoutFourrages extends Component {
  render() {
    const { edit, valueGetter } = this.props;

    return (
      <div className="k-m-4 tab-donnees-annuelles">
        <div className="k-mb-4 grid-wrapper-form-2">
          <h3 className="k-font-weight-bold text-color-primary">
            Coût fourrage caprins
          </h3>
        </div>
        <div className="k-mb-4 grid-wrapper-form-2 k-align-items-center">
          <div className="k-font-weight-bold">CULTURES</div>
          <div className="grid-wrapper-form-5">
            <div className="k-text-center k-font-weight-bold">
              SEMENCES <br />
              (€ HT/ha)
            </div>
            <div className="k-text-center k-font-weight-bold">
              TRAITEMENTS <br />
              (€ HT/ha)
            </div>
            <div className="k-text-center k-font-weight-bold">
              FERTILISATION <br />
              (€ HT/ha)
            </div>
            <div className="k-text-center k-font-weight-bold">
              TOTAL <br />
              (€ HT/ha)
            </div>
            <div className="k-text-center">
              Rendements <br />
              (€ t MS/ha)
            </div>
          </div>
        </div>
        <PanelBar
          style={{ marginBottom: "16px" }}
          className={`${edit && "panelbar-editable"}`}
        >
          <PanelBarItem title="SURFACES FOURRAGÈRES" expanded>
            <div className="k-m-4">
              <div className="k-mb-4 grid-wrapper-form-2">
                <div>Graminées cultivées pures (fétuques, ray-grass, ...)</div>
                <div className="grid-wrapper-form-5">
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfGramSemHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfGramTraitHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfGramFertHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                  <Input
                    format="n0"
                    className="k-state-disabled"
                    disabled
                    value={
                      valueGetter("cfGramSemHa") +
                      valueGetter("cfGramTraitHa") +
                      valueGetter("cfGramFertHa")
                    }
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfGramRendHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                </div>
              </div>
              <div className="k-mb-4 grid-wrapper-form-2">
                <div>Légumineuses (Luzerne, trèfle, ...)</div>
                <div className="grid-wrapper-form-5">
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfLegSemHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfLegTraitHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfLegFertHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                  <NumericTextBox
                    format="n0"
                    disabled
                    value={
                      valueGetter("cfLegSemHa") +
                      valueGetter("cfLegTraitHa") +
                      valueGetter("cfLegFertHa")
                    }
                    spinners={false}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfLegRendHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                </div>
              </div>

              <div className="k-mb-4 grid-wrapper-form-2">
                <div>Association graminées + Légumineuses</div>
                <div className="grid-wrapper-form-5">
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfAssoSemHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfAssoTraitHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfAssoFertHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                  <NumericTextBox
                    format="n0"
                    disabled
                    value={
                      valueGetter("cfAssoSemHa") +
                      valueGetter("cfAssoTraitHa") +
                      valueGetter("cfAssoFertHa")
                    }
                    spinners={false}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfAssoRendHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                </div>
              </div>

              <div className="k-mb-4 grid-wrapper-form-2">
                <div>Maïs ensilage ou céréales ensilées</div>
                <div className="grid-wrapper-form-5">
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfMaisSemHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfMaisTraitHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfMaisFertHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                  <NumericTextBox
                    format="n0"
                    disabled
                    value={
                      valueGetter("cfMaisSemHa") +
                      valueGetter("cfMaisTraitHa") +
                      valueGetter("cfMaisFertHa")
                    }
                    spinners={false}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfMaisRendHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                </div>
              </div>

              <div className="k-mb-4 grid-wrapper-form-2">
                <div>Prairies naturelles</div>
                <div className="grid-wrapper-form-5">
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfPraiSemHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfPraiTraitHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfPraiFertHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                  <NumericTextBox
                    format="n0"
                    disabled
                    value={
                      valueGetter("cfPraiSemHa") +
                      valueGetter("cfPraiTraitHa") +
                      valueGetter("cfPraiFertHa")
                    }
                    spinners={false}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfPraiRendHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                </div>
              </div>

              <div className="k-mb-4 grid-wrapper-form-2">
                <div className="grid-wrapper-form-2">
                  <div>Autre 1</div>
                  <Field
                    className={`${!edit && "k-state-disabled"}`}
                    component={Input}
                    format="n0"
                    name="asAutre1Lib"
                    disabled={!edit}
                    min={0}
                  />
                </div>
                <div className="grid-wrapper-form-5">
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfAutre1SemHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfAutre1TraitHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfAutre1FertHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                  <NumericTextBox
                    format="n0"
                    disabled
                    value={
                      valueGetter("cfAutre1SemHa") +
                      valueGetter("cfAutre1TraitHa") +
                      valueGetter("cfAutre1FertHa")
                    }
                    spinners={false}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfAutre1RendHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                </div>
              </div>

              <div className="k-mb-4 grid-wrapper-form-2">
                <div className="grid-wrapper-form-2">
                  <div>Autre 2</div>
                  <Field
                    className={`${!edit && "k-state-disabled"}`}
                    component={Input}
                    format="n0"
                    name="asAutre2Lib"
                    disabled={!edit}
                    min={0}
                  />
                </div>
                <div className="grid-wrapper-form-5">
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfAutre2SemHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfAutre2TraitHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfAutre2FertHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                  <NumericTextBox
                    format="n0"
                    disabled
                    value={
                      valueGetter("cfAutre2SemHa") +
                      valueGetter("cfAutre2TraitHa") +
                      valueGetter("cfAutre2FertHa")
                    }
                    spinners={false}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfAutre2RendHa"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                </div>
              </div>
            </div>
          </PanelBarItem>
        </PanelBar>

        <PanelBar
          style={{ marginBottom: "16px" }}
          className={`${edit && "panelbar-editable"}`}
        >
          <PanelBarItem title="CULTURES DEROBEES" expanded>
            <div className="k-m-4">
              <div className="k-mb-4 grid-wrapper-form-2">
                <div>Cultures dérobées</div>
                <div className="grid-wrapper-form-5">
                  <NumericTextBox
                    format="n0"
                    disabled
                    value={
                      valueGetter("cfDerob1SemHa") +
                      valueGetter("cfDerob2SemHa")
                    }
                    spinners={false}
                    min={0}
                  />
                  <NumericTextBox
                    format="n0"
                    disabled
                    value={
                      valueGetter("cfDerob1TraitHa") +
                      valueGetter("cfDerob2TraitHa")
                    }
                    spinners={false}
                    min={0}
                  />
                  <NumericTextBox
                    format="n0"
                    disabled
                    value={
                      valueGetter("cfDerob1FertHa") +
                      valueGetter("cfDerob2FertHa")
                    }
                    spinners={false}
                    min={0}
                  />
                  <NumericTextBox
                    format="n0"
                    disabled
                    value={
                      valueGetter("cfDerob1SemHa") +
                      valueGetter("cfDerob2SemHa") +
                      valueGetter("cfDerob1TraitHa") +
                      valueGetter("cfDerob2TraitHa") +
                      valueGetter("cfDerob1FertHa") +
                      valueGetter("cfDerob2FertHa")
                    }
                    spinners={false}
                    min={0}
                  />
                  <NumericTextBox
                    format="n0"
                    disabled
                    value={
                      valueGetter("cfDerob1RendHa") +
                      valueGetter("cfDerob2RendHa")
                    }
                    spinners={false}
                    min={0}
                  />
                </div>
              </div>

              <div className="k-mb-4 grid-wrapper-form-2">
                <div className="grid-wrapper-form-2">
                  <div>Dérobée 1</div>
                  <Field
                    component={Input}
                    format="n0"
                    className={`${!edit && "k-state-disabled"}`}
                    disabled={!edit}
                    name="asDerob1Lib"
                    required={
                      valueGetter("cfDerob1SemHa") !== null ||
                      valueGetter("cfDerob1TraitHa") !== null ||
                      valueGetter("cfDerob1FertHa") !== null ||
                      valueGetter("cfDerob1RendHa") !== null
                    }
                    min={0}
                  />
                </div>
                <div className="grid-wrapper-form-5">
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    disabled={!edit}
                    name="cfDerob1SemHa"
                    spinners={edit}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    disabled={!edit}
                    name="cfDerob1TraitHa"
                    spinners={edit}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    disabled={!edit}
                    name="cfDerob1FertHa"
                    spinners={edit}
                    min={0}
                  />
                  <NumericTextBox
                    format="n0"
                    disabled
                    value={
                      valueGetter("cfDerob1SemHa") +
                      valueGetter("cfDerob1TraitHa") +
                      valueGetter("cfDerob1FertHa")
                    }
                    spinners={false}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    disabled={!edit}
                    name="cfDerob1RendHa"
                    spinners={edit}
                    min={0}
                  />
                </div>
              </div>

              <div className="k-mb-4 grid-wrapper-form-2">
                <div className="grid-wrapper-form-2">
                  <div>Dérobée 2</div>
                  <Field
                    component={Input}
                    className={`${!edit && "k-state-disabled"}`}
                    format="n0"
                    name="asDerob2Lib"
                    disabled={!edit}
                    required={
                      valueGetter("cfDerob2SemHa") !== null ||
                      valueGetter("cfDerob2TraitHa") !== null ||
                      valueGetter("cfDerob2FertHa") !== null ||
                      valueGetter("cfDerob2RendHa") !== null
                    }
                    min={0}
                  />
                </div>
                <div className="grid-wrapper-form-5">
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    disabled={!edit}
                    name="cfDerob2SemHa"
                    spinners={edit}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    disabled={!edit}
                    name="cfDerob2TraitHa"
                    spinners={edit}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    disabled={!edit}
                    name="cfDerob2FertHa"
                    spinners={edit}
                    min={0}
                  />
                  <NumericTextBox
                    format="n0"
                    disabled
                    value={
                      valueGetter("cfDerob2SemHa") +
                      valueGetter("cfDerob2TraitHa") +
                      valueGetter("cfDerob2FertHa")
                    }
                    spinners={false}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    disabled={!edit}
                    name="cfDerob2RendHa"
                    spinners={edit}
                    min={0}
                  />
                </div>
              </div>
            </div>
          </PanelBarItem>
        </PanelBar>

        <PanelBar className={`${edit && "panelbar-editable"}`}>
          <PanelBarItem title="SURFACES FOURRAGÈRES" expanded>
            <div className="k-m-4">
              <div className="k-mb-4 grid-wrapper-form-2">
                <h3 className="k-font-weight-bold text-color-primary">
                  Fourrages achetés (dont paille)
                </h3>
                <h3 className="k-font-weight-bold text-color-primary">
                  Fourrages vendus
                </h3>
              </div>

              <div className="k-mb-2 grid-wrapper-form-2">
                <div className="grid-wrapper-form-3">
                  <div className="k-font-weight-bold">Nom du fourrage</div>
                  <div className="k-font-weight-bold">
                    Quantite (en tonne brut)
                  </div>
                  <div className="k-font-weight-bold">Montant total HT (€)</div>
                </div>
                <div className="grid-wrapper-form-3">
                  <div className="k-font-weight-bold">Nom du fourrage</div>
                  <div className="k-font-weight-bold">
                    Quantite (en tonne brut)
                  </div>
                  <div className="k-font-weight-bold">Montant total HT (€)</div>
                </div>
              </div>

              <div className="k-mb-2 grid-wrapper-form-2">
                <div className="grid-wrapper-form-3">
                  <Field
                    component={Input}
                    format="n0"
                    name="cfFourAch1Lib"
                    disabled={!edit}
                    className={!edit && "k-state-disabled"}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfFourAch1Qte"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfFourAch1Mt"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                </div>
                <div className="grid-wrapper-form-3">
                  <Field
                    component={Input}
                    format="n0"
                    name="prFourVend1Lib"
                    disabled={!edit}
                    className={!edit && "k-state-disabled"}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="prFourVend1Qte"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="prFourVend1Mt"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                </div>
              </div>

              <div className="k-mb-4 grid-wrapper-form-2">
                <div className="grid-wrapper-form-3">
                  <Field
                    component={Input}
                    format="n0"
                    name="cfFourAsch2Lib"
                    disabled={!edit}
                    className={!edit && "k-state-disabled"}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfFourAch2Qte"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="cfFourAch2Mt"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                </div>
                <div className="grid-wrapper-form-3">
                  <Field
                    component={Input}
                    format="n0"
                    name="prFourVend2Lib"
                    disabled={!edit}
                    className={!edit && "k-state-disabled"}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="prFourVend2Qte"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                  <Field
                    component={NumericTextBox}
                    format="n0"
                    name="prFourVend2Mt"
                    disabled={!edit}
                    spinners={edit}
                    min={0}
                  />
                </div>
              </div>

              <div className="k-mb-4 grid-wrapper-form-2">
                <div className="grid-wrapper-form-3">
                  <div
                    className="k-font-weight-bold k-text-right k-align-self-center"
                    style={{ gridColumnStart: 2 }}
                  >
                    TOTAL
                  </div>

                  <NumericTextBox
                    format="n0"
                    disabled
                    value={
                      valueGetter("cfFourAch1Mt") + valueGetter("cfFourAch2Mt")
                    }
                    spinners={false}
                    min={0}
                  />
                </div>
                <div className="grid-wrapper-form-3">
                  <div
                    className="k-font-weight-bold k-text-right k-align-self-center"
                    style={{ gridColumnStart: 2 }}
                  >
                    TOTAL
                  </div>

                  <NumericTextBox
                    format="n0"
                    disabled
                    value={
                      valueGetter("prFourVend1Mt") +
                      valueGetter("prFourVend2Mt")
                    }
                    spinners={false}
                    min={0}
                  />
                </div>
              </div>
            </div>
          </PanelBarItem>
        </PanelBar>
      </div>
    );
  }
}

CoutFourrages.propTypes = {
  edit: PropTypes.bool.isRequired,
  valueGetter: PropTypes.func.isRequired,
};

export default CoutFourrages;
