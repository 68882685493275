import { Component } from "react";
import { Field } from "@progress/kendo-react-form";
import { ComboBox, DropDownList } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";
import { Input, NumericTextBox, TextArea } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { Loader } from "@progress/kendo-react-indicators";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-buttons";
import dataSource from "../../../../dataSource/dataSource";

class Description extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      raisonsSociales: [],
      typesElevage: [],
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const raisonsSociales = await dataSource.loadModaliste("RASO");
    const typesElevage = await dataSource.loadModaliste("TYEL");
    this.setState({ loading: false, raisonsSociales, typesElevage });
  }

  render() {
    const { loading, raisonsSociales, typesElevage } = this.state;
    const { edit, valueGetter, onChange, errors } = this.props;

    const raisonSociale = raisonsSociales.find(
      (rs) => rs.rang_modaliste === valueGetter("raisonSocialeId")
    );

    const typeElevageChevre = typesElevage.find(
      (te) => te.rang_modaliste === valueGetter("typeElevageChevre")
    );

    return loading === true ? (
      <div className="flex-fill k-text-center k-mt-18">
        <Loader themeColor="primary" />
      </div>
    ) : (
      <div className="k-d-flex k-flex-column k-m-4">
        <div className="k-d-grid k-grid-cols-2 k-gap-3">
          <div className="k-p-4">
            <div className="grid-wrapper-form-2 k-mb-4">
              <Tooltip>
                <Label>
                  Code
                  <span className="required k-ml-2" title="Champ obligatoire">
                    *
                  </span>
                </Label>
              </Tooltip>
              <Input
                data-cy="elevage_code"
                value={valueGetter("code")}
                onChange={(value) => onChange("code", value)}
                disabled={!edit}
                name="code"
                required
                valid={!errors?.description?.code}
              />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Tooltip>
                <Label>
                  Nom de l&#39;élevage
                  <span className="required k-ml-2" title="Champ obligatoire">
                    *
                  </span>
                </Label>
              </Tooltip>

              <Input
                value={valueGetter("nom")}
                onChange={(value) => onChange("nom", value)}
                disabled={!edit}
                name="nom"
                required
                valid={!errors?.description?.nom}
              />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Label>Raison Sociale</Label>
              <ComboBox
                disabled={!edit}
                data={raisonsSociales}
                textField="libelle"
                value={raisonSociale}
                onChange={({ value }) => {
                  onChange("raisonSocialeId", { value: value.rang_modaliste });
                  onChange("raisonSociale", { value: value.libelle });
                }}
                clearButton={false}
              />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Label>Adresse</Label>
              <Field disabled={!edit} component={TextArea} name="adresse" />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Label>Code postal</Label>
              <Field disabled={!edit} component={Input} name="codePostal" />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Label>Ville</Label>
              <Field disabled={!edit} component={Input} name="commune" />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Label>Pays</Label>
              <Field
                disabled={!edit}
                component={Input}
                name="pays"
                maxLength="2"
              />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Label>SIRET</Label>
              <Field
                disabled={!edit}
                component={Input}
                name="codeSiret"
                maxLength="14"
              />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Label>N° PACAGE</Label>
              <Field disabled={!edit} component={Input} name="numPac" />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Label>SAU (ha)</Label>
              <Field
                disabled={!edit}
                component={NumericTextBox}
                format="n2"
                name="sauTotal"
              />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Tooltip>
                <Label>
                  Type d&#39;élevage
                  <span className="required k-ml-2" title="Champ obligatoire">
                    *
                  </span>
                </Label>
              </Tooltip>

              <DropDownList
                disabled={!edit}
                data={typesElevage}
                value={typeElevageChevre}
                textField="libelle"
                onChange={({ value }) => {
                  onChange("typeElevageChevre", {
                    value: value.rang_modaliste,
                  });
                }}
                required
                valid={!errors?.description?.typeElevageChevre}
              />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Tooltip>
                <Label>
                  Date début
                  <span className="required k-ml-2" title="Champ obligatoire">
                    *
                  </span>
                </Label>
              </Tooltip>

              <div className="k-d-flex">
                <DatePicker
                  required
                  disabled={!edit}
                  value={
                    valueGetter("dateDebut") &&
                    dayjs(valueGetter("dateDebut")).isValid()
                      ? dayjs(valueGetter("dateDebut")).toDate()
                      : null
                  }
                  onChange={({ value }) =>
                    onChange("dateDebut", {
                      value,
                    })
                  }
                  valid={!errors?.description?.dateDebut}
                />
                <Button
                  type="button"
                  disabled={!edit}
                  icon="reset"
                  title="réinitialiser la date"
                  onClick={() => {
                    onChange("dateDebut", { value: "" });
                  }}
                />
              </div>
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Label>Date fin</Label>
              <div className="k-d-flex">
                <DatePicker
                  disabled={!edit}
                  value={
                    valueGetter("dateFin") &&
                    dayjs(valueGetter("dateFin")).isValid()
                      ? dayjs(valueGetter("dateFin")).toDate()
                      : null
                  }
                  onChange={({ value }) =>
                    onChange("dateFin", {
                      value,
                    })
                  }
                  valid={!errors?.description?.dateFin}
                />
                <Button
                  type="button"
                  disabled={!edit}
                  icon="reset"
                  title="réinitialiser la date"
                  onClick={() => {
                    onChange("dateFin", { value: "" });
                  }}
                />
              </div>
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Label>Commentaire</Label>
              <Field disabled={!edit} component={TextArea} name="commentaire" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Description.propTypes = {
  edit: PropTypes.bool.isRequired,
  valueGetter: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

Description.defaultProps = {
  errors: {},
};

export default Description;
