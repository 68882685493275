import dayjs from "dayjs";
import { Component } from "react";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { withRouter } from "./utils/withRouter";
import { compose } from "redux";
import Elevages from "./components/administration/elevages";
import Groupes from "./components/administration/groupes";
import Cooperatives from "./components/administration/cooperatives";
import SaisieCooperative from "./components/administration/saisieCooperative";
import Techniciens from "./components/administration/techniciens";
import SaisieElevage from "./components/administration/saisieElevage";
import SaisieTechnicien from "./components/administration/saisieTechnicien";
import SaisieGroupe from "./components/administration/saisieGroupe";
import DonneesAnnuelles from "./components/DonneesAnnuelles/DonneesAnnuelles";
import SaisieDonneesAnnuelles from "./components/DonneesAnnuelles/SaisieDonneesAnnuelles";
import SaisieDonneeMensuelles from "./components/donneesMensuelles/catalogue/SaisieDonneesMensuelles";
import FicheDonneeMensuelle from "./components/donneesMensuelles/fiche/FicheDonneeMensuelle";
import GenerationDonneesMensuelles from "./components/donneesMensuelles/generation/GenerationDonneesMensuelles";
import Home from "./components/home";
import Component404 from "./components/shared/404";
import Assistance from "./components/user/Assistance";
import InfosPersonnelles from "./components/user/InfosPersonnelles";
import { attemptLogout, attemptRefreshToken } from "./redux/actions/auth";
import PaieDeLait from "./components/donneesMensuelles/catalogue/PaieDeLait";
import { loadAadConfig } from "./Azure.js";

const clientInactivityLimit =
  Number(process.env.REACT_APP_CLIENT_INACTIVITY_LIMIT) || 1800;

const mapStateToProps = (state) => ({
  sessionExpirationTime: state.auth.sessionExpirationTime,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  handleLogout: async (causedByInactivity) => {
    try {
      await dispatch(attemptLogout());
    } catch (error) {
      if (error.status !== 401) {
        throw error;
      }
    } finally {
      ownProps.router.navigate(
        `/login?disconnected=${
          !causedByInactivity ? "session-expired" : "inactivity"
        }`,{replace : true}
      );
    }
  },
  handleRefreshToken: () => dispatch(attemptRefreshToken()),
});
class AppRouter extends Component {
  interval;

  secondsSinceLastActivity = 0;

  async componentDidMount() {
    const { router } = this.props;
    await loadAadConfig(router);
    this.checkTokenExpiration();
    [
      "mousedown",
      "mousemove",
      "keydown",
      "scroll",
      "touchstart",
    ].forEach((event) => document.addEventListener(event, this.setActivity));

    // TODO: A voir avec le client
    // this.interval = setInterval(() => {
    //   this.secondsSinceLastActivity += 1;
    //   this.checkTokenExpiration();
    // }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  checkTokenExpiration = () => {
    const {
      handleLogout,
      sessionExpirationTime,
      handleRefreshToken,
    } = this.props;
    const now = dayjs();
    const secondsLeft = now.diff(sessionExpirationTime, "seconds") * -1;
    if (
      this.secondsSinceLastActivity >= clientInactivityLimit ||
      secondsLeft < 0
    ) {
      handleLogout(secondsLeft >= 0);
    }
    // check JWT session expiration time
    else if (secondsLeft < 40) {
      // refresh token call if token has less than 40 seconds left (chosen arbitrarily)
      handleRefreshToken();
    }
  };

  setActivity = () => {
    this.secondsSinceLastActivity = 0;
  };

  render() {
    return (
      <Routes>
        <Route path="administration/elevages/nouveau" element={
          <SaisieElevage createNew />}>
        </Route>
        <Route path="administration/elevages/:idElevage" element=
          {<SaisieElevage/>}>
        </Route>
        <Route path="administration/elevages" element={
          <Elevages />}>
        </Route>
        <Route path="administration/techniciens/nouveau" element={
          <SaisieTechnicien createNew />}>
        </Route>
        <Route path="administration/techniciens/:idTechnicien"
          element = {<SaisieTechnicien />}>
        </Route>
        <Route path="administration/techniciens" element={
          <Techniciens /> }>
        </Route>
        <Route path="administration/cooperatives/nouveau" element={
          <SaisieCooperative createNew />}>
        </Route>
        <Route path="administration/cooperatives/:idCooperative"
          element = {<SaisieCooperative />}>
        </Route>
        <Route path="administration/cooperatives" element={
          <Cooperatives />}>
        </Route>
        <Route path="administration/groupes/nouveau" element={
          <SaisieGroupe createNew />}>
        </Route>
        <Route path="administration/groupes/duplicate"
          element = {<SaisieGroupe mode={"duplicate"} edit />}>
        </Route>
        <Route path="administration/groupes/:idGroupe/edit" element=
          {<SaisieGroupe edit/>}>
        </Route>
        <Route path="administration/groupes/:idGroupe" render=
          {<SaisieGroupe />}>
        </Route>
        <Route path="administration/groupes" element={
          <Groupes />}>
        </Route>
        <Route path="donnees-mensuelles/saisie/fiche/:nomElevage/:idFiche"
          element = { <FicheDonneeMensuelle />}>
        </Route>
        <Route path="donnees-mensuelles/generation" element={
          <GenerationDonneesMensuelles />}>
        </Route>
        <Route path="infos-personnelles" element={
          <InfosPersonnelles />}>
        </Route>
        <Route path="donnees-mensuelles/saisie" element={
          <SaisieDonneeMensuelles />}>
        </Route>
        <Route path="donnees-mensuelles/paie-de-lait" element={
          <PaieDeLait />}>
        </Route>
        <Route path="assistance" element={
          <Assistance />}>
        </Route>
        <Route path="donnees-annuelles/:nomElevage/:groupeId/:idFiche"
          element = {
            <SaisieDonneesAnnuelles/>
          }>
        </Route>
        <Route path="donnees-annuelles" element={
          <DonneesAnnuelles />}>
        </Route>

        <Route path="/" element={
          <Home />}>
        </Route>
        <Route element={
          <Component404 />}>
        </Route>
      </Routes>
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AppRouter);
