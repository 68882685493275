import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import { Checkbox, Input, TextArea } from "@progress/kendo-react-inputs";
import { Error as ErrorKendo, Hint, Label } from "@progress/kendo-react-labels";
import { Card, CardBody, CardHeader } from "@progress/kendo-react-layout";
import React, { Component } from "react";
import { connect } from "react-redux";
import dataSource from "../../dataSource/dataSource";
import toastService from "../../utils/toastService";
import {
  checkPassword,
  emailValidator,
  requiredValidator,
} from "../form/validator";
import { attemptCgu } from "../../redux/actions/auth";

const mapStateToProps = (state) => ({
  utilisateurId: state.auth.utilisateurId,
  cguChecked: state.auth.cguChecked,
  privacyChecked: state.auth.privacyChecked,
});

const mapDispatchToProps = (dispatch) => ({
  reduxCgu: async (values) => {
    return dispatch(
      attemptCgu(values.utilisateurId, {
        cguChecked: values.cguChecked,
        privacyChecked: values.privacyChecked,
      })
    );
  },
});

class InfosPersonnelles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
      showNewPassword: false,
      isEditing: false,
      infosUtilisateur: null,
      loading: true,
    };
  }

  async componentDidMount() {
    await this.initData();
  }

  initData = async () => {
    const { utilisateurId } = this.props;
    const data = await dataSource.loadInfosUtilisateur(utilisateurId);
    this.setState({
      infosUtilisateur: data,
      loading: false,
    });
  };

  handleClickShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleClickShowNewPassword = () => {
    this.setState((prevState) => ({
      showNewPassword: !prevState.showNewPassword,
    }));
  };

  toggleDialog = () => {
    this.setState((prevState) => ({
      isEditing: !prevState.isEditing,
    }));
  };

  updatePassword = async (data) => {
    const { utilisateurId } = this.props;
    try {
      await dataSource.updatePasswordById(utilisateurId, {
        newPassword: data.password,
      });
    } catch (err) {
      // show toast for error
      toastService.addToast({
        id: "update-password-error",
        type: "error",
        message: err.message,
      });
    }
    // show toast for success
    toastService.addToast({
      id: "updatePassword-success",
      type: "success",
      message: "Votre mote de passe a bien été mises à jour",
    });
  };

  handleSubmit = async (data) => {
    const { utilisateurId, reduxCgu } = this.props;
    // submit data
    try {
      await reduxCgu({
        utilisateurId,
        cguChecked: data.values.cguChecked,
        privacyChecked: data.values.privacyChecked,
      });
      await dataSource.updateInfosUtilisateurById(utilisateurId, {
        cguChecked: data.values.cguChecked,
        privacyChecked: data.values.privacyChecked,
        ...data.values,
      });
    } catch (err) {
      // show toast for error
      toastService.addToast({
        id: "infos-personnelles-error",
        type: "error",
        message: err.message,
      });
    }

    // show toast for success
    toastService.addToast({
      id: "infosPersonnelles-success",
      type: "success",
      message: "Vos informations ont bien été mises à jour",
    });
  };

  render() {
    const {
      showPassword,
      showNewPassword,
      isEditing,
      infosUtilisateur,
      loading,
    } = this.state;
    return (
      <div className="k-m-4">
        {loading && (
          <div className="flex-fill k-text-center k-mt-18">
            <Loader themeColor="primary" />
          </div>
        )}
        {loading === false && (
          <div className="k-d-flex">
            <Form
              initialValues={infosUtilisateur}
              onSubmitClick={this.handleSubmit}
              render={(formRenderProps) => (
                <Card style={{ width: "100%" }}>
                  <CardHeader>
                    <h2 style={{ margin: 0 }}>Informations utilisateur</h2>
                  </CardHeader>
                  <CardBody>
                    <FormElement
                      style={{ maxWidth: 800 }}
                      className="info-user"
                    >
                      <fieldset className="k-form-fieldset">
                        <div className="k-mb-3">
                          <Label>E-mail</Label>
                          <Field
                            component={Input}
                            name={"email"}
                            type={"email"}
                            disabled={true}
                            validator={emailValidator}
                          />
                          {!!formRenderProps.errors.email &&
                            formRenderProps.touched && (
                              <ErrorKendo>
                                L&apos;email n&apos;a pas le bon format
                              </ErrorKendo>
                            )}
                        </div>
                        <Label className="k-mb-1">Mot de passe</Label>
                        <div className="k-mb-3">
                        </div>
                        <div className="k-mb-3 k-d-flex">
                          <div style={{ width: "50%" }}>
                            <Label>Nom</Label>
                            <Field
                              component={Input}
                              name={"nom"}
                              validator={requiredValidator}
                              required
                            />
                            {!!formRenderProps.errors.nom &&
                              formRenderProps.touched && (
                                <ErrorKendo>
                                  Le champ nom doit être renseigné
                                </ErrorKendo>
                              )}
                          </div>
                          <div className="k-ml-6" style={{ width: "50%" }}>
                            <Label>Prénom</Label>
                            <Field
                              component={Input}
                              name={"prenom"}
                              validationMessage={
                                "Le prénom doit contenir uniquement des lettres"
                              }
                              validator={requiredValidator}
                            />
                            {!!formRenderProps.errors.prenom &&
                              formRenderProps.touched && (
                                <ErrorKendo>
                                  Le champ prénom doit être renseigné
                                </ErrorKendo>
                              )}
                          </div>
                        </div>
                        <div className="k-mb-3">
                          <Label>Adresse</Label>
                          <Field
                            component={TextArea}
                            name={"adresse"}
                            label={"Adresse"}
                          />
                        </div>
                        <div className="k-d-flex k-mb-3">
                          <div style={{ width: "50%" }}>
                            <Label>Code postal</Label>
                            <Field component={Input} name={"codePostal"} />
                          </div>
                          <div className="k-ml-6" style={{ width: "50%" }}>
                            <Label>Commune</Label>
                            <Field
                              component={Input}
                              type={"text"}
                              name={"commune"}
                            />
                          </div>
                        </div>

                        <h4 className="k-mt-12">
                          1. Conditions d&apos;utilisation et avis de
                          confidentialité
                        </h4>
                        <div className="k-d-flex k-mb-8">
                          <Field
                            className="checkbox"
                            component={Checkbox}
                            name="cguChecked"
                            disabled
                          />
                          <p className="k-ml-4">
                            J&apos;accepte les{" "}
                            <a
                              href={
                                "https://www.legal.admanimalnutrition.com/documents/terms-of-use/"
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              Conditions Générales d&apos;Utilisation
                            </a>
                            , et j&apos;ai lu et compris la{" "}
                            <a
                              href={
                                "https://www.legal.admanimalnutrition.com/documents/privacy-notice-for-apps/"
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              Confidentialité des données personnelles.
                            </a>
                          </p>
                        </div>
                        <h4>2. Partage de données personnelles avec ADM</h4>
                        <div className="k-d-flex k-mt-4">
                          <Field component={Checkbox} name="privacyChecked" />
                          <p className="k-ml-4">
                            J&apos;accepte qu&apos;ADM puisse stocker, utiliser
                            et traiter mes données personnelles en
                            m&apos;envoyant des communications ciblées à des
                            fins de prospection.
                          </p>
                        </div>

                        <div className="k-form-buttons k-mt-10">
                          <Button primary type={"submit"}>
                            Valider mes informations
                          </Button>
                        </div>
                        <p className="k-mt-8">
                          © 2023 {process.env.REACT_APP_CHEVRYPLAN_LABEL} -{" "}
                          <a
                            href={
                              "https://www.legal.admanimalnutrition.com/contact"
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            Se désinscrire{" "}
                          </a>
                        </p>
                      </fieldset>
                    </FormElement>
                  </CardBody>
                </Card>
              )}
            />
          </div>
        )}

        {isEditing && (
          <Dialog
            title={"Changement de mot de passe"}
            onClose={this.toggleDialog}
          >
            <Form
              onSubmit={(data) => {
                this.updatePassword(data);
                this.toggleDialog();
              }}
              validator={(values, valueGetter) => {
                const errors = {};
                if (valueGetter("password") !== valueGetter("newPassword")) {
                  errors.confirmPassword = `Le mot de passe de confirmation est différent du précédent. Veuillez saisir 2 mots de passe identiques`;
                }
                return errors;
              }}
              render={(formRenderProps) => (
                <>
                  <p>Veuillez saisir votre nouveau mot de passe</p>
                  <div className="k-d-flex k-align-items-center">
                    <Field
                      component={Input}
                      name={"password"}
                      type={showPassword ? "text" : "password"}
                      validator={checkPassword}
                      required
                    />
                    <button
                      type="button"
                      className="k-button k-button-clear"
                      onClick={this.handleClickShowPassword}
                    >
                      <span className="k-icon k-i-preview" />
                    </button>
                  </div>
                  {!!formRenderProps.errors.password &&
                    formRenderProps.touched && (
                      <ErrorKendo>
                        Le mot de passe n&apos;a pas le bon format
                      </ErrorKendo>
                    )}
                  <Hint className="k-mb-5">
                    5 caractères minimum, dont un chiffre, une lettre et un
                    caractère spécial parmi # ? ! @ $ % ^ & * - . : = &lt; &gt;
                    / ; |
                  </Hint>

                  <div className="k-mb-8">
                    <p>Veuillez confirmer votre nouveau mot de passe</p>
                    <div className="k-d-flex k-align-items-center">
                      <Field
                        component={Input}
                        name={"newPassword"}
                        type={showNewPassword ? "text" : "password"}
                        required
                      />
                      <Button
                        className="k-button k-button-clear"
                        onClick={this.handleClickShowNewPassword}
                      >
                        <span className="k-icon k-i-preview" />
                      </Button>
                    </div>
                    {!!formRenderProps.errors.confirmPassword &&
                      formRenderProps.touched && (
                        <ErrorKendo>
                          {formRenderProps.errors.confirmPassword}
                        </ErrorKendo>
                      )}
                  </div>

                  <DialogActionsBar>
                    <button
                      type="button"
                      className="k-button"
                      onClick={this.toggleDialog}
                    >
                      Annuler
                    </button>
                    <button
                      type="button"
                      className="k-button"
                      onClick={() => formRenderProps.onSubmit()}
                    >
                      Valider
                    </button>
                  </DialogActionsBar>
                </>
              )}
            />
          </Dialog>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InfosPersonnelles);
