import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { ChartPie } from "./ChartPie";




/**
 * @typedef {Object} Page1Props
 * @param {Page1Props} props {@link Page1Props}
 */
export const Page2 = () => {
  const queryClient = useQueryClient();
  const CriteresAnnuel =
  /** @type {Array<string> | undefined} */ (
    queryClient.getQueryData(["donneesAnnuelles", "CriteresAnnuels"])
  );



  /**
   * @typedef {Object} LigneTableauProps
   * @property {string} intitule
   * @property {string} base
   * @property {string} classe
   * @param {LigneTableauProps} props {@link LigneTableauProps}
   */
  function LigneTableau({intitule, base, classe})
  {
    const style = {"backgroundColor":couleurs[base]};
    const array = ["n", "ParChev", "1000L", "ParUMO"];

    const listItems = array.map(suffixe => 
      <td key={suffixe} className={`valeur ${classe}`} style={(suffixe === "n") ? style : {}}>
        {CriteresAnnuel[base + "_" + suffixe] ? CriteresAnnuel[base + "_" + suffixe].toLocaleString() : 0 }
      </td>
      );

    return (
      <tr>
        <td className={`intitule ${classe}`} style={style}>{intitule}</td>
        <td className="separate"></td>
        <td key="n_1" className={`valeur ${classe}`}>
          {CriteresAnnuel[base + "_n_1"] ? CriteresAnnuel[base + "_n_1"].toLocaleString() : 0 }
        </td>
        <td className="separate"></td>
        
        {listItems}
      </tr>
    )
  }

  const couleurs = {
    "RecetteLaitLaiterie":"rgba(192, 255, 255, 1)", 
    "ProdViande":"rgba(255, 224, 192, 1)",
    "TotPrFourVendMt":"rgba(192, 255, 192, 1)", 
    "TotAutreProd":"rgba(255, 255, 192, 1)",

    "CoutTotAlimChev":"rgba(255, 255, 192, 1)", 
    "FeTot":"rgba(192, 192, 255, 1)", 
    "FcTot":"rgba(192, 255, 192, 1)",

    "CoutFfFonc":"rgba(255, 224, 192, 1)", 
    "CoutFfMeca":"rgba(192, 255, 255, 1)",
    "CoutFfBat":"rgba(255, 192, 255, 1)",
    "CoutFfMoMsa":"rgba(192, 192, 0, 1)",
    "CoutFfFin":"rgba(192, 255, 192, 1)",
    "CoutFfDiv":"rgba(255, 255, 192, 1)"
  }

  const chartPiesData = {
    Produits: {
        intitules:["Lait", "Viande", "Fourrages", "Autre"],
        bases:["RecetteLaitLaiterie", "ProdViande", "TotPrFourVendMt", "TotAutreProd"]
    },
    ChOpe:{
      intitules:["Alimentation", "Frais d'élevage", "Frais de cultures"],
      bases:["CoutTotAlimChev", "FeTot", "FcTot"]
    },
    Structures:{
      intitules:["Foncier", "Mécanisation", "Bâtiment", "MO-MSA", "Frais financier", "Autres charges"],
      bases:["CoutFfFonc", "CoutFfMeca", "CoutFfBat", "CoutFfMoMsa", "CoutFfFin", "CoutFfDiv"]
    }

  }

  return (
    <>
      <div className="pdf-annuel-page2">
        <div>

          <div className="div-flex">
            <div>
              <table>
              <thead>
                <tr>
                  <th className="intitule"></th>
                  <th className="separate"></th>
                  <th>Total N-1</th>
                  <th className="separate"></th>
                  <th>Total N</th>
                  <th>/ Chevre</th>
                  <th>/ 1000L</th>
                  <th>/ UMO</th>
                </tr>
              </thead>
              <tbody>
                <LigneTableau intitule="Lait" base="RecetteLaitLaiterie"></LigneTableau>
                <LigneTableau intitule="Viande" base="ProdViande"></LigneTableau>
                <LigneTableau intitule="Fourrages" base="TotPrFourVendMt"></LigneTableau>
                <LigneTableau intitule="Autre" base="TotAutreProd"></LigneTableau>
                <LigneTableau intitule="Total Produits" base="PrTot" classe="total" ></LigneTableau>
              </tbody>
              </table>
            </div>


            {
              existe(CriteresAnnuel.PrTot_n) &&
              <div className="chart chart-produit">
                <ChartPie  
                  intitules={chartPiesData.Produits.intitules}
                  couleurs={chartPiesData.Produits.bases.map(base => couleurs[base])}
                  donnees={chartPiesData.Produits.bases.map(base => CriteresAnnuel[base+"_n"])}
                  titre="Total Produits"/>
              </div>
            }

          </div>
          
          <div className="div-flex">
            <table>
            <tbody className="separate">
              <LigneTableau intitule="Alimentation" base="CoutTotAlimChev"></LigneTableau>
              <LigneTableau intitule="Frais d'élevage" base="FeTot"></LigneTableau>
              <LigneTableau intitule="Frais de cultures" base="FcTot"></LigneTableau>
              <LigneTableau intitule="Total charges opérationnelles" base="ChargOpTot" classe="total"></LigneTableau>
            </tbody>
            </table>

            {
              existe(CriteresAnnuel.ChargOpTot_n) &&
              <div className="chart chart-produit">
                <ChartPie  
                  intitules={chartPiesData.ChOpe.intitules}
                  couleurs={chartPiesData.ChOpe.bases.map(base => couleurs[base])}
                  donnees={chartPiesData.ChOpe.bases.map(base => CriteresAnnuel[base+"_n"])}
                  titre="Charges Opérationnelles"/>
              </div>
            }
          </div>

          <div className="div-flex">
            {
              (existe(CriteresAnnuel.MargeBruteTot_n) || existe(CriteresAnnuel.MargeBruteTot_n_1)) &&
              <table>
              <tbody className="separate">
                <LigneTableau intitule="Marge Brute" base="MargeBruteTot" classe="total"></LigneTableau>
              </tbody>
              </table>
            }

            {
              existe(CriteresAnnuel.MargeBruteTot_n) && existe(CriteresAnnuel.PrTot_n) &&
                <div className="pctMarge margeBrute">{calculMarge(CriteresAnnuel.MargeBruteTot_n, CriteresAnnuel.PrTot_n)}</div>
            }
          </div>
          
          <div className="div-flex">
           {
            (existe(CriteresAnnuel.ProdPrimTot_n) || existe(CriteresAnnuel.ProdPrimTot_n_1)) &&
            <table>
            <tbody className="separate">
              <LigneTableau intitule="Primes surfaces" base="PrPrimSurfFour"></LigneTableau>
              <LigneTableau intitule="Primes structurelles" base="PrPrimStruct"></LigneTableau>
              <LigneTableau intitule="Total primes" base="ProdPrimTot" classe="total"></LigneTableau>
            </tbody>
            </table>
            }
          </div>

          <div className="div-flex">
            {
              (existe(CriteresAnnuel.CoutTotFf_n) || existe(CriteresAnnuel.CoutTotFf_n_1)) &&
              <table>
              <tbody className="separate">
                <LigneTableau intitule="Foncier" base="CoutFfFonc"></LigneTableau>
                <LigneTableau intitule="Mécanisation" base="CoutFfMeca"></LigneTableau>
                <LigneTableau intitule="Bâtiment" base="CoutFfBat"></LigneTableau>
                <LigneTableau intitule="MO-MSA" base="CoutFfMoMsa"></LigneTableau>
                <LigneTableau intitule="Frais financier" base="CoutFfFin"></LigneTableau>
                <LigneTableau intitule="Autres charges" base="CoutFfDiv"></LigneTableau>
                <LigneTableau intitule="Total charges fixes" base="CoutTotFf" classe="total"></LigneTableau>
              </tbody>
              </table>
            }

            {
              existe(CriteresAnnuel.CoutTotFf_n) &&
              <div className="chart chart-structures">
                <ChartPie  
                  intitules={chartPiesData.Structures.intitules}
                  couleurs={chartPiesData.Structures.bases.map(base => couleurs[base])}
                  donnees={chartPiesData.Structures.bases.map(base => CriteresAnnuel[base+"_n"])}
                  titre="Structures"/>
              </div>
            }
          </div>
          
          <div className="div-flex">
            {
              (existe(CriteresAnnuel.MargeNetteTot_n) || existe(CriteresAnnuel.MargeNetteTot_n_1)) &&
              <table>
              <tbody className="separate">
                <LigneTableau intitule="Marge Nette ou Revenu Disponible Lait" base="MargeNetteTot" classe="total"></LigneTableau>
              </tbody>
              </table>
            }

            {
              existe(CriteresAnnuel.MargeNetteTot_n) && existe(CriteresAnnuel.PrTot_n) &&
                <div className="pctMarge margeNette">{calculMarge(CriteresAnnuel.MargeNetteTot_n, CriteresAnnuel.PrTot_n)}</div>
            }
          </div>

        </div>
      </div>
    </>
  )
}

/**
 * @typedef {Object} existeProps
 * @property {string | undefined} element
 * @param {existeProps} props {@link existeProps}
 */
function existe(element)
{
  return (element != 0 && element != null)
}

/**
 * @typedef {Object} calculMargeProps
 * @property {string | undefined} margeTot
 * @property {string | undefined} recettesTot
 * @param {calculMargeProps} props {@link calculMargeProps}
 */
function calculMarge(margeTot, recettesTot)
{
  if (existe(margeTot) && existe(recettesTot))
  {
    return (margeTot / recettesTot * 100).toFixed(1) + " %";
  }
  return "";
}