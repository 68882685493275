import {
  LOGIN,
  LOGOUT,
  REFRESH_TOKEN,
  CGU,
  RESET_PASSWORD,
} from "../actions/auth";

const authInitialState = {
  groupeId: null,
  domaineId: null,
  utilisateurId: null,
  typeUtilisateurId: null,
  nom: null,
  prenom: null,
  sessionExpirationTime: null,
  cguChecked: null,
  privacyChecked: null,
  isFirstLogin: null,
  haveResetPwd: null,
};

const authReducer = (state = authInitialState, action) => {
  switch (action.type) {
    case LOGIN: {
      const {
        id: utilisateurId,
        type: typeUtilisateurId,
        nom,
        prenom,
        defaultGrpId: groupeId,
        domaine: domaineId,
        sessionExpirationTime,
        cguChecked,
        privacyChecked,
        isFirstLogin,
        haveResetPwd,
      } = action.payload;
      return {
        ...state,
        utilisateurId,
        typeUtilisateurId,
        nom,
        prenom,
        groupeId,
        domaineId,
        sessionExpirationTime,
        cguChecked,
        privacyChecked,
        isFirstLogin,
        haveResetPwd,
      };
    }
    case REFRESH_TOKEN: {
      const { sessionExpirationTime } = action.payload;
      return { ...state, sessionExpirationTime };
    }
    case LOGOUT:
      return authInitialState;
    case CGU: {
      const { cguChecked, privacyChecked } = action.payload;
      return { ...state, cguChecked, privacyChecked };
    }
    case RESET_PASSWORD: {
      const { isFirstLogin, haveResetPwd } = action.payload;
      return {
        ...state,
        isFirstLogin,
        haveResetPwd,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
