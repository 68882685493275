import {
  addToast as actionAdd,
  clearAllToasts as actionClear,
  removeToast as actionRemove,
} from "../redux/actions/toastr";
import store from "../redux/store";

/**
 * Toastr Service (for display toast messages to user)
 */

/**
 * Add a toast to the array of displayed toast
 * @param toast the toast to display.
 * {
 *  id: a single identifier to make it removable easily,
 *  type : the type determining color of the toast,
 *  message: the message to be displayed
 * }
 */
const addToast = ({ id, type = "info", message }) => {
  store.dispatch(actionAdd({ id, type, message }));
  // 5s timeout for message
  setTimeout(() => store.dispatch(actionRemove({ id })), 3000);
};

/**
 * Remove the specified toast from the array of displayed toasts
 * @param id the identifier of the toast to remove
 */
const removeToast = (id) => store.dispatch(actionRemove({ id }));

/**
 * Clears all toasts
 */
const clearAllToasts = () => store.dispatch(actionClear());

export default { addToast, removeToast, clearAllToasts };
