import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldWrapper,
  Form,
  FormElement,
} from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Error as ErrorKendo } from "@progress/kendo-react-labels";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { Component } from "react";
import { connect } from "react-redux";
import dataSource from "../../../dataSource/dataSource";
import toastService from "../../../utils/toastService";

const mapStateToProps = (state) => ({
  domaineId: state.auth.domaineId,
});

class GenerationDonneesMensuelles extends Component {
  constructor(props) {
    super(props);
    const now = new Date();
    this.state = {
      form: { anneeDep: now.getFullYear(), moisDep: now.getMonth() + 1 },
      generating: false,
    };
  }

  onSubmit = async ({ isValid, values: { anneeDep, moisDep } }) => {
    try {
      if (isValid) {
        const { domaineId } = this.props;
        this.setState({ generating: true });
        await dataSource.generateDonneesMensuelles(
          domaineId,
          moisDep,
          anneeDep
        );
        toastService.addToast({
          id: "generation-dm-success",
          type: "success",
          message:
            "La génération des données mensuelles a été effectuée avec succès.",
        });
      } else {
        // TODO show error on form
        toastService.addToast({
          id: "generation-dm-form-invalid",
          type: "error",
          message:
            "Impossible de lancer la génération des données mensuelles, le formulaire n'est pas valide.",
        });
      }
    } catch (error) {
      let errorMsg =
        "Une erreur a été rencontrée lors de la génération des données mensuelles";
      if (error.message) {
        errorMsg = `${errorMsg} : ${error.message}`;
      } else {
        errorMsg += ".";
      }
      toastService.addToast({
        id: "generation-dm-form-error",
        type: "error",
        message: errorMsg,
      });
    } finally {
      this.setState({ generating: false });
    }
  };

  render() {
    const { form, generating } = this.state;
    return (
      <div className="flex-fill k-d-flex-col k-align-items-center k-justify-content-center k-mt-3">
        <Card>
          <CardHeader>
            <CardTitle>Génération des données mensuelles</CardTitle>
          </CardHeader>
          <CardBody>
            <Form
              initialValues={form}
              onSubmitClick={this.onSubmit}
              validator={({ anneeDep, moisDep }) => {
                const errors = {};
                if (!anneeDep || !(typeof anneeDep === "number")) {
                  errors.anneeDep = "doit être renseigné";
                }
                if (!moisDep || !(typeof moisDep === "number")) {
                  errors.moisDep = "doit être renseigné";
                }

                return errors;
              }}
              render={(formRenderProps) => (
                <FormElement>
                  <div className="k-d-flex-row k-justify-content-center k-align-items-baseline">
                    <FieldWrapper style={{ width: "100px" }} className="k-mr-3">
                      <Field
                        format="####"
                        component={NumericTextBox}
                        name="anneeDep"
                        label="Année"
                      />
                      {formRenderProps.errors.anneeDep && (
                        <ErrorKendo>
                          {formRenderProps.errors.anneeDep}
                        </ErrorKendo>
                      )}
                    </FieldWrapper>
                    <FieldWrapper style={{ width: "100px" }} className="k-mr-3">
                      <Field
                        component={NumericTextBox}
                        format="0#"
                        name="moisDep"
                        label="Mois"
                        min={1}
                        max={12}
                      />
                      {formRenderProps.errors.moisDep && (
                        <ErrorKendo>
                          {formRenderProps.errors.moisDep}
                        </ErrorKendo>
                      )}
                    </FieldWrapper>
                    {generating ? (
                      <Loader themeColor="primary" />
                    ) : (
                      <Button themeColor="primary" onClick={formRenderProps.onSubmit}>
                        Générer
                      </Button>
                    )}
                  </div>
                </FormElement>
              )}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default connect(mapStateToProps)(GenerationDonneesMensuelles);
