import { ADD_TOAST, CLEAR_ALL_TOASTS, REMOVE_TOAST } from "../actions/toastr";

const toastrInitialState = {
  toasts: [],
};

const toastrReducer = (state = toastrInitialState, { type, payload }) => {
  switch (type) {
    case ADD_TOAST:
      return { ...state, toasts: [...state.toasts, payload] };
    case REMOVE_TOAST:
      return {
        ...state,
        toasts: [...state.toasts.filter((toast) => toast.id !== payload.id)],
      };
    case CLEAR_ALL_TOASTS:
      return { ...state, toasts: [] };
    default:
      return state;
  }
};

export default toastrReducer;
