import { Component } from "react";
import PropTypes from "prop-types";
import { Field } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { NumericTextBox } from "@progress/kendo-react-inputs";

class DetailFraisVeterinaires extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // C'est moche mais ça marche 🤷🏻‍♂️
      oldFields: {
        feVetHonoChevNb: props.valueGetter("feVetHonoChevNb"),
        feVetHonoChevMt: props.valueGetter("feVetHonoChevMt"),
        feVetHonoChetMt: props.valueGetter("feVetHonoChetMt"),
        feVetHonoChetNb: props.valueGetter("feVetHonoChetNb"),
        feVetDeparChevMt: props.valueGetter("feVetDeparChevMt"),
        feVetDeparChevNb: props.valueGetter("feVetDeparChevNb"),
        feVetDeparChetMt: props.valueGetter("feVetDeparChetMt"),
        feVetDeparChetNb: props.valueGetter("feVetDeparChetNb"),
        feVetCellChevMt: props.valueGetter("feVetCellChevMt"),
        feVetCellChevNb: props.valueGetter("feVetCellChevNb"),
        feVetCellChetMt: props.valueGetter("feVetCellChetMt"),
        feVetCellChetNb: props.valueGetter("feVetCellChetNb"),
        feVetTariChevMt: props.valueGetter("feVetTariChevMt"),
        feVetTariChevNb: props.valueGetter("feVetTariChevNb"),
        feVetTariChetMt: props.valueGetter("feVetTariChetMt"),
        feVetTariChetNb: props.valueGetter("feVetTariChetNb"),
        feVetVaccChevMt: props.valueGetter("feVetVaccChevMt"),
        feVetVaccChevNb: props.valueGetter("feVetVaccChevNb"),
        feVetVaccChetMt: props.valueGetter("feVetVaccChetMt"),
        feVetVaccChetNb: props.valueGetter("feVetVaccChetNb"),
        feVetProphyChevMt: props.valueGetter("feVetProphyChevMt"),
        feVetProphyChevNb: props.valueGetter("feVetProphyChevNb"),
        feVetProphyChetMt: props.valueGetter("feVetProphyChetMt"),
        feVetProphyChetNb: props.valueGetter("feVetProphyChetNb"),
        feVetAutreChevMt: props.valueGetter("feVetAutreChevMt"),
        feVetAutreChevNb: props.valueGetter("feVetAutreChevNb"),
        feVetAutreChetMt: props.valueGetter("feVetAutreChetMt"),
        feVetAutreChetNb: props.valueGetter("feVetAutreChetNb"),
      },
    };
  }

  handleOnCancel = () => {
    const { onChange, onClose } = this.props;
    const { oldFields } = this.state;

    // C'est moche mais ça marche 🤷🏻‍♂️
    Object.keys(oldFields).forEach((k) => {
      onChange(k, { value: oldFields[k] });
    });
    onClose();
  };

  handleOnOK = () => {
    const { onSubmit, onClose } = this.props;

    onSubmit();
    onClose();
  };

  render() {
    const { valueGetter, edit } = this.props;

    return (
      <div>
        <div className="k-mb-4 grid-wrapper-form-1-2-2-4">
          <div
            className="k-text-center k-font-weight-bold"
            style={{ gridColumnStart: 2 }}
          >
            CHÈVRES
          </div>
          <div className="k-text-center k-font-weight-bold">CHEVRETTES</div>
        </div>

        <div className="k-mb-4 grid-wrapper-form-1-2-2-4 k-gap-20">
          <div style={{ gridColumnStart: 2 }} className="grid-wrapper-form-2">
            <div className="k-text-center k-font-weight-bold">
              Montant HT (€)
            </div>
            <div className="k-text-center k-font-weight-bold">
              Nb d&#39;animaux
            </div>
          </div>
          <div className="grid-wrapper-form-2">
            <div className="k-text-center k-font-weight-bold">
              Montant HT (€)
            </div>
            <div className="k-text-center k-font-weight-bold">
              Nb d&#39;animaux
            </div>
          </div>
          <div className="grid-wrapper-form-2">
            <div className="k-font-weight-bold" style={{ gridColumnStart: 2 }}>
              TOTAL Montant HT (€)
            </div>
          </div>
        </div>

        <div className="k-mb-2 grid-wrapper-form-1-2-2-4 k-gap-20">
          <div className="k-align-self-center">Honoraires</div>
          <div className="grid-wrapper-form-2">
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetHonoChevMt"
              disabled={!edit}
              spinners={edit}
            />
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetHonoChevNb"
              disabled={!edit}
              spinners={edit}
            />
          </div>
          <div className="grid-wrapper-form-2">
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetHonoChetMt"
              disabled={!edit}
              spinners={edit}
            />
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetHonoChetNb"
              disabled={!edit}
              spinners={edit}
            />
          </div>
          <div className="grid-wrapper-form-2">
            <div style={{ gridColumnStart: 2 }}>
              <NumericTextBox
                format="n0"
                disabled
                value={
                  valueGetter("feVetHonoChevMt") +
                  valueGetter("feVetHonoChetMt")
                }
                spinners={false}
              />
            </div>
          </div>
        </div>

        <div className="k-mb-2 grid-wrapper-form-1-2-2-4 k-gap-20">
          <div className="k-align-self-center">Déparasitage</div>
          <div className="grid-wrapper-form-2">
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetDeparChevMt"
              disabled={!edit}
              spinners={edit}
            />
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetDeparChevNb"
              disabled={!edit}
              spinners={edit}
            />
          </div>
          <div className="grid-wrapper-form-2">
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetDeparChetMt"
              disabled={!edit}
              spinners={edit}
            />
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetDeparChetNb"
              disabled={!edit}
              spinners={edit}
            />
          </div>
          <div className="grid-wrapper-form-2">
            <div style={{ gridColumnStart: 2 }}>
              <NumericTextBox
                format="n0"
                disabled
                value={
                  valueGetter("feVetDeparChevMt") +
                  valueGetter("feVetDeparChetMt")
                }
                spinners={false}
              />
            </div>
          </div>
        </div>

        <div className="k-mb-2 grid-wrapper-form-1-2-2-4 k-gap-20">
          <div className="k-align-self-center">Cellules</div>
          <div className="grid-wrapper-form-2">
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetCellChevMt"
              disabled={!edit}
              spinners={edit}
            />
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetCellChevNb"
              disabled={!edit}
              spinners={edit}
            />
          </div>
          <div className="grid-wrapper-form-2">
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetCellChetMt"
              disabled={!edit}
              spinners={edit}
            />
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetCellChetNb"
              disabled={!edit}
              spinners={edit}
            />
          </div>
          <div className="grid-wrapper-form-2">
            <div style={{ gridColumnStart: 2 }}>
              <NumericTextBox
                format="n0"
                disabled
                value={
                  valueGetter("feVetCellChevMt") +
                  valueGetter("feVetCellChetMt")
                }
                spinners={false}
              />
            </div>
          </div>
        </div>

        <div className="k-mb-2 grid-wrapper-form-1-2-2-4 k-gap-20">
          <div className="k-align-self-center">Tarissement</div>
          <div className="grid-wrapper-form-2">
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetTariChevMt"
              disabled={!edit}
              spinners={edit}
            />
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetTariChevNb"
              disabled={!edit}
              spinners={edit}
            />
          </div>
          <div className="grid-wrapper-form-2">
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetTariChetMt"
              disabled={!edit}
              spinners={edit}
            />
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetTariChetNb"
              disabled={!edit}
              spinners={edit}
            />
          </div>
          <div className="grid-wrapper-form-2">
            <div style={{ gridColumnStart: 2 }}>
              <NumericTextBox
                format="n0"
                disabled
                value={
                  valueGetter("feVetTariChevMt") +
                  valueGetter("feVetTariChetMt")
                }
                spinners={false}
              />
            </div>
          </div>
        </div>

        <div className="k-mb-2 grid-wrapper-form-1-2-2-4 k-gap-20">
          <div className="k-align-self-center">Vaccins</div>
          <div className="grid-wrapper-form-2">
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetVaccChevMt"
              disabled={!edit}
              spinners={edit}
            />
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetVaccChevNb"
              disabled={!edit}
              spinners={edit}
            />
          </div>
          <div className="grid-wrapper-form-2">
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetVaccChetMt"
              disabled={!edit}
              spinners={edit}
            />
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetVaccChetNb"
              disabled={!edit}
              spinners={edit}
            />
          </div>
          <div className="grid-wrapper-form-2">
            <div style={{ gridColumnStart: 2 }}>
              <NumericTextBox
                format="n0"
                disabled
                value={
                  valueGetter("feVetVaccChevMt") +
                  valueGetter("feVetVaccChetMt")
                }
                spinners={false}
              />
            </div>
          </div>
        </div>

        <div className="k-mb-2 grid-wrapper-form-1-2-2-4 k-gap-20">
          <div className="k-align-self-center">Prophylaxie</div>
          <div className="grid-wrapper-form-2">
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetProphyChevMt"
              disabled={!edit}
              spinners={edit}
            />
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetProphyChevNb"
              disabled={!edit}
              spinners={edit}
            />
          </div>
          <div className="grid-wrapper-form-2">
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetProphyChetMt"
              disabled={!edit}
              spinners={edit}
            />
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetProphyChetNb"
              disabled={!edit}
              spinners={edit}
            />
          </div>
          <div className="grid-wrapper-form-2">
            <div style={{ gridColumnStart: 2 }}>
              <NumericTextBox
                format="n0"
                disabled
                value={
                  valueGetter("feVetProphyChevMt") +
                  valueGetter("feVetProphyChetMt")
                }
                spinners={false}
              />
            </div>
          </div>
        </div>

        <div className="k-mb-2 grid-wrapper-form-1-2-2-4 k-gap-20">
          <div className="k-align-self-center">Autres</div>
          <div className="grid-wrapper-form-2">
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetAutreChevMt"
              disabled={!edit}
              spinners={edit}
            />
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetAutreChevNb"
              disabled={!edit}
              spinners={edit}
            />
          </div>
          <div className="grid-wrapper-form-2">
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetAutreChetMt"
              disabled={!edit}
              spinners={edit}
            />
            <Field
              component={NumericTextBox}
              format="n0"
              name="feVetAutreChetNb"
              disabled={!edit}
              spinners={edit}
            />
          </div>
          <div className="grid-wrapper-form-2">
            <div style={{ gridColumnStart: 2 }}>
              <NumericTextBox
                format="n0"
                disabled
                value={
                  valueGetter("feVetAutreChevMt") +
                  valueGetter("feVetAutreChetMt")
                }
                spinners={false}
              />
            </div>
          </div>
        </div>

        <div className="k-mt-4 k-mb-2 grid-wrapper-form-1-2-2-4 k-gap-20">
          <div className="k-align-self-center">Total</div>
          <div className="grid-wrapper-form-2">
            <NumericTextBox
              format="n0"
              disabled
              value={
                valueGetter("feVetHonoChevMt") +
                valueGetter("feVetDeparChevMt") +
                valueGetter("feVetCellChevMt") +
                valueGetter("feVetTariChevMt") +
                valueGetter("feVetVaccChevMt") +
                valueGetter("feVetProphyChevMt") +
                valueGetter("feVetAutreChevMt")
              }
              spinners={false}
            />
            <NumericTextBox
              format="n0"
              disabled
              value={
                valueGetter("feVetHonoChevNb") +
                valueGetter("feVetDeparChevNb") +
                valueGetter("feVetCellChevNb") +
                valueGetter("feVetTariChevNb") +
                valueGetter("feVetVaccChevNb") +
                valueGetter("feVetProphyChevNb") +
                valueGetter("feVetAutreChevNb")
              }
              spinners={false}
            />
          </div>
          <div className="grid-wrapper-form-2">
            <NumericTextBox
              format="n0"
              disabled
              value={
                valueGetter("feVetHonoChetMt") +
                valueGetter("feVetDeparChetMt") +
                valueGetter("feVetCellChetMt") +
                valueGetter("feVetTariChetMt") +
                valueGetter("feVetVaccChetMt") +
                valueGetter("feVetProphyChetMt") +
                valueGetter("feVetAutreChetMt")
              }
              spinners={false}
            />
            <NumericTextBox
              format="n0"
              disabled
              value={
                valueGetter("feVetHonoChetNb") +
                valueGetter("feVetDeparChetNb") +
                valueGetter("feVetCellChetNb") +
                valueGetter("feVetTariChetNb") +
                valueGetter("feVetVaccChetNb") +
                valueGetter("feVetProphyChetNb") +
                valueGetter("feVetAutreChetNb")
              }
              spinners={false}
            />
          </div>
          <div className="grid-wrapper-form-2">
            <div className="k-font-weight-bold k-text-right k-align-self-center">
              Coût total
            </div>
            <div>
              <NumericTextBox
                format="n0"
                disabled
                value={
                  valueGetter("feVetHonoChevMt") +
                  valueGetter("feVetHonoChetMt") +
                  valueGetter("feVetDeparChevMt") +
                  valueGetter("feVetDeparChetMt") +
                  valueGetter("feVetCellChevMt") +
                  valueGetter("feVetCellChetMt") +
                  valueGetter("feVetTariChevMt") +
                  valueGetter("feVetTariChetMt") +
                  valueGetter("feVetVaccChevMt") +
                  valueGetter("feVetVaccChetMt") +
                  valueGetter("feVetProphyChevMt") +
                  valueGetter("feVetProphyChetMt") +
                  valueGetter("feVetAutreChevMt") +
                  valueGetter("feVetAutreChetMt")
                }
                spinners={false}
              />
            </div>
          </div>
        </div>

        <div className="k-d-flex k-justify-content-center k-mt-6">
          <Button
            type="button"
            themeColor="primary"
            className="k-m-2 k-button"
            onClick={this.handleOnOK}
          >
            OK
          </Button>
          <Button
            type="button"
            onClick={this.handleOnCancel}
            className="k-m-2 k-button"
          >
            Annuler
          </Button>
        </div>
      </div>
    );
  }
}

DetailFraisVeterinaires.propTypes = {
  edit: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  valueGetter: PropTypes.func.isRequired,
};

export default DetailFraisVeterinaires;
