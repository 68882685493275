import {
  SET_ADMIN_GROUPE_FILTERS,
  SET_ADMIN_GROUPE_DUPLICATE,
  SET_DA_FILTERS,
  SET_DM_FILTERS,
  SET_DM_PDL_FILTERS,
  SET_DOMAINE,
} from "../actions/app";

import { LOGIN } from "../actions/auth";

export const DOMAINE_OPTICHEVRE = "DOMAINE_OPTICHEVRE";
export const DOMAINE_CHEVRYPLAN = "DOMAINE_CHEVRYPLAN";
export const APP_DOMAINES = [DOMAINE_OPTICHEVRE, DOMAINE_CHEVRYPLAN];

const appInitialState = {
  filters: {
    DA: {
      campagneSelected: null,
      groupeSelected: null,
      eleveurSelected: null,
    },
    DM: {
      campagne: null,
      debut: true,
      fin: true,
      groupe: null,
      elevage: null,
      anneeDep: null,
      moisDep: null,
      statut: undefined,
      societeAgricoleId: null,
    },
    DM_PDL: {
      campagne: null,
      debut: true,
      fin: true,
      groupeSelected: null,
      isPaieNonSaisie: false,
      anneeDep: null,
      moisDep: null,
    },
    adminGroupe: {
      campagne: null,
      debut: true,
      fin: true,
    },
  },
  adminGroupeDuplicate: null,
};

const { PUBLIC_URL } = process.env;

const appReducer = (state = appInitialState, { type, payload }) => {
  const OPTICHEVRE_URL = process.env.REACT_APP_OPTICHEVRE_URL;
  const myLocation = () => {
    return window.location.href;
  };
  const isOptiChevre = myLocation().toString().includes(OPTICHEVRE_URL);
  switch (type) {
    case SET_DOMAINE: {
      // handle document title rewrite
      document.title = isOptiChevre ? "Optichèvre" : "Chevry-plan";
      const headTitle = document.querySelector("head");
      const setFavicon = document.createElement("link");
      setFavicon.setAttribute("rel", "shortcut icon");
      setFavicon.setAttribute(
        "href",
        `${PUBLIC_URL}/${
          isOptiChevre ? "favicon_optichevre.png" : "favicon.png"
        }`
      );
      headTitle.appendChild(setFavicon);
      return { ...state, domaine: payload };
    }
    case LOGIN: {
      // handle document title rewrite
      document.title = isOptiChevre ? "Optichèvre" : "Chevry-plan";
      const headTitle = document.querySelector("head");
      const setFavicon = document.createElement("link");
      setFavicon.setAttribute("rel", "shortcut icon");
      setFavicon.setAttribute(
        "href",
        `${PUBLIC_URL}/${
          isOptiChevre ? "favicon_optichevre.png" : "favicon.png"
        }`
      );
      headTitle.appendChild(setFavicon);
      return {
        domaine: isOptiChevre ? DOMAINE_OPTICHEVRE : DOMAINE_CHEVRYPLAN,
      };
    }

    case SET_DA_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, DA: { ...state.filters?.DA, ...payload } },
      };
    case SET_DM_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, DM: { ...state.filters?.DM, ...payload } },
      };
    case SET_DM_PDL_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          DM_PDL: { ...state.filters?.DM_PDL, ...payload },
        },
      };
    case SET_ADMIN_GROUPE_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          adminGroupe: { ...state.filters?.adminGroupe, ...payload },
        },
      };
    case SET_ADMIN_GROUPE_DUPLICATE:
      return {
        ...state,
        adminGroupeDuplicate: { ...state.adminGroupeDuplicate, ...payload },
      };
    default:
      return state;
  }
};

export default appReducer;
