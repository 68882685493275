import { Component } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Field, Form } from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Error as ErrorKendo } from "@progress/kendo-react-labels/dist/npm/Error";
import { connect } from "react-redux";
import { Hint } from "@progress/kendo-react-labels";
import toastService from "../utils/toastService";
import { attemptCgu, attemptResetPassword } from "../redux/actions/auth";
import { checkPassword } from "./form/validator";

const mapStateToProps = (state) => ({
  utilisateurId: state.auth.utilisateurId,
  cguChecked: state.auth.cguChecked,
  privacyChecked: state.auth.privacyChecked,
  isFirstLogin: state.auth.isFirstLogin,
  haveResetPwd: state.auth.haveResetPwd,
});

const mapDispatchToProps = (dispatch) => ({
  reduxCgu: async (values) => {
    return dispatch(
      attemptCgu(values.utilisateurId, {
        cguChecked: values.cguChecked,
        privacyChecked: values.privacyChecked,
      })
    );
  },
  reduxResetPassword: async (data) => {
    return dispatch(attemptResetPassword(data));
  },
});

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCguError: false,
      showCguDialog: false,
      cguChecked: props.cguChecked,
      privacyChecked: props.privacyChecked,
      loading: true,
      showPassword: false,
      showSetPasswordDialog: false,
    };
  }

  async componentDidMount() {
    const { cguChecked, haveResetPwd, isFirstLogin } = this.props;
    if (!cguChecked) {
      this.setState({
        showCguDialog: true,
        loading: false,
      });
    }
    if (isFirstLogin || haveResetPwd) {
      this.toggleSetPasswordDialog();
    }
  }

  handleClickShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  toggleSetPasswordDialog = () => {
    this.setState((prevState) => ({
      showSetPasswordDialog: !prevState.showSetPasswordDialog,
    }));
  };

  updatePassword = async (data) => {
    const { utilisateurId, reduxResetPassword } = this.props;
    try {
      await reduxResetPassword({
        utilisateurId,
        newPassword: data.password,
      });
    } catch (err) {
      // show toast for error
      toastService.addToast({
        id: "update-password-error",
        type: "error",
        message: err.message,
      });
    }
    // show toast for success
    toastService.addToast({
      id: "updatePassword-success",
      type: "success",
      message: "Votre mote de passe a bien été mises à jour",
    });
  };

  handleSubmit = async ({ values, isValid }) => {
    const { utilisateurId, reduxCgu } = this.props;
    if (!isValid) {
      this.setState({
        showCguError: true,
      });
    } else {
      // submit data
      try {
        await reduxCgu({
          utilisateurId,
          cguChecked: values.cguChecked,
          privacyChecked: values.privacyChecked,
        });
      } catch (err) {
        // show toast for error
        toastService.addToast({
          id: "infos-acceptation-cgu-error",
          type: "error",
          message: err.message,
        });
      }
      this.toggleCguDialog();
      // show toast for success
      toastService.addToast({
        id: "infos-acceptation-cgu-success",
        type: "success",
        message: "Vos informations ont bien été mises à jour",
      });
    }
  };

  toggleCguDialog = () => {
    this.setState((prevState) => ({
      showCguDialog: !prevState.showCguDialog,
    }));
  };

  renderCguPopup = () => {
    const {
      showCguDialog,
      showCguError,
      loading,
      cguChecked,
      privacyChecked,
    } = this.state;

    return (
      showCguDialog && (
        <Dialog
          title={"Conditions générales"}
          closeIcon={false}
          width={500}
          className="cgu-dialog"
        >
          <Form
            className="k-form"
            initialValues={{ cguChecked, privacyChecked }}
            onSubmitClick={this.handleSubmit}
            validator={(values, valueGetter) => {
              const errors = {};
              if (!valueGetter("cguChecked")) {
                errors.cgu = `Veuillez accepter les Conditions Générales d''Utilisation et prendre connaissance de la Confidentialité des données personnelles pour accéder à l''application.`;
              }
              return errors;
            }}
            render={(formRenderProps) => (
              <>
                {loading && (
                  <div className="flex-fill k-text-center k-mt-18">
                    <Loader themeColor="primary" />
                  </div>
                )}
                <h4>
                  1. Conditions d&apos;utilisation et avis de confidentialité
                </h4>
                <div className="k-d-flex k-mb-8">
                  <Field component={Checkbox} name="cguChecked" />
                  <p className="k-ml-4">
                    J&apos;accepte les{" "}
                    <a
                      href={
                        "https://www.legal.admanimalnutrition.com/documents/terms-of-use/"
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      Conditions Générales d&apos;Utilisation
                    </a>
                    , et j&apos;ai lu et compris la{" "}
                    <a
                      href={
                        "https://www.legal.admanimalnutrition.com/documents/privacy-notice-for-apps/"
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      Confidentialité des données personnelles.
                    </a>
                  </p>
                </div>
                <h4>2. Partage de données personnelles avec ADM</h4>
                <div className="k-d-flex k-mt-4">
                  <Field component={Checkbox} name="privacyChecked" />
                  <p className="k-ml-4">
                    J&apos;accepte qu&apos;ADM puisse stocker, utiliser et
                    traiter mes données personnelles en m&apos;envoyant des
                    communications ciblées à des fins de prospection.
                  </p>
                </div>
                <Button
                  className="k-button k-primary k-mt-8"
                  onClick={formRenderProps.onSubmit}
                >
                  Accéder à l&apos;application
                </Button>
                <Button
                  className="k-button k-mt-8 k-ml-4"
                  onClick={this.toggleCguDialog}
                >
                  Refuser
                </Button>
                {showCguError && (
                  <ErrorKendo className="k-mt-4">
                    {formRenderProps.errors.cgu}
                  </ErrorKendo>
                )}
              </>
            )}
          />
        </Dialog>
      )
    );
  };

  renderSetPasswordPopup = () => {
    const { showPassword, showSetPasswordDialog } = this.state;
    return (
      showSetPasswordDialog && (
        <Dialog
          title={"Définir votre nouveau mot de passe"}
          onClose={this.toggleSetPasswordDialog}
          closeIcon={false}
        >
          <Form
            onSubmit={(data) => {
              this.updatePassword(data);
              this.toggleSetPasswordDialog();
            }}
            validator={(values, valueGetter) => {
              const errors = {};
              if (valueGetter("password") !== valueGetter("newPassword")) {
                errors.confirmPassword = `Le mot de passe de confirmation est différent du précédent. Veuillez saisir 2 mots de passe identiques`;
              }
              return errors;
            }}
            render={(formRenderProps) => (
              <>
                <p>Veuillez saisir votre nouveau mot de passe</p>
                <>
                  <div className="k-d-flex k-align-items-center">
                    <Field
                      component={Input}
                      name={"password"}
                      type={showPassword ? "text" : "password"}
                      validator={checkPassword}
                      required
                    />
                    <Button
                      className="k-button k-button-clear"
                      onClick={this.handleClickShowPassword}
                    >
                      <span className="k-icon k-i-preview" />
                    </Button>
                  </div>
                  {!!formRenderProps.errors.password &&
                    formRenderProps.touched && (
                      <ErrorKendo>
                        Le mot de passe n&apos;a pas le bon format
                      </ErrorKendo>
                    )}
                  <Hint className="k-mb-5">
                    5 caractères minimum, dont un chiffre, une lettre et un
                    caractère spécial parmi # ? ! @ $ % ^ & * - . : = &lt; &gt;
                    / ; |
                  </Hint>
                  <div className="k-mb-8">
                    <p>Veuillez confirmer votre nouveau mot de passe</p>
                    <div className="k-d-flex k-align-items-center">
                      <Field
                        component={Input}
                        name={"newPassword"}
                        type={showPassword ? "text" : "password"}
                        required
                      />
                      <Button
                        className="k-button k-button-clear"
                        onClick={this.handleClickShowPassword}
                      >
                        <span className="k-icon k-i-preview" />
                      </Button>
                    </div>
                    {!!formRenderProps.errors.confirmPassword &&
                      formRenderProps.touched && (
                        <ErrorKendo>
                          Le mot de passe de confirmtion est différent
                        </ErrorKendo>
                      )}
                  </div>
                </>
                <DialogActionsBar className="k-mt-4">
                  <Button
                    className="k-button"
                    themeColor="primary"
                    onClick={() => formRenderProps.onSubmit()}
                  >
                    Valider
                  </Button>
                </DialogActionsBar>
              </>
            )}
          />
        </Dialog>
      )
    );
  };

  render() {
    return (
      <>
        {this.renderSetPasswordPopup()}
        {this.renderCguPopup()}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
