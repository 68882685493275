import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { loadState, saveState } from "../utils/localStorage";
import rootReducer from "./reducers";

const persistedState = loadState();

// use extension [REDUX-DEVTOOLS](https://github.com/zalmoxisus/redux-devtools-extension#usage)
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(applyMiddleware(thunk))
);

store.subscribe(() => {
  const { ...stateToSave } = store.getState();
  saveState(stateToSave);
});

export default store;
