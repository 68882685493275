import PropTypes from "prop-types";
import { Component } from "react";
import CustomGrid from "../../shared/CustomGrid";

const columns = [
  {
    field: "anneeMois",
    title: "Mois",
    type: "text",
    width: 80,
    editable: () => false,
    headerClassName: "k-font-weight-bold",
    cellClassName: "k-font-weight-bold",
  },
  {
    field: "productionQuantiteLaitLivre",
    fieldProps: { spinners: false },
    title: "Lait livré (l)",
    type: "number",
    format: "n0",
    width: 120,
    editable: () => false,
  },
  {
    field: "productionQuantiteLaitPaye",
    fieldProps: { spinners: false },
    title: "Lait payé (l)",
    type: "number",
    width: 120,
    format: "n0",
    editable: () => true,
  },
  {
    field: "productionQuantiteLaitValeurTotaleHT",
    fieldProps: { spinners: false },
    title: "Total HT",
    format: "c",
    type: "number",
    width: 100,
    editable: () => true,
  },
  {
    field: "prixPourMLitres",
    fieldProps: { spinners: false },
    title: "Prix / 1000 L",
    type: "number",
    format: "n2",
    width: 120,
    editable: () => false,
    computedValue: (value) =>
      ["prixPourMLitres"].map((field) =>
        value.productionQuantiteLaitPaye > 0
          ? (1000 * value.productionQuantiteLaitValeurTotaleHT) /
            value.productionQuantiteLaitPaye
          : Number(value[field])
      ),
  },
  {
    field: "primesHT",
    fieldProps: { spinners: false },
    title: "Primes HT",
    type: "number",
    width: 110,
    format: "n2",
    editable: () => true,
  },
  {
    field: "tb",
    fieldProps: { spinners: false },
    title: "TB",
    type: "number",
    width: 60,
    format: "n1",
    editable: () => true,
  },
  {
    field: "tp",
    fieldProps: { spinners: false },
    title: "TP",
    type: "number",
    width: 60,
    format: "n1",
    editable: () => true,
  },
  {
    field: "germes",
    fieldProps: { spinners: false },
    title: "Germes",
    type: "number",
    width: 90,
    format: "n0",
    editable: () => true,
  },
  {
    field: "cellules",
    fieldProps: { spinners: false },
    title: "Cellules",
    type: "number",
    width: 90,
    format: "n0",
    editable: () => true,
  },
  {
    field: "autres",
    fieldProps: { spinners: false },
    title: "Autres",
    type: "number",
    width: 80,
    format: "n0",
    editable: () => true,
  },
  {
    field: "totalPenalites",
    fieldProps: { spinners: false },
    title: "Pénalités",
    type: "number",
    width: 100,
    format: "n2",
    editable: () => false,
    computedValue: (value) =>
      [
        "germes",
        "cellules",
        "icg",
        "antibiothiqueInhibiteurs",
        "coliformes",
        "listeria",
        "lipolyse",
        "txInverse",
        "autres",
      ]
        .map((field) => Number(value[field]))
        .reduce((sum, current) => sum + current, 0),
  },
];

class PaieDeLait extends Component {
  render() {
    const {
      dataPaieDeLait,
      onChange,
      errors: { ...paieDeLaitErrors },
      canEdit,
    } = this.props;
    const pdlErrors = paieDeLaitErrors?.paieDeLait;
    const errors = pdlErrors != null ? Object.values(pdlErrors).flatMap(({ lines, field }) =>
      lines.map((l) => ({ index: l, field }))
    ) : [];
    return (
      <>
        <CustomGrid
          className="grid-paie-de-lait"
          dataGrid={dataPaieDeLait}
          fieldId="donneesMensuellesID"
          columns={columns}
          onChange={onChange}
          errors={errors}
          canEdit
          disabled={!canEdit}
        />
      </>
    );
  }
}

PaieDeLait.propTypes = {
  dataPaieDeLait: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  canEdit: PropTypes.bool,
};

PaieDeLait.defaultProps = {
  errors: {},
  canEdit: false,
};

export default PaieDeLait;
