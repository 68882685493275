import { Button } from "@progress/kendo-react-buttons";
import { ComboBox, DropDownList } from "@progress/kendo-react-dropdowns";
import { Splitter } from "@progress/kendo-react-layout";
import dayjs from "dayjs";
import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { withRouter } from "../../utils/withRouter";
import { compose } from "redux";
import { filterBy } from "@progress/kendo-data-query";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { Label, Error as ErrorKendo } from "@progress/kendo-react-labels";
import { Loader } from "@progress/kendo-react-indicators";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import dataSource from "../../dataSource/dataSource";
import CustomGrid from "../shared/CustomGrid";
import { SET_DA_FILTERS } from "../../redux/actions/app";
import ExportPopup from "./ExportPopup";
import { RULE } from "../shared/constants";
import ReportViewerDA from "./pdf/ReportViewerDA";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const mapDispatchToProps = (dispatch) => ({
  saveFilter: (filters) => {
    dispatch({ type: SET_DA_FILTERS, payload: filters });
  },
});

const mapStateToProps = (state) => ({
  domaineId: state.auth.domaineId,
  utilisateurId: state.auth.utilisateurId,
  typeUtilisateurId: state.auth.typeUtilisateurId,
  campagneSelected: state.app.filters?.DA?.campagneSelected,
  groupeSelected: state.app.filters?.DA?.groupeSelected,
  eleveurSelected: state.app.filters?.DA?.eleveurSelected,
});

class DonneesAnnuelles extends Component {
  constructor(props) {
    super(props);
    const now = new Date();
    this.state = {
      showDialog: false,
      showAddPopup: false,
      formAdd: {
        dateFinCamp: now,
      },
      panes: [
        {
          keepMounted: true,
          size: "300px",
        },
        {},
      ],
      campagnes: [],
      campagneGroupes: [],
      groupeEleveurs: [],
      eleveurDAs: [],
      selectedId: null,
      DAExportResultats: null,
      showPdfResultat: false,
      DAStatut: { statut: null, text: "Tous" },
      columns: [],
      loading: false,
    };
  }

  async componentDidMount() {
    const {
      domaineId,
      utilisateurId,
      typeUtilisateurId,
      saveFilter,
      campagneSelected,
      groupeSelected,
      eleveurSelected,
    } = this.props;
    this.setState({ loading: true });
    const campagnes = await dataSource.loadCampagnes({
      domaineId,
      utilisateurId,
      typeUtilisateurId,
    });
    const campagneGroupes = await this.initCampagneGroupes(campagnes);
    const groupeEleveurs = await this.initGroupeEleveurs(campagneGroupes);
    groupeEleveurs.sort((a, b) => {
      const nomA = (a.nom || "").toLowerCase();
      const nomB = (b.nom || "").toLowerCase();
      if (nomA < nomB) return -1;
      if (nomA > nomB) return 1;
      return 0;
    });

    const eleveurDAs = await this.initEleveurDAs(groupeEleveurs);
    saveFilter({
      campagneSelected:
        campagneSelected || (campagnes.length > 0 ? campagnes[0] : null),
      groupeSelected:
        groupeSelected ||
        (campagneGroupes.length > 0 ? campagneGroupes[0] : null),
      eleveurSelected:
        eleveurSelected ||
        (groupeEleveurs.length > 0 ? groupeEleveurs[0] : null),
    });
    this.setState({
      loading: false,
      campagnes,
      campagneGroupes,
      groupeEleveurs,
      eleveurDAs,
      columns: [
        {
          title: "Actions",
          type: "actions",
          actions: [
            {
              title: "Modifier",
              icon: "pencil",
              action: (dataItem) =>
                this.goToDonneeAnnuelle(dataItem.id, dataItem.groupeId),
            },
            {
              icon: "paste-plain-text",
              title: "Résultats",
              show: ({ cloture }) =>
                typeUtilisateurId === RULE.CONTACT_ELEVEUR ? cloture : true,
              action: (dataItem) => {
                this.setState({
                  DAExportResultats: dataItem,
                });
              },
            },
          ],
          editable: () => false,
          width: 120,
          headerClassName: "k-font-weight-bold",
        },
        {
          title: "Date de fin",
          type: "dateFinDA",
          field: "dateFin",
          width: 100,
          editable: () => false,
          headerClassName: "k-font-weight-bold",
          cellClassName: "k-font-weight-bold",
        },
        {
          title: "Statut",
          type: "text",
          field: "libellestatut",
          width: 150,
          editable: () => false,
          headerClassName: "k-font-weight-bold",
        },
      ],
    });
  }

  initCampagneGroupes = async (campagnes) => {
    const {
      domaineId,
      utilisateurId,
      typeUtilisateurId,
      campagneSelected,
    } = this.props;

    return campagneSelected || campagnes.length > 0
      ? dataSource.loadGroupesDonneesAnnuelles({
          domaineId,
          utilisateurId,
          typeUtilisateurId,
          dateFin: dayjs(
            campagneSelected ? campagneSelected.dateFin : campagnes[0].dateFin
          ).format("YYYY-MM-DD"),
        })
      : [];
  };

  initGroupeEleveurs = async (campagneGroupes) => {
    const {
      domaineId,
      utilisateurId,
      typeUtilisateurId,
      groupeSelected,
    } = this.props;

    return groupeSelected || campagneGroupes.length > 0
      ? dataSource.loadElevages({
          domaineId,
          utilisateurId,
          typeUtilisateurId,
          groupeId: groupeSelected ? groupeSelected.id : campagneGroupes[0].id,
        })
      : [];
  };

  initEleveurDAs = async (groupeEleveurs) => {
    const { eleveurSelected } = this.props;

    if (eleveurSelected || groupeEleveurs.length > 0) {
      const eleveur = eleveurSelected
        ? eleveurSelected.societeAgricoleId
        : groupeEleveurs[0].societeAgricoleId;
      return dataSource.loadEleveurDonneesAnnuelles(eleveur);
    }
    return [];
  };

  handleOnCampagneChange = async ({ value: campagneSelected }) => {
    const {
      domaineId,
      utilisateurId,
      typeUtilisateurId,
      saveFilter,
    } = this.props;
    this.setState({ loading: true });

    const campagneGroupes = await dataSource.loadGroupesDonneesAnnuelles({
      domaineId,
      utilisateurId,
      typeUtilisateurId,
      dateFin: dayjs(campagneSelected.dateFin).format("YYYY-MM-DD"),
    });

    const groupeEleveurs =
      campagneGroupes.length > 0
        ? await dataSource.loadElevages({
            domaineId,
            utilisateurId,
            typeUtilisateurId,
            groupeId: campagneGroupes[0].id,
          })
        : [];

    const eleveurDAs =
      groupeEleveurs.length > 0
        ? await dataSource.loadEleveurDonneesAnnuelles(
            groupeEleveurs[0].societeAgricoleId
          )
        : [];

    saveFilter({
      campagneSelected,
      groupeSelected: campagneGroupes.length > 0 ? campagneGroupes[0] : null,
      eleveurSelected: groupeEleveurs.length > 0 ? groupeEleveurs[0] : null,
    });

    this.setState({
      loading: false,
      campagneGroupes,
      groupeEleveurs,
      eleveurDAs,
    });
  };

  handleOnGroupeChange = async ({ value: groupeSelected }) => {
    const {
      domaineId,
      utilisateurId,
      typeUtilisateurId,
      saveFilter,
    } = this.props;
    this.setState({ loading: true });

    const groupeEleveurs = await dataSource.loadElevages({
      domaineId,
      utilisateurId,
      typeUtilisateurId,
      groupeId: groupeSelected.id,
    });

    const eleveurDAs =
      groupeEleveurs.length > 0
        ? await dataSource.loadEleveurDonneesAnnuelles(
            groupeEleveurs[0].societeAgricoleId
          )
        : [];

    saveFilter({
      groupeSelected,
      eleveurSelected: groupeEleveurs.length > 0 ? groupeEleveurs[0] : null,
    });

    this.setState({
      loading: false,
      groupeEleveurs,
      eleveurDAs,
    });
  };

  handleOnEleveurChange = async ({ value: eleveurSelected }) => {
    const { saveFilter } = this.props;
    this.setState({ loading: true });
    const eleveurDAs = await dataSource.loadEleveurDonneesAnnuelles(
      eleveurSelected.societeAgricoleId
    );

    saveFilter({ eleveurSelected });

    this.setState({
      loading: false,
      eleveurDAs,
    });
  };

  handleChangeStatut = (value) => {
    this.setState({ DAStatut: value });
  };

  handleOnDoFilterClick = async () => {
    const { eleveurSelected } = this.props;
    this.setState({ loading: true });

    const eleveurDAs = await dataSource.loadEleveurDonneesAnnuelles(
      eleveurSelected.societeAgricoleId
    );
    this.setState({
      loading: false,
      eleveurDAs,
    });
  };

  handleOnAddSubmit = async ({ values, isValid }) => {
    const { eleveurSelected } = this.props;
    const { dateFinCamp } = values;
    const jsonDateFinCamp = dayjs(dateFinCamp).format("YYYY-MM-DD");

    if (isValid === false) return;
    await dataSource.addDonneeAnnuelle({
      dateFinCamp: jsonDateFinCamp,
      societeAgricoleId: eleveurSelected?.societeAgricoleId,
    });

    const eleveurDAs = await dataSource.loadEleveurDonneesAnnuelles(
      eleveurSelected.societeAgricoleId
    );
    this.setState({
      showAddPopup: false,
      loading: false,
      eleveurDAs,
    });
  };

  toggleFilters = () => {
    const { panes } = this.state;
    const [filterPane, ...otherPanes] = panes;
    this.setState({
      panes: [
        {
          ...filterPane,
          size: filterPane.size === "0px" ? "300px" : "0px",
          min: "0px",
        },
        ...otherPanes,
      ],
    });
  };

  goToDonneeAnnuelle = (donneeAnnuelleId, groupeId) => {
    const { router, eleveurSelected } = this.props;
    const encodedEleveurName = encodeURI(eleveurSelected.nom);
    router.navigate(
      `./${encodedEleveurName}/${groupeId}/${donneeAnnuelleId}`
    );
  };

  togglePDFPreview = () => {
    const { showPdfResultat } = this.state;
    this.setState({ showPdfResultat: !showPdfResultat });
  };

  toggleDialog = () => {
    this.setState((prevState) => ({
      showDialog: !prevState.showDialog,
    }));
  };

  toggleAddPopup = () => {
    this.setState((prevState) => ({
      showAddPopup: !prevState.showAddPopup,
    }));
  };

  filterGroupeChange = (event) => {
    this.setState({
      campagneGroupes: this.filterCampagneGroupeData(event.filter),
    });
  };

  filterEleveurChange = (event) => {
    this.setState({
      groupeEleveurs: this.filterGroupeEleveurData(event.filter),
    });
  };

  filterCampagneGroupeData(filter) {
    const { campagneGroupes } = this.state;
    return filterBy(campagneGroupes, filter);
  }

  filterGroupeEleveurData(filter) {
    const { groupeEleveurs } = this.state;
    return filterBy(groupeEleveurs, filter);
  }

  renderRightPane = () => {
    const { eleveurDAs, columns, DAStatut, selectedId, loading } = this.state;
    const { groupeSelected, typeUtilisateurId, eleveurSelected } = this.props;

    return (
      <>
        <div className="k-d-flex k-align-items-center k-mb-4">
          <Button
            title="Filtres"
            icon="filter"
            themeColor="primary"
            onClick={this.toggleFilters}
            style={{
              top: 0,
              position: "sticky",
              zIndex: 1,
            }}
          />
          {typeUtilisateurId !== RULE.CONTACT_ELEVEUR &&
            eleveurSelected != null &&
            groupeSelected != null && (
              <Button
                title="Ajouter"
                type="button"
                icon="add"
                className="k-ml-4"
                style={{ top: 0, position: "sticky", zIndex: 1 }}
                onClick={this.toggleAddPopup}
              >
                Ajouter une donnée annuelle
              </Button>
            )}
        </div>
        {loading === true && (
          <div className="flex-fill k-text-center k-mt-18">
            <Loader themeColor="primary" />
          </div>
        )}

        {loading === false && eleveurDAs.length > 0 && (
          <CustomGrid
            style={{ maxHeight: "70vh" }}
            gridName="grilleDonneesAnnuelles"
            selectedField="selected"
            className="grid-saisie-da"
            columns={columns}
            dataGrid={eleveurDAs
              .filter((item) =>
                DAStatut.statut != null
                  ? DAStatut.statut === item.cloture
                  : item
              )
              .map((item) => ({
                ...item,
                groupeId: groupeSelected.id,
                selected: item.id === selectedId,
              }))}
            onRowDoubleClick={({ dataItem }) => {
              this.goToDonneeAnnuelle(dataItem.id, groupeSelected.id);
            }}
            onRowClick={({ dataItem: donneeAnnuelle }) => {
              this.setState({ selectedId: donneeAnnuelle.id });
            }}
          />
        )}
      </>
    );
  };

  renderLeftPane = () => {
    const { campagnes, campagneGroupes, groupeEleveurs, DAStatut } = this.state;

    const {
      campagneSelected,
      groupeSelected,
      eleveurSelected,
      typeUtilisateurId,
    } = this.props;

    return (
      <>
        <h2 className="k-ml-4 k-my-2 k-pt-0">Données annuelles</h2>
        <div className="flex-fill k-d-flex-col k-align-items-stretch k-m-3">
          <div className="k-d-flex k-align-items-baseline k-mb-3">
            <DropDownList
              id="da-campagne"
              data={campagnes}
              textField="libelle"
              value={campagneSelected}
              onChange={this.handleOnCampagneChange}
              label="Date de fin de la campagne"
              style={{ width: "100%" }}
              disabled={typeUtilisateurId === RULE.CONTACT_ELEVEUR}
            />
          </div>

          <div className="k-d-flex k-d-flex-col k-align-items-stretch k-align-items-baseline k-mb-3">
            <div className="k-display-flex k-align-items-baseline">
              <ComboBox
                data={campagneGroupes}
                textField="libelle"
                filterable
                onChange={this.handleOnGroupeChange}
                onFilterChange={this.filterGroupeChange}
                style={{ width: "100%" }}
                className="k-mb-3"
                name="groupe"
                label="Groupe"
                value={groupeSelected}
                disabled={typeUtilisateurId === RULE.CONTACT_ELEVEUR}
              />
              {(typeUtilisateurId !== RULE.CONTACT_ELEVEUR ||
                DAStatut.statut) && (
                <Button
                  themeColor="primary"
                  className="k-my-4 k-ml-3"
                  onClick={this.togglePDFPreview}
                >
                  Résultats
                </Button>
              )}
            </div>
            <div className="k-display-flex k-align-items-baseline">
              <ComboBox
                id="da-societe-agricole"
                data={groupeEleveurs}
                textField="nom"
                filterable
                onChange={this.handleOnEleveurChange}
                onFilterChange={this.filterEleveurChange}
                className="k-mb-3"
                name="groupe"
                label="Elevage"
                style={{ width: "100%" }}
                value={eleveurSelected}
                disabled={typeUtilisateurId === RULE.CONTACT_ELEVEUR}
              />

              {eleveurSelected !== null &&
                typeUtilisateurId === RULE.SUPERVISEUR_DOMAINE && (
                  <Button
                    themeColor="primary"
                    className="k-my-4 k-ml-3"
                    onClick={this.toggleDialog}
                  >
                    Export
                  </Button>
                )}
            </div>
          </div>

          <DropDownList
            label="Statut"
            textField="text"
            value={DAStatut}
            data={[
              { statut: null, text: "Tous" },
              { statut: true, text: "Clôturé" },
              { statut: false, text: "Non clôturé" },
            ]}
            onChange={({ value }) => this.handleChangeStatut(value)}
          />
          <Button
            className="k-mt-4"
            type="button"
            themeColor="primary"
            onClick={this.handleOnDoFilterClick}
          >
            Filtrer
          </Button>
        </div>
      </>
    );
  };

  renderAddPopup() {
    const {
      showAddPopup,
      formAdd,
      eleveurDAs,
      DAStatut,
      selectedId,
    } = this.state;
    const { eleveurSelected, groupeSelected, typeUtilisateurId } = this.props;

    const allDAs = eleveurDAs
      .filter((item) =>
        DAStatut.statut != null ? DAStatut.statut === item.cloture : item
      )
      .map((item) => ({
        ...item,
        groupeId: groupeSelected.id,
        selected: item.id === selectedId,
      }));

    return (
      showAddPopup &&
      typeUtilisateurId !== RULE.CONTACT_ELEVEUR && (
        <Dialog
          title="Nouvelle donnée annuelle"
          onClose={this.toggleAddPopup}
          width={500}
        >
          <Form
            initialValues={{
              ...formAdd,
              societeAgricoleId: eleveurSelected?.societeAgricoleId,
            }}
            validator={(values, valueGetter) => {
              const errors = {};
              const dateFinCamp = valueGetter("dateFinCamp");
              const jsonDateFinCamp = dayjs(dateFinCamp).format("MM-YYYY");

              const dateFinExist = allDAs
                .map((item) =>
                  dayjs(item.dateFin)
                    .format("MM-YYYY")
                    .includes(jsonDateFinCamp)
                )
                .some((element) => element === true);

              if (dateFinExist) {
                errors.dateFinCamp = `Une Donnée Annuelle existe déjà avec une Fin de Campagne au ${jsonDateFinCamp} pour cet élevage. Veuillez modifier la date fin campagne`;
              }
              return errors;
            }}
            onSubmitClick={this.handleOnAddSubmit}
            render={(formRenderProps) => (
              <div>
                <FormElement>
                  {eleveurSelected?.nom}
                  <div className="k-d-flex-row k-justify-content-between k-align-items-baseline k-mt-4">
                    <Label>Fin de campagne</Label>
                    <Field
                      className="k-ml-4"
                      component={DatePicker}
                      name="dateFinCamp"
                      width={350}
                      required
                    />
                  </div>
                  {formRenderProps.errors.dateFinCamp && (
                    <div>
                      <ErrorKendo>
                        {formRenderProps.errors.dateFinCamp}
                      </ErrorKendo>
                    </div>
                  )}
                  <div className="k-d-flex k-justify-content-end">
                    <Button
                      className="k-mt-4"
                      themeColor="primary"
                      onClick={formRenderProps.onSubmit}
                    >
                      Ajouter
                    </Button>
                  </div>
                </FormElement>
              </div>
            )}
          />
        </Dialog>
      )
    );
  }

  render() {
    const {
      panes,
      DAExportResultats,
      showPdfResultat,
      showDialog,
    } = this.state;
    const {
      domaineId,
      campagneSelected,
      groupeSelected,
      eleveurSelected,
    } = this.props;
    return (
      <div className="flex-fill k-d-flex-col k-align-items-stretch k-m-4">
        <Splitter
          panes={panes}
          style={{ height: "100%" }}
          onChange={({ newState }) => this.setState({ panes: newState })}
        >
          {this.renderLeftPane()}

          <div className="k-m-4">
            {this.renderRightPane()}
            <Suspense fallback={<div>chargement lecteur pdf</div>}>
              <QueryClientProvider client={queryClient}>
                {!!DAExportResultats && (
                <ReportViewerDA // Rapport Eleveur
                      title={`Rapport Données Annuelles Résultats`}
                      params={{
                        domaine: domaineId,
                        anneeDateFinCamp: Number(
                          DAExportResultats?.dateFin.substring(0, 4)
                        ),
                        moisDateFinCamp: Number(
                          Number(DAExportResultats?.dateFin.substring(5, 7))
                        ),
                        groupeId: groupeSelected?.id,
                        societeAgricoleId: eleveurSelected?.societeAgricoleId,
                        donAnId: DAExportResultats?.id
                      }}
                      onClose={() => this.setState({ DAExportResultats: null })}
                  ></ReportViewerDA>
                    )}
              </QueryClientProvider>
            </Suspense>
            <Suspense fallback={<div>chargement lecteur pdf</div>}>

            <QueryClientProvider client={queryClient}>
              {!!showPdfResultat && (
              <ReportViewerDA // Rapport groupe
                      title={`Rapport Données Annuelles Résultats`}
                      params={{
                        domaine: domaineId,
                        anneeDateFinCamp: Number(
                          campagneSelected?.dateFin.substring(0, 4)
                        ),
                        moisDateFinCamp: Number(
                          Number(campagneSelected?.dateFin.substring(5, 7))
                        ),
                        groupeId: groupeSelected?.id,
                        societeAgricoleId: -1,
                        donAnId: -1
                      }}
                      onClose={() => this.setState({ showPdfResultat: null })}
                ></ReportViewerDA>
                    )}

            </QueryClientProvider>

            </Suspense>
            {showDialog && (
              <ExportPopup
                onClose={this.toggleDialog}
                eleveur={eleveurSelected}
                groupe={groupeSelected}
                dateFin={campagneSelected.dateFin}
              />
            )}
            {this.renderAddPopup()}
          </div>
        </Splitter>
      </div>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(DonneesAnnuelles);
