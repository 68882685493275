import React, { Component } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import dataSource from "../../../dataSource/dataSource";
import toastService from "../../../utils/toastService";

class PopupAjoutAliment extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      categorieAlimentSelected: null,
      typeAlimentSelected: null,
    };
  }

  handleChangeCategorie = (event) => {
    this.formRef.current.onChange("categorieAliment", {
      value: event.target.value,
    });
    this.setState({
      categorieAlimentSelected: event.target.value,
    });
  };

  handleChangeTypeAliment = (event) => {
    const { alimentsHierarchy } = this.props;
    this.formRef.current.onChange("typeAliment", { value: event.target.value });
    if (event.target.value.libelle === "Minéraux") {
      this.formRef.current.onChange("categorieAliment", {
        value: alimentsHierarchy.find(
          (a) => a.rang_modaliste === event.target.value?.rang_modaliste
        )?.categories[0],
      });
    }
    this.setState({
      typeAlimentSelected: event.target.value,
      categorieAlimentSelected:
        event.target.value.libelle === "Minéraux"
          ? alimentsHierarchy.find(
              (a) => a.rang_modaliste === event.target.value?.rang_modaliste
            )?.categories[0]
          : null,
    });
  };

  render() {
    const {
      donneeMensuelle,
      resolveNewAliment,
      alimentsHierarchy,
      closeAlimentDialog,
    } = this.props;

    const { typeAlimentSelected, categorieAlimentSelected } = this.state;
    return (
      <Dialog title="Ajout d'un aliment " onClose={closeAlimentDialog}>
        <Form
          ref={this.formRef}
          initialValues={{
            typeAliment: null,
            categorieAliment: null,
            nomAliment: "",
            msPourcent: 0,
            prixEuroHT: 0,
          }}
          onSubmitClick={async ({
            isValid,
            values: { typeAliment, categorieAliment, ...otherValues },
          }) => {
            if (isValid) {
              const newAliment = await dataSource.createAliment(
                donneeMensuelle.groupeId,
                {
                  typeAliment: typeAliment.rang_modaliste,
                  nomTypeAliment: typeAliment.libelle,
                  categorieAliment: categorieAliment.rang_modaliste,
                  nomCategorieAliment: categorieAliment.abrev_modaliste,
                  ...otherValues,
                }
              );
              toastService.addToast({
                id: "add-aliment-success",
                type: "success",
                message: `L'aliment ${newAliment.nomAliment} a bien été enregistré`,
              });

              resolveNewAliment(newAliment);
            }
          }}
          validator={(values) => {
            return Object.entries(values)
              .map(([key, value]) => ({
                [key]: !value ? "Ne doit pas être vide" : "",
              }))
              .reduce((acc, curr) => ({ ...acc, ...curr }), {});
          }}
          render={(formRenderProps) => (
            <FormElement>
              <div className="k-d-flex-col">
                <DropDownList
                  data={alimentsHierarchy.filter(
                    (a) => ![1, 2].includes(a.rang_modaliste)
                  )}
                  value={typeAlimentSelected}
                  onChange={this.handleChangeTypeAliment}
                  label="Type d'aliment"
                  textField="libelle"
                  className="k-mb-2"
                  name="typeAliment"
                />
                <DropDownList
                  data={
                    alimentsHierarchy.find(
                      (a) =>
                        a.rang_modaliste === typeAlimentSelected?.rang_modaliste
                    )?.categories || []
                  }
                  textField="abrev_modaliste"
                  label="Categorie d'aliment"
                  value={categorieAlimentSelected}
                  onChange={this.handleChangeCategorie}
                  name="categorieAliment"
                  className="k-mb-2"
                />
                <Field
                  component={Input}
                  label="Nom de l'aliment"
                  name="nomAliment"
                  className="k-mb-2"
                />
                <Field
                  component={NumericTextBox}
                  spinners={false}
                  label="% MS"
                  format="###"
                  min={0}
                  max={100}
                  name="msPourcent"
                  className="k-mb-2"
                />
                <Field
                  component={NumericTextBox}
                  spinners={false}
                  label="Prix euros HT"
                  min={0}
                  name="prixEuroHT"
                  className="k-mb-4"
                />
                <Button
                  type="button"
                  onClick={formRenderProps.onSubmit}
                  disabled={!formRenderProps.allowSubmit}
                  themeColor="primary"
                >
                  Valider
                </Button>
              </div>
            </FormElement>
          )}
        />
      </Dialog>
    );
  }
}

export default PopupAjoutAliment;
