import { Component, createRef } from "react";
import { Splitter } from "@progress/kendo-react-layout";
import { NumericTextBox, Checkbox } from "@progress/kendo-react-inputs";
import dayjs from "dayjs";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { withRouter } from "../../utils/withRouter";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { compose } from "redux";
import { connect } from "react-redux";
import CustomGrid from "../shared/CustomGrid";
import dataSource from "../../dataSource/dataSource";
import {
  SET_ADMIN_GROUPE_DUPLICATE,
  SET_ADMIN_GROUPE_FILTERS,
} from "../../redux/actions/app";

const mapDispatchToProps = (dispatch) => ({
  saveFilter: (filters) => {
    dispatch({ type: SET_ADMIN_GROUPE_FILTERS, payload: filters });
  },
  saveDuplicateAdminGroupe: (duplicateGroupes) => {
    dispatch({ type: SET_ADMIN_GROUPE_DUPLICATE, payload: duplicateGroupes });
  },
});

const mapStateToProps = (state) => ({
  campagne: parseInt(state.app.filters?.adminGroupe?.campagne || dayjs().format("YYYY"), 10),
  debut:
    state.app.filters?.adminGroupe?.debut !== undefined
      ? state.app.filters?.adminGroupe?.debut
      : true,
  fin:
    state.app.filters?.adminGroupe?.fin !== undefined
      ? state.app.filters?.adminGroupe?.fin
      : true,
});

class Groupes extends Component {
  columns = [
    {
      title: "Actions",
      type: "actions",
      actions: [
        {
          title: "Modifier",
          icon: "pencil",
          action: (dataItem) => this.goToAdminGroupe(dataItem.groupeId),
        },
        {
          title: "Dupliquer",
          icon: "copy",
          action: async (dataItem) => {
            const { router, saveDuplicateAdminGroupe } = this.props;
            const duplicate = await dataSource.loadGroupeElevage(
              dataItem.groupeId
            );

            duplicate.nom = `copie de ${duplicate.nom}`;
            duplicate.code = `copie de ${duplicate.code}`;
            duplicate.copyOfGroupeId = dataItem.groupeId;
            duplicate.groupeId = -1;

            duplicate.societesAgricoles = duplicate.societesAgricoles.map(
              (sa) => ({
                ...sa,
                groupeSteAgricoleId: -1,
                groupeId: -1,
              })
            );
            duplicate.techniciens = duplicate.techniciens.map((t) => ({
              ...t,
              groupeCoopUtilisateurId: -1,
            }));

            saveDuplicateAdminGroupe(duplicate);

            /* const r = await dataSource.postGroupeElevage({
              ...duplicate,
              groupeId: -1,
              nom: `copie de ${duplicate.nom}`,
              code: `copie de ${duplicate.code}`,
              copyOfGroupeId: dataItem.groupeId,
              societesAgricoles,
              techniciens,
            }); */
            router.navigate(`./duplicate`);
            /*     history.push(`./groupes/-1/edit`); */
          },
        },
        {
          title: "Supprimer",
          icon: "trash",
          action: async (dataItem) => {
            this.toggleDialog();
            this.currentItem.current = dataItem;
          },
        },
      ],
      editable: false,
      width: 95,
    },
    {
      title: "Code",
      type: "text",
      field: "code",
      width: 90,
    },
    {
      title: "Nom",
      type: "text",
      field: "nom",
      width: 150,
    },
    {
      title: "Date début",
      type: "text",
      field: "dateDebut",
      width: 90,
    },
    {
      title: "Date fin",
      type: "text",
      field: "dateFin",
      width: 90,
    },
  ];

  constructor(props) {
    super(props);
    this.currentItem = createRef();
    this.state = {
      panes: [
        {
          keepMounted: true,
          size: "300px",
        },
        {},
      ],
      groupes: [],
      dialogIsOpen: false,
      deletePending: false,
    };
  }

  async componentDidMount() {
    const { campagne, debut, fin } = this.props;
    this.setState({ loading: true });
    const groupes = await dataSource.loadGroupesElevages({
      campagne,
      debut,
      fin,
    });
    this.setState({ loading: false, groupes });
  }

  goToAdminGroupe = (id) => {
    const { router } = this.props;
    router.navigate(`./${id}/edit`);
  };

  handleOnFilterClick = async () => {
    const { campagne, debut, fin } = this.props;
    this.setState({ loading: true });
    const groupes = await dataSource.loadGroupesElevages({
      campagne,
      debut,
      fin,
    });
    this.setState({ loading: false, groupes });
  };

  handleOnAddClick = () => {
    const { router } = this.props;
    router.navigate(`./nouveau`);
  };

  toggleDialog = () => {
    this.setState((s) => ({ dialogIsOpen: !s.dialogIsOpen }));
  };

  onItemDelete = async () => {
    this.setState({ deletePending: true });
    await dataSource.deleteGroupeElevage(this.currentItem.current.groupeId);
    window.location.reload();
  };

  toggleFilters = () => {
    const { panes } = this.state;
    const [filterPane, ...otherPanes] = panes;
    this.setState({
      panes: [
        {
          ...filterPane,
          size: filterPane.size === "0px" ? "300px" : "0px",
          min: "0px",
        },
        ...otherPanes,
      ],
    });
  };

  render() {
    const { panes, groupes, loading, dialogIsOpen, deletePending } = this.state;
    const { saveFilter, campagne, debut, fin } = this.props;
    return (
      <div className="flex-fill k-d-flex-col k-align-items-stretch k-m-4">
        <Splitter
          panes={panes}
          style={{ height: "100%" }}
          onChange={({ newState }) => this.setState({ panes: newState })}
        >
          <div>
            <h2 className="k-ml-4 k-my-2 k-pt-0">Groupes</h2>
            <div className="k-d-flex k-align-items-baseline k-m-3">
              <NumericTextBox
                style={{ maxWidth: "7rem" }}
                value={campagne}
                onChange={({ value }) => {
                  saveFilter({ campagne: value });
                }}
                label={`Campagne`}
              />
              <Checkbox
                className="k-flex-shrink-0 k-ml-2"
                value={debut}
                onChange={({ value }) => {
                  saveFilter({ debut: value });
                }}
                label="Début"
              />
              <Checkbox
                className="k-flex-shrink-0 k-ml-3 "
                value={fin}
                onChange={({ value }) => {
                  saveFilter({ fin: value });
                }}
                label="Fin"
              />
            </div>
            <div className="flex-fill k-d-flex-col k-align-items-stretch k-m-3">
              <Button
                className="k-mt-2"
                type="button"
                themeColor="primary"
                onClick={this.handleOnFilterClick}
              >
                Filtrer
              </Button>
            </div>
          </div>
          <div className="k-m-4">
            {loading === true && (
              <div className="flex-fill k-text-center k-mt-18">
                <Loader themeColor="primary" />
              </div>
            )}
            {loading === false && groupes.length > 0 && (
              <>
                <Button
                  title="Filtres"
                  icon="filter"
                  themeColor="primary"
                  onClick={this.toggleFilters}
                  style={{
                    top: 0,
                    position: "sticky",
                    zIndex: 1,
                  }}
                  className="k-mr-4"
                />
                <Button
                  title="Ajouter un groupe"
                  type="button"
                  icon="add"
                  onClick={this.handleOnAddClick}
                >
                  Ajouter un groupe
                </Button>
                <CustomGrid
                  style={{ maxHeight: "70vh" }}
                  className="k-mt-4"
                  columns={this.columns}
                  dataGrid={groupes}
                />
              </>
            )}
          </div>
        </Splitter>
        {dialogIsOpen && (
          <Dialog
            title={"Confirmation suppression"}
            onClose={this.toggleDialog}
          >
            <p>Confirmez-vous la suppression de la ligne sélectionnée ?</p>
            <DialogActionsBar>
              <Button onClick={this.toggleDialog}>Non</Button>
              <Button onClick={this.onItemDelete}>Oui</Button>
            </DialogActionsBar>
            {deletePending ? (
              <div className="k-d-flex k-justify-content-center">
                <Loader themeColor="primary" />
              </div>
            ) : null}
          </Dialog>
        )}
      </div>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Groupes);
