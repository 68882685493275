import { Dialog } from "@progress/kendo-react-dialogs";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import PropTypes from "prop-types";
import { Component } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import dayjs from "dayjs";
import { Loader } from "@progress/kendo-react-indicators";
import { Upload } from "@progress/kendo-react-upload";
import dataSource from "../../dataSource/dataSource";

class ExportPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anneeFin: dayjs(props.dateFin).format("YYYY"),
      moisFin: dayjs(props.dateFin).format("MM"),
      calcul: false,
      loading: false,
    };
  }

  handleOnCalculClick = async () => {
    const { eleveur, groupe } = this.props;
    const { anneeFin, moisFin } = this.state;
    this.setState({ loading: true });
    await dataSource.postExportCalcul({
      societeAgricoleId: eleveur.societeAgricoleId,
      groupeId: groupe.id,
      annee: Number(anneeFin),
      mois: Number(moisFin),
    });
    this.setState({ calcul: true, loading: false });
  };

  handleOnExportAllClick = async () => {
    const { groupe } = this.props;
    const { anneeFin, moisFin } = this.state;
    await dataSource.loadExportAll({
      groupeId: groupe.id,
      annee: Number(anneeFin),
      mois: Number(moisFin),
    });
  };

  handleOnExportEleveurClick = async () => {
    const { groupe, eleveur } = this.props;
    const { anneeFin, moisFin } = this.state;
    await dataSource.loadExportEleveur({
      groupeId: groupe.id,
      societeAgricoleId: eleveur.societeAgricoleId,
      annee: Number(anneeFin),
      mois: Number(moisFin),
    });
  };

  handleOnExportGroupeClick = async () => {
    const { groupe, eleveur } = this.props;
    const { anneeFin, moisFin } = this.state;
    await dataSource.loadExportGroupe({
      groupeId: groupe.id,
      societeAgricoleId: eleveur.societeAgricoleId,
      annee: Number(anneeFin),
      mois: Number(moisFin),
    });
  };

  render() {
    const { anneeFin, moisFin, calcul, loading } = this.state;
    const { eleveur, groupe, onClose } = this.props;

    return (
      <Dialog title={"Export de données"} onClose={onClose}>
        <Form
          render={() => (
            <FormElement>
              <Label>Nom de l&apos;éleveur</Label>
              <Input
                type={"text"}
                disabled
                value={eleveur.nom}
                className="k-mb-3"
              />
              <Label>Année fin</Label>
              <NumericTextBox
                className="k-mb-3"
                value={anneeFin}
                onChange={({ value }) =>
                  this.setState({ anneeFin: value, calcul: false })
                }
              />
              <Label>Mois fin</Label>
              <NumericTextBox
                className="k-mb-3"
                value={moisFin}
                max={12}
                onChange={({ value }) =>
                  this.setState({ moisFin: value, calcul: false })
                }
              />
              <Label>Groupe</Label>
              <Input type={"text"} disabled value={groupe.libelle} />
              <div className="k-mt-8 k-d-flex k-align-items-center">
                <Button
                  themeColor="primary"
                  onClick={this.handleOnCalculClick}
                  disabled={calcul}
                >
                  Exécuter calcul
                </Button>
                {loading && <Loader themeColor="primary" />}
              </div>
              <div>
                <Button
                  className="k-mt-8"
                  themeColor="primary"
                  onClick={this.handleOnExportAllClick}
                  disabled={!calcul}
                >
                  Télécharger tous les résultats
                </Button>
                <Button
                  className="k-ml-4 k-mt-8"
                  themeColor="primary"
                  onClick={this.handleOnExportEleveurClick}
                  disabled={!calcul}
                >
                  Télécharger résultats éleveur
                </Button>
                <Button
                  className="k-ml-4 k-mt-8"
                  themeColor="primary"
                  onClick={this.handleOnExportGroupeClick}
                  disabled={!calcul}
                >
                  Télécharger résultats groupe
                </Button>
                <Label className="k-mt-8">Choix du fichier à uploader</Label>
                <Upload
                  batch={false}
                  multiple={false}
                  defaultFiles={[]}
                  withCredentials={true}
                  saveHeaders={({
                    "Authorization": localStorage.getItem('idToken')
                  })}
                  saveUrl={`${process.env.PUBLIC_URL}/api/donneesAnnuelles/import`}
                />
              </div>
            </FormElement>
          )}
        />
      </Dialog>
    );
  }
}

ExportPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  eleveur: PropTypes.object.isRequired,
  groupe: PropTypes.object.isRequired,
  dateFin: PropTypes.string.isRequired,
};

export default ExportPopup;
