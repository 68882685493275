import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { unique } from "../../shared/ReportViewer/utils";

/**
 * @typedef {Object} FluxIndividuelProps
 * @property {import('./ReportViewer').ReportParameter} params
 * @property {import('./ReportViewer').Report} report
 * @param {FluxIndividuelProps} props {@link FluxIndividuelProps}
 */
export const FluxIndividuel = ({ params, report }) => {
  return (
    <div className="table-wrapper">
      <table>
        <DateHeader params={params} />
        <tbody className="separate border-b">
          {unique(report.ListLaitJourChevreTraite, "LibLigne").map((r, i) => (
            <Row
              key={i}
              params={params}
              label={r}
              reportKey="ListLaitJourChevreTraite"
              highlight={({ TypeLigne }) => TypeLigne}
              highlightClassName="bg-gray-100 border-y"
            />
          ))}
        </tbody>
        <tbody className="separate border-b">
          {unique(report.ListIngestionTotale, "LibLigne").map((r, i) => (
            <Row
              key={i}
              params={params}
              label={r}
              reportKey="ListIngestionTotale"
              highlight={({ TypeLigne }) => TypeLigne}
              highlightClassName="bg-gray-100 border-y"
            />
          ))}
        </tbody>
        <tbody className="separate border-b">
          {unique(report.ListCoutAlimentaireTotal, "LibLigne").map((r, i) => (
            <Row
              key={i}
              params={params}
              label={r}
              reportKey="ListCoutAlimentaireTotal"
              highlight={({ TypeLigne }) => TypeLigne}
              highlightClassName="bg-gray-100 border-y"
            />
          ))}
        </tbody>
        <tbody className="separate border-b">
          {unique(report.ListPrixDuLait, "LibLigne").map((r, i) => (
            <Row
              key={i}
              params={params}
              label={r}
              reportKey="ListPrixDuLait"
              highlight={({ TypeLigne }) => TypeLigne}
              highlightClassName="bg-gray-100 border-y"
              className="border-t"
            />
          ))}
        </tbody>
        <tbody className="separate border-b">
          {unique(report.ListMargeAlimentaire, "LibLigne").map((r, i) => (
            <Row
              key={i}
              params={params}
              label={r}
              reportKey="ListMargeAlimentaire"
              highlight={({ TypeLigne }) => TypeLigne}
              highlightClassName="bg-gray-100 border-y"
            />
          ))}
        </tbody>
      </table>

      <table className="ml-1">
        <thead>
          <tr>
            <td colSpan={2} align="center" className="border-x border-t">
              Cumul depuis
            </td>
          </tr>
          <tr className="border-x">
            <td align="center" className="pr-1 border">
              12 mois éleveur
            </td>
            <td align="center" className="pr-1 border-y border-r">
              12 mois groupe
            </td>
          </tr>
        </thead>
        <tbody className="separate fix border-b">
          {unique(report.ListLaitJourChevreTraiteCumul, "LibLigne").map(
            (r, i) => (
              <Row
                key={i}
                hideLabel
                params={params}
                label={r}
                reportKey="ListLaitJourChevreTraiteCumul"
                highlight={({ TypeLigne }) => TypeLigne}
                highlightClassName="bg-gray-100 border-y"
              />
            )
          )}
        </tbody>
        <tbody className="separate border-b">
          {unique(report.ListIngestionTotaleCumul, "LibLigne").map((r, i) => (
            <Row
              key={i}
              hideLabel
              params={params}
              label={r}
              reportKey="ListIngestionTotaleCumul"
              highlight={({ TypeLigne }) => TypeLigne}
              highlightClassName="bg-gray-100 border-y"
            />
          ))}
        </tbody>
        <tbody className="separate border-b">
          {unique(report.ListCoutAlimentaireTotalCumul, "LibLigne").map(
            (r, i) => (
              <Row
                key={i}
                hideLabel
                params={params}
                label={r}
                reportKey="ListCoutAlimentaireTotalCumul"
                highlight={({ TypeLigne }) => TypeLigne}
                highlightClassName="bg-gray-100 border-y"
              />
            )
          )}
        </tbody>
        <tbody className="separate border-b">
          {unique(report.ListPrixDuLaitCumul, "LibLigne").map((r, i) => (
            <Row
              key={i}
              hideLabel
              params={params}
              label={r}
              reportKey="ListPrixDuLaitCumul"
              highlight={({ TypeLigne }) => TypeLigne}
              highlightClassName="bg-gray-100 border-y"
              className="border-t"
            />
          ))}
        </tbody>
        <tbody className="separate border-b">
          {unique(report.ListMargeAlimentaireCumul, "LibLigne").map((r, i) => (
            <Row
              key={i}
              hideLabel
              params={params}
              label={r}
              reportKey="ListMargeAlimentaireCumul"
              highlight={({ TypeLigne }) => TypeLigne}
              highlightClassName="bg-gray-100 border-y"
              className="border-t"
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};
/**
 * Entête avec les dates du rapport
 * @typedef {Object} DateHeaderProps
 * @property {import('./ReportViewer').ReportParameter} params {@link DateHeaderProps}
 * @param {DateHeaderProps} props
 */
const DateHeader = ({ params }) => {
  const queryClient = useQueryClient();
  const dates = /** @type {Array<string>} */ (
    queryClient.getQueryData(["donneesMensuelles", "Dates", params])
  );
  return (
    <thead>
      <tr>
        <td colSpan={14}></td>
      </tr>
      <tr>
        <td></td>
        {dates.map((m, i) => (
          <td
            align="center"
            key={i}
            className={`${i === 0 ? "border-x" : "border-r"} border-y`}
          >
            {m}
          </td>
        ))}
      </tr>
    </thead>
  );
};

/**
 * @typedef {Object} RowProps
 * @property {import('./ReportViewer').ReportParameter} params
 * @property {string} label
 * @property {keyof import('./ReportViewer').Report} reportKey
 * @property {(row: import('./ReportViewer').FluxIndividuelPdf) => boolean} highlight
 * @property {string} highlightClassName
 * @property {boolean} [hideLabel=false]
 * @param {RowProps & React.HTMLAttributes<HTMLTableRowElement>} props {@link RowProps}
 */
const Row = ({
  label,
  reportKey,
  params,
  highlight,
  highlightClassName,
  hideLabel,
}) => {
  const queryClient = useQueryClient();
  const data =
    /** @type {Array<import('./ReportViewer').FluxIndividuelPdf> | undefined} */ (
      queryClient.getQueryData(["donneesMensuelles", "Row", reportKey, params])
    );

  const row = (data || []).filter((r) => r.LibLigne === label);

  return row !== undefined ? (
    <tr className={`${highlight(row[0]) ? highlightClassName : ""}`}>
      {!hideLabel && (
        <td className="px-1 border-l w-96">
          <span>{label}</span>
          {row[0].Unite !== undefined && (
            <span className="float-right">{row[0].Unite}</span>
          )}
        </td>
      )}
      {row.map((r, i) => (
        <td
          align="right"
          key={i}
          className={`pr-1 ${i === 0 ? "border-x" : "border-r"}`}
        >
          {r.Valeur}
        </td>
      ))}
    </tr>
  ) : null;
};
