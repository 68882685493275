import dayjs from "dayjs";
import { Component, createRef } from "react";
import { Splitter } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { withRouter } from "../../utils/withRouter";
import { Loader } from "@progress/kendo-react-indicators";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import { DateRangePicker } from "@progress/kendo-react-dateinputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";

import { Label } from "@progress/kendo-react-labels";
import CustomGrid from "../shared/CustomGrid";
import dataSource from "../../dataSource/dataSource";

const FIELDS_CONFIGURATION = {
  typeCoopLibelle: { type: "string" },
  type: { type: "string" },
  code: { type: "string" },
  raisonSociale: { type: "string" },
  coopLibelleCourt: { type: "string" },
  coopLibelleLong: { type: "string" },
  dateDebut: { type: "date", format: "DD/MM/YYYY" },
  dateFin: { type: "date", format: "DD/MM/YYYY" },
};

class Cooperatives extends Component {
  columns = [
    {
      title: "Actions",
      type: "actions",
      actions: [
        {
          title: "Modifier",
          icon: "pencil",
          action: (dataItem) => this.goToAdminCooperative(dataItem.coopId),
        },
        {
          title: "Supprimer",
          icon: "trash",
          action: async (dataItem) => {
            if (
              dataItem.typeCoopLibelle === "Domaine" ||
              dataItem.typeCoopLibelle === "Tous"
            ) {
              this.toggleAlert();
              return;
            }
            this.toggleDialog();
            this.currentItem.current = dataItem;
          },
        },
      ],
      editable: false,
      width: 60,
    },
    {
      title: "Type",
      type: "text",
      field: "typeCoopLibelle",
      width: 100,
    },
    {
      title: "Code",
      type: "text",
      field: "code",
      width: 40,
    },
    {
      title: "Raison sociale",
      type: "text",
      field: "raisonSociale",
      width: 130,
    },
    {
      title: "Libellé court",
      type: "text",
      field: "coopLibelleCourt",
      width: 130,
    },
    {
      title: "Libellé long",
      type: "text",
      field: "coopLibelleLong",
      width: 130,
    },
    {
      title: "Date Début",
      type: "text",
      field: "dateDebut",
      width: 90,
    },
    {
      title: "Date Fin",
      type: "text",
      field: "dateFin",
      width: 90,
    },
  ];

  constructor(props) {
    super(props);
    this.currentItem = createRef();
    this.state = {
      panes: [
        {
          keepMounted: true,
          size: "370px",
        },
        {},
      ],
      loading: false,
      cooperatives: undefined,
      filters: {
        active: true,
        type: "",
        code: "",
        raisonSociale: "",
        coopLibelleCourt: "",
        coopLibelleLong: "",
        dateDebut: { start: "", end: "" },
        dateFin: { start: "", end: "" },
      },
      sort: [],
      alertIsOpen: false,
      dialogIsOpen: false,
      deletePending: false,
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const cooperatives = await dataSource.loadAdminCooperatives();
    const typesCoopAll = await dataSource.loadTypesCoop();
    const typesCoop = typesCoopAll.map((t) => {
      return {
        id: t.typeCoopId,
        libelle: t.typeCoopLib,
      };
    });
    typesCoop.unshift({
      id: null,
      libelle: "",
    });
    this.setState({ typesCoop, cooperatives, loading: false });
  }

  handleOnFilterChange = ({ value, target: { name } }) => {
    this.setState((s) => ({
      filters: {
        ...s.filters,
        [name]: value,
      },
    }));
  };

  handleOnDateFilterChange = (key) => ({ value: { start, end } }) => {
    this.setState((s) => ({
      filters: {
        ...s.filters,
        [key]: {
          start: dayjs(start).isValid()
            ? dayjs(start).format("YYYY-MM-DD")
            : "",
          end: dayjs(end).isValid() ? dayjs(end).format("YYYY-MM-DD") : "",
        },
      },
    }));
  };

  handleOnFilterClick = async () => {
    const { filters } = this.state;
    this.setState({ loading: true });
    const cooperatives = await dataSource.loadAdminCooperatives({
      ...filters,
      active: Number(filters.active),
    });
    this.setState({ cooperatives, loading: false });
  };

  handleOnKeyDown = ({ key }) => {
    if (key === "Enter") {
      this.handleOnFilterClick();
    }
  };

  goToAdminCooperative = (id) => {
    const { router } = this.props;
    router.navigate(`/app/administration/cooperatives/${id}`);
  };

  handleOnAddClick = () => {
    const { router } = this.props;
    router.navigate(`/app/administration/cooperatives/nouveau`);
  };

  toggleAlert = () => {
    this.setState((s) => ({ alertIsOpen: !s.alertIsOpen }));
  };

  toggleDialog = () => {
    this.setState((s) => ({ dialogIsOpen: !s.dialogIsOpen }));
  };

  onItemDelete = async () => {
    this.setState({ deletePending: true });
    await dataSource.deleteAdminCooperative(this.currentItem.current.coopId);
    // TODO: Gérer le retour en erreur
    this.handleOnFilterClick();
    this.setState({ deletePending: false });
    this.setState({ dialogIsOpen: false });
  };

  toggleFilters = () => {
    const { panes } = this.state;
    const [filterPane, ...otherPanes] = panes;
    this.setState({
      panes: [
        {
          ...filterPane,
          size: filterPane.size === "0px" ? "350px" : "0px",
          min: "0px",
        },
        ...otherPanes,
      ],
    });
  };

  handleOnSortChange = ({ sort: s }) => {
    if (s.length > 0)
      this.setState({
        sort: [{ ...s[0], ...FIELDS_CONFIGURATION[s[0].field] }],
      });
    else {
      this.setState({ sort: s });
    }
  };

  render() {
    const {
      panes,
      cooperatives,
      loading,
      filters: {
        code,
        raisonSociale,
        coopLibelleCourt,
        coopLibelleLong,
        active,
      },
      sort,
      alertIsOpen,
      dialogIsOpen,
      deletePending,
      typesCoop,
    } = this.state;

    return (
      <div className="flex-fill k-d-flex-col k-align-items-stretch k-m-4">
        <Splitter
          panes={panes}
          style={{ height: "100%" }}
          onChange={({ newState }) => this.setState({ panes: newState })}
        >
          <>
            <h2 className="k-ml-4 k-my-2 k-pt-0">Cooperatives</h2>
            <div className="flex-fill k-d-flex-col k-align-items-stretch k-m-3 admin-filter-elevage">
              <div className="k-d-flex k-align-items-baseline k-mb-3">
                <Checkbox
                  label="Actif"
                  value={active}
                  name="active"
                  onChange={({ value }) =>
                    this.handleOnFilterChange({
                      value,
                      target: { name: "active" },
                    })
                  }
                />
              </div>
              <p className="k-mb-0 k-mt-0">Filtrer les cooperatives par :</p>
              <div className="k-d-flex">
                <Label className="k-font-weight-bold" style={{ width: 110 }}>
                  Type
                </Label>
                <div className="k-mb-1">
                  <DropDownList
                    id="filter_type_coop"
                    data={typesCoop}
                    textField="libelle"
                    onChange={({ value }) => {
                      this.setState((s) => ({
                        filters: {
                          ...s.filters,
                          type: value.id,
                        },
                      }));
                    }}
                    style={{ width: 215 }}
                  />
                </div>
              </div>
              <div className="k-d-flex">
                <div className="k-mb-1 k-mr-2" style={{ width: 100 }}>
                  <Input
                    onKeyDown={this.handleOnKeyDown}
                    value={code}
                    name="code"
                    onChange={this.handleOnFilterChange}
                    label="Code"
                  />
                </div>
                <div className="k-d-flex k-align-items-baseline k-mb-1">
                  <Input
                    onKeyDown={this.handleOnKeyDown}
                    value={raisonSociale}
                    name="raisonSociale"
                    onChange={this.handleOnFilterChange}
                    label="Raison sociale"
                  />
                </div>
              </div>
              <div className="k-d-flex">
                <div className="k-mb-1 k-mr-2" style={{ width: 100 }}>
                  <Input
                    onKeyDown={this.handleOnKeyDown}
                    value={coopLibelleCourt}
                    name="coopLibelleCourt"
                    onChange={this.handleOnFilterChange}
                    label="Libellé court"
                  />
                </div>
                <div className="k-d-flex k-align-items-baseline k-mb-1">
                  <Input
                    onKeyDown={this.handleOnKeyDown}
                    value={coopLibelleLong}
                    name="coopLibelleLong"
                    onChange={this.handleOnFilterChange}
                    label="Libellé Long"
                  />
                </div>
              </div>
              <div className="k-d-flex k-flex-col k-align-items-baseline k-my-4">
                <Label className="k-font-weight-bold">Date de début</Label>
                <DateRangePicker
                  style={{ maxWidth: "100%" }}
                  startDateInputSettings={{
                    label: "du",
                  }}
                  endDateInputSettings={{
                    label: "au",
                  }}
                  onChange={this.handleOnDateFilterChange("dateDebut")}
                />
              </div>
              <div className="k-d-flex k-flex-col k-align-items-baseline k-mb-8">
                <Label className="k-font-weight-bold">Date de fin</Label>
                <DateRangePicker
                  style={{ maxWidth: "100%" }}
                  startDateInputSettings={{
                    label: "du",
                  }}
                  endDateInputSettings={{
                    label: "au",
                  }}
                  onChange={this.handleOnDateFilterChange("dateFin")}
                />
              </div>

              <Button type="button" themeColor="primary" onClick={this.handleOnFilterClick}>
                Filtrer
              </Button>
            </div>
          </>
          <div className="k-m-4">
            {loading === true && (
              <div className="flex-fill k-text-center k-mt-18">
                <Loader themeColor="primary" />
              </div>
            )}
            {loading === false && cooperatives !== undefined && (
              <>
                <Button
                  title="Filtres"
                  icon="filter"
                  themeColor="primary"
                  onClick={this.toggleFilters}
                  style={{
                    top: 0,
                    position: "sticky",
                    zIndex: 1,
                  }}
                  className="k-mr-4"
                />
                <Button
                  title="Ajouter une cooperative"
                  type="button"
                  icon="add"
                  onClick={this.handleOnAddClick}
                >
                  Ajouter une cooperative
                </Button>

                <CustomGrid
                  className="grid-elevage k-mt-4"
                  style={{ maxHeight: "70vh" }}
                  columns={this.columns}
                  dataGrid={cooperatives.map((item) => {
                    return {
                      ...item,
                      dateDebut:
                        item.dateDebut !== null
                          ? dayjs(item.dateDebut).format("DD/MM/YYYY")
                          : null,
                      dateFin:
                        item.dateFin !== null
                          ? dayjs(item.dateFin).format("DD/MM/YYYY")
                          : null,
                    };
                  })}
                  onRowDoubleClick={({ dataItem }) => {
                    this.goToAdminCooperative(dataItem.utilisateurId);
                  }}
                  sortable
                  sort={sort}
                  onSortChange={this.handleOnSortChange}
                />
              </>
            )}
          </div>
        </Splitter>
        {dialogIsOpen && (
          <Dialog
            title={"Confirmation suppression"}
            onClose={this.toggleDialog}
          >
            <p>Confirmez-vous la suppression de la ligne sélectionnée ?</p>
            <p>
              ({this.currentItem.current.typeCoopLibelle}){" "}
              {this.currentItem.current.code}{" "}
              {this.currentItem.current.raisonSociale}
            </p>
            <DialogActionsBar>
              <Button onClick={this.toggleDialog}>Non</Button>
              <Button onClick={this.onItemDelete}>Oui</Button>
            </DialogActionsBar>
            {deletePending ? (
              <div className="k-d-flex k-justify-content-center">
                <Loader themeColor="primary" />
              </div>
            ) : null}
          </Dialog>
        )}
        {alertIsOpen && (
          <Dialog title={"Suppression impossible"} onClose={this.toggleAlert}>
            <p>
              La suppression est impossible pour une coopérative de type Domaine
            </p>
            <DialogActionsBar>
              <Button onClick={this.toggleAlert}>Fermer</Button>
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
    );
  }
}

export default withRouter(Cooperatives);
