export const SET_DOMAINE = "SET_DOMAINE";
export const SET_DA_FILTERS = "SET_DA_FILTERS";
export const SET_DM_FILTERS = "SET_DM_FILTERS";
export const SET_DM_PDL_FILTERS = "SET_DM_PDL_FILTERS";
export const SET_ADMIN_GROUPE_FILTERS = "SET_ADMIN_GROUPE_FILTERS";
export const SET_ADMIN_GROUPE_DUPLICATE = "SET ADMIN_GROUPE_DUPLICATE";

export const setDomaine = (domaine) => ({
  type: SET_DOMAINE,
  payload: domaine,
});
