import dayjs from "dayjs";

// Les composants kendo datepicker entre autre ont un problème non résolu
// de conception (librairie kendo-ui)
// https://www.telerik.com/forums/datetime-picker-is-adding-timezone-to-time
// https://github.com/telerik/kendo-ui-core/issues/1864

export default function truncTimeZone(date) {
  // Si la date vient d'être saisie ou modifiée, elle est une instance de Date (dû au composant kendoo)
  // Si la date est déjà renseignée, elle est au format YYYY-MM-DD (champs non touché en écriture)
  if (date !== undefined && date !== null) {
    if (typeof date === "string" && date.length === 10) {
      // YYYY-MM-DD
      // 0123456789
      if (date.charAt(4) === "-" && date.charAt(7) === "-") {
        return date;
      }
    }
    if (date !== "Invalid Date" && date instanceof Date) {
      // La date est entrée dans la locale du navigateur, il faut donc absolument ressortir la date
      // dans le même timezone et la tronquer sinon on envoie une date UTC qui sera en fait décalée
      return dayjs(date).format("YYYY-MM-DD");
    }
  }
  return null;
}
