import { combineReducers } from "redux";
import appReducer from "./app";
import authReducer from "./auth";
import toastrReducer from "./toastr";

export default combineReducers({
  auth: authReducer,
  toastr: toastrReducer,
  app: appReducer,
});
