import { useQueryClient } from "@tanstack/react-query";
import React from "react";

/**
 * @typedef {Object} FluxBilanProps
 * @property {import('../ReportViewer').ReportParameter} params
 * @param {FluxBilanProps} props {@link FluxBilanProps}
 */
export const FluxBilan = ({ params }) => {
  return (
    <div className="flux-bilan">
      <table>
        <thead className="">
          <tr>
            <th
              className="border-x border-y"
              rowSpan={2}
              style={{ width: "5cm" }}
            >
              Quelques prix…
            </th>
            <th className="border-r border-y" colSpan={2}>
              Réalisé dans le mois
            </th>
            <th className="border-r border-y" colSpan={2}>
              Cumul depuis
            </th>
          </tr>
          <tr>
            <th className="border-b border-r">
              Éleveur
              <br />
              Euros
            </th>
            <th className="border-b border-r">
              Groupe
              <br />
              Euros
            </th>
            <th className="border-b border-r">
              12 mois Éleveur
              <br />
              Euros
            </th>
            <th className="border-b border-r">
              12 mois Groupe
              <br />
              Euros
            </th>
          </tr>
        </thead>
        <tbody
          style={
            /** @type {React.CSSProperties} */ ({
              "--cell-height": "8mm",
            })
          }
        >
          <RowBilan params={params} reportKey="ListFluxBilanAliment" />
        </tbody>
        <tbody
          className=""
          style={
            /** @type {React.CSSProperties} */ ({
              "--cell-height": "4mm",
            })
          }
        >
          <RowBilan params={params} reportKey="ListFluxBilanChevreaux" />
        </tbody>
        <tbody
          style={
            /** @type {React.CSSProperties} */ ({
              "--cell-height": "8mm",
            })
          }
        >
          <RowBilan
            params={params}
            reportKey="ListFluxBilanReformes"
            rowClassName="border-b"
          />
        </tbody>
      </table>
    </div>
  );
};

/**
 * @typedef {Object} RowBilanProps
 * @property {string} [rowClassName]
 * @property {import('../ReportViewer').ReportParameter} params
 * @property {keyof import('../ReportViewer').Report} reportKey
 * @param {RowBilanProps & React.HTMLAttributes<HTMLTableRowElement>} props {@link RowBilanProps}
 */
const RowBilan = ({ rowClassName, reportKey, params }) => {
  const queryClient = useQueryClient();
  const row =
    /** @type {Array<import('../ReportViewer').FluxBilanPdf> | undefined} */ (
      queryClient.getQueryData(["donneesMensuelles", "Row", reportKey, params])
    );

  return (
    <>
      {(row || []).map((row, i) => (
        <tr key={i} className={rowClassName}>
          <td className="border-x">{row.LibLigne}</td>
          <td align="center" className="border-r">
            {row.Valeur1}
          </td>
          <td align="center" className="border-r">
            {row.Valeur2}
          </td>
          <td align="center" className="border-r">
            {row.Valeur3}
          </td>
          <td align="center" className="border-r">
            {row.Valeur4}
          </td>
        </tr>
      ))}
    </>
  );
};
