import dataSource from "../../dataSource/dataSource";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const CGU = "CGU";

export const login = (loginParams) => ({
  type: LOGIN,
  payload: loginParams,
});

export const attemptLogin = (loginParams) => {
  return async (dispatch) => {
    const success = await dataSource.login(loginParams);
    await dispatch(login(success));
  };
};

export const attemptLoginAad = (loginParams) => {
  return async (dispatch) => {
    await dispatch(login(loginParams.detail));
  };
};

export const resetPassword = (params) => ({
  type: RESET_PASSWORD,
  payload: params,
});

export const attemptResetPassword = (data) => {
  return async (dispatch) => {
    await dataSource.updatePasswordById(data.utilisateurId, {
      newPassword: data.newPassword,
    });
    await dispatch(
      resetPassword({
        isFirstLogin: false,
        haveResetPwd: false,
      })
    );
  };
};

const logout = () => ({
  type: LOGOUT,
});

export const attemptLogout = () => {
  return async (dispatch) => {
    try {
      await dataSource.logout();
    } catch (error) {
      // ignore 401 Unauthorized if the logout attempt was too late
      if (error.status !== 401) {
        throw error;
      }
    }

    await dispatch(logout());
  };
};

export const refreshToken = (params) => ({
  type: REFRESH_TOKEN,
  payload: params,
});

export const attemptRefreshToken = () => async (dispatch) => {
  try {
    await dispatch(refreshToken(await dataSource.refreshToken()));
  } catch (error) {
    await dispatch(attemptLogout());
  }
};

export const cgu = (cguParams) => ({
  type: CGU,
  payload: cguParams,
});

export const attemptCgu = (id, data) => {
  return async (dispatch) => {
    await dataSource.putAcceptationCgu(id, data);
    await dispatch(
      cgu({ cguChecked: data.cguChecked, privacyChecked: data.privacyChecked })
    );
  };
};
