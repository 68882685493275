import { Field } from "@progress/kendo-react-form";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import PropTypes from "prop-types";
import React, { Component } from "react";

class DonneesTechniques extends Component {
  render() {
    const {
      currentMonth,
      lastMonth,
      currentYear,
      errors: { ...donneesTechniquesErrors },
      valueGetter,
      onChange,
      canEdit,
    } = this.props;
    return (
      <div className="k-d-flex k-flex-column k-m-4 tab-donnees-mensuelles">
        <h3>A. Le troupeau</h3>
        <div className="k-d-grid grid-wrapper">
          <PanelBar className={`${canEdit && "panelbar-editable"}`}>
            <PanelBarItem tabIndex="-1" title="Les mises bas" expanded>
              <div className="k-m-4 grid-wrapper-form-2">
                <Label editorId={"misesBasChevresNombre"}>
                  Chèvres ayant mis bas
                </Label>
                <Field
                  component={NumericTextBox}
                  id="misesBasChevresNombre"
                  name="misesBasChevresNombre"
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />

                <Label editorId={"misesBasChevrettesNombre"}>
                  Chèvrettes ayant mis bas
                </Label>
                <Field
                  component={NumericTextBox}
                  id="misesBasChevrettesNombre"
                  name="misesBasChevrettesNombre"
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />

                <Label editorId={"misesBasChevreauxNes"}>
                  Nombre de chevreaux nés (y compris les morts-nés)
                </Label>
                <Field
                  id="misesBasChevreauxNes"
                  name="misesBasChevreauxNes"
                  component={NumericTextBox}
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />
              </div>
            </PanelBarItem>
          </PanelBar>
          <PanelBar className={`${canEdit && "panelbar-editable"}`}>
            <PanelBarItem
              tabIndex="-1"
              title="Effectifs dernier jour du mois"
              expanded
            >
              <div className="k-m-4 grid-wrapper-form-3">
                <span className="k-font-weight-bold">Libellé</span>
                <span className="k-font-weight-bold">
                  {currentMonth} / {currentYear}
                </span>
                <span className="k-font-weight-bold">
                  {currentMonth === "Janvier" ? "Décembre" : lastMonth} /{" "}
                  {currentMonth === "Janvier" ? currentYear - 1 : currentYear}
                </span>

                <Label editorId={"effectifChevresNombre"}>Chèvres</Label>
                <NumericTextBox
                  value={valueGetter("effectifChevresNombre")}
                  onChange={({ value }) =>
                    onChange("effectifChevresNombre", value)
                  }
                  valid={!donneesTechniquesErrors.effectifChevresNombreInvalid}
                  id="effectifChevresNombre"
                  name="effectifChevresNombre"
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />
                <Field
                  component={NumericTextBox}
                  id="effectifChevresNombre"
                  name="effectifChevresNombreM_1"
                  disabled
                  format={"n0"}
                  min={0}
                  spinners={false}
                />

                <Label editorId={"effectifChevrettesGrandesNombre"}>
                  Grandes chevrettes
                </Label>
                <NumericTextBox
                  value={valueGetter("effectifChevrettesGrandesNombre")}
                  onChange={({ value }) =>
                    onChange("effectifChevrettesGrandesNombre", value)
                  }
                  valid={
                    !donneesTechniquesErrors.effectifsGrandesChevrettesNombreInvalid
                  }
                  id="effectifChevrettesGrandesNombre"
                  name="effectifChevrettesGrandesNombre"
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />
                <Field
                  component={NumericTextBox}
                  id="effectifChevrettesGrandesNombre"
                  name="effectifChevrettesGrandesNombreM_1"
                  disabled
                  format={"n0"}
                  min={0}
                  spinners={false}
                />

                <Label editorId={"effectifChevrettesPetitesNombre"}>
                  Petites chevrettes
                </Label>
                <Field
                  component={NumericTextBox}
                  id="effectifChevrettesPetitesNombre"
                  name="effectifChevrettesPetitesNombre"
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />
                <Field
                  component={NumericTextBox}
                  id="effectifChevrettesGrandesNombre"
                  name="effectifChevrettesPetitesNombreM_1"
                  disabled
                  format={"n0"}
                  min={0}
                  spinners={false}
                />

                <Label editorId={"effectifBoucsNombre"}>Boucs</Label>
                <Field
                  id="effectifBoucsNombre"
                  name="effectifBoucsNombre"
                  component={NumericTextBox}
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />
                <Field
                  component={NumericTextBox}
                  id="effectifChevrettesGrandesNombre"
                  name="effectifBoucsNombreM_1"
                  disabled
                  format={"n0"}
                  min={0}
                  spinners={false}
                />
              </div>
            </PanelBarItem>
          </PanelBar>
        </div>

        <h3>B. Mouvements d&apos;animaux</h3>
        <div className="k-d-grid grid-wrapper">
          <PanelBar className={`${canEdit && "panelbar-editable"}`}>
            <PanelBarItem tabIndex="-1" title="Chevreaux" expanded>
              <div className="k-m-4 grid-wrapper-form-3">
                <span className="k-font-weight-bold">Libellé</span>
                <span className="k-font-weight-bold">Nombre</span>
                <span className="k-font-weight-bold">Montant (€) HT</span>
                <Label editorId={"mouvementsChevreauxVente"}>
                  Vendus à 4 jours
                </Label>
                <Field
                  component={NumericTextBox}
                  id="mouvementsChevreauxVente"
                  name="mouvementsChevreauxVenteNombre"
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />
                <Field
                  component={NumericTextBox}
                  id="mouvementsChevreauxVente"
                  name="mouvementsChevreauxVenteValeurHT"
                  disabled={!canEdit}
                  format={"n1"}
                  spinners={false}
                  min={0}
                />

                <Label editorId={"mouvementsChevreauxEngraisses"}>
                  Chevreaux engraissés
                </Label>
                <Field
                  component={NumericTextBox}
                  id="mouvementsChevreauxEngraisses"
                  name="mouvementsChevreauxEngraissesNombre"
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />
                <Field
                  component={NumericTextBox}
                  id="mouvementsChevreauxEngraisses"
                  name="mouvementsChevreauxEngraissesValeurHT"
                  disabled={!canEdit}
                  format={"n1"}
                  spinners={false}
                  min={0}
                />

                <Label editorId={"mouvementsChevreauxPerte"}>
                  Pertes (y compris les morts nés)
                </Label>
                <Field
                  id="mouvementsChevreauxPerte"
                  name="mouvementsChevreauxPerteNombre"
                  component={NumericTextBox}
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />
              </div>
            </PanelBarItem>
          </PanelBar>
          <PanelBar className={`${canEdit && "panelbar-editable"}`}>
            <PanelBarItem
              tabIndex="-1"
              title="Chèvres et boucs adultes"
              expanded
            >
              <div className="k-m-4 grid-wrapper-form-4">
                <span className="k-font-weight-bold">Libellé</span>
                <span className="k-font-weight-bold">Chèvres</span>
                <span className="k-font-weight-bold">Boucs</span>
                <span className="k-font-weight-bold">Montant (€) HT</span>
                <Label editorId={"mouvementsVentes"}>Ventes</Label>
                <Field
                  component={NumericTextBox}
                  id="mouvementsVentes"
                  name="mouvementsChevresVenteNombre"
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />
                <Field
                  component={NumericTextBox}
                  id="mouvementsVentes"
                  name="mouvementsBoucsVenteNombre"
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />
                <Field
                  component={NumericTextBox}
                  id="mouvementsVentes"
                  name="mouvementsChevresBoucsVenteValeurHT"
                  disabled={!canEdit}
                  format={"n1"}
                  spinners={false}
                  min={0}
                />

                <Label editorId={"mouvementsAchats"}>Achats</Label>
                <Field
                  component={NumericTextBox}
                  id="mouvementsAchats"
                  name="mouvementsChevresAchatNombre"
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />
                <Field
                  component={NumericTextBox}
                  id="mouvementsAchats"
                  name="mouvementsBoucsAchatNombre"
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />
                <Field
                  component={NumericTextBox}
                  id="mouvementsAchats"
                  name="mouvementsChevresBoucsAchatValeurHT"
                  disabled={!canEdit}
                  format={"n1"}
                  spinners={false}
                  min={0}
                />

                <Label editorId={"mouvementsPertes"}>Pertes</Label>
                <Field
                  component={NumericTextBox}
                  id="mouvementsPertes"
                  name="mouvementsChevresPerteNombre"
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />
                <Field
                  component={NumericTextBox}
                  id="mouvementsPertes"
                  name="mouvementsBoucsPerteNombre"
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />
              </div>
            </PanelBarItem>
          </PanelBar>
          <PanelBar className={`${canEdit && "panelbar-editable"}`}>
            <PanelBarItem tabIndex="-1" title="Reproducteurs" expanded>
              <div className="k-m-4 grid-wrapper-form-4">
                <span className="k-font-weight-bold">Libellé</span>
                <span className="k-font-weight-bold">Chevrettes</span>
                <span className="k-font-weight-bold">Jeunes boucs</span>
                <span className="k-font-weight-bold">Montant (€) HT</span>
                <Label editorId={"mouvementsReprodVentes"}>Ventes</Label>
                <Field
                  component={NumericTextBox}
                  id="mouvementsReprodVentes"
                  name="mouvementsReprodChevrettesVenteNombre"
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />
                <Field
                  component={NumericTextBox}
                  id="mouvementsReprodVentes"
                  name="mouvementsReprodBoucsVenteNombre"
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />
                <Field
                  component={NumericTextBox}
                  id="mouvementsReprodVentes"
                  name="mouvementsReprodVenteValeurHT"
                  disabled={!canEdit}
                  format={"n1"}
                  spinners={false}
                  min={0}
                />

                <Label editorId={"mouvementsReprodAchats"}>Achats</Label>
                <Field
                  component={NumericTextBox}
                  id="mouvementsReprodAchats"
                  name="mouvementsReprodChevrettesAchatNombre"
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />
                <Field
                  component={NumericTextBox}
                  id="mouvementsReprodAchats"
                  name="mouvementsReprodBoucsAchatNombre"
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />
                <Field
                  component={NumericTextBox}
                  id="mouvementsReprodAchats"
                  name="mouvementsReprodAchatValeurHT"
                  disabled={!canEdit}
                  format={"n1"}
                  spinners={false}
                  min={0}
                />

                <Label editorId={"mouvementsReprodPertes"}>Pertes</Label>
                <Field
                  component={NumericTextBox}
                  id="mouvementsReprodPertes"
                  name="mouvementsReprodChevrettesPerteNombre"
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />
                <Field
                  component={NumericTextBox}
                  id="mouvementsReprodPertes"
                  name="mouvementsReprodBoucsPerteNombre"
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />
              </div>
            </PanelBarItem>
          </PanelBar>
        </div>

        <h3>C. Production laitière du mois</h3>
        <div className="k-d-grid grid-wrapper">
          <PanelBar className={`${canEdit && "panelbar-editable"}`}>
            <PanelBarItem
              tabIndex="-1"
              title="Nombre de chèvres traites / décade"
              expanded
            >
              <div className="k-m-4 grid-wrapper-form-2">
                <span className="k-font-weight-bold show-content">Libellé</span>
                <span className="k-font-weight-bold show-content">Nombre</span>
                <Label editorId={"productionChevresTraitesDecade1"}>
                  1ère décade
                </Label>
                <Field
                  component={NumericTextBox}
                  id="productionChevresTraitesDecade1"
                  name="productionChevresTraitesDecade1"
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />

                <Label editorId={"productionChevresTraitesDecade2"}>
                  2ème décade
                </Label>
                <Field
                  component={NumericTextBox}
                  id="productionChevresTraitesDecade2"
                  name="productionChevresTraitesDecade2"
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />

                <Label editorId={"productionChevresTraitesDecade3"}>
                  3ème décade
                </Label>
                <NumericTextBox
                  value={valueGetter("productionChevresTraitesDecade3")}
                  onChange={({ value }) =>
                    onChange("productionChevresTraitesDecade3", value)
                  }
                  valid={
                    !donneesTechniquesErrors.productionChevresTraitesDecade3Invalid
                  }
                  id="productionChevresTraitesDecade3"
                  name="productionChevresTraitesDecade3"
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />
              </div>
              <p
                style={{
                  fontStyle: "italic",
                  marginBottom: "0",
                  padding: "0 16px 16px 16px",
                }}
              >
                2ème décade valeur par défaut si le nombre de chèvres traites
                est identique sur les 3 décades
              </p>
            </PanelBarItem>
          </PanelBar>
          <PanelBar className={`${canEdit && "panelbar-editable"}`}>
            <PanelBarItem
              tabIndex="-1"
              title="Quantités de lait (en litres)"
              expanded
            >
              <div className="k-m-4 grid-wrapper-form-2">
                <span className="k-font-weight-bold show-content">Libellé</span>
                <span className="k-font-weight-bold show-content">Litres</span>

                <Label editorId={"productionQuantiteLaitLivre"}>
                  Lait livré
                </Label>
                <NumericTextBox
                  id="productionQuantiteLaitLivre"
                  disabled={!canEdit}
                  value={valueGetter("productionQuantiteLaitLivre")}
                  onChange={({ value }) => {
                    onChange("productionQuantiteLaitLivre", value);
                    const [premiereLigne, ...resteTableau] = valueGetter(
                      "paieDeLait"
                    );
                    onChange("paieDeLait", [
                      { ...premiereLigne, productionQuantiteLaitLivre: value },
                      ...resteTableau,
                    ]);
                  }}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />

                <Label editorId={"productionLaitDivers"}>Lait divers</Label>
                <Field
                  component={NumericTextBox}
                  id="productionLaitDivers"
                  name="productionLaitDivers"
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />

                <Label editorId={"productionLaitConsomme"}>
                  Lait consommé (chevrettes et chevreaux, hors colostrum)
                </Label>
                <Field
                  component={NumericTextBox}
                  id="productionLaitConsomme"
                  name="productionLaitConsomme"
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />
              </div>
            </PanelBarItem>
          </PanelBar>
          <PanelBar className={`${canEdit && "panelbar-editable"}`}>
            <PanelBarItem
              tabIndex="-1"
              title="Nombre de jours de production livrée"
              expanded
            >
              <div className="k-m-4 grid-wrapper-form-2">
                <Label editorId={"productionNombreJoursLivres"}>
                  Nombre de jours de production livrée
                </Label>
                <Field
                  component={NumericTextBox}
                  id="productionNombreJoursLivres"
                  name="productionNombreJoursLivres"
                  disabled={!canEdit}
                  format={"n0"}
                  min={0}
                  spinners={canEdit}
                />
                <p style={{ fontStyle: "italic", marginBottom: "0" }}>
                  (si non renseigné, le nombre de jours du mois sera utilisé
                  pour le calcul des résultats)
                </p>
              </div>
            </PanelBarItem>
          </PanelBar>
        </div>
      </div>
    );
  }
}

DonneesTechniques.propTypes = {
  errors: PropTypes.object,
  currentMonth: PropTypes.string.isRequired,
  lastMonth: PropTypes.string,
  currentYear: PropTypes.number.isRequired,
  valueGetter: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
};

DonneesTechniques.defaultProps = {
  errors: {},
  lastMonth: "",
  canEdit: false,
};

export default DonneesTechniques;
