import { Component } from "react";
import { Button } from "@progress/kendo-react-buttons";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { Loader } from "@progress/kendo-react-indicators";
import dataSource from "../../../../dataSource/dataSource";
import CustomGrid from "../../../shared/CustomGrid";

const EMPTY_TECHNICIEN = {
  coopId: null,
  coopUtilisateurId: null,
  dateDebut: dayjs().format("YYYY-MM-DD"),
  dateFin: null,
  societeAgricoleId: null,
  steAgricoleCoopUtilId: 0,
  structure: null,
};

class Techniciens extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      techniciens: [],
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const techniciens = await dataSource.loadTechniciens();
    this.setState({ loading: false, techniciens });
  }

  columns = () => {
    const { techniciens } = this.state;
    const { valueGetter, societeAgricoleId } = this.props;
    let structures = valueGetter("structures") || [];
    const m = new Map(structures.map((s) => [s.coopLibelleLong, s]));
    structures = [...m.values()];
    return [
      {
        title: "Structure",
        type: "text",
        field: "structure",
        editable: () => false,
        width: 100,
      },
      {
        title: "Code",
        type: "text",
        field: "code",
        editable: () => false,
        width: 100,
      },
      {
        title: "Nom",
        type: "dropdowntree",
        field: "nomCoopUtilisateur",
        dropdowntree: {
          list: techniciens.map((t) => ({ ...t, text: t.nom })),
          textField: "text",
          groupKey: "coopLibelle",
          dataItemKey: "text",
          value: (dataItem) => ({
            text: dataItem.nomCoopUtilisateur || "",
          }),
          updateItem: (
            dataItem,
            { nom, utilisateurId, code, coopLibelle, coopId }
          ) => ({
            ...dataItem,
            nomCoopUtilisateur: nom,
            coopUtilisateurId: utilisateurId,
            code,
            codeCoop: code,
            structure: coopLibelle,
            coopId,
            societeAgricoleId,
          }),
        },
        width: 100,
      },
      {
        title: "Date début",
        type: "date",
        field: "dateDebut",
        date: {
          value: (dataItem) => {
            const dateDebut = dayjs(dataItem.dateDebut);
            return dateDebut.isValid() ? dateDebut.toDate() : dayjs().toDate();
          },
          updateItem: (dataItem, value) => ({
            ...dataItem,
            dateDebut: dayjs(value).format("YYYY-MM-DD"),
          }),
          onClear: (dataItem) => ({
            ...dataItem,
            dateDebut: dayjs().format("YYYY-MM-DD"),
          }),
        },
        width: 100,
      },
      {
        title: "Date fin",
        type: "date",
        field: "dateFin",
        date: {
          value: (dataItem) => {
            const dateFin = dayjs(dataItem.dateFin);
            return dateFin.isValid() ? dateFin.toDate() : null;
          },
          updateItem: (dataItem, value) => {
            return {
              ...dataItem,
              dateFin: dayjs(value).format("YYYY-MM-DD"),
            };
          },
          onClear: (dataItem) => ({
            ...dataItem,
            dateFin: "",
          }),
        },
        width: 100,
      },
      {
        type: "delete",
        width: 60,
        headerClassName: "delete",
      },
    ];
  };

  render() {
    const { valueGetter, edit, onChange, onAddClick } = this.props;
    const { loading } = this.state;

    const techniciens = valueGetter("techniciens") || [];

    return loading === true ? (
      <div className="flex-fill k-text-center k-mt-18">
        <Loader themeColor="primary" />
      </div>
    ) : (
      <div>
        <Button
          title="Ajouter un technicien"
          type="button"
          className="k-mb-4 k-mt-2"
          icon="add"
          disabled={!edit}
          onClick={() => {
            onChange("techniciens", {
              value: [...techniciens, EMPTY_TECHNICIEN],
            });
            onAddClick();
          }}
        >
          Ajouter un technicien
        </Button>
        <CustomGrid
          className="grid-admin"
          style={{ maxHeight: "60vh" }}
          columns={this.columns()}
          dataGrid={techniciens}
          canEdit
          disabled={!edit}
          onChange={(newData) => {
            onChange("techniciens", { value: newData });
          }}
        />
      </div>
    );
  }
}

Techniciens.propTypes = {
  edit: PropTypes.bool.isRequired,
  valueGetter: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  societeAgricoleId: PropTypes.number.isRequired,
};

export default Techniciens;
