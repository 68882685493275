import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { Chart } from "react-chartjs-2";

/**
 * @typedef {Object} ChartProductionLaitiereProps
 * @property {import('../ReportViewer').ReportParameter} params
 * @param {ChartProductionLaitiereProps & React.HTMLAttributes<HTMLTableRowElement>} props {@link ChartProductionLaitiereProps}
 */
export const ChartProductionLaitiere = ({ params }) => {
  const queryClient = useQueryClient();
  return (
    <Chart
      type="bar"
      options={{
        aspectRatio: 1 / 0.4,
        plugins: {
          title: {
            display: true,
            text: "Production laitière",
            font: {
              size: 16,
            },
          },
        },
        scales: {
          y: {
            type: "linear",
            display: true,
            position: "left",
            title: {
              display: true,
              text: "Litres",
            },
          },
          y1: {
            type: "linear",
            display: true,
            position: "right",
            grid: {},
          },
        },
      }}
      data={{
        labels: /** @type {Array<import('../ReportViewer').Dataset>} */ (
          queryClient.getQueryData([
            "donneesMensuelles",
            "Chart",
            // peu importe, c'est la même échelle partout
            "ListTButyreuxGraph",
            params,
          ]) || []
        ).map(({ Date }) => Date),
        datasets: [
          {
            type: "line",
            label: "Taux butyreux",
            borderWidth: 2,
            fill: false,
            backgroundColor: "#FFC000",
            borderColor: "#FFC000",
            data: /** @type {Array<import('../ReportViewer').Dataset>} */ (
              queryClient.getQueryData([
                "donneesMensuelles",
                "Chart",
                "ListTButyreuxGraph",
                params,
              ]) || []
            ).map(({ Valeur }) => Valeur),
            yAxisID: "y1",
          },
          {
            type: "line",
            label: "Taux protéique",
            borderWidth: 2,
            fill: false,
            backgroundColor: "#BFBFBF",
            borderColor: "#BFBFBF",
            data: /** @type {Array<import('../ReportViewer').Dataset>} */ (
              queryClient.getQueryData([
                "donneesMensuelles",
                "Chart",
                "ListTProteiqueGraph",
                params,
              ]) || []
            ).map(({ Valeur }) => Valeur),
            yAxisID: "y1",
          },
          {
            type: "bar",
            label: "Lait/jour/chèvre traite",
            backgroundColor: "#0099FF",
            borderColor: "#0099FF",
            data: /** @type {Array<import('../ReportViewer').Dataset>} */ (
              queryClient.getQueryData([
                "donneesMensuelles",
                "Chart",
                "ListTraiteGraph",
                params,
              ]) || []
            ).map(({ Valeur }) => Valeur),
            borderWidth: 2,
            yAxisID: "y",
          },
        ],
      }}
    />
  );
};
