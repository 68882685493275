import { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import dayjs from "dayjs";
import CustomGrid from "../../../shared/CustomGrid";
import dataSource from "../../../../dataSource/dataSource";

const EMPTY_STRUCTURE = {
  codeAdherent: null,
  codeCoop: "",
  coopId: null,
  coopLibelleCourt: "",
  coopLibelleLong: "",
  coopSteAgricoleId: 0,
  dateDebut: null,
  dateFin: null,
  droit: null,
  relationCommCoop: null,
  societeAgricoleId: null,
  typeCoop: "",
  typeCoopId: null,
};

class Structures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      droits: [],
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const droits = await dataSource.loadModaliste("COOPDRT");

    this.setState({ loading: false, droits });
  }

  columns = () => {
    const { valueGetter, structures } = this.props;
    const { droits } = this.state;

    return [
      {
        title: "Type",
        type: "text",
        field: "typeCoop",
        editable: () => false,
        width: 80,
      },
      {
        title: "Code",
        type: "number",
        field: "codeCoop",
        editable: () => false,
        width: 70,
      },
      {
        title: "Nom de structure",
        type: "dropdowntree",
        field: "coopLibelleLong",
        dropdowntree: {
          list: structures.map((s) => ({
            ...s,
            text: s.coopLibelleLong,
            typeCoopLibelle: s.typeCoopLibelle,
          })),
          textField: "text",
          groupKey: "typeCoopLibelle",
          width: "200",
          value: ({ coopLibelleLong, typeCoopLibelle }) => ({
            text: coopLibelleLong,
            typeCoopLibelle,
          }),
          updateItem: (
            dataItem,
            { text, typeCoopLibelle, typeCoopId, coopId }
          ) => {
            const structure = structures.find(
              ({ coopLibelleLong }) => coopLibelleLong === text
            );
            return {
              ...dataItem,
              coopLibelleLong: text,
              typeCoopLibelle,
              codeCoop: structure?.code || "",
              typeCoop: typeCoopLibelle,
              typeCoopId,
              societeAgricoleId: valueGetter("societeAgricoleId"),
              coopId,
            };
          },
        },
        width: 170,
      },
      {
        title: "Droit",
        type: "combobox",
        field: "droitLibelle",
        combobox: {
          list: droits,
          textField: "libelle",
          dataItemKey: "id",
          value: (dataItem) => {
            const d = droits.find(
              (droit) => droit.rang_modaliste === dataItem.droit
            );
            return { libelle: d?.libelle || "", id: dataItem.droit };
          },
          updateItem: (dataItem, { libelle, rang_modaliste }) => {
            return {
              ...dataItem,
              libelle,
              droit: rang_modaliste,
              droitLibelle: libelle,
            };
          },
        },
        width: 110,
      },
      {
        title: "Code adhérent",
        type: "text",
        field: "codeAdherent",
        width: 100,
      },
      {
        title: "Date début",
        type: "date",
        field: "dateDebut",
        date: {
          value: (dataItem) => {
            const dateDebut = dayjs(dataItem.dateDebut);
            return dateDebut.isValid() ? dateDebut.toDate() : dayjs().toDate();
          },
          updateItem: (dataItem, value) => ({
            ...dataItem,
            dateDebut: dayjs(value).format("YYYY-MM-DD"),
          }),
          // TODO à implémenter pour toutes les colonnes dates
          onClear: (dataItem) => ({
            ...dataItem,
            dateDebut: dayjs().format("YYYY-MM-DD"),
          }),
        },
        width: 70,
      },
      {
        title: "Date fin",
        type: "date",
        field: "dateFin",
        date: {
          value: (dataItem) => {
            const dateFin = dayjs(dataItem.dateFin);
            return dataItem.dateFin && dateFin.isValid()
              ? dateFin.toDate()
              : null;
          },
          updateItem: (dataItem, value) => ({
            ...dataItem,
            dateFin: dayjs(value).format("YYYY-MM-DD"),
          }),
          onClear: (dataItem) => ({
            ...dataItem,
            dateFin: "",
          }),
        },
        width: 70,
      },
      {
        type: "delete",
        width: 60,
        headerClassName: "delete",
      },
    ];
  };

  render() {
    const { valueGetter, onChange, edit, onAddClick } = this.props;
    const { loading, droits } = this.state;

    const structures = valueGetter("structures") || [];

    return loading === true ? (
      <div className="flex-fill k-text-center k-mt-18">
        <Loader themeColor="primary" />
      </div>
    ) : (
      <div>
        <Button
          className="k-mb-4 k-mt-2"
          icon="add"
          type="button"
          disabled={!edit}
          onClick={() => {
            onAddClick();
            onChange("structures", { value: [...structures, EMPTY_STRUCTURE] });
          }}
        >
          Ajouter une structure
        </Button>
        <CustomGrid
          className="grid-structure"
          style={{ maxHeight: "60vh" }}
          columns={this.columns()}
          dataGrid={structures.map((s) => {
            const d = droits.find((droit) => droit.rang_modaliste === s.droit);
            return { ...s, droitLibelle: d?.libelle || "" };
          })}
          canEdit
          disabled={!edit}
          onChange={(newData) => {
            onChange("structures", { value: newData });
          }}
        />
      </div>
    );
  }
}

Structures.propTypes = {
  edit: PropTypes.bool.isRequired,
  valueGetter: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  structures: PropTypes.array.isRequired,
};

export default Structures;
