import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { Bar } from "react-chartjs-2";

/**
 * @typedef {Object} MiniChartMargeStartProps
 * @property {import('../ReportViewer').ReportParameter} params
 * @param {MiniChartMargeStartProps & React.HTMLAttributes<HTMLTableRowElement>} props {@link MiniChartMargeStartProps}
 */
export const MiniChartMargeStart = ({ params }) => {
  const queryClient = useQueryClient();

  return (
    <Bar
      options={{
        aspectRatio: 1,
        plugins: {
          title: {
            display: true,
            text: /** @type {Array<import('../ReportViewer').Dataset>} */ (
              queryClient.getQueryData([
                "donneesMensuelles",
                "Chart",
                // peu importe, c'est la même échelle partout
                "ListProdLaitGraph",
                params,
              ]) || []
            ).map(({ Date }) => Date)[0],
            font: {
              size: 16,
            },
          },
          legend: {
            position: "bottom",
          },
        },
        scales: {
          x: { stacked: true },
          y: { stacked: true },
        },
      }}
      data={{
        labels: /** @type {Array<import('../ReportViewer').Dataset>} */ (
          queryClient.getQueryData([
            "donneesMensuelles",
            "Chart",
            "ListProdLaitGraph",
            params,
          ]) || []
        )
          .map(({ Date }) => Date)
          .slice(0, 1),
        datasets: [
          {
            label: "Produit lait",
            backgroundColor: "#0099FF",
            borderColor: "#0099FF",
            data: /** @type {Array<import('../ReportViewer').Dataset>} */ (
              queryClient.getQueryData([
                "donneesMensuelles",
                "Chart",
                "ListProdLaitGraph",
                params,
              ]) || []
            )
              .map(({ Valeur }) => Valeur)
              .slice(0, 1),
            borderWidth: 2,
            stack: "Stack 0",
          },
          {
            label: "Fourrage",
            backgroundColor: "#000000",
            borderColor: "#000000",
            data: /** @type {Array<import('../ReportViewer').Dataset>} */ (
              queryClient.getQueryData([
                "donneesMensuelles",
                "Chart",
                "ListFourragesGraph",
                params,
              ]) || []
            )
              .map(({ Valeur }) => Valeur)
              .slice(0, 1),
            borderWidth: 2,
            stack: "Stack 1",
          },
          {
            label: "Simples + Composés",
            backgroundColor: "#C3EAEF",
            borderColor: "#C3EAEF",
            data:
              /** @type {Array<import('../ReportViewer').Dataset>} */
              (
                queryClient.getQueryData([
                  "donneesMensuelles",
                  "Chart",
                  "ListCompIndivGraph",
                  params,
                ]) || []
              )
                .map(({ Valeur }) => Valeur)
                .slice(0, 1),
            borderWidth: 2,
            stack: "Stack 1",
          },
          {
            label: "MCA",
            backgroundColor: "#00B050",
            borderColor: "#00B050",
            data:
              /** @type {Array<import('../ReportViewer').Dataset>} */
              (
                queryClient.getQueryData([
                  "donneesMensuelles",
                  "Chart",
                  "ListMCAGraph",
                  params,
                ]) || []
              )
                .map(({ Valeur }) => Valeur)
                .slice(0, 1),
            borderWidth: 2,
            stack: "Stack 1",
          },
        ],
      }}
    />
  );
};
