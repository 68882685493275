import { Button } from "@progress/kendo-react-buttons";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import PropTypes from "prop-types";
import { Component } from "react";
import toastService from "../../utils/toastService";

class GridComboBox extends Component {
  constructor(props) {
    super(props);
    const { list } = props;
    this.state = {
      filteredList: list,
    };
  }

  render() {
    const { filteredList } = this.state;
    const {
      list,
      value,
      onChange,
      textField,
      dataItemKey,
      canAdd,
      onClickAdd,
      ...otherProps
    } = this.props;
    return (
      <div className="k-d-flex">
        <ComboBox
          {...otherProps}
          data={filteredList}
          value={value}
          textField={textField}
          onChange={onChange}
          clearButton={false}
          filterable
          expandField={"expanded"}
          dataItemKey={dataItemKey}
          groupField="groupText"
          onFilterChange={({ filter: { value: input } }) => {
            this.setState({
              filteredList: list.filter((l) =>
                l[textField].toUpperCase().includes(input.toUpperCase())
              ),
            });
          }}
        />
        {canAdd && (
          <Button
            tabIndex={-1}
            type="button"
            icon="plus"
            title="Ajouter un aliment"
            onClick={() =>
              new Promise(onClickAdd)
                .then((newAliment) => {
                  onChange(
                    {
                      value: { ...newAliment, text: newAliment.nomAliment },
                    },
                    () => {}
                  );
                })
                .catch((error) => {
                  if (error !== "close") {
                    toastService.addToast({
                      id: "add-aliment-error",
                      type: "error",
                      message: `Erreur lors de l'ajout de l'aliment : ${error.toString()}`,
                    });
                  }
                })
            }
          />
        )}
      </div>
    );
  }
}

GridComboBox.propTypes = {
  list: PropTypes.array.isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  textField: PropTypes.string.isRequired,
  dataItemKey: PropTypes.string.isRequired,
  canAdd: PropTypes.bool,
  onClickAdd: PropTypes.func,
};

GridComboBox.defaultProps = {
  canAdd: false,
  onClickAdd: () => {},
};

export default GridComboBox;
