import { Component } from "react";
import PropTypes from "prop-types";
import { Loader } from "@progress/kendo-react-indicators";
import { Button } from "@progress/kendo-react-buttons";
import CustomGrid from "../../../shared/CustomGrid";
import dataSource from "../../../../dataSource/dataSource";

const EMPTY_TECHNICIEN = {
  groupeCoopUtilisateurId: 0,
  technicienId: 0,
  groupeId: 0,
  coopUtilisateurId: 0,
  coopId: 0,
  coopLibelle: null,
  codeTechnicien: null,
  nomTechnicien: null,
  typeDroitResultatMensuel: 0,
};

class Techniciens extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      typesDroit: [],
      techniciens: [],
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const typesDroit = await dataSource.loadModaliste("TYDM");
    const techniciens = await dataSource.loadTechniciens();
    techniciens.sort((a, b) => {
      const nomA = (a.nom || "").toLowerCase();
      const nomB = (b.nom || "").toLowerCase();
      if (nomA < nomB) return -1;
      if (nomA > nomB) return 1;
      return 0;
    });

    this.setState({ loading: false, typesDroit, techniciens });
  }

  columns = () => {
    const { typesDroit, techniciens } = this.state;
    return [
      {
        title: "Code technicien",
        type: "text",
        field: "codeTechnicien",
        editable: () => false,
        width: 90,
      },
      {
        title: "Nom technicien",
        type: "combobox",
        field: "nomTechnicien",
        combobox: {
          list: techniciens,
          textField: "nom",
          dataItemKey: "utilisateurId",
          value: (dataItem) => {
            const td = techniciens.find(
              ({ utilisateurId }) =>
                utilisateurId === dataItem.coopUtilisateurId
            );
            return { nom: td?.nom || "" };
          },
          updateItem: (dataItem, d) => {
            return {
              ...dataItem,
              nomTechnicien: d.nom,
              coopUtilisateurId: d.utilisateurId,
              codeTechnicien: d.code,
            };
          },
        },
        width: 150,
      },
      {
        title: "Types droits résultats mensuels",
        type: "combobox",
        field: "typeDroitResultatMensuelLib",
        combobox: {
          list: typesDroit,
          textField: "libelle",
          dataItemKey: "id",
          value: (dataItem) => {
            const td = typesDroit.find(
              ({ rang_modaliste }) =>
                rang_modaliste === dataItem.typeDroitResultatMensuel
            );
            return { libelle: td?.libelle };
          },
          updateItem: (dataItem, d) => {
            return {
              ...dataItem,
              typeDroitResultatMensuel: d.rang_modaliste,
              typeDroitResultatMensuelLib: d.libelle,
            };
          },
        },
        width: 100,
      },
      {
        type: "delete",
        width: 20,
        headerClassName: "delete",
      },
    ];
  };

  render() {
    const { edit, valueGetter, onChange, onAddClick } = this.props;
    const { typesDroit, loading } = this.state;
    const techniciens = (valueGetter("techniciens") || []).map((t) => {
      const td = typesDroit.find(
        ({ rang_modaliste }) => rang_modaliste === t.typeDroitResultatMensuel
      );
      return { ...t, typeDroitResultatMensuelLib: td?.libelle || "" };
    });

    return loading === true ? (
      <div className="flex-fill k-text-center k-mt-18">
        <Loader themeColor="primary" />
      </div>
    ) : (
      <div>
        <Button
          className="k-mb-4 k-mt-2"
          icon="add"
          type="button"
          onClick={() => {
            onAddClick();
            onChange("techniciens", {
              value: [...techniciens, EMPTY_TECHNICIEN],
            });
          }}
        >
          Ajouter un technicien
        </Button>
        <CustomGrid
          className="grid-admin"
          columns={this.columns()}
          style={{ maxHeight: "60vh",width: "90vw" }}
          gridName={"gridAdminTech"}
          dataGrid={techniciens}
          canEdit={edit}
          onChange={(value) => {
            onChange("techniciens", { value });
          }}
        />
      </div>
    );
  }
}

Techniciens.propTypes = {
  edit: PropTypes.bool.isRequired,
  valueGetter: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
};

export default Techniciens;
