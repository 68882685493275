import {
    Button,
    ButtonGroup,
    Toolbar,
    ToolbarItem,
    ToolbarSpacer,
  } from "@progress/kendo-react-buttons";
  import { Dialog } from "@progress/kendo-react-dialogs";
  import { Loader } from "@progress/kendo-react-indicators";
  import { useQuery, useQueryClient } from "@tanstack/react-query";
  // https://github.com/eKoopmans/html2pdf.js/issues/570
  import html2pdf from "html2pdf.js/dist/html2pdf.min.js";
  import React, { useState } from "react";
  import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    BarController,
    LineController,
    Colors,
  } from "chart.js";
  import { clamp } from "../../shared/ReportViewer/utils";
  import dataSource from "../../../dataSource/dataSource";
  import { FluxRappDMA } from "./FluxRappDMA";
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineController,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    BarController,
    // @ts-ignore
    Colors
  );
  
  const { PUBLIC_URL } = process.env;
  const ZOOM_STEP = 5;
  
  /**
   * @template T
   * @param {Record<string, T>} o
   * @returns {Record<string,string>}
   */
  function convertPropertiesToString(o) {
    /** @type {Record<string,string>} */
    let result = {};
    Object.keys(o).forEach((key) => {
      result[key] = String(o[key]);
    });
  
    return result;
  }
  

  /**
   * @typedef {Object} ReportParameter paramètre pour l'appel d'API
   * @property {number} domaine
   * @property {number} codeEleveur
   * @property {number} annee
   * @property {number} mois
   */

  /**
   * @typedef {Object} FluxPresentation
   * @property {string} Nom
   * @property {string} Date
   * @property {number} GroupeId
   * @property {string} CodeGroupe
   * @property {string} Periode
   * @property {string} Titre
   * @property {string} Titre1
   * @property {string} Copyright
   * 
   * @typedef {Object} FluxRappDMA
   * @property {string} OrdreMensuel
   * @property {string} Fourrages
   * @property {string} AlimentsSimples
   * @property {string} AlimentsComposes
  */

  /**
  * @typedef {Object} Report
  * @property {Array<FluxPresentation>} FluxPresentation
  * @property {Array<FluxRappDMA>} ListCritRappDMA
  */

  /**
   * @param {ReportParameter} params
   * @returns {Promise<Report>}
   */
  async function fetchReport(params) {
    const stringParameters = convertPropertiesToString(params);
    const urlParams = new URLSearchParams(stringParameters);
    const response = await dataSource.fetchPdfDMA(urlParams);
    
    return response;
  }
  
  /**
   *
   * @param {FluxPresentation | undefined} data {@link FluxPresentation}
   * @param {ReportParameter | undefined} params {@link ReportParameter}
   */
  async function printReport(data, params) {
    let element = document.getElementById("pdf-dma");
    const opt = {
      filename: `Rapport Alimentation ${params?.nomEleveur}.pdf`,
      html2canvas: { scale: 2 },
      jsPDF: { orientation: "portrait" },
    };
  
    html2pdf()
      .from(element)
      .set(opt)
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          pdf.setTextColor(150);
          pdf.text(
            pdf.internal.pageSize.getWidth() - 30,
            pdf.internal.pageSize.getHeight() - 10,
            "Page " + i + "/" + totalPages
          );
          
          pdf.text(
            pdf.internal.pageSize.getWidth() - 30,
            pdf.internal.pageSize.getHeight() - 17,
            "Le " + new Date().toLocaleDateString('fr-FR')
          );


          pdf.text(
            pdf.internal.pageSize.getWidth() / 2 - 20,
            pdf.internal.pageSize.getHeight() - 10,
            data?.Copyright
          );

          const img = new Image();
          img.src = `${PUBLIC_URL}/images/logo_adm.png`;
          pdf.addImage(img, "PNG", 5, pdf.internal.pageSize.getHeight() - 17, 15, 15*557/675);
        }
      })
      .save();
  }
  
  /**
   * @param {ReportParameter} params
   */
  const useReport = (params) => {
    const queryClient = useQueryClient();
    return useQuery(["donneesMensuellesDMA", params], async () => {

      const report = await fetchReport(params);

      queryClient.setQueryData(
        ["Header", params],
        report.FluxPresentation[0]
      );

      queryClient.setQueryData(
        ["donneesMensuellesDMA", "ListCritRappDMA", params],
        report.ListCritRappDMA
      );
      
      return report;
    });
  };
  
  /**
   * ReportViewer est une pop-up pour afficher _et_ télécharger le fichier pdf
   * et le fichier Excel pour un éleveur et un mois défini
   * @typedef {Object} Props
   * @property {string} title titre de la pop-up
   * @property {() => void} onClose callback appelé lors de la fermeture
   * @property {ReportParameter} params paramètre pour l'appel d'API
   * @param {Props} props {@link Props}
   */
  const ReportViewer = ({ title, onClose, params }) => {
    const [zoom, setZoom] = useState(100);
    // TODO gérer les erreurs
    const { data: report, isLoading } = useReport(params);
    const queryClient = useQueryClient();
    const handleOnItemClick = () => {
      const data = /** @type {FluxPresentation | undefined} */ (
        queryClient.getQueryData(["Header", params])
      );
  
      printReport(data, params);
    };
  
    return (
      <Dialog
        title={title}
        onClose={onClose}
        contentStyle={{
          height: "80vh",
          width: "75vw",
          padding: 0,
        }}
      >
        <div
          className="k-d-flex-col"
          style={{ overflow: "auto", height: "100%" }}
        >
          <Toolbar className="k-flex-shrink-0">
            <ToolbarItem>Zoom : {zoom}%</ToolbarItem>
            <ToolbarItem>
              <ButtonGroup>
                <Button
                  icon="zoom-in"
                  title="Zoom +"
                  onClick={() => {
                    setZoom((zoom) => clamp(zoom + ZOOM_STEP, 50, 150));
                  }}
                />
                <Button
                  icon="zoom-out"
                  title="Zoom -"
                  onClick={() => {
                    setZoom((zoom) => clamp(zoom - ZOOM_STEP, 50, 150));
                  }}
                />
                <Button
                  onClick={() => {
                    setZoom(100);
                  }}
                >
                  Ré-initialiser
                </Button>
              </ButtonGroup>
            </ToolbarItem>
            <ToolbarSpacer />
            <ToolbarItem>
              <Button disabled={report === undefined} onClick={handleOnItemClick}>
                Télécharger le PDF
              </Button>
            </ToolbarItem>
          </Toolbar>
          <div className="flex-fill k-overflow-auto k-p-5 bg-white">
            {isLoading && <Loader />}
            {!isLoading && report !== undefined && (
              <div id="pdf-dma"
                className="pdf-dma"
                style={
                  /** @type {React.CSSProperties} */ ({
                    "--zoom": zoom,
                  })
                }
              >
                
                <FluxRappDMA params={params}/>

              </div>
            )}
          </div>
        </div>
      </Dialog>
    );
  };
  
  export default ReportViewer;
  