import { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { withRouter } from "../../utils/withRouter";
import { connect } from "react-redux";
import { DOMAINE_OPTICHEVRE } from "../../redux/reducers/app";

const mapStateToProps = (state) => ({
  domaine: state.app.domaine,
});

class Assistance extends Component {
  render() {
    const OPTICHEVRE_LABEL = process.env.REACT_APP_OPTICHEVRE_LABEL; // BTPL
    const CHEVRYPLAN_LABEL = process.env.REACT_APP_CHEVRYPLAN_LABEL; // ADM
    const OPTICHEVRE_COORDONNEES = process.env.REACT_APP_OPTICHEVRE_COORDONNEES; // BTPL
    const CHEVRYPLAN_COORDONNEES = process.env.REACT_APP_CHEVRYPLAN_COORDONNEES; // ADM
    const CHEVRE_VERSION = process.env.REACT_APP_CHEVRE_VERSION;
    const { domaine } = this.props;

    return (
      <div className="k-m-4">
        <Card className="k-d-flex">
          <CardHeader>
            <h2 style={{ margin: 0 }}>Assistance</h2>
          </CardHeader>
          <CardBody style={{ maxWidth: 800 }}>
            <p>
              Le service assistance {" "}
              {domaine === DOMAINE_OPTICHEVRE
                ? "de "+OPTICHEVRE_LABEL
                : "d'"+CHEVRYPLAN_LABEL}{" "}
              se tient à votre disposition pour vous aider dans
              l&apos;utilisation de cette application.
            </p>
            <Card>
              <CardHeader>
                <CardTitle>Coordonnées</CardTitle>
              </CardHeader>
              <CardBody>
                <p
                  style={{ fontSize: "1em" }}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html:
                      domaine === DOMAINE_OPTICHEVRE
                        ? OPTICHEVRE_COORDONNEES
                        : CHEVRYPLAN_COORDONNEES,
                  }}
                />
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Informations techniques</CardTitle>
              </CardHeader>
              <CardBody>
                <p
                  style={{ fontSize: "1em" }}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: `Version de l'application : ${CHEVRE_VERSION}`,
                  }}
                />
              </CardBody>
            </Card>
          </CardBody>
        </Card>
      </div>
    );
  }
}
export default withRouter(connect(mapStateToProps)(Assistance));
