import { Component, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "../../utils/withRouter";
import { compose } from "redux";
import PropTypes from "prop-types";
import { Form, FormElement } from "@progress/kendo-react-form";
import {
  PanelBar,
  PanelBarItem,
  TabStrip,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import dayjs from "dayjs";
import Contact from "./fiche/elevage/Contact";
import dataSource from "../../dataSource/dataSource";
import toastService from "../../utils/toastService";
import truncTimeZone from "../../utils/dateTools";
import { RULE, LIMIT } from "../shared/constants";

const mapStateToProps = (state) => ({
  utilisateurId: state.auth.utilisateurId,
  domaineId: state.auth.domaineId,
});

// TODO: Ajouter l'affichage conditionnel de la combo des structures en fonction du type de technicien choisi
// TODO: Ajouter le contrôle obligatoire de la structure si la combo est affichée

class SaisieTechnicien extends Component {
  constructor(props) {
    super(props);
    this.formRef = createRef();
    this.state = {
      selectedTab: 0,
      edit: false,
      loading: false,
      //   structure: undefined,
      technicien: undefined,
      newTechnicien: undefined,
    };
  }

  async componentDidMount() {
    const { idTechnicien } = this.props.router.params;
    const { createNew } = this.props;
    this.setState({ loading: true });
    if (createNew) {
      const today = dayjs().format("YYYY-MM-DD");
      this.setState({
        edit: true,
        newTechnicien: {
          contact: {
            dateDebut: today,
          },
        },
        loading: false,
      });
    } else {
      const techniciens = await dataSource.loadAdminTechniciens({
        active: -1,
        utilisateurId: idTechnicien,
      });
      if (Array.isArray(techniciens) && techniciens.length === 1) {
        this.setState({
          technicien: {
            contact: techniciens[0],
          },
          loading: false,
        });
      }
    }
  }

  handleOnClickEdit = () => this.setState((s) => ({ edit: !s.edit }));

  validator = (values, valueGetter) => {
    const { createNew } = this.props;
    const errors = {};
    errors.description = {};
    errors.contact = {};
    if (createNew && !valueGetter("contact.motDePasse")) {
      errors.contact = {
        ...errors.contact,
        tabTitle: "Contact",
        motDePasse: {
          level: "error",
          message: "Saisie obligatoire du mot de passe du contact",
          tabIndex: 0,
        },
      };
    }
    if (!valueGetter("contact.typeUser")) {
      errors.contact = {
        ...errors.contact,
        tabTitle: "Contact",
        typeUser: {
          level: "error",
          message: "Saisie obligatoire du type de technicien",
          tabIndex: 0,
        },
      };
    }
    if (
      valueGetter("contact.typeUser") !== RULE.SUPERVISEUR_DOMAINE &&
      !valueGetter("contact.structures")
    ) {
      errors.contact = {
        ...errors.contact,
        tabTitle: "Contact",
        structures: {
          level: "error",
          message: "Saisie obligatoire de la structure",
          tabIndex: 0,
        },
      };
    }
    if (!valueGetter("contact.nom")) {
      errors.contact = {
        ...errors.contact,
        tabTitle: "Contact",
        nom: {
          level: "error",
          message: "Saisie obligatoire du nom du contact",
          tabIndex: 0,
        },
      };
    }
    if (!valueGetter("contact.identifiant")) {
      errors.contact = {
        ...errors.contact,
        tabTitle: "Contact",
        identifiant: {
          level: "error",
          message: "Saisie obligatoire de l'identifiant du contact",
          tabIndex: 0,
        },
      };
    }
    if (
      !valueGetter("contact.dateDebut") ||
      valueGetter("contact.dateDebut") === "Invalid Date" ||
      (valueGetter("contact.dateDebut") instanceof Date &&
        valueGetter("contact.dateDebut").getFullYear() <
          LIMIT.MIN_KENDO_DATEPICKER_YEAR)
    ) {
      errors.contact = {
        ...errors.contact,
        tabTitle: "Contact",
        dateDebut: {
          level: "error",
          message: "Saisie obligatoire de la date de début du contact",
          tabIndex: 0,
        },
      };
    }
    if (
      valueGetter("contact.dateFin") instanceof Date &&
      valueGetter("contact.dateFin").getFullYear() <
        LIMIT.MIN_KENDO_DATEPICKER_YEAR
    ) {
      errors.contact = {
        ...errors.contact,
        tabTitle: "Contact",
        dateFin: {
          level: "error",
          message: "La date de fin est erronée",
          tabIndex: 0,
        },
      };
    }

    const isFormValid = !Object.values(errors)
      .map((item) => Object.values(item).length)
      .some((item) => item > 0);
    return isFormValid ? {} : errors;
  };

  submitValues = async ({ values, isValid }) => {
    const { createNew } = this.props;
    if (!isValid) return;
    if (createNew) {
      try {
        const newTechnicien = await dataSource.postTechnicien({
          ...values.contact,
          dateDebut: truncTimeZone(values.contact.dateDebut),
          dateFin: truncTimeZone(values.contact.dateFin),
        });
        toastService.addToast({
          id: "da-success",
          type: "success",
          message: "Vos informations ont bien été enregistrées",
        });
        window.location = `${process.env.PUBLIC_URL}/app/administration/techniciens/${newTechnicien.utilisateurId}`;
      } catch (e) {
        toastService.addToast({
          id: "da-error",
          type: "error",
          message: e.message,
        });
      }
    } else {
      try {
        await dataSource.putTechnicien(values.contact.utilisateurId, {
          ...values.contact,
          dateDebut: truncTimeZone(values.contact.dateDebut),
          dateFin: truncTimeZone(values.contact.dateFin),
        });
        toastService.addToast({
          id: "da-success",
          type: "success",
          message: "Vos informations ont bien été mises à jour",
        });
      } catch (e) {
        toastService.addToast({
          id: "da-error",
          type: "error",
          message: e.message,
        });
      }
    }
  };

  render() {
    const { createNew, router } = this.props;
    const {
      selectedTab,
      edit,
      loading,
      technicien,
      newTechnicien,
    } = this.state;
    // TODO à supprimer
    const typeUtilisateurId = 1;
    const err = "Aucun enregistrement trouvé.";
    if (!createNew && technicien === undefined) return <p>{err}</p>;

    return loading || (createNew && newTechnicien === undefined) ? (
      <div className="flex-fill k-text-center k-mt-18">
        <Loader themeColor="primary" />
      </div>
    ) : (
      <Form
        ref={this.formRef}
        initialValues={createNew ? newTechnicien : technicien}
        onSubmitClick={this.submitValues}
        validator={this.validator}
        render={(formProps) => {
          return (
            <div className="k-d-flex k-flex-column k-m-4">
              <FormElement>
                <div className="k-display-flex k-justify-content-between k-align-items-center k-mb-4">
                  <div className="k-d-inline-flex k-align-items-baseline">
                    <h3 className="k-my-0">
                      {createNew ? "Ajout" : "Édition"} d&#39;un technicien
                    </h3>
                  </div>
                </div>
                {Object.keys(formProps.errors).filter(
                  (k) => typeof formProps.errors[k] === "object"
                ).length > 0 && (
                  <div className="row">
                    <div className="panel-error k-my-4">
                      <PanelBar>
                        <PanelBarItem
                          title="Erreurs dans le formulaire"
                          className="k-state-expanded"
                          style={{ width: "100%" }}
                        >
                          {Object.keys(formProps.errors)
                            .filter(
                              (k) => typeof formProps.errors[k] === "object"
                            )
                            .map((k) => formProps.errors[k])
                            .map(({ tabTitle, ...errors }) => {
                              return !!tabTitle &&
                                Object.values(errors).filter(
                                  (error) => !!error.message
                                ).length > 0 ? (
                                <div key={tabTitle}>
                                  <h3 style={{ marginBottom: "0.5em" }}>
                                    {tabTitle}
                                  </h3>
                                  {Object.entries(errors).map(
                                    ([key, { message, lines, tabIndex }]) => (
                                      <div key={key}>
                                        <Button
                                          type="button"
                                          key={key}
                                          className="k-mb-2 button-error"
                                          onClick={() =>
                                            this.setState({
                                              selectedTab:
                                                tabIndex != null ? tabIndex : 0,
                                            })
                                          }
                                        >
                                          {message}{" "}
                                          {lines && lines.length > 0 && (
                                            <span>
                                              (Ligne
                                              {lines.length > 1 && "s"}{" "}
                                              {lines
                                                .map((l) => l + 1)
                                                .join(",")}
                                              )
                                            </span>
                                          )}
                                        </Button>
                                      </div>
                                    )
                                  )}
                                </div>
                              ) : null;
                            })}
                        </PanelBarItem>
                      </PanelBar>
                    </div>
                  </div>
                )}
                <TabStrip
                  selected={selectedTab}
                  onSelect={({ selected }) =>
                    this.setState({
                      selectedTab: selected,
                    })
                  }
                  className="tab-strip-responsive"
                >
                  <TabStripTab title="Contact">
                    <Contact
                      edit={edit}
                      valueGetter={formProps.valueGetter}
                      onChange={formProps.onChange}
                      createNew={createNew}
                      isTechnicien
                      errors={formProps.errors}
                    />
                  </TabStripTab>
                </TabStrip>
              </FormElement>
              <div className="k-display-flex k-justify-content-center sticky-footer">
                {
                  <Button
                    className="k-mr-4"
                    icon="close"
                    title="Fermer"
                    onClick={() => {
                      router.navigate(-1);
                    }}
                  >
                    Fermer
                  </Button>
                }
                {/* eslint-disable-next-line eqeqeq */}
                {(typeUtilisateurId === RULE.SUPERVISEUR_DOMAINE ||
                  typeUtilisateurId === RULE.TECHNICIEN_COOP) &&
                  !edit && (
                    <Button
                      className="k-mr-4"
                      icon={edit ? "undo" : "pencil"}
                      title={edit ? "Visualiser" : "Editer"}
                      onClick={() => {
                        this.handleOnClickEdit();
                      }}
                    >
                      Editer
                    </Button>
                  )}
                {edit && (
                  <>
                    <Button
                      data-cy="submit_form"
                      icon="save"
                      onClick={() => {
                        formProps.onSubmit();
                      }}
                    >
                      Enregistrer les données
                    </Button>
                    <Button
                      className="k-ml-4"
                      icon="reload"
                      onClick={() => window.location.reload()}
                    >
                      Annuler la saisie
                    </Button>
                  </>
                )}
              </div>
            </div>
          );
        }}
      />
    );
  }
}

SaisieTechnicien.propTypes = {
  idTechnicien: PropTypes.string,
  createNew: PropTypes.bool,
  router: PropTypes.object.isRequired,
  //   domaineId: PropTypes.number.isRequired,
};

SaisieTechnicien.defaultProps = {
  idTechnicien: "",
  createNew: false,
};
export default compose(connect(mapStateToProps), withRouter)(SaisieTechnicien);
