import {
  effectifsChevresValidator,
  effectifsGrandesChevrettesValidator,
  effectifsLot1Validator,
  effectifsLot2Validator,
  effectifsLot3Validator,
  effectifsLot4Validator,
  penaliteValidator,
  prixPourMLitresValidator,
  productionValidator,
  quantiteAutresAlimentsValidator,
  quantiteFourrageValidator,
  quantiteLotTariesValidator,
  tbValidator,
  tpValidator,
  troisiemeDecadeValidator,
} from "../../form/validator";

const validateForm = (values) => {
  const errors = {
    tabsBrowsed: {},
    donneesTechniques: {},
    paieDeLait: {},
    alimChevresEffectifs: {},
    alimChevresFourrages: {},
    alimChevresAlimentsSimples: {},
    alimChevresLotTariNombre: {},
    alimPetitesChevrettesFourrages: {},
    alimPetitesChevrettesAlimentsSimples: {},
    alimGrandesChevrettesFourrages: {},
    alimGrandesChevrettesAlimentsSimples: {},
    alimBoucsFourrages: {},
    alimBoucsAlimentsSimples: {},
  };

  const alimentationOk = [
    "alimentationChevresOk",
    "alimentationChevrettesOk",
    "alimentationBoucsOk",
  ];

  const alimentationKo = alimentationOk.filter((item) => values[item] === 0);

  if (alimentationKo.length > 0) {
    errors.tabsBrowsed = {
      ...errors.tabsBrowsed,
      tabTitle: "Onglets non parcourus",
      tabsBrowsedInvalid: {
        message: alimentationKo.map((onglet) => {
          return {
            libelle: onglet.slice(12, -2),
            tabIndex: alimentationOk.indexOf(onglet) + 2,
          };
        }),
      },
    };
  }

  // Validation données techniques
  if (!productionValidator(values)) {
    errors.donneesTechniques = {
      ...errors.donneesTechniques,
      tabTitle: "Données Techniques",
      productionInvalid: {
        tabIndex: 0,
        field: "effectifChevresNombre",
        message:
          "La production a chuté : (lait livré + lait divers + lait consommé) doit être supérieur à (lait livré_M_1 + lait divers_M_1 + lait consommé_M_1) / 2",
      },
    };
  }
  if (!effectifsChevresValidator(values)) {
    errors.donneesTechniques = {
      ...errors.donneesTechniques,
      tabTitle: "Données Techniques",
      effectifChevresNombreInvalid: {
        tabIndex: 0,
        field: "effectifChevresNombre",
        message:
          "Effectif des Chèvres doit être égal à : [Effectif mois M-1] – Vente + Achat – Perte + [Chevrettes MB mois M]",
      },
    };
  }
  if (!effectifsGrandesChevrettesValidator(values)) {
    errors.donneesTechniques = {
      ...errors.donneesTechniques,
      tabTitle: "Données Techniques",
      effectifsGrandesChevrettesNombreInvalid: {
        tabIndex: 0,
        field: "effectifChevrettesGrandesNombre",
        message:
          "Effectif des Grandes Chevrettes doit être égal à : [Effectif Grandes Chevrettes mois M-1] – [Grandes Chevrettes MB mois M]",
      },
    };
  }
  if (!troisiemeDecadeValidator(values)) {
    errors.donneesTechniques = {
      ...errors.donneesTechniques,
      tabTitle: "Données Techniques",
      productionChevresTraitesDecade3Invalid: {
        tabIndex: 0,
        field: "productionChevresTraitesDecade3",
        message:
          "Le nombre de chèvres 3ème Décade doit être inférieur ou égal au nombre de chèvres dernier jour du mois",
      },
    };
  }

  // Validation paie de lait
  values.paieDeLait.forEach((value, index) => {
    if (!prixPourMLitresValidator(value)) {
      errors.paieDeLait = {
        ...errors?.paieDeLait,
        tabTitle: "Paie de Lait",
        prixPourMLitresInvalid: {
          tabIndex: 1,
          lines: [
            ...(errors.paieDeLait?.prixPourMLitresInvalid?.lines || []),
            index,
          ],
          field: "prixPourMLitres",
          message:
            "La valeur saisie pour le champ Prix / 1000 L doit être comprise entre 100 et 4000",
        },
      };
    }
    if (!tbValidator(value.tb)) {
      errors.paieDeLait = {
        ...errors.paieDeLait,
        tabTitle: "Paie de Lait",
        tbInvalid: {
          tabIndex: 1,
          lines: [...(errors.paieDeLait?.tbInvalid?.lines || []), index],
          field: "tb",
          message:
            "La valeur saisie pour le champ TB doit être comprise entre 20 et 60",
        },
      };
    }
    if (!tpValidator(value.tp)) {
      errors.paieDeLait = {
        ...errors.paieDeLait,
        tabTitle: "Paie de Lait",
        tpInvalid: {
          tabIndex: 1,
          lines: [...(errors.paieDeLait?.tpInvalid?.lines || []), index],
          field: "tp",
          message:
            "La valeur saisie pour le champ TP doit être comprise entre 20 et 50",
        },
      };
    }
    if (!penaliteValidator(value.totalPenalites)) {
      errors.paieDeLait = {
        ...errors.paieDeLait,
        tabTitle: "Paie de Lait",
        penaliteInvalid: {
          tabIndex: 1,
          lines: [...(errors.paieDeLait?.penaliteInvalid?.lines || []), index],
          field: "totalPenalites",
          message:
            "La valeur saisie pour le champ Pénalités doit être comprise entre 0 et 150",
        },
      };
    }
  });

  // validation alimentation chèvre effectif
  if (!effectifsLot1Validator(values)) {
    errors.alimChevresEffectifs = {
      ...errors.alimChevresEffectifs,
      tabTitle: "Alim Chèvres Effectifs",
      alimentationChevresLot1NombreInvalid: {
        tabIndex: 2,
        field: "alimentationChevresLot1Nombre",
        message: "L'effectif du lot 1 doit être saisi'",
      },
    };
  }
  if (!effectifsLot2Validator(values)) {
    errors.alimChevresEffectifs = {
      ...errors.alimChevresEffectifs,
      tabTitle: "Alim Chèvres Effectifs",
      alimentationChevresLot2NombreInvalid: {
        tabIndex: 2,
        field: "alimentationChevresLot2Nombre",
        message: "L'effectif du lot 2 doit être saisi'",
      },
    };
  }
  if (!effectifsLot3Validator(values)) {
    errors.alimChevresEffectifs = {
      ...errors.alimChevresEffectifs,
      tabTitle: "Alim Chèvres Effectifs",
      alimentationChevresLot3NombreInvalid: {
        tabIndex: 2,
        field: "alimentationChevresLot3Nombre",
        message: "L'effectif du lot 3 doit être saisi'",
      },
    };
  }
  if (!effectifsLot4Validator(values)) {
    errors.alimChevresEffectifs = {
      ...errors.alimChevresEffectifs,
      tabTitle: "Alim Chèvres Effectifs",
      alimentationChevresLot4NombreInvalid: {
        tabIndex: 2,
        field: "alimentationChevresLot4Nombre",
        message: "L'effectif du lot 4 doit être saisi'",
      },
    };
  }
  if (!quantiteLotTariesValidator(values)) {
    errors.alimChevresEffectifs = {
      ...errors.alimChevresEffectifs,
      tabTitle: "Alim Chèvres Effectifs",
      quantiteLotTariInvalid: {
        tabIndex: 2,
        field: "quantiteLotTari",
        message: "Lot taries doit être >= 0",
      },
    };
  }

  // Validation alimentation chèvre fourrage
  values.alimChevresFourrages.forEach((value, index) => {
    if (!quantiteFourrageValidator(value.quantiteLot1)) {
      errors.alimChevresFourrages = {
        ...errors.alimChevresFourrages,
        tabTitle: "Alim Chèvres Aliments fourrages",
        quantiteLot1Invalid: {
          tabIndex: 2,
          lines: [
            ...(errors.alimChevresFourrages?.quantiteLot1Invalid?.lines || []),
            index,
          ],
          field: "quantiteLot1",
          message: `La valeur saisie pour le champ "Fourrage / Quantité Lot 1" doit être comprise entre 0 et 5`,
        },
      };
    }
    if (!quantiteFourrageValidator(value.quantiteLot2)) {
      errors.alimChevresFourrages = {
        ...errors.alimChevresFourrages,
        tabTitle: "Alim Chèvres Aliments fourrages",
        quantiteLot2Invalid: {
          tabIndex: 2,
          lines: [
            ...(errors.alimChevresFourrages?.quantiteLot2Invalid?.lines || []),
            index,
          ],
          field: "quantiteLot2",
          message: `La valeur saisie pour le champ "Fourrage / Quantité Lot 2" doit être comprise entre 0 et 5`,
        },
      };
    }
    if (!quantiteFourrageValidator(value.quantiteLot3)) {
      errors.alimChevresFourrages = {
        ...errors.alimChevresFourrages,
        tabTitle: "Alim Chèvres Aliments fourrages",
        quantiteLot3Invalid: {
          tabIndex: 2,
          lines: [
            ...(errors.alimChevresFourrages?.quantiteLot3Invalid?.lines || []),
            index,
          ],
          field: "quantiteLot3",
          message: `La valeur saisie pour le champ "Fourrage / Quantité Lot 3" doit être comprise entre 0 et 5`,
        },
      };
    }
    if (!quantiteFourrageValidator(value.quantiteLot4)) {
      errors.alimChevresFourrages = {
        ...errors.alimChevresFourrages,
        tabTitle: "Alim Chèvres Aliments fourrages",
        quantiteLot4Invalid: {
          tabIndex: 2,
          lines: [
            ...(errors.alimChevresFourrages?.quantiteLot4Invalid?.lines || []),
            index,
          ],
          field: "quantiteLot4",
          message: `La valeur saisie pour le champ "Fourrage / Quantité Lot 4" doit être comprise entre 0 et 5`,
        },
      };
    }
  });

  // Validation alimentation chèvre autres aliments
  values.alimChevresAlimentsSimples.forEach((value, index) => {
    if (!quantiteAutresAlimentsValidator(value.quantiteLot1)) {
      errors.alimChevresAlimentsSimples = {
        ...errors.alimChevresAlimentsSimples,
        tabTitle: "Alim Chèvres Aliments simples",
        quantiteLot1Invalid: {
          tabIndex: 2,
          lines: [
            ...(errors.alimChevresAlimentsSimples?.quantiteLot1Invalid?.lines ||
              []),
            index,
          ],
          field: "quantiteLot1",
          message: `La valeur saisie pour le champ "Aliments simples / Quantité Lot 1" doit être comprise entre 0 et 5000`,
        },
      };
    }
    if (!quantiteAutresAlimentsValidator(value.quantiteLot2)) {
      errors.alimChevresAlimentsSimples = {
        ...errors.alimChevresAlimentsSimples,
        tabTitle: "Alim Chèvres Aliments simples",
        quantiteLot2Invalid: {
          tabIndex: 2,
          lines: [
            ...(errors.alimChevresAlimentsSimples?.quantiteLot1Invalid?.lines ||
              []),
            index,
          ],
          field: "quantiteLot2",
          message: `La valeur saisie pour le champ "Aliments simples / Quantité Lot 2" doit être comprise entre 0 et 5000`,
        },
      };
    }
    if (!quantiteAutresAlimentsValidator(value.quantiteLot3)) {
      errors.alimChevresAlimentsSimples = {
        ...errors.alimChevresAlimentsSimples,
        tabTitle: "Alim Chèvres Aliments simples",
        quantiteLot3Invalid: {
          tabIndex: 2,
          lines: [
            ...(errors.alimChevresAlimentsSimples?.quantiteLot1Invalid?.lines ||
              []),
            index,
          ],
          field: "quantiteLot3",
          message: `La valeur saisie pour le champ "Aliments simples / Quantité Lot 3" doit être comprise entre 0 et 5000`,
        },
      };
    }
    if (!quantiteAutresAlimentsValidator(value.quantiteLot4)) {
      errors.alimChevresAlimentsSimples = {
        ...errors.alimChevresAlimentsSimples,
        tabTitle: "Alim Chèvres Aliments simples",
        quantiteLot4Invalid: {
          tabIndex: 2,
          lines: [
            ...(errors.alimChevresAlimentsSimples?.quantiteLot4Invalid?.lines ||
              []),
            index,
          ],
          field: "quantiteLot4",
          message: `La valeur saisie pour le champ "Aliments simples / Quantité Lot 4" doit être comprise entre 0 et 5000`,
        },
      };
    }
  });

  // Validation alimentation petites chevrettes fourrages
  values.alimPetitesChevrettesFourrages.forEach((value, index) => {
    if (!quantiteFourrageValidator(value.quantiteLot1)) {
      errors.alimPetitesChevrettesFourrages = {
        ...errors.alimPetitesChevrettesFourrages,
        tabTitle: "Alim Petites Chèvrettes Aliments fourrages",
        quantiteLot1Invalid: {
          tabIndex: 2,
          lines: [
            ...(errors.alimPetitesChevrettesFourrages?.quantiteLot1Invalid
              ?.lines || []),
            index,
          ],
          field: "quantiteLot1",
          message: `La valeur saisie pour le champ "Fourrage / Qte kg / ani" doit être comprise entre 0 et 5`,
        },
      };
    }
  });

  // Validation alimentation petites chevrettes aliments simples
  values.alimPetitesChevrettesAlimentsSimples.forEach((value, index) => {
    if (!quantiteAutresAlimentsValidator(value.quantiteLot1)) {
      errors.alimPetitesChevrettesAlimentsSimples = {
        ...errors.alimPetitesChevrettesAlimentsSimples,
        tabTitle: "Alim Petites Chèvrettes Aliments simples",
        quantiteLot1Invalid: {
          tabIndex: 3,
          lines: [
            ...(errors.alimPetitesChevrettesAlimentsSimples?.quantiteLot1Invalid
              ?.lines || []),
            index,
          ],
          field: "quantiteLot1",
          message: `La valeur saisie pour le champ "Aliments simples / Qte kg / ani" doit être comprise entre 0 et 5000`,
        },
      };
    }
  });

  // Validation alimentation grandes chevrettes fourrages
  values.alimGrandesChevrettesFourrages.forEach((value, index) => {
    if (!quantiteFourrageValidator(value.quantiteLot1)) {
      errors.alimGrandesChevrettesFourrages = {
        ...errors.alimGrandesChevrettesFourrages,
        tabTitle: "Alim Grandes Chèvrettes Aliments fourrages",
        quantiteLot1Invalid: {
          tabIndex: 3,
          lines: [
            ...(errors.alimGrandesChevrettesFourrages?.quantiteLot1Invalid
              ?.lines || []),
            index,
          ],
          field: "quantiteLot1",
          message: `La valeur saisie pour le champ "Fourrage / Qte kg / ani" doit être comprise entre 0 et 5`,
        },
      };
    }
  });

  // Validation alimentation petites chevrettes aliments simples
  values.alimGrandesChevrettesAlimentsSimples.forEach((value, index) => {
    if (!quantiteAutresAlimentsValidator(value.quantiteLot1)) {
      errors.alimGrandesChevrettesAlimentsSimples = {
        ...errors.alimGrandesChevrettesAlimentsSimples,
        tabTitle: "Alim Grandes Chèvrettes Aliments simples",
        quantiteLot1Invalid: {
          tabIndex: 3,
          lines: [
            ...(errors.alimGrandesChevrettesAlimentsSimples?.quantiteLot1Invalid
              ?.lines || []),
            index,
          ],
          field: "quantiteLot1",
          message: `La valeur saisie pour le champ "Aliments simples / Qte kg / ani" doit être comprise entre 0 et 5000`,
        },
      };
    }
  });

  // Validation alimentation grandes chevrettes fourrages
  values.alimBoucsFourrages.forEach((value, index) => {
    if (!quantiteFourrageValidator(value.quantiteLot1)) {
      errors.alimBoucsFourrages = {
        ...errors.alimBoucsFourrages,
        tabTitle: "Alim Boucs fourrages",
        quantiteLot1Invalid: {
          tabIndex: 3,
          lines: [
            ...(errors.alimBoucsFourrages?.quantiteLot1Invalid?.lines || []),
            index,
          ],
          field: "quantiteLot1",
          message: `La valeur saisie pour le champ "Fourrage / Qte kg / ani" doit être comprise entre 0 et 5`,
        },
      };
    }
  });

  // Validation alimentation petites chevrettes aliments simples
  values.alimBoucsAlimentsSimples.forEach((value, index) => {
    if (!quantiteAutresAlimentsValidator(value.quantiteLot1)) {
      errors.alimBoucsAlimentsSimples = {
        ...errors.alimBoucsAlimentsSimples,
        tabTitle: "Alim Boucs Aliments simples",
        quantiteLot1Invalid: {
          tabIndex: 4,
          lines: [
            ...(errors.alimBoucsAlimentsSimples?.quantiteLot1Invalid?.lines ||
              []),
            index,
          ],
          field: "quantiteLot1",
          message: `La valeur saisie pour le champ "Aliments simples / Qte kg / ani" doit être comprise entre 0 et 5000`,
        },
      };
    }
  });

  // console.warn("Form validation", values, errors);
  return errors;
};

export default validateForm;
