/* eslint-disable react/destructuring-assignment */
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import PropTypes from "prop-types";
import { Component } from "react";
import CustomGrid from "../../shared/CustomGrid";
import {
  CHEVRES_ALIMENTS_SIMPLES,
  CHEVRES_FOURRAGE,
} from "./FicheDonneeMensuelle";

const GRID_FOURRAGE = "GRID_FOURRAGE";
const GRID_ALIMENT = "GRID_ALIMENT";

class AlimChevres extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantiteLot1Width: 0,
      quantiteLot2Width: 0,
      quantiteLot3Width: 0,
      quantiteLot4Width: 0,
      quantiteLotTariWidth: 0,
      deleteWidth: 0,
      currentEditedGrid: undefined,
    };
  }

  componentDidMount() {
    const observer = (fieldObserved) => (entries) => {
      entries
        .filter((entry) => !!entry.borderBoxSize)
        .forEach((entry) => {
          // Chrome & Firefox implements contentBoxSize differently, integrating both
          const borderBoxSize = Array.isArray(entry.borderBoxSize)
            ? entry.borderBoxSize[0]
            : entry.borderBoxSize;
          const containerWidth = borderBoxSize.inlineSize;
          this.setState({
            [fieldObserved]: containerWidth,
          });
        });
    };
    this.resizeObserverLot1 = new ResizeObserver(observer("quantiteLot1Width"));
    this.resizeObserverLot1.observe(
      document.getElementsByClassName("quantiteLot1")[0]
    );
    this.resizeObserverLot2 = new ResizeObserver(observer("quantiteLot2Width"));
    this.resizeObserverLot2.observe(
      document.getElementsByClassName("quantiteLot2")[0]
    );
    this.resizeObserverLot3 = new ResizeObserver(observer("quantiteLot3Width"));
    this.resizeObserverLot3.observe(
      document.getElementsByClassName("quantiteLot3")[0]
    );
    this.resizeObserverLot4 = new ResizeObserver(observer("quantiteLot4Width"));
    this.resizeObserverLot4.observe(
      document.getElementsByClassName("quantiteLot4")[0]
    );
    this.resizeObserverLotTaries = new ResizeObserver(
      observer("quantiteLotTariWidth")
    );
    this.resizeObserverLotTaries.observe(
      document.getElementsByClassName("quantiteLotTari")[0]
    );
    this.resizeObserverDelete = new ResizeObserver(observer("deleteWidth"));
    this.resizeObserverDelete.observe(
      document.getElementsByClassName("delete")[0]
    );
  }

  componentWillUnmount() {
    this.resizeObserverLot1.disconnect();
    this.resizeObserverLot2.disconnect();
    this.resizeObserverLot3.disconnect();
    this.resizeObserverLot4.disconnect();
    this.resizeObserverLotTaries.disconnect();
    this.resizeObserverDelete.disconnect();
  }

  computeColumnsFourrages = (listeAlimentFourrage) => [
    {
      field: "isAVolonte",
      title: "A volonté",
      type: "checkbox",
      width: 110,
      editable: () => this.state.currentEditedGrid === GRID_FOURRAGE,
      afterChange: (editIndex, dataItemToUpdate, dataGrid) =>
        dataGrid.map((dataItem, i) =>
          i !== editIndex
            ? {
                ...dataItem,
                // set others isAVolonte to false if the new line is true
                isAVolonte: !dataItemToUpdate.isAVolonte && dataItem.isAVolonte,
              }
            : dataItemToUpdate
        ),
    },
    {
      field: "nomAliment",
      title: "Nom du fourrage",
      type: "dropdowntree",
      dropdowntree: {
        list: listeAlimentFourrage,
        textField: "text",
        dataItemKey: "groupeAlimentId",
        groupKey: "groupText",
        value: ({ groupeAlimentId, nomAliment, nomCategorieAliment }) =>
          listeAlimentFourrage.find(
            (a) => a.groupeAlimentId === groupeAlimentId
          ) || {
            groupText: nomCategorieAliment,
            groupeAlimentId,
            text: nomAliment,
          },
        updateItem: (
          dataItem,
          { text, groupeAlimentId, msPourcent, prixEuroHT }
        ) => ({
          ...dataItem,
          groupeAlimentId,
          nomAliment: text,
          msPourcent,
          prixAchatEuroHT: prixEuroHT,
        }),
      },
      width: 240,
      editable: () => this.state.currentEditedGrid === GRID_FOURRAGE,
    },
    {
      field: "msPourcent",
      fieldProps: { spinners: false },
      title: "MS %",
      type: "number",
      width: 80,
      format: "n1",
      editable: () => this.state.currentEditedGrid === GRID_FOURRAGE,
    },
    {
      field: "prixAchatEuroHT",
      fieldProps: {
        spinners: false,
      },
      title: "Prix € HT",
      type: "number",
      format: "n0",
      width: 110,
      editable: () => this.state.currentEditedGrid === GRID_FOURRAGE,
    },
    {
      field: "quantiteLot1",
      fieldProps: { spinners: false },
      title: "Qté lot 1",
      type: "number",
      format: "n2",
      width: 110,
      editable: () => {
        const { valueGetter } = this.props;

        return (
          this.state.currentEditedGrid === GRID_FOURRAGE &&
          valueGetter("alimentationChevresLot1Nombre") > 0
        );
      },
      headerClassName: "quantiteLot1",
    },
    {
      field: "quantiteLot2",
      fieldProps: { spinners: false },
      title: "Qté lot 2",
      type: "number",
      format: "n2",
      width: 110,
      editable: () => {
        const { valueGetter } = this.props;
        return (
          this.state.currentEditedGrid === GRID_FOURRAGE &&
          valueGetter("alimentationChevresLot2Nombre") > 0
        );
      },
      headerClassName: "quantiteLot2",
    },
    {
      field: "quantiteLot3",
      fieldProps: { spinners: false },
      title: "Qté lot 3",
      type: "number",
      format: "n2",
      width: 110,
      editable: () => {
        const { valueGetter } = this.props;
        return (
          this.state.currentEditedGrid === GRID_FOURRAGE &&
          valueGetter("alimentationChevresLot3Nombre") > 0
        );
      },
      headerClassName: "quantiteLot3",
    },
    {
      field: "quantiteLot4",
      fieldProps: { spinners: false },
      title: "Qté lot 4",
      type: "number",
      format: "n2",
      width: 110,
      editable: () => {
        const { valueGetter } = this.props;
        return (
          this.state.currentEditedGrid === GRID_FOURRAGE &&
          valueGetter("alimentationChevresLot4Nombre") > 0
        );
      },
      headerClassName: "quantiteLot4",
    },
    {
      field: "quantiteLotTari",
      fieldProps: { spinners: false },
      title: "Qté lot Taries",
      type: "number",
      format: "n2",
      width: 135,
      editable: () => {
        const { valueGetter } = this.props;
        return (
          this.state.currentEditedGrid === GRID_FOURRAGE &&
          valueGetter("effectifChevresNombre") -
            valueGetter("alimentationChevresLot1Nombre") -
            valueGetter("alimentationChevresLot2Nombre") -
            valueGetter("alimentationChevresLot3Nombre") -
            valueGetter("alimentationChevresLot4Nombre") >
            0
        );
      },
      headerClassName: "quantiteLotTari",
    },
    {
      type: "delete",
      width: 40,
      editable: () => this.state.currentEditedGrid === GRID_FOURRAGE,
      headerClassName: "delete",
    },
  ];

  computeColumnsAlimentsSimples = (listeAlimentsSimples) => [
    {
      width: 110,
      editable: () => false,
    },
    {
      field: "nomAliment",
      title: "Nom de l'aliment",
      type: "dropdowntree",
      dropdowntree: {
        list: listeAlimentsSimples,
        textField: "text",
        dataItemKey: "groupeAlimentId",
        groupKey: "groupText",
        canAdd: true,
        onClickAdd: this.props.onAddAliment,
        value: ({ groupeAlimentId, nomAliment, nomCategorieAliment }) =>
          listeAlimentsSimples.find(
            (a) => a.groupeAlimentId === groupeAlimentId
          ) || {
            groupText: nomCategorieAliment,
            groupeAlimentId,
            text: nomAliment,
          },
        updateItem: (
          dataItem,
          { text, groupeAlimentId, msPourcent, prixEuroHT }
        ) => ({
          ...dataItem,
          groupeAlimentId,
          nomAliment: text,
          msPourcent,
          prixAchatEuroHT: prixEuroHT,
        }),
      },
      width: 240,
      editable: () => this.state.currentEditedGrid === GRID_ALIMENT,
    },
    {
      field: "msPourcent",
      fieldProps: { spinners: false },
      title: "MS %",
      type: "number",
      width: 80,
      format: "n1",
      editable: () => this.state.currentEditedGrid === GRID_ALIMENT,
    },
    {
      field: "prixAchatEuroHT",
      fieldProps: { spinners: false },
      title: "Prix € HT",
      type: "number",
      width: 110,
      format: "n0",
      editable: () => this.state.currentEditedGrid === GRID_ALIMENT,
    },
    {
      field: "quantiteLot1",
      fieldProps: {
        spinners: false,
      },
      title: "Qté lot 1",
      type: "number",
      width: 110,
      format: "n0",
      editable: () => {
        const { valueGetter } = this.props;
        return (
          this.state.currentEditedGrid === GRID_ALIMENT &&
          valueGetter("alimentationChevresLot1Nombre") > 0
        );
      },
    },
    {
      field: "quantiteLot2",
      fieldProps: { spinners: false },
      title: "Qté lot 2",
      type: "number",
      width: 110,
      format: "n0",
      editable: () => {
        const { valueGetter } = this.props;
        return (
          this.state.currentEditedGrid === GRID_ALIMENT &&
          valueGetter("alimentationChevresLot2Nombre") > 0
        );
      },
    },
    {
      field: "quantiteLot3",
      fieldProps: { spinners: false },
      title: "Qté lot 3",
      type: "number",
      width: 110,
      format: "n0",
      editable: () => {
        const { valueGetter } = this.props;
        return (
          this.state.currentEditedGrid === GRID_ALIMENT &&
          valueGetter("alimentationChevresLot3Nombre") > 0
        );
      },
    },
    {
      field: "quantiteLot4",
      fieldProps: { spinners: false },
      title: "Qté lot 4",
      type: "number",
      width: 110,
      format: "n0",
      editable: () => {
        const { valueGetter } = this.props;
        return (
          this.state.currentEditedGrid === GRID_ALIMENT &&
          valueGetter("alimentationChevresLot4Nombre") > 0
        );
      },
    },
    {
      field: "quantiteLotTari",
      fieldProps: { spinners: false },
      title: "Qté lot Taries",
      type: "number",
      width: 135,
      format: "n0",
      editable: () => {
        const { valueGetter } = this.props;
        return (
          this.state.currentEditedGrid === GRID_ALIMENT &&
          valueGetter("effectifChevresNombre") -
            valueGetter("alimentationChevresLot1Nombre") -
            valueGetter("alimentationChevresLot2Nombre") -
            valueGetter("alimentationChevresLot3Nombre") -
            valueGetter("alimentationChevresLot4Nombre") >
            0
        );
      },
    },
    {
      type: "delete",
      width: 40,
      editable: () => this.state.currentEditedGrid === GRID_ALIMENT,
    },
  ];

  getColonneWidth = (arrayColumns, columnName) =>
    arrayColumns.find((item) => item.field === columnName).width;

  render() {
    const {
      onChange,
      errors: {
        errorsChevresFourrages,
        errorsChevresAlimentsSimples,
        errorsAlimChevresEffectifs,
      },
      valueGetter,
      canEdit,
      alimentsFourrages,
      alimentsSimples,
    } = this.props;

    const {
      quantiteLot1Width,
      quantiteLot2Width,
      quantiteLot3Width,
      quantiteLot4Width,
      quantiteLotTariWidth,
      deleteWidth,
    } = this.state;

    const totalChevresLot = valueGetter("effectifChevresNombre");
    const fourragesChevres = valueGetter(CHEVRES_FOURRAGE);
    const alimentsSimplesChevres = valueGetter(CHEVRES_ALIMENTS_SIMPLES);
    const columnsFourrages = this.computeColumnsFourrages(alimentsFourrages);
    const columnsAlimentsSimples = this.computeColumnsAlimentsSimples(
      alimentsSimples
    );

    const errorsFourages = Object.entries(errorsChevresFourrages)
      .filter(([key]) => key !== "tabTitle")
      .flatMap(([, error]) =>
        error.lines.map((l) => ({ index: l, field: error.field }))
      );
    const errorsAlimentsSimples = Object.entries(errorsChevresAlimentsSimples)
      .filter(([key]) => key !== "tabTitle")
      .flatMap(([, error]) =>
        error.lines.map((l) => ({ index: l, field: error.field }))
      );
    const valueLotTaries =
      totalChevresLot -
      valueGetter("alimentationChevresLot1Nombre") -
      valueGetter("alimentationChevresLot2Nombre") -
      valueGetter("alimentationChevresLot3Nombre") -
      valueGetter("alimentationChevresLot4Nombre");
    return (
      <div className="k-d-flex k-flex-column k-m-4">
        <div className="k-display-flex k-justify-content-between">
          <h4 className="k-d-flex k-justify-content-between k-flex-1 k-mt-6">
            <span>
              {totalChevresLot} chèvre{totalChevresLot > 1 && "s"}
            </span>
            <span className="k-mr-4">Effectifs par lot</span>
          </h4>

          <div
            className="k-display-flex"
            style={{ marginRight: deleteWidth + 4 }}
          >
            <div
              style={{
                width: `${quantiteLot1Width}px`,
              }}
            >
              <Label className="k-font-weight-bold k-display-block k-text-right">
                Lot 1
              </Label>
              <Input
                value={valueGetter("alimentationChevresLot1Nombre") ?? ""}
                onChange={({ value }) =>
                  onChange("alimentationChevresLot1Nombre", value)
                }
                valid={
                  !errorsAlimChevresEffectifs.alimentationChevresLot1NombreInvalid
                }
                className="k-numerictextbox-align-right"
                name="alimentationChevresLot1Nombre"
                disabled={!canEdit}
              />
            </div>
            <div
              style={{
                width: `${quantiteLot2Width}px`,
              }}
            >
              <Label className="k-font-weight-bold k-display-block k-text-right">
                Lot 2
              </Label>
              <Input
                value={valueGetter("alimentationChevresLot2Nombre") ?? ""}
                onChange={({ value }) =>
                  onChange("alimentationChevresLot2Nombre", value)
                }
                valid={
                  !errorsAlimChevresEffectifs.alimentationChevresLot2NombreInvalid
                }
                className="k-numerictextbox-align-right"
                name="alimentationChevresLot2Nombre"
                disabled={!canEdit}
              />
            </div>
            <div
              style={{
                width: `${quantiteLot3Width}px`,
              }}
            >
              <Label className="k-font-weight-bold k-display-block k-text-right">
                Lot 3
              </Label>
              <Input
                value={valueGetter("alimentationChevresLot3Nombre") ?? ""}
                onChange={({ value }) =>
                  onChange("alimentationChevresLot3Nombre", value)
                }
                valid={
                  !errorsAlimChevresEffectifs.alimentationChevresLot3NombreInvalid
                }
                className="k-numerictextbox-align-right"
                name="alimentationChevresLot3Nombre"
                disabled={!canEdit}
              />
            </div>
            <div
              style={{
                width: `${quantiteLot4Width}px`,
              }}
            >
              <Label className="k-font-weight-bold k-display-block k-text-right">
                Lot 4
              </Label>
              <Input
                value={valueGetter("alimentationChevresLot4Nombre") ?? ""}
                onChange={({ value }) =>
                  onChange("alimentationChevresLot4Nombre", value)
                }
                valid={
                  !errorsAlimChevresEffectifs.alimentationChevresLot4NombreInvalid
                }
                className="k-numerictextbox-align-right"
                name="alimentationChevresLot4Nombre"
                disabled={!canEdit}
              />
            </div>
            <div
              style={{
                width: `${quantiteLotTariWidth}px`,
              }}
            >
              <Label className="k-font-weight-bold k-display-block k-text-right">
                Lot taries
              </Label>
              <Input
                value={valueLotTaries <= 0 ? 0 : valueLotTaries}
                onChange={({ value }) =>
                  onChange("alimentationChevresLotTariNombre", value)
                }
                valid={!errorsAlimChevresEffectifs.quantiteLotTariInvalid}
                id="alimentationChevresLotTariNombre"
                name="alimentationChevresLotTariNombre"
                className="k-numerictextbox-align-right"
                disabled
              />
            </div>
          </div>
        </div>
        <CustomGrid
          title=" Fourrages (Qté en kg brut / Ani. / j) Cocher le fourrage à volonté (un
          seul possible)"
          className="grid-fourrage-chevre k-mb-4"
          dataGrid={fourragesChevres}
          fieldId="dmAlimentationId"
          columns={columnsFourrages}
          onChange={(newData) => onChange(CHEVRES_FOURRAGE, newData)}
          canAdd
          onItemAdd={() =>
            onChange(CHEVRES_FOURRAGE, [
              ...fourragesChevres,
              {
                typeAnimal: 1,
                typeAliment: 1,
                msPourcent: null,
                prixAchatEuroHT: null,
                quantiteLot1: null,
                quantiteLot2: null,
                quantiteLot3: null,
                quantiteLot4: null,
                quantiteLotTari: null,
              },
            ])
          }
          errors={errorsFourages}
          canEdit
          disabled={!canEdit}
          onRowClick={() => {
            this.setState({ currentEditedGrid: GRID_FOURRAGE });
          }}
        />

        <CustomGrid
          title="Aliments simples, composés ou minéraux (Qté en g. / Ani. / j)"
          className="grid-aliment-chevre"
          dataGrid={alimentsSimplesChevres}
          fieldId="dmAlimentationId"
          columns={columnsAlimentsSimples}
          onChange={(newData) =>
            onChange(CHEVRES_ALIMENTS_SIMPLES, [...newData])
          }
          canAdd
          onItemAdd={() =>
            onChange(CHEVRES_ALIMENTS_SIMPLES, [
              ...alimentsSimplesChevres,
              {
                typeAnimal: 1,
                typeAliment: 2,
                msPourcent: null,
                prixAchatEuroHT: null,
                quantiteLot1: null,
                quantiteLot2: null,
                quantiteLot3: null,
                quantiteLot4: null,
                quantiteLotTari: null,
              },
            ])
          }
          errors={errorsAlimentsSimples}
          canEdit
          disabled={!canEdit}
          onRowClick={() => {
            this.setState({ currentEditedGrid: GRID_ALIMENT });
          }}
        />
      </div>
    );
  }
}

AlimChevres.propTypes = {
  valueGetter: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  alimentsSimples: PropTypes.array.isRequired,
  alimentsFourrages: PropTypes.array.isRequired,
  canEdit: PropTypes.bool,
  onAddAliment: PropTypes.func.isRequired,
};

AlimChevres.defaultProps = {
  canEdit: false,
};

export default AlimChevres;
