import { Component } from "react";
import { Field } from "@progress/kendo-react-form";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { Loader } from "@progress/kendo-react-indicators";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-buttons";
import { Upload, UploadOnAddEvent } from "@progress/kendo-react-upload";
import {
  IntlProvider,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-react-intl";
import dataSource from "../../../../dataSource/dataSource";
// https://www.telerik.com/kendo-react-ui/components/upload/globalization/
loadMessages(
  {
    upload: {
      cancel: "Annuler",
      clearSelectedFiles: "Effacer",
      dropFilesHere: "Déposer ici",
      headerStatusUploaded: "Fait",
      headerStatusUploading: "Envoi ...",
      invalidFileExtension: "Type de fichier non autorisé.",
      invalidFiles: "Fichier invalide.",
      invalidMaxFileSize: "Fichier trop grand.",
      invalidMinFileSize: "Fichier trop petit",
      remove: "Enlever",
      retry: "Réessayer",
      select: "Sélectionnez les fichiers ...",
      uploadSelectedFiles: "Envoi",
      total: "Total",
      files: "Fichiers",
    },
  },
  "fr-FR"
);

class Description extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      typesCoop: [],
      typeCoopIdSelected: props.valueGetter("typeCoopId"),
    };
  }

  async componentDidMount() {
    const { onChange } = this.props;
    const { typeCoopIdSelected } = this.state;
    if (typeCoopIdSelected !== undefined) {
      onChange("description.typeCoopId", {
        value: typeCoopIdSelected,
      });
    }
    this.setState({ loading: true });
    const typesCoopAll = await dataSource.loadTypesCoop();
    const typeCoopFound = typesCoopAll.find((t) => {
      return t.typeCoopId === typeCoopIdSelected;
    });
    const isRestricted =
      typeCoopFound?.typeCoopLib === "Domaine" ||
      typeCoopFound?.typeCoopLib === "Tous";
    const typesCoop = typesCoopAll
      .filter((t) => {
        if (isRestricted) {
          return t.typeCoopId === typeCoopIdSelected;
        }
        return t.typeCoopLib !== "Domaine" && t.typeCoopLib !== "Tous";
      })
      .map((t) => {
        return {
          id: t.typeCoopId,
          libelle: t.typeCoopLib,
        };
      });
    if (!isRestricted) {
      typesCoop.unshift({
        id: null,
        libelle: "",
      });
    }
    this.setState({
      loading: false,
      isRestricted,
      typesCoop,
    });
  }

  render() {
    const { loading, typesCoop, typeCoopIdSelected, isRestricted } = this.state;
    const { edit, valueGetter, onChange, errors } = this.props;

    return loading === true ? (
      <div className="flex-fill k-text-center k-mt-18">
        <Loader themeColor="primary" />
      </div>
    ) : (
      <div className="k-d-flex k-flex-column k-m-4">
        <div className="k-d-grid k-grid-cols-2 k-gap-3">
          <div className="k-p-4">
            <div className="grid-wrapper-form-2 k-mb-4">
              <Tooltip>
                <Label>
                  Type de coopérative
                  <span className="required k-ml-2" title="Champ obligatoire">
                    *
                  </span>
                </Label>
              </Tooltip>

              <DropDownList
                id="type_coop"
                disabled={!edit || isRestricted}
                data={typesCoop}
                textField="libelle"
                value={typesCoop.find((t) => t.id === typeCoopIdSelected) || {}}
                onChange={({ value }) => {
                  this.setState({
                    typeCoopIdSelected: value.id,
                  });
                  onChange("description.typeCoopId", {
                    value: value.id,
                  });
                }}
                name="typeCoopId"
                required
                valid={!errors?.description?.typeCoopId}
              />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Tooltip>
                <Label>
                  Structure
                  <span className="required k-ml-2" title="Champ obligatoire">
                    *
                  </span>
                </Label>
              </Tooltip>
              <Input
                data-cy="description.structure"
                value={valueGetter("defaultStructure.coopLibelleCourt")}
                disabled
                name="structure"
              />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Tooltip>
                <Label>
                  Code
                  <span className="required k-ml-2" title="Champ obligatoire">
                    *
                  </span>
                </Label>
              </Tooltip>
              <Input
                data-cy="description.code"
                value={valueGetter("code")}
                onChange={(value) => onChange("code", value)}
                disabled={!edit}
                name="code"
                required
                valid={!errors?.description?.code}
              />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Tooltip>
                <Label>Raison sociale</Label>
              </Tooltip>
              <Input
                data-cy="description.raisonSociale"
                value={valueGetter("raisonSociale")}
                onChange={(value) => onChange("raisonSociale", value)}
                disabled={!edit}
                name="raisonSociale"
                required
                valid={!errors?.description?.raisonSociale}
              />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Label>Libellé court</Label>
              <Field
                data-cy="description.libelleCourt"
                disabled={!edit}
                component={Input}
                name="coopLibelleCourt"
              />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Label>Libellé long</Label>
              <Field
                data-cy="description.libelleLong"
                disabled={!edit}
                component={Input}
                name="coopLibelleLong"
              />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Tooltip>
                <Label>
                  Date début
                  <span className="required k-ml-2" title="Champ obligatoire">
                    *
                  </span>
                </Label>
              </Tooltip>

              <div data-cy="description.datedebut" className="k-d-flex">
                <DatePicker
                  required
                  disabled={!edit}
                  value={
                    valueGetter("dateDebut") &&
                    dayjs(valueGetter("dateDebut")).isValid()
                      ? dayjs(valueGetter("dateDebut")).toDate()
                      : null
                  }
                  onChange={({ value }) =>
                    onChange("dateDebut", {
                      value,
                    })
                  }
                  valid={!errors?.description?.dateDebut}
                />
                <Button
                  type="button"
                  disabled={!edit}
                  icon="reset"
                  title="réinitialiser la date"
                  onClick={() => {
                    onChange("dateDebut", { value: "" });
                  }}
                />
              </div>
            </div>
            <div className="grid-wrapper-form-2 k-mb-4">
              <Label>Date fin</Label>
              <div className="k-d-flex">
                <DatePicker
                  disabled={!edit}
                  value={
                    valueGetter("dateFin") &&
                    dayjs(valueGetter("dateFin")).isValid()
                      ? dayjs(valueGetter("dateFin")).toDate()
                      : null
                  }
                  onChange={({ value }) =>
                    onChange("dateFin", {
                      value,
                    })
                  }
                  valid={!errors?.description?.dateFin}
                />
                <Button
                  type="button"
                  disabled={!edit}
                  icon="reset"
                  title="réinitialiser la date"
                  onClick={() => {
                    onChange("dateFin", { value: "" });
                  }}
                />
              </div>
            </div>
            <div className="grid-wrapper-form-2 k-mb-4 hide-action">
              <Label>Logo 1</Label>
              <img
                alt="logo1"
                src={`data:image/png;base64,${valueGetter("logo1")}`}
                className="img-logo"
              />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4 hide-action">
              <Label>&nbsp;</Label>
              <div className="k-d-flex">
                <LocalizationProvider language="fr-FR">
                  <IntlProvider locale="fr">
                    <Upload
                      id="logo1"
                      batch={false}
                      multiple={false}
                      defaultFiles={[]}
                      withCredentials={false}
                      autoUpload={false}
                      onRemove={() => {
                        onChange("logo1", {
                          value: "",
                        });
                      }}
                      onAdd={(event: UploadOnAddEvent) => {
                        if (
                          Array.isArray(event?.affectedFiles) &&
                          event?.affectedFiles.length > 0
                        ) {
                          const file = event.affectedFiles[0].getRawFile();
                          const reader = new FileReader();
                          reader.onloadend = () => {
                            // Use a regex to remove data url part
                            const base64String = reader.result
                              .replace("data:", "")
                              .replace(/^.+,/, "");
                            onChange("logo1", {
                              value: base64String,
                            });
                          };
                          reader.readAsDataURL(file);
                        }
                      }}
                    />
                  </IntlProvider>
                </LocalizationProvider>
              </div>
            </div>
            <div className="grid-wrapper-form-2 k-mb-4 hide-action">
              <Label>Logo 2</Label>
              <img
                alt="logo2"
                src={`data:image/png;base64,${valueGetter("logo2")}`}
                className="img-logo"
              />
            </div>
            <div className="grid-wrapper-form-2 k-mb-4 hide-action">
              <Label>&nbsp;</Label>
              <div className="k-d-flex">
                <LocalizationProvider language="fr-FR">
                  <IntlProvider locale="fr">
                    <Upload
                      id="logo2"
                      batch={false}
                      multiple={false}
                      defaultFiles={[]}
                      withCredentials={false}
                      autoUpload={false}
                      onRemove={() => {
                        onChange("logo2", {
                          value: "",
                        });
                      }}
                      onAdd={(event: UploadOnAddEvent) => {
                        if (
                          Array.isArray(event?.affectedFiles) &&
                          event?.affectedFiles.length > 0
                        ) {
                          const file = event.affectedFiles[0].getRawFile();
                          const reader = new FileReader();
                          reader.onloadend = () => {
                            // Use a regex to remove data url part
                            const base64String = reader.result
                              .replace("data:", "")
                              .replace(/^.+,/, "");
                            onChange("logo2", {
                              value: base64String,
                            });
                          };
                          reader.readAsDataURL(file);
                        }
                      }}
                    />
                  </IntlProvider>
                </LocalizationProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Description.propTypes = {
  edit: PropTypes.bool.isRequired,
  valueGetter: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

Description.defaultProps = {
  errors: {},
};

export default Description;
