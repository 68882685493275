import { Field } from "@progress/kendo-react-form";
import { NumericTextBox, Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { Component } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { requiredValidator } from "../../form/validator";

class DonneesGenerales extends Component {
  renderMainDŒuvre() {
    const { edit } = this.props;

    return (
      <PanelBar className={`${edit && "panelbar-editable"}`}>
        <PanelBarItem title="MAIN D'OEUVRE" expanded>
          <div className="k-m-4 grid-wrapper-form-2">
            <Tooltip>
              <Label>
                Total UMO Exploitation
                <span className="required k-ml-2" title="Champ obligatoire">
                  *
                </span>
              </Label>
            </Tooltip>

            <Field
              component={NumericTextBox}
              format="n1"
              disabled={!edit}
              name="moTotExpl"
              min={0}
              required
              spinners={edit}
            />
            <Tooltip>
              <Label>
                Main d&#39;œuvre troupeau caprin
                <span className="required k-ml-2" title="Champ obligatoire">
                  *
                </span>
              </Label>
            </Tooltip>
            <Field
              component={NumericTextBox}
              format="n1"
              disabled={!edit}
              name="moTroupCap"
              validator={requiredValidator}
              min={0}
              required
              spinners={edit}
            />

            <Label className="k-ml-4">dont main d&#39;œuvre salariée</Label>
            <Field
              component={NumericTextBox}
              format="n1"
              disabled={!edit}
              min={0}
              name="moSalarie"
              spinners={edit}
            />
          </div>
        </PanelBarItem>
      </PanelBar>
    );
  }

  renderLactationsLongues() {
    const { edit, valueGetter } = this.props;

    return (
      <PanelBar className={`${edit && "panelbar-editable"}`}>
        <PanelBarItem title="LACTATIONS LONGUES" expanded>
          <div className="k-m-4 grid-wrapper-form-2">
            <Label className="k-max-width-2">
              Subies = nombre de chèvres mises à la reproduction, mais vides
            </Label>
            <Field
              component={NumericTextBox}
              format="n0"
              className="k-align-self-start k-ml-2"
              disabled={!edit}
              name="trLacLSub"
              min={0}
              spinners={edit}
            />

            <Label>
              Volontaires = nombre de chèvres non mises à la reproduction
            </Label>
            <Field
              component={NumericTextBox}
              format="n0"
              className="k-align-self-start k-ml-2"
              disabled={!edit}
              name="trLacLVol"
              min={0}
              spinners={edit}
            />

            <Label>Total</Label>
            <Input
              format="n0"
              className="k-align-self-start k-ml-2 k-state-disabled"
              disabled
              value={valueGetter("trLacLSub") + valueGetter("trLacLVol")}
              min={0}
            />
          </div>
        </PanelBarItem>
      </PanelBar>
    );
  }

  renderAutresProduits() {
    const { edit, valueGetter } = this.props;

    return (
      <PanelBar className={`${edit && "panelbar-editable"}`}>
        <PanelBarItem title="AUTRES PRODUITS" expanded>
          <div className="k-m-4 grid-wrapper-form-2">
            <Label>Aide IA (€) </Label>
            <Field
              component={NumericTextBox}
              format="n0"
              disabled={!edit}
              name="prAideIA"
              min={0}
              spinners={edit}
            />

            <Label>Ristourne aliments (€)</Label>
            <Field
              component={NumericTextBox}
              format="n0"
              disabled={!edit}
              name="prRistAlim"
              min={0}
              spinners={edit}
            />

            <Label>Prime caprine (€)</Label>
            <Field
              component={NumericTextBox}
              format="n0"
              disabled={!edit}
              name="prPrimCapr"
              min={0}
              spinners={edit}
            />

            <Label>Autre (€)</Label>
            <Field
              component={NumericTextBox}
              format="n0"
              disabled={!edit}
              name="prAutre"
              min={0}
              spinners={edit}
            />

            <Label className="k-font-weight-bold k-mt-8">Total (€)</Label>
            <Input
              className="k-font-weight-bold k-state-disabled k-mt-8"
              format="n0"
              disabled
              value={
                valueGetter("prAideIA") +
                valueGetter("prRistAlim") +
                valueGetter("prPrimCapr") +
                valueGetter("prAutre")
              }
              min={0}
            />
          </div>
        </PanelBarItem>
      </PanelBar>
    );
  }

  renderAutresCharges() {
    const { edit } = this.props;

    return (
      <PanelBar className={`${edit && "panelbar-editable"}`}>
        <PanelBarItem title="AUTRES CHARGES ET INVENTAIRES" expanded>
          <div className="k-m-4 grid-wrapper-form-2">
            <Label>Autres charges (€) </Label>
            <Field
              component={NumericTextBox}
              format="n0"
              disabled={!edit}
              name="fdAutreCharg"
              min={0}
              spinners={edit}
            />

            <Label>Valeur d&#39;inventaire (€)</Label>
            <Field
              component={NumericTextBox}
              format="n0"
              disabled={!edit}
              name="prInventValeur"
              spinners={edit}
            />
          </div>
        </PanelBarItem>
      </PanelBar>
    );
  }

  renderLaiterie() {
    const { edit } = this.props;

    return (
      <PanelBar className={`${edit && "panelbar-editable"} `}>
        <PanelBarItem title="LAITERIE" expanded>
          <div className="k-m-4 grid-wrapper-form-2">
            <Label>Ristourne laiterie (€)</Label>
            <Field
              component={NumericTextBox}
              format="n0"
              disabled={!edit}
              name="prRistLait"
              min={0}
              spinners={edit}
            />

            <Label>Pénalités dépassement de la référence (€)</Label>
            <Field
              component={NumericTextBox}
              format="n0"
              disabled={!edit}
              name="fdLaitPenalite"
              min={0}
              spinners={edit}
            />
          </div>
        </PanelBarItem>
      </PanelBar>
    );
  }

  render() {
    return (
      <div className="tab-donnees-annuelles">
        <div className="k-d-flex k-flex-column k-m-4">
          <div className="k-d-grid k-grid-cols-3 k-gap-3">
            {this.renderMainDŒuvre()}
            {this.renderLactationsLongues()}
          </div>
        </div>
        <div className="k-d-flex k-flex-column k-m-4">
          <div className="k-d-grid k-grid-cols-3 k-gap-3">
            {this.renderAutresProduits()}
            <div className="k-d-grid k-grid-cols-1 k-gap-3 ">
              {this.renderAutresCharges()}
              {this.renderLaiterie()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DonneesGenerales.propTypes = {
  edit: PropTypes.bool.isRequired,
  valueGetter: PropTypes.func.isRequired,
};
export default DonneesGenerales;
